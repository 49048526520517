import { Box, SxProps } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { FontAPI } from "../../../services/font.services";
import { merge } from "lodash";
import { eventTracker } from "../../../helpers/eventTracker";

export interface ExternalFontNameProps
{
  font: FontAPI;
  sx?: SxProps;
}

export function ExternalFontName( props: ExternalFontNameProps )
{

  useEffect( () =>
  {
    loadExternalFonts();
  }, [] );

  async function loadExternalFonts()
  {
    if ( !!props.font.custom_font_url )
    {
      const urlString = `url(${props.font.custom_font_url})`;
      const newFontFace = new FontFace( props.font.font_family, urlString );
      try
      {
        await newFontFace.load();
        document.fonts.add( newFontFace );
      }
      catch (error)
      {
        eventTracker.logLoadExternalFontError( props.font, error );
      }
    }
  }

  let commonSxProps = {
    height: "100%",
    width: "100%",
    display: "block",
    cursor: "default",
    textAlign: "left",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: props.font.font_family,
  };

  const sxProps = merge( commonSxProps, props.sx )
  return <Box sx={sxProps}>
    {props.font.display_name}
  </Box>
}
