import * as React from 'react';
import { Stack } from "@mui/material";
import "./dateTimePicker.scss";
import { browserUtils } from "../utils/browserUtils";

export interface SocialNetworkAccountsBadBrowserWallProps
{
  children?: React.ReactNode;
}

export function SocialNetworkAccountsBadBrowserWall( props: SocialNetworkAccountsBadBrowserWallProps )
{
  const inInstagramOrFacebookBrowser = browserUtils.isFacebookBrowser() || browserUtils.isInstagramBrowser();

  return <Stack sx={{
    position: "relative"
  }}>
    {props.children}
    {inInstagramOrFacebookBrowser && <Stack sx={{
      position: 'absolute',
      background: "rgba(255,255,255,.5)",
      backdropFilter: "blur(1px)",
      top: 0,
      left: -10,
      right: -10,
      bottom: -10,
    }}/>}
  </Stack>
}