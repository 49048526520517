import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { merge } from "lodash";
import { UserSettingsRowIcon } from "./userSettingsRowIcon";

export interface UserSettingsRowProps
{
  icon?: React.ReactNode;
  label?: string | React.ReactNode;
  onClick?: ( e: React.MouseEvent ) => void;
  sx?: SxProps;
  children?: React.ReactNode;
  href?: string;
}

export function UserSettingsRow( props: UserSettingsRowProps )
{
  function handleClick( e: React.MouseEvent )
  {
    if ( props.onClick )
    {
      props.onClick( e );
    }
    else if ( props.href )
    {
      window.open( props.href, '_blank', 'noopener' );
    }
  }

  return <Stack direction="row"
                onClick={handleClick}
                sx={merge( { cursor: "pointer" }, props.sx )}>
    {props.icon && <UserSettingsRowIcon icon={props.icon}/>}
    {props.label && <Typography>{props.label}</Typography>}
    {props.children}
  </Stack>
}
