import * as React from "react";
import { Stack } from "@mui/material";

export interface PostSuccessSafeAreaProps
{
  children: React.ReactNode;
}

export function PostSuccessSafeArea( props: PostSuccessSafeAreaProps )
{
  const safeAreaBottomInsetForMobileSafari = 150;
  return (
      <Stack sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 12, mx: "auto", height: "100vh", pb: `${safeAreaBottomInsetForMobileSafari}px` }}>
        {props.children}
      </Stack>
  )
}
