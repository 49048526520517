import { EMAIL_SIGN_IN_VERIFY_FAILED, PHONE_NUMBER_SIGN_IN_VERIFY_FAILED, SIGN_IN_PROVIDER_PHONE_NUMBER } from "../constants";

export function getVerificationFailureMessage( signInProvider: string )
{
  if ( signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER )
  {
    return PHONE_NUMBER_SIGN_IN_VERIFY_FAILED;
  }

  return EMAIL_SIGN_IN_VERIFY_FAILED;
}

export function getVerificationFailureMessageForExistingUserChoice( signInProvider: string, inputValue: string)
{
  let providerDisplayName = "email";

  if ( signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER )
  {
    providerDisplayName = "phone number";
  }

  return "Verification failed, please try signing in again with " + providerDisplayName + ": " +  inputValue;
}