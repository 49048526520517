import React from "react";
import ContentLoader from "react-content-loader";

export function PostPreviewPlaceholder( props: any )
{
  return (
    <ContentLoader
      speed={4}
      width="100%"
      height="100%"
      viewBox="0 0 9 16"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="9" height="16"/>
    </ContentLoader>
  );
}