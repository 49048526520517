import { AccordionSummary, Stack } from "@mui/material";
import React, { ReactNode } from "react";
import { PlanIdeaTitleTypography } from "./planIdeaTitleTypography";
import { PlanAccordionHeader } from "./planAccordionHeader";

export interface PlanAccordionSummaryProps
{
  onClick: () => void;
  icon: ReactNode;
  savedPostIdeaTitle: string | undefined | ReactNode;
  accentColor: string;
  helperText?: string;
  expanded: boolean;
  allowedToCollapse: boolean;
  inProgress?: boolean;
  planCTAFooter?: ReactNode;
  hideExpandIcon?: boolean;
  additionalHeaderContent?: ReactNode;
}

export function PlanAccordionSummary( props: PlanAccordionSummaryProps )
{
  return <AccordionSummary
    onClick={props.onClick}
    sx={{
      px: 0,
      "& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded": { mt: 0, mb: "0px" },
      borderRadius: "5px",
      width: "100%",
      cursor: props.allowedToCollapse ? "pointer" : "default !important"
    }}>
    <Stack sx={{ width: "100%", borderRadius: "5px" }}>
      <PlanAccordionHeader icon={props.icon}
                           accentColor={props.accentColor}
                           helperText={props.helperText}
                           inProgress={props.inProgress}
                           expanded={props.expanded}
                           hideExpandIcon={props.hideExpandIcon}>
        {!!props.additionalHeaderContent && props.additionalHeaderContent}
      </PlanAccordionHeader>

      <Stack direction="row" alignItems="flex-start" sx={{ mb: 5, px: 8, textAlign: "left" }}>
        <PlanIdeaTitleTypography>{props.savedPostIdeaTitle}</PlanIdeaTitleTypography>
      </Stack>
      {!props.expanded && props.planCTAFooter}
    </Stack>
  </AccordionSummary>;
}