import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { isUndefined } from "lodash";

export interface PostIdeaState
{
  postIdeas?: { [key: string]: PostIdeaDataAPI };
}

const initialState: PostIdeaState = {
  postIdeas: {},
}

export const postIdeaSlice = createSlice( {
  name: 'postIdea',
  initialState,
  reducers: {
    addPostIdea: ( state, action: PayloadAction<PostIdeaDataAPI> ) =>
    {
      if ( isUndefined( action.payload.id ) )
      {
        return { ...state };
      }

      const prevPostIdeas = state.postIdeas || {};
      const prevPostIdeaMatchingId = prevPostIdeas[action.payload.id] || {};
      return {
        ...state,
        postIdeas: {
          ...prevPostIdeas,
          [action.payload.id]: {
            ...prevPostIdeaMatchingId,
            ...action.payload
          },
        }
      }
    },
    clearPostIdeas: () =>
    {
      return {
        ...initialState
      }
    },
  }
} )

export const getPostIdea = ( state, postIdeaId ) =>
{
  return state.postIdea.postIdeas[postIdeaId];
}

// Action creators are generated for each case reducer function
export const {
  addPostIdea,
  clearPostIdeas,
} = postIdeaSlice.actions

export default postIdeaSlice.reducer
