import React from "react";
import { Box, Stack, ToggleButton, ToggleButtonProps, Typography } from "@mui/material";
import { OutputFormatAPI } from "../../services/outputFormat.services";
import { outputFormatHelper } from "../planner/outputFormatHelper";
import { PlanIdeaTitleTypography } from "../planner/planIdeaTitleTypography";

export interface PlanOutputFormatSelectItemProps extends ToggleButtonProps
{
  outputFormat: OutputFormatAPI;
}

export function PlanOutputFormatSelectItem( props: PlanOutputFormatSelectItemProps )
{
  const { outputFormat, ...toggleButtonProps } = props

  const comingSoonBannerSize = 75;
  const comingSoonBannerOffset = "-25px";
  return <ToggleButton {...toggleButtonProps}>
    <Stack sx={{ textAlign: "center", minHeight: "175px" }}>
      <Stack sx={{ textAlign: "center", justifyContent: "space-between", height: "120px" }}>
        <Stack sx={{ alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
          <Box sx={{ width: "65%" }} component="img"
               src={outputFormatHelper.getIcon( props.outputFormat.slug )}/>
        </Stack>
      </Stack>
      <PlanIdeaTitleTypography sx={{ textTransform: "none", lineHeight: 1.25 }}>{outputFormatHelper.getDisplayName(
        props.outputFormat.slug )}</PlanIdeaTitleTypography>
    </Stack>
    {props.outputFormat.coming_soon && <Stack sx={{
      position: "absolute",
      justifyContent: "flex-end",
      transform: "rotate(-45deg)",
      transformOrigin: "center",
      width: comingSoonBannerSize,
      height: comingSoonBannerSize,
      left: comingSoonBannerOffset,
      top: comingSoonBannerOffset,
      background: "red",
      alignItems: "center"
    }}>
      <Box sx={{ background: "red", p: 2, width: 120, textAlign: "center" }}>
        <Typography variant="subtitle2" sx={{ lineHeight: 1, fontSize: "10px", color: "white" }}>coming soon</Typography>
      </Box>
    </Stack>}
  </ToggleButton>;
}