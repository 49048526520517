import { Box, SxProps } from "@mui/material";
import Logo from "../../assets/alkai-wordmark-logo-white.svg";

export interface AlkaiWordmarkLogoWhiteProps
{
  sx?: SxProps;
}

export function AlkaiWordmarkLogoWhite( props: AlkaiWordmarkLogoWhiteProps )
{
  return (<Box component="img" src={Logo} alt="Alkai" sx={props.sx}/>);
}
