import * as React from "react";
import { alpha, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { isApplicationLoading } from "../loadingIndicator/loadingSlice";

export function ApplicationLevelLoader()
{
  const showApplicationLevelLoader = useSelector( ( state ) => isApplicationLoading( state ) );
  return (
    <>
      {showApplicationLevelLoader && <Box sx={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, background: alpha( "#000000", 0.5 ) }}>
        <CircularProgress sx={{ position: "absolute", top: "calc(50% - 20px)", left: "calc(50% - 20px)" }}/>
      </Box>}
    </>
  )
}