import React from "react";
import { Stack, Switch, Tooltip, Typography } from "@mui/material";
import { AlkaiCaption } from "../typography/alkaiCaption";

interface DetailedToggleProps
{
  disabled: boolean;
  checked: boolean;
  title: string;
  description: string;
  disabledHint: string;
  onChange: ( event: React.ChangeEvent<HTMLInputElement> ) => void;
}

export function DetailedToggle( props: DetailedToggleProps )
{
  return <Stack textAlign="left" width="100%" sx={{mb: 8}}>
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Typography variant="subtitle1" sx={{ lineHeight: 1.5, fontWeight: 500 }}>{props.title}</Typography>
      <Tooltip disableHoverListener={!props.disabled} title={props.disabledHint}
               enterTouchDelay={50}>
              <span>
            <Switch disabled={props.disabled} checked={props.checked}
                    onChange={props.onChange}/>
                </span>
      </Tooltip>
    </Stack>
    <AlkaiCaption sx={{ mb: 4 }}>{props.description}</AlkaiCaption>
  </Stack>;
}