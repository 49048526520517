import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "../../assets/settings.png";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ColorPaletteAPI } from "../../services/colorPalette.services";
import { ColorPalette } from "../editing/colors/colorPalette";

export interface BrandColorPaletteButtonProps
{
  colorPalette?: ColorPaletteAPI;
  handleColorPalettePickerClicked: () => void;
}

export default function BrandColorPaletteButton( props: BrandColorPaletteButtonProps )
{

  return (
    <Stack alignItems="center" sx={{ mb: 4, mr: 5 }}>
      <Box
        sx={{
          minWidth: "90px",
          height: "90px",
          border: "1px solid #9D9D9D",
          borderRadius: "5px",
          p: 4,
          position: "relative",
          cursor: "pointer",
          backgroundColor: "white",
        }}
        onClick={props.handleColorPalettePickerClicked}
      >
        {
          props.colorPalette &&
          <>
            <Stack alignItems="center" justifyContent="center" height="100%" sx={{ pointerEvents: "none" }}>
              <ColorPalette key={props.colorPalette.slug}
                            colorPalette={props.colorPalette}
                            selected={false}/>
            </Stack>
            <IconButton sx={{ position: "absolute", right: 0, top: 0, p: 1, color: "white" }}>
              <Box component="img" src={SettingsIcon} alt="edit colors"/>
            </IconButton>
          </>
        }

        {!props.colorPalette &&
         <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
           <IconButton>
             <AddCircleOutlineIcon sx={{ color: "#BBBBBB" }}/>
           </IconButton>
         </Stack>
        }
      </Box>
      <Typography variant="subtitle2" sx={{ mt: 2 }}>Colors</Typography>
    </Stack>
  );
}