import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getBusinessName } from "../business/businessSlice";
import React from "react";
import { Stack, SxProps, TextField } from "@mui/material";
import { businessServices } from "../../services/business.services";
import { LoadingButton } from "@mui/lab";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";

interface UserSettingsBusinessDetailsProps
{
  sx?: SxProps;
}

export function UserSettingsBusinessDetails( props: UserSettingsBusinessDetailsProps )
{
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState( false );
  const initialBusinessName = useSelector( ( state: RootState ) => getBusinessName( state ) );
  const [businessName, setBusinessName] = React.useState( initialBusinessName || "" );

  function handleNameChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    const newValue = event.target.value;
    setBusinessName( newValue );
  }

  async function saveBusinessNameClicked()
  {
    setLoading( true );

    try
    {
      await businessServices.updateName( businessName );
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "There was an issue updating your business's name, please try again later.", "top" ) ) );
    }

    setLoading( false );
  }

  function shouldDisableSaveButton()
  {
    return !businessName || businessName.length === 0 || businessName === initialBusinessName;
  }

  return (
    <Stack spacing={10} sx={props.sx}>
      <Stack direction="row" spacing={10}>
        <TextField label="Business Name"
                   variant="outlined"
                   sx={{ width: "75%" }}
                   value={businessName}
                   onChange={handleNameChange}
        />
        <LoadingButton variant="contained"
                       color="primary"
                       disabled={shouldDisableSaveButton()}
                       loading={loading}
                       onClick={saveBusinessNameClicked}>Update</LoadingButton>
      </Stack>
    </Stack>
  );
}
