import * as React from 'react';
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export interface DownloadAgainDrawerContentProps
{
  handleDownloadAgainClicked: () => void;
  handleCancelClicked: () => void;
}

export function NativeShareDownloadAgainDrawerContent( props: DownloadAgainDrawerContentProps )
{
  return (
    <Box>
      <Typography sx={{ my: 5, textAlign: "center" }}>Your download is ready!</Typography>
      <Divider sx={{ mb: 15 }}/>
      <Stack direction={"row"} justifyContent={"space-evenly"} sx={{ width: 320, mx: "auto", my: 10 }}>
        <Button startIcon={<HighlightOffIcon/>} variant="outlined" color="negative" onClick={props.handleCancelClicked}>Cancel</Button>
        <Button aria-label="download" variant="contained" color="primary"
                onClick={props.handleDownloadAgainClicked}>Download now</Button>
      </Stack>
    </Box>
  )
}

