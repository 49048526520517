import { Typography } from "@mui/material";
import React, { ReactNode } from "react";

export interface SocialShareStatusBarTypographyProps
{
  children: ReactNode;
}

export function SocialShareStatusBarTypography(props: SocialShareStatusBarTypographyProps)
{
  return(
    <Typography
      sx={{ mx: 1, textAlign: "left", fontSize: "12px", color: "white", fontWeight: "800" }}>{props.children}</Typography>
  )
}