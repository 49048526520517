import * as React from "react";
import { ButtonProps, Stack, Typography } from "@mui/material";
import { ADD_FROM_ANOTHER_SOURCE_BUTTON_TEXT } from "../constants";
import { DottedOutlineButton } from "./dottedOutlineButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export function SelectAnotherSourceButton( props: ButtonProps )
{
  const { ...buttonProps } = props;

  return (
    <DottedOutlineButton {...buttonProps}>
      <Stack alignItems="center">
        <AddCircleOutlineIcon sx={{ height: [45, 64], width: [45, 64], p: 2 }}/>
        <Typography variant="subtitle2"
                    sx={{ lineHeight: 1.1, fontSize: ["14px"], textAlign: "left" }}>{ADD_FROM_ANOTHER_SOURCE_BUTTON_TEXT}</Typography>
      </Stack>
    </DottedOutlineButton>
  )
}
