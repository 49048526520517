import { SxProps, Typography } from "@mui/material";
import React from "react";

export interface DrawerTitleProps
{
  children: React.ReactNode;
  sx?: SxProps;
}

export function DrawerTitle( props: DrawerTitleProps )
{
  return (<Typography variant={"subtitle1"} sx={{ textAlign: "center", p: 2, mt: 2, fontWeight: 600 }}>
    {props.children}
  </Typography>)
}