import { connect } from "react-redux";
import { StockMediaPicker, StockMediaPickerDispatchProps, StockMediaPickerProps } from "./StockMediaPicker";
import { AppDispatch, RootState, store } from "../../app/store";
import {
  changeStockMediaSearchType,
  ExternalMediaData,
  getSearchedStockMedia,
  getSearchedStockMediaNextPage,
  getStockMediaLoading,
  getStockMediaSearchError,
  getStockMediaSearchTerm,
  getStockMediaSearchType,
  getStockMediaTotalMatchCount,
  searchPhotosFailed,
  searchPhotosLoading,
  searchResultsCleared
} from "./stockMediaSlice";
import { STOCK_MEDIA_PEXELS_ATTRIBUTION, STOCK_MEDIA_PEXELS_ATTRIBUTION_LINK, StockMediaSearchType } from "../constants";
import { stockMediaServices } from "../../services/stockMediaServices";
import { first } from "lodash";

export interface StockMediaContainerOwnProps
{
  suggestedSearchTerms?: string[];
  showStockMediaConfirmationDialog( url: ExternalMediaData | undefined ): void;
}

const mapStateToProps = ( storeState: RootState, ownProps: StockMediaContainerOwnProps ): StockMediaPickerProps =>
{
  return {
    searchResults: getSearchedStockMedia( storeState ) || [],
    nextPage: getSearchedStockMediaNextPage( storeState ),
    totalCount: getStockMediaTotalMatchCount( storeState ),
    externalMediaUploadStatus: { TODO: false },
    designSupportsVideo: true,
    hasError: getStockMediaSearchError( storeState ),
    isLoadingSearchResults: getStockMediaLoading( storeState ),
    searchTerm: getStockMediaSearchTerm( storeState ),
    defaultStockMediaSearchTerm: first( ownProps.suggestedSearchTerms ) || "",
    shouldShowDefaultStockMediaSearchResults: true,
    selectedMediaType: getStockMediaSearchType( storeState ),
    stockMediaAttribution: STOCK_MEDIA_PEXELS_ATTRIBUTION,
    stockMediaAttributionLink: STOCK_MEDIA_PEXELS_ATTRIBUTION_LINK,
    ...ownProps,
  };

};
const mapDispatchToProps = ( dispatch: AppDispatch, ownProps: StockMediaContainerOwnProps ): StockMediaPickerDispatchProps =>
{
  return {
    onSearchSubmitted: ( selectedMediaType: StockMediaSearchType, searchTerm: string, isUserInitiatedSearch: boolean ) =>
    {
      store.dispatch( searchResultsCleared() );
      store.dispatch( searchPhotosLoading() );
      stockMediaServices.search( searchTerm, 1, selectedMediaType ).catch(
        ( error ) =>
        {
          store.dispatch( searchPhotosFailed( {} ) );
        }
      );
    },
    onStockMediaClicked: async ( stockMediaData: ExternalMediaData ) =>
    {
      ownProps.showStockMediaConfirmationDialog( stockMediaData );
    },
    loadMore: async ( selectedMediaType: StockMediaSearchType, searchTerm: string, page: number ) =>
    {
      try
      {
        await stockMediaServices.search( searchTerm, page, selectedMediaType );
      }
      catch (error)
      {
        store.dispatch( searchPhotosFailed( {} ) );
      }
    },
    updateSelectedMediaType: ( selectedMediaType: StockMediaSearchType ) =>
    {
      store.dispatch( changeStockMediaSearchType( selectedMediaType ) );
    },
    updateShowDefaultSearchTerm: () =>
    {
    },
  };
};

// const guardForMinMaxStockMediaRules = ( dispatch: Dispatch<StoreState> ) =>
// {
//   return guardForMinMaxMediaRules( dispatch, 1 );
// };
//
// const guardForMinMaxVideoStockMediaRules = ( dispatch: Dispatch<StoreState> ) =>
// {
//   return guardForMinMaxMediaRules( dispatch, 1, 1 );
// };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( StockMediaPicker );
