import React from 'react'
import { clsx } from "clsx";
import "./message.scss";
import { MessageAPI } from "../assistantChat/assistantChatSlice";
import { Box, Stack } from "@mui/material";
import { AlkaiLogo } from "../ui/alkaiLogo";

export interface MessageWithAvatarProps
{
  message: MessageAPI;
  children: React.ReactNode;
  clsx?: {}
}

export function MessageWithAvatar( props: MessageWithAvatarProps )
{
  return (
    <Stack direction="row" sx={{ width: "100%", position: "relative" }}>
      {props.message.showAvatar && <AlkaiLogo size={40} sx={{ position: "absolute", l: 0 }}/>}
      <Box className={clsx( "message", props.clsx )}>
        {props.children}
      </Box>
    </Stack>
  )
}
