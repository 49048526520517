
export const s3UploadServices = {
  uploadFileToS3,
}

export interface S3PresignedPost
{
  key: string;
  success_action_status: string;
  "Content-Type": string;
  acl: string;
  policy: string;
  "x-amz-credential": string;
  "x-amz-algorithm": string;
  "x-amz-date": string;
  "x-amz-signature": string;
}


export interface RequestPresignedUploadAPIResponse
{
  url: string;
  s3_direct_url: string;
  fields: S3PresignedPost;
}


function uploadFileToS3( s3BaseUrl: string, presignedPost: S3PresignedPost, file: Blob ): Promise<any>
{
  const body = new FormData();

  for ( let key in presignedPost )
  {
    body.append( key, presignedPost[key as keyof S3PresignedPost] );
  }

  body.append( "file", file );

  return fetch( s3BaseUrl, {
    method: "POST",
    body,
  } );
}

