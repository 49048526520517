import { apptimizeWrapper } from "./apptimizeWrapper";
import { store } from "../../app/store";
import { getIsInternalUser } from "../user/userSlice";

let injectableApptimizeWrapper: typeof apptimizeWrapper;
export const PRICING_TEST_MAY_2024 = "pricingTestMay2024";
export const THREE_MONTHS_50_OFF_PARAM = "threeMonths50Off";
export const EXCLUDE_RECENTLY_USED_MEDIA = "excludeRecentlyUsedMedia";
export const FIRST_POST_INFORMATIVE = "firstPostInformative";
export const SUPPORTS_OUTPUT_FORMATS = "supportsOutputFormats";
export const USE_VARIANT_OPEN_AI_MODEL = "useVariantOpenAIModel";
export const USE_NEW_PRODUCT_SERVICE_PROMPT = "useNewProductServicePrompt";
export const SOCIAL_PROOF_LOGIN = "socialProofLogin";
export const CORE_ASSISTANT_WITH_FORMATS = "useCoreAssistantWithFormats";

const APPTIMIZE_BOOLEAN_FEATURE_FLAGS: string[] = [EXCLUDE_RECENTLY_USED_MEDIA,
                                                   FIRST_POST_INFORMATIVE,
                                                   USE_VARIANT_OPEN_AI_MODEL,
                                                   USE_NEW_PRODUCT_SERVICE_PROMPT,
                                                   SOCIAL_PROOF_LOGIN,
                                                   CORE_ASSISTANT_WITH_FORMATS];
export const FEATURE_FLAG_SUFFIX = "FeatureFlag";

function getBool( name: string, defaultValue: boolean ): boolean
{
  return getApptimizeWrapper().getBool( name, defaultValue );
}

function getApptimizeVariablesForServerRequestParams()
{
  return {
    exclude_recent_media_feature: isInExcludeRecentlyUsedMediaTest(),
    first_post_informative_feature: isInFirstPostInformativeTest(),
    supports_output_formats_feature: supportsOutputFormats(),
    use_variant_open_ai_model_feature: shouldUseVariantOpenAiModel(),
    use_new_product_service_prompt_feature: shouldUseNewProductServicePrompt(),
    use_core_assistant_with_formats_feature: shouldUseCoreAssistantWithFormats()
  }
}

function isInExcludeRecentlyUsedMediaTest()
{
  return isFeatureFlagOrVariableSet( EXCLUDE_RECENTLY_USED_MEDIA );
}

function isInFirstPostInformativeTest()
{
  return isFeatureFlagOrVariableSet( FIRST_POST_INFORMATIVE );
}

function supportsOutputFormats()
{
  return isFeatureFlagOrVariableSet( SUPPORTS_OUTPUT_FORMATS );
}

function shouldUseVariantOpenAiModel()
{
  return isFeatureFlagOrVariableSet( USE_VARIANT_OPEN_AI_MODEL );
}

function shouldUseNewProductServicePrompt()
{
  return isFeatureFlagOrVariableSet( USE_NEW_PRODUCT_SERVICE_PROMPT );
}

function shouldShowSocialProofLogin()
{
  return isFeatureFlagOrVariableSet( SOCIAL_PROOF_LOGIN );
}

function shouldUseCoreAssistantWithFormats()
{
  return isFeatureFlagOrVariableSet( CORE_ASSISTANT_WITH_FORMATS );
}

function shouldAllowTiktok()
{
  return true;
}

function shouldAllowYoutube()
{
  const state = store.getState();
  return getIsInternalUser( state );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getString( name: string, defaultValue: string ): string
{
  return getApptimizeWrapper().getString( name, defaultValue );
}

function isFeatureEnabled( name: string ): boolean
{
  const theVariableFeatureFlag = name + FEATURE_FLAG_SUFFIX;
  return getApptimizeWrapper().isFeatureFlagEnabled( theVariableFeatureFlag );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function isFeatureFlagOrVariableSet( key )
{
  return isFeatureEnabled( key ) || getBool( key, false );
}

function getApptimizeWrapper()
{
  return injectableApptimizeWrapper ? injectableApptimizeWrapper : apptimizeWrapper;
}

export const apptimizeVariables = {
  APPTIMIZE_BOOLEAN_FEATURE_FLAGS,
  supportsOutputFormats,
  getApptimizeVariablesForServerRequestParams,
  shouldAllowTiktok,
  shouldAllowYoutube,
  shouldShowSocialProofLogin,
};
