import { Box } from "@mui/material";
import { ColorAPI } from "../../../services/colorPalette.services";

import * as React from "react";

export interface EditColorPaletteSwatchButtonProps
{
  colorObject: ColorAPI;
  selected: boolean;
  handleColorSelected: ( colorObject: ColorAPI ) => void;
}

export function EditColorPaletteSwatchButton( props: EditColorPaletteSwatchButtonProps )
{
  const outline = props.selected ? "2px solid" : "1px solid";
  const borderColor = props.selected ? ( theme ) => theme.palette.primary.main : "lightgrey";

  const onClick = () =>
  {
    props.handleColorSelected( props.colorObject );
  }

  return (
    <Box sx={{ height: "30px",
      cursor: "pointer",
      border: outline,
      mb: 4,
      borderColor: borderColor,
      backgroundColor: props.colorObject.hex }} onClick={onClick}/>
  )
}
