export const WAITING_TYPE_CREATING_POST_IDEA = "creating_post_idea"
export const WAITING_TYPE_CREATING_FIRST_POST_IDEA = "creating_first_post_idea"
export const WAITING_TYPE_GETTING_WEBSITE = "getting_website"

const newDraftPostMessages = [
  "🖊️ Writing post text",
  "🍥 Selecting design",
  "🏞️ Finding images",
  "🔤 Selecting fonts",
  "🎨 Selecting colors",
  "✨ Generating post",
  "🤩 Adding branding"
];

const getWebsiteContentsMessages = [
  "📖 Reading website",
  "🖊️ Updating business info",
  "✨ Generating post ideas",
  "🗓️ Building posting plan",
  "🏞️ Finding images",
  "🎨 Locating logo and brand colors"
];

export function getRotatingWaitingMessage( waitingType?: string ): string[]
{
  switch ( waitingType )
  {
    case WAITING_TYPE_GETTING_WEBSITE:
    case WAITING_TYPE_CREATING_FIRST_POST_IDEA:
      return getWebsiteContentsMessages
    case WAITING_TYPE_CREATING_POST_IDEA:
      return newDraftPostMessages
    default:
      return [];
  }
}