import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  YOUTUBE_PRIVACY_OPTIONS_DISPLAY_NAME_MAP,
  YOUTUBE_PRIVACY_PRIVATE,
  YOUTUBE_PRIVACY_PUBLIC,
  YOUTUBE_PRIVACY_UNLISTED,
  YoutubePrivacyOption
} from "../constants";
import { map } from "lodash";

export interface YoutubeSettingsSectionProps
{
  privacy: YoutubePrivacyOption,
  videoTitle: string,
  handlePrivacyChanged: ( privacy: YoutubePrivacyOption ) => void,
  handleTitleChanged: ( title: string ) => void,
}

export function YoutubeSettingsSection( props: YoutubeSettingsSectionProps )
{
  const privacyOptions = [YOUTUBE_PRIVACY_PUBLIC, YOUTUBE_PRIVACY_UNLISTED, YOUTUBE_PRIVACY_PRIVATE];

  function handlePrivacyChanged( event: SelectChangeEvent )
  {
    props.handlePrivacyChanged && props.handlePrivacyChanged( event.target.value as YoutubePrivacyOption )
  }

  function handleTitleChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    props.handleTitleChanged && props.handleTitleChanged( event.target.value );
  }

  function renderPrivacyAndInteractionsSection()
  {
    return <>
      <Typography variant={"caption"} sx={{ alignSelf: "start", color: "black", textAlign: "center", mb: 2 }}>Who can view this post:</Typography>
      <FormControl fullWidth>
        <Select
          sx={{ mb: 6, fontSize: "12px" }}
          inputProps={{ sx: { p: "7px" } }}
          value={props.privacy}
          onChange={handlePrivacyChanged}
        >
          {
            map( privacyOptions, ( option ) =>
            {
              const displayName = YOUTUBE_PRIVACY_OPTIONS_DISPLAY_NAME_MAP[option]
              return <MenuItem key={`youtube-privacy-option-${option}`} value={option} sx={{ py: 0, minHeight: "28px" }}>{displayName}</MenuItem>
            } )
          }
        </Select>
        <Typography variant={"caption"} sx={{ alignSelf: "start", color: "black", textAlign: "center", mb: 2 }}>Video title:</Typography>
        <TextField id="youtube-video-title" variant="standard" onChange={handleTitleChanged} value={props.videoTitle} fullWidth/>
      </FormControl>
    </>;
  }

  return <Box sx={{ backgroundColor: "#FFFFFF", width: '87%', alignSelf: "self-end" }}>
    <Accordion defaultExpanded
               sx={{
                 backgroundColor: "#FFFFFF",
                 '.MuiAccordionSummary-content': { m: 0, py: 0 },
                 '.MuiButtonBase-root.MuiAccordionSummary-root': { minHeight: '36px', maxHeight: '36px' },
                 boxShadow: "none"
               }}>
      <AccordionSummary sx={{ px: 5 }}
                        expandIcon={<ArrowDropDownIcon/>}>
        <Typography variant={"subtitle2"} sx={{ fontSize: "13px", fontWeight: 400 }}>YouTube Sharing Settings</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 5, pb: 5 }}>
        <Stack sx={{ alignItems: "left", '.MuiButtonBase-root.MuiCheckbox-root': { padding: 2 } }}>
          {renderPrivacyAndInteractionsSection()}
        </Stack>
      </AccordionDetails>
    </Accordion>
  </Box>
}