import React, { useRef } from "react";
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { compact, includes, map, remove, uniq } from "lodash";

export interface PostingStrategyQuizSocialChannelsProps
{
  id: string;
  value: string[];
  onQuestionAnswered: ( id: string, value: string[] ) => void;
}

export function PostingStrategyQuizSocialChannels( props: PostingStrategyQuizSocialChannelsProps )
{
  const results = useRef( props.value || [] );
  const DATA_ID_KEY = "data-id";
  const socialChannels = [
    {
      id: "facebook",
      label: "Facebook",
    },
    {
      id: "instagram",
      label: "Instagram",
    },
    {
      id: "tiktok",
      label: "TikTok",
    },
    {
      id: "linkedin",
      label: "LinkedIn",
    },
    {
      id: "youtube",
      label: "YouTube",
    },
    {
      id: "pinterest",
      label: "Pinterest",
    },
    {
      id: "twitter",
      label: "X (formerly Twitter)",
    },
    {
      id: "threads",
      label: "Threads (by Instagram)",
    }
  ];

  function handleChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    const socialChannelId = event.target.getAttribute( DATA_ID_KEY ) as string;
    if ( event.target.checked )
    {
      results.current.push( socialChannelId );
    }
    else
    {
      remove( results.current, ( socialChannelIdentifier ) => socialChannelIdentifier === socialChannelId );
    }

    const newResults = uniq( compact( results.current ) );
    props.onQuestionAnswered( props.id, newResults );
  }

  return (<Stack spacing={10}>
    <Typography variant="subtitle1">Which of these social networks do you post to most often? Check all that apply.</Typography>
    <FormGroup>
      {map( socialChannels, ( channel ) =>
      {
        const control = <Checkbox onChange={handleChange} inputProps={{ 'aria-label': 'controlled', [DATA_ID_KEY]: channel.id } as any}
                                  checked={includes( results.current, channel.id )}/>
        return <FormControlLabel key={channel.id} control={control} label={channel.label}/>
      } )}
    </FormGroup>
  </Stack>);
}