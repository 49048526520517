import { Box } from "@mui/material";
import React from "react";
import { RefreshBusinessAPI } from "../../services/business.services";
import { size } from "lodash";
import { businessHelper } from "../../helpers/businessHelper";

export interface BusinessAvatarProps
{
  business: RefreshBusinessAPI;
}

export function BusinessAvatar( props: BusinessAvatarProps )
{
  const brandStyleLogo = props.business.brand_style?.logo;

  function getBusinessNameFirstLetter()
  {
    const businessNameToDisplay = businessHelper.getBusinessNameToDisplay( props.business );
    return size( businessNameToDisplay ) > 0 ? businessNameToDisplay.charAt( 0 ) : "";
  }

  return (<>
    {brandStyleLogo && <Box component="img" src={brandStyleLogo.poster_url}
                            sx={{ height: "25px", width: "25px", borderRadius: "100%", objectFit: "contain", background: "white" }}/>
    }
    {
      !brandStyleLogo && <Box sx={{
                            border: "1px solid black",
                            height: "25px",
                            width: "25px",
                            borderRadius: "100%",
                            background: "#D9D9D9",
                            textAlign: "center",
                          }}>{getBusinessNameFirstLetter()}</Box>
    }
  </>);
}