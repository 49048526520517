import { Stack, Typography } from "@mui/material";

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CURRENT_FONT_SET_SECTION_HEIGHT_PX } from "../../constants";

export interface AddFontSetButtonProps
{
  handleAddFontSetClicked: () => void;
}

export function AddFontSetButton( props: AddFontSetButtonProps )
{
  return (
    <Stack onClick={props.handleAddFontSetClicked} justifyContent={"center"} alignItems={"center"}
           sx={{ mx: 4, justifyContent: "flex-start", height: CURRENT_FONT_SET_SECTION_HEIGHT_PX, cursor: "pointer" }}>
        <span>
          <IconButton
            color={"primary"}
            sx={{ p: 0 }}
          ><AddCircleIcon sx={{ fontSize: "25px" }}/></IconButton>
        </span>
      <Typography variant={"caption"}>Add Font Set</Typography>
    </Stack>
  )
}
