import { ROUTES } from "../constants";
import { AddBusinessPaymentConfirmationDialog } from "./addBusinessPaymentConfirmationDialog";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { businessServices } from "../../services/business.services";
import { authenticationServices } from "../../services/authentication.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { userHelper } from "../user/userHelper";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { eventTracker } from "../../helpers/eventTracker";
import { size } from "lodash";
import { SelectFromDisabledBusinessesDialog } from "../selectBusinesses/selectFromDisabledBusinessesDialog";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";

export function AddBusinessPurchaseCompletionPage()
{
  const dispatch = useDispatch();
  const [showNewBusinessSuccessDialog, setShowNewBusinessSuccessDialog] = useState( false );
  const [showSelectDisabledBusinessDialog, setShowSelectDisabledBusinessDialog] = useState( false );
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [loading, setLoading] = useState( true );

  useEffect( () =>
  {
    onMount();
  }, [] );

  async function onMount()
  {
    const response = await businessServices.getAddBusinessesEligibility();
    if ( response.can_add_more_businesses && response.upgrade_required )
    {
      reportErrorAndNavigateToSettings();
    }
    else
    {
      const listBusinessAPI = await businessServices.listBusinesses( true );
      const disabledBusinesses = listBusinessAPI.businesses;

      if ( size( disabledBusinesses ) > 0 )
      {
        setLoading( false );
        setShowSelectDisabledBusinessDialog( true );
      }
      else
      {
        await makeNewBusiness();
      }
    }
    setLoading( false );
  }

  async function makeNewBusiness()
  {
    try
    {
      dispatch( setApplicationLoadingState( true ) );
      await createNewBusinessAndRefreshUser()
      eventTracker.logStripeUpgradeComplete();
      setShowNewBusinessSuccessDialog( true );
    }
    catch (e)
    {
      reportErrorAndNavigateToSettings();
    }
    finally
    {
      dispatch( setApplicationLoadingState( false ) );
    }
  }

  async function createNewBusinessAndRefreshUser()
  {
    userHelper.stopInFlightBrowserRequests();
    await businessServices.createNewBusiness();
    await authenticationServices.refreshUser();
  }

  function handleCloseAddBusinessConfirmationDialog()
  {
    navigateWithSearchParams( ROUTES.CHAT, {} );
    setShowNewBusinessSuccessDialog( false );
  }

  async function handleCloseSelectBusinessAndMakeNewBusiness()
  {
    setShowSelectDisabledBusinessDialog( false );
    dispatch( setApplicationLoadingState( true ) );
    await makeNewBusiness();
  }

  function reportErrorAndNavigateToSettings()
  {
    dispatch( setAlertMessage( errorAlert( "We are having a problem processing the request. Please try again." ) ) );
    navigateWithSearchParams( ROUTES.USER_SETTINGS, {} );
  }

  return (<>
    {loading && <ProgressOverlay/>}
    {showNewBusinessSuccessDialog && <AddBusinessPaymentConfirmationDialog manageOpenStateExternally={true}
                                                                           externalStateOpen={showNewBusinessSuccessDialog}
                                                                           handleClose={handleCloseAddBusinessConfirmationDialog}
                                                                           hideTopBar={true}/>}
    {showSelectDisabledBusinessDialog && <SelectFromDisabledBusinessesDialog manageOpenStateExternally={true}
                                                                             externalStateOpen={showSelectDisabledBusinessDialog}
                                                                             hideTopBar={true}
                                                                             handleCloseSelectBusinessAndMakeNewBusiness={handleCloseSelectBusinessAndMakeNewBusiness}/>}
  </>);
}