import React, { useEffect } from 'react'
import {
  businessServices,
  FACEBOOK_INSTAGRAM,
  FACEBOOK_PAGE,
  SocialNetworkAccountAPI,
  SocialNetworkAccountType,
  TIKTOK,
  YOUTUBE
} from "../../services/business.services";
import { SocialMediaAccountRow } from "./SocialMediaAccountRow";
import { Box, Stack, Typography } from "@mui/material";
import { ConnectFacebookPagesButton } from "../buttons/connectFacebookPagesButton";
import { ConnectFacebookInstagramButton } from "../buttons/connectFacebookInstagramButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { CONNECTS_WITH_RIPL_TEXT } from "../constants";
import { SocialNetworkAccountsBadBrowserWall } from "../editing/socialNetworkAccountsBadBrowserWall";
import { currentUserBusinessId } from "../business/businessSlice";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import { apptimizeVariables } from "../apptimize/apptimizeVariables";
import { ExternalConnectionInfoSection } from "../socialMediaAccounts/externalConnectionInfoSection";
import { ConnectOAuthSocialNetworkButton } from "../buttons/connectOAuthSocialNetworkButton";
import { SETTINGS_PAGE_SOURCE } from "../../helpers/trackingConstants";
import { YouTube } from "@mui/icons-material";
import TiktokIcon from "../../assets/TiktokIcon";
import { eventTracker } from "../../helpers/eventTracker";

export function SocialNetworkAccountsSection()
{
  const [socialNetworkAccounts, setSocialNetworkAccounts] = React.useState<SocialNetworkAccountAPI[]>( [] );
  const isShowingConnectButton = socialNetworkAccountHelper.hasYetToConnectBothSocialNetworks( socialNetworkAccounts );

  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );
  const shouldAllowTiktok = apptimizeVariables.shouldAllowTiktok();
  const shouldAllowYoutube = apptimizeVariables.shouldAllowYoutube();
  const [connectingSocialNetworkAccountType, setConnectingSocialNetworkAccountType] = React.useState<SocialNetworkAccountType | undefined>( undefined );
  const facebookInstagramConnectionInProgress = connectingSocialNetworkAccountType === FACEBOOK_INSTAGRAM || connectingSocialNetworkAccountType === FACEBOOK_PAGE;
  const youtubeConnectionInProgress = connectingSocialNetworkAccountType === YOUTUBE;
  const tiktokConnectionInProgress = connectingSocialNetworkAccountType === TIKTOK;
  const oauthConnectionInProgress = tiktokConnectionInProgress || youtubeConnectionInProgress;
  const connecting = facebookInstagramConnectionInProgress || tiktokConnectionInProgress || youtubeConnectionInProgress;
  const [loadingMessage, setLoadingMessage] = React.useState( "" );

  useEffect( () =>
  {
    businessServices.listSocialNetworkAccounts().then( ( response ) =>
    {
      setSocialNetworkAccounts( response.social_network_accounts );
    } )

  }, [] );

  useEffect( () =>
  {
    if ( isCurrentBusinessSet )
    {
      businessServices.listSocialNetworkAccounts().then( ( response ) =>
      {
        setSocialNetworkAccounts( response.social_network_accounts );
      } )
    }
  }, [isCurrentBusinessSet] );

  function getFacebookRow()
  {
    const accountType = FACEBOOK_PAGE;
    const connectedFacebookAccount = socialNetworkAccountHelper.getConnectedFacebookPage( socialNetworkAccounts );
    if ( connectedFacebookAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedFacebookAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    source={SETTINGS_PAGE_SOURCE}
      />
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectFacebookPagesButton source={SETTINGS_PAGE_SOURCE}
                                    handleConnectionStarted={handleFacebookPageConnectionStarted}
                                    handleConnectionSucceeded={handleAccountConnectionFinished}
                                    handleConnectionFailed={handleAccountConnectionFinished}
        />
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function getInstagramRow()
  {
    const accountType = FACEBOOK_INSTAGRAM;
    const connectedInstagramAccount = socialNetworkAccountHelper.getConnectedInstagram( socialNetworkAccounts );
    if ( connectedInstagramAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedInstagramAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    source={SETTINGS_PAGE_SOURCE}/>
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectFacebookInstagramButton source={SETTINGS_PAGE_SOURCE}
                                        handleConnectionStarted={handleFacebookInstagramConnectionStarted}
                                        handleConnectionSucceeded={handleAccountConnectionFinished}
                                        handleConnectionFailed={handleAccountConnectionFinished}/>
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function getTiktokRow()
  {
    const accountType = TIKTOK;
    const connectedTiktokAccount = socialNetworkAccountHelper.getConnectedTiktok( socialNetworkAccounts );
    if ( connectedTiktokAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedTiktokAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    source={SETTINGS_PAGE_SOURCE}
      />
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectOAuthSocialNetworkButton accountType={TIKTOK}
                                         source={SETTINGS_PAGE_SOURCE}
                                         iconType={TiktokIcon}
                                         handleConnectionStarted={handleTikTokAccountConnectionStarted}
                                         handleConnectionSucceeded={handleAccountConnectionFinished}
                                         handleConnectionFailed={handleAccountConnectionFinished}/>
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function getYoutubeRow()
  {
    const accountType = YOUTUBE;
    const connectedYoutubeAccount = socialNetworkAccountHelper.getConnectedYoutube( socialNetworkAccounts );
    if ( connectedYoutubeAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedYoutubeAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    source={SETTINGS_PAGE_SOURCE}/>
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectOAuthSocialNetworkButton accountType={YOUTUBE}
                                         source={SETTINGS_PAGE_SOURCE}
                                         iconType={YouTube}
                                         handleConnectionStarted={handleYoutubeAccountConnectionStarted}
                                         handleConnectionSucceeded={handleAccountConnectionFinished}
                                         handleConnectionFailed={handleAccountConnectionFinished}/>
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function handleTikTokAccountConnectionStarted()
  {
    setConnectingSocialNetworkAccountType( TIKTOK );
    setLoadingMessage( "Connecting TikTok..." );
  }

  function handleFacebookInstagramConnectionStarted()
  {
    setConnectingSocialNetworkAccountType( FACEBOOK_INSTAGRAM );
    setLoadingMessage( "Connecting Instagram..." );
  }

  function handleFacebookPageConnectionStarted()
  {
    setConnectingSocialNetworkAccountType( FACEBOOK_PAGE );
    setLoadingMessage( "Connecting Facebook..." );
  }

  function handleYoutubeAccountConnectionStarted()
  {
    setConnectingSocialNetworkAccountType( YOUTUBE );
    setLoadingMessage( "Connecting YouTube..." );
  }

  function handleUserCancelConnectFromInfoSection()
  {
    const errorText = 'User cancel from progress overlay'
    if ( connectingSocialNetworkAccountType === FACEBOOK_PAGE )
    {
      eventTracker.logFacebookPageConnectFailed( SETTINGS_PAGE_SOURCE, errorText )
    }
    else if ( connectingSocialNetworkAccountType === FACEBOOK_INSTAGRAM )
    {
      eventTracker.logInstagramConnectFailed( SETTINGS_PAGE_SOURCE, errorText )
    }
    else if ( !!connectingSocialNetworkAccountType )
    {
      eventTracker.logSocialNetworkConnectFailed( connectingSocialNetworkAccountType, SETTINGS_PAGE_SOURCE, errorText )
    }
    handleAccountConnectionFinished()
  }

  function handleAccountConnectionFinished()
  {
    setConnectingSocialNetworkAccountType( undefined );
    setLoadingMessage( "" );
  }

  function updateSocialNetworkAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] )
  {
    setSocialNetworkAccounts( socialNetworkAccounts );
  }

  return (
    <>
      <SocialNetworkAccountsContext.Provider value={{ socialNetworkAccounts, updateSocialNetworkAccounts }}>
        {<Stack sx={{ width: "100%", px: 5, py: 5 }}>

          <Stack sx={{ position: "relative", width: "100%" }} spacing={5}>
            {getFacebookRow()}
            {getInstagramRow()}
            {shouldAllowTiktok && getTiktokRow()}
            {shouldAllowYoutube && getYoutubeRow()}
          </Stack>

          {isShowingConnectButton && <SocialNetworkAccountsBadBrowserWall>
            <Box textAlign="left" sx={{ pl: 5 }}>
              <Typography variant={"caption"} sx={{ color: "black", }}>{CONNECTS_WITH_RIPL_TEXT}</Typography>
            </Box>
          </SocialNetworkAccountsBadBrowserWall>}
        </Stack>}
        {connecting && <ExternalConnectionInfoSection loadingMessage={loadingMessage}
                                                      isConnectingFacebookOrInstagram={facebookInstagramConnectionInProgress}
                                                      isConnectingOAuthAccount={oauthConnectionInProgress}
                                                      handleCancelOAuthConnect={handleUserCancelConnectFromInfoSection}/>}
      </SocialNetworkAccountsContext.Provider>
    </>
  )
}

