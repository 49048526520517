import { Box, Stack } from "@mui/material";
import React from "react";
import { PlanIdeaTitleTypography } from "./planIdeaTitleTypography";
import { apptimizeVariables } from "../apptimize/apptimizeVariables";
import { contentGoalHelper } from "./contentGoalHelper";
import { merge } from "lodash";

interface ContentGoalIconAndLabelProps
{
  contentGoal?: string;
}

export function ContentGoalIconAndLabel( props: ContentGoalIconAndLabelProps )
{
  if ( apptimizeVariables.supportsOutputFormats() && !!props.contentGoal && contentGoalHelper.doesClientSupport( props.contentGoal ) )
  {
    return (<Stack direction="row" sx={{ mt: 4, alignItems: "center" }}>
      <Box sx={merge( { mr: 4 }, contentGoalHelper.getSmallSxProps( props.contentGoal ) )} component="img"
           src={contentGoalHelper.getIcon( props.contentGoal )}/>
      <PlanIdeaTitleTypography>{contentGoalHelper.getDisplayName( props.contentGoal )}</PlanIdeaTitleTypography>
    </Stack>);
  }
  return null;
}
