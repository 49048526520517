import * as React from "react";
import { useSelector } from "react-redux";
import { isApptimizeInitialized } from "./apptimizeSlice";
import { RootState } from "../../app/store";
import { Stack, Typography } from "@mui/material";
import { apptimizeVariables, FEATURE_FLAG_SUFFIX } from "./apptimizeVariables";
import Apptimize from "@apptimize/apptimize-web-sdk";
import { map } from "lodash";

export function ApptimizeVariablesLogView()
{
  const apptimizeIntialized = useSelector( ( state: RootState ) => isApptimizeInitialized( state ) );
  return (
    <Stack>
      <Typography variant="subtitle2">Apptimize state log</Typography>
      <Typography variant="caption">apptimizeIntialized: {apptimizeIntialized ? "true" : "false"}</Typography>
      <table style={{ fontSize: "10px" }}>
        <thead>
        <tr>
          <th>Name</th>
          <th>Direct (isFeatureFlagEnabled)</th>
          <th>Direct (getBool)</th>
        </tr>
        </thead>

        <tbody>
        {map( apptimizeVariables.APPTIMIZE_BOOLEAN_FEATURE_FLAGS, ( featureFlag ) =>
        {
          return <tr key={featureFlag}>
            <td>{featureFlag}</td>
            <td>{Apptimize.isFeatureFlagEnabled( featureFlag + FEATURE_FLAG_SUFFIX ) ? "true" : "false"}</td>
            <td>{Apptimize.getBool( featureFlag, false ) ? "true" : "false"}</td>
          </tr>
        } )}
        </tbody>
      </table>
    </Stack>
  )
}
