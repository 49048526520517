import { Box, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

export interface PlanAccordionHeaderProps
{
  icon: React.ReactNode;
  accentColor: string;
  helperText?: string;
  expanded: boolean;
  inProgress?: boolean;
  hideExpandIcon?: boolean;
  children?: React.ReactNode;
}

export function PlanAccordionHeader( props: PlanAccordionHeaderProps )
{
  return <Stack sx={{ mb: 5 }}>
    <Box sx={{ width: "100%", backgroundColor: props.accentColor, borderRadius: "5px 5px 0 0" }}>
      <Stack direction={"row"} gap={"5px"} alignItems={"center"} justifyContent={"end"} sx={{ textAlign: "center", p: 2 }}>
        <Box sx={{ ml: 0, mr: "auto" }}>
          {props.icon}
        </Box>
        <Typography sx={{ textAlign: "right", fontSize: "10px", color: "white", fontWeight: "400" }}>{props.helperText}</Typography>
        {!props.inProgress && !props.hideExpandIcon && <ExpandMoreIcon
          sx={{ color: "white", height: "15px", width: "15px", transform: props.expanded ? "scaley(-1)" : "unset" }}/>}
      </Stack>
    </Box>
    {!!props.children && props.children}
  </Stack>;
}
