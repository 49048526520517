import { Box, Stack } from "@mui/material";

import * as React from "react";
import { FontSetAPI } from "../../../services/fontSet.services";
import { Favorite } from "@mui/icons-material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import { isFontSetFavorited } from "./fontSetHelper";
import EditIcon from "@mui/icons-material/Edit";
import { map } from "lodash";
import { FontAPI } from "../../../services/font.services";
import { FontOption } from "./fontOption";

export interface CurrentFontSetSectionProps
{
  fontSet: FontSetAPI;
  startEditingFont: ( fontSet: FontSetAPI, font: FontAPI ) => void;
  favoriteFontSet: ( fontSet: FontSetAPI ) => void;
  unfavoriteFontSet: ( fontSet: FontSetAPI ) => void;
}

export function CurrentFontSetSection( props: CurrentFontSetSectionProps )
{
  const handleEditButtonClick = ( font: FontAPI ) =>
  {
    props.startEditingFont( props.fontSet, font );
  }

  const handleFavoriteButtonClick = ( event: React.MouseEvent ) =>
  {
    if ( isFontSetFavorited( props.fontSet ) )
    {
      props.unfavoriteFontSet( props.fontSet );
    }
    else
    {
      props.favoriteFontSet( props.fontSet );
    }
  }

  return (
    <Box sx={{
      height: "auto",
      display: "inline-block",
      textAlign: "center",
      position: "relative",
    }}>
      <Stack>
        {map( props.fontSet.fonts, ( font, index ) =>
        {
          return <Stack key={font.slug + index} direction={"row"}>
            <FontOption font={font} selected={false} handleSelectFont={() =>
            {
            }} sx={{ width: "100%", mr: 6 }}/>
            <IconButton onClick={() =>
            {
              handleEditButtonClick( font )
            }} sx={{ p: 2, color: "black" }}><EditIcon/></IconButton>
          </Stack>
        } )}
      </Stack>

      <Box sx={{
        position: "absolute",
        height: "100%",
        top: 0,
        left: "calc(100% + 10px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>

        <IconButton onClick={handleFavoriteButtonClick}
                    sx={{ p: 2, color: isFontSetFavorited( props.fontSet ) ? "red" : "black" }}>
          {isFontSetFavorited( props.fontSet ) ? <Favorite/> : <FavoriteBorderIcon/>}
        </IconButton>
      </Box>
    </Box>
  );
}
