import React from "react";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getBusiness } from "../business/businessSlice";
import { isEmpty, map } from "lodash";
import { businessServices, RefreshBusinessAPI } from "../../services/business.services";
import { BusinessSwitcherRow } from "./businessSwitcherRow";
import { userHelper } from "../user/userHelper";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const BUSINESS_SWITCHER_TEXT_COLOR_CLASS_NAME = "businessSwitcherTextColor";

export function BusinessSwitcher()
{
  const dispatch = useDispatch();
  const currentBusiness = useSelector( ( state: RootState ) => getBusiness( state ) );
  const [openBusinessSwitcher, setOpenBusinessSwitcher] = React.useState( false );
  const [loadingBusinessList, setLoadingBusinessList] = React.useState( false );
  const [businesses, setBusinesses] = React.useState<RefreshBusinessAPI[]>( [] );

  async function handleSwitchBusiness( event: SelectChangeEvent )
  {
    const newBusinessSlug = event.target.value;

    if ( newBusinessSlug !== currentBusiness.slug )
    {
      await userHelper.switchBusinesses( newBusinessSlug, dispatch );
    }
    setOpenBusinessSwitcher( false );
  }

  async function toggleSelectOpen()
  {
    if ( !openBusinessSwitcher )
    {
      setLoadingBusinessList( true );
      const businessesResponse = await businessServices.listBusinesses();
      setBusinesses( businessesResponse.businesses );
      setLoadingBusinessList( false );
    }
    setOpenBusinessSwitcher( !openBusinessSwitcher );
  }

  return <Box sx={{ background: ( theme ) => theme.palette.primary.main }}>
    <Select
      sx={{ width: "100%", background: ( theme ) => theme.palette.primary.main, '& .MuiSvgIcon-root': { color: "white" } }}
      inputProps={{ sx: { p: "7px" } }}
      value={currentBusiness.slug || ""}
      onClick={toggleSelectOpen}
      open={openBusinessSwitcher}
      onChange={handleSwitchBusiness}
      IconComponent={() =>
      {
        if ( loadingBusinessList )
        {
          return <Box sx={{width: 20, height: 20, mr: 4}}>
            <CircularProgress sx={{ color: "white"}} size={"20px"}/>
          </Box>;
        }
        else if ( openBusinessSwitcher )
        {
          return <ArrowDropUpIcon/>;
        }
        else
        {
          return <ArrowDropDownIcon/>;
        }
      }}
      renderValue={( businessSlug ) => <BusinessSwitcherRow selected={true} business={currentBusiness}/>}
    >
      {
        !isEmpty( businesses ) &&
        map( businesses, ( business ) =>
        {
          const backgroundColor = business.slug === currentBusiness.slug ? ( theme ) => theme.palette.primary.main : "white";
          return <MenuItem key={business.slug}
                           value={business.slug}
                           sx={{
                             "&.Mui-selected": { background: backgroundColor },
                             [`&:hover .${BUSINESS_SWITCHER_TEXT_COLOR_CLASS_NAME}`]: { color: "black" }
                           }}><BusinessSwitcherRow
            selected={business.slug === currentBusiness.slug} business={business}/></MenuItem>;
        } )
      }
      {
        isEmpty( businesses ) && <MenuItem key={currentBusiness.slug}
                                           value={currentBusiness.slug}
                                           sx={{ "&.Mui-selected": { background: ( theme ) => theme.palette.primary.main } }}><BusinessSwitcherRow
                                selected={true} business={currentBusiness}/></MenuItem>
      }
    </Select>
  </Box>;

}