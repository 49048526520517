import { Box, Stack } from "@mui/material";
import React from "react";
import { outputFormatHelper } from "./outputFormatHelper";
import { PlanIdeaTitleTypography } from "./planIdeaTitleTypography";
import { merge } from "lodash";
import { apptimizeVariables } from "../apptimize/apptimizeVariables";

interface OutputFormatIconAndLabelProps
{
  outputFormatSlug?: string;
}

export function OutputFormatIconAndLabel( props: OutputFormatIconAndLabelProps )
{
  if ( apptimizeVariables.supportsOutputFormats() && !!props.outputFormatSlug && outputFormatHelper.doesClientSupport( props.outputFormatSlug ) )
  {
    return (<Stack direction="row" sx={{ mt: 4, alignItems: "center" }}>
      <Box sx={merge( { mr: 4 }, outputFormatHelper.getSmallSxProps( props.outputFormatSlug ) )} component="img"
           src={outputFormatHelper.getIcon( props.outputFormatSlug )}/>
      <PlanIdeaTitleTypography>{outputFormatHelper.getDisplayName( props.outputFormatSlug )}</PlanIdeaTitleTypography>
    </Stack>);
  }
  return null;
}
