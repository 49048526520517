import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";
import { BOTTOM_NAV_EDIT_DRAWER_HEIGHT_PX } from "../../constants";
import { merge } from "lodash";

export interface PostEditingDrawerContainerProps
{
  children: ReactNode;
  sx?: SxProps;
}

export function PostEditingDrawerContainer( props: PostEditingDrawerContainerProps )
{
  return (
    <Box sx={merge( { height: BOTTOM_NAV_EDIT_DRAWER_HEIGHT_PX, width: "100%" }, props.sx )}>
      {props.children}
    </Box>
  );
}
