import * as React from "react";
import { Stack } from "@mui/material";

export interface PostSuccessFlexContentWrapProps
{
  children: React.ReactNode;
}

export function PostSuccessFlexContentWrap( props: PostSuccessFlexContentWrapProps )
{
  return (
    <Stack sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center", maxHeight: "500px" }}>
      {props.children}
    </Stack>
  )
}
