import { SxProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { merge } from "lodash";

export interface PlanModelBodyTextProps
{
  sx?: SxProps;
  children: ReactNode;
}

export function PlanModalBodyText( props: PlanModelBodyTextProps )
{
  const defaultSxProps = { fontSize: "14px", fontWeight: 400, lineHeight: "17px",margin: "auto", mb: 8, textWrap: "balance", textAlign: "center"};
  const mergedSxProps = merge( defaultSxProps, props.sx );
  return (<Typography variant="subtitle2" sx={mergedSxProps}>
    {props.children}
  </Typography>);
}

