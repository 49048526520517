import React from "react";
import { ConfigurationAPI, PostIdeaDataAPI, PostIdeaFormulaAPI } from "../assistantChat/assistantChatSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getPostForPostIdea } from "./postsSlice";
import { Box, Button, Container, Drawer, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { get, map } from "lodash";
import { getToApi } from "../../services/requestManager";
import Dialog from "@mui/material/Dialog";

interface DevModePostIdeaContentProps
{
  drawerOpened: boolean,
  postIdea: PostIdeaDataAPI,
  showSafeArea: boolean,
  showInstagramOverlay: boolean,
  closeDrawer: () => void,
  handleShowInstagramOverlay( event: React.MouseEvent<HTMLElement>,
                              newValue: boolean );
  handleShowSafeAreaChange( event: React.MouseEvent<HTMLElement>,
                            newValue: boolean );
}

export function DevModePostIdeaContent( props: DevModePostIdeaContentProps )
{
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, props.postIdea.id ) );
  const [detailsOpened, setDetailsOpened] = React.useState( false );

  function getPostStyle()
  {
    if ( !post )
    {
      return <></>
    }
    else
    {
      let globalSettingsData = JSON.parse( post.global_settings_data );
      let postStyle = globalSettingsData["postStyle"];
      return <p><b>Preset name: </b>{postStyle}</p>;
    }
  }

  function getTextStyles()
  {
    if ( !post )
    {
      return <></>
    }
    else
    {
      let globalSettingsData = JSON.parse( post.global_settings_data );
      let extraCaptionStore = globalSettingsData["extraCaptionStore"];
      let renderStyles = "";
      map( extraCaptionStore, ( extraCaption ) =>
      {
        if ( !!extraCaption )
        {
          renderStyles += extraCaption["renderStyle"] + " ";
        }
      } );
      return renderStyles;
    }
  }

  function getFonts()
  {
    if ( !post )
    {
      return <></>
    }
    else
    {
      let globalSettingsData = JSON.parse( post.global_settings_data );
      let extraCaptionStore = globalSettingsData["extraCaptionStore"];
      let fonts = "";
      map( extraCaptionStore, ( extraCaption ) =>
      {
        const captionFont = get( extraCaption, "configurableSettings.textStyle.fontFamily" );
        if ( !!captionFont )
        {
          fonts += captionFont + " ";
        }
      } );
      return fonts;
    }
  }

  function getPostIdeaId()
  {
    if ( !props.postIdea )
    {
      return <></>
    }
    else
    {
      return <p><b>id: </b><a target="_blank" href={props.postIdea.url} onClick={openPostJson} rel="noreferrer">{props.postIdea.id}</a></p>;
    }
  }

  function openPostJson( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> )
  {
    e.preventDefault();
    getToApi( "posts/" + props.postIdea.id + ".json" ).then( ( response ) =>
    {
      const str = JSON.stringify( response );
      const bytes = new TextEncoder().encode( str );
      const blob = new Blob( [bytes], {
        type: "application/json;charset=utf-8"
      } );

      const url = createObjectUrl( blob )
      if ( window && url )
      {
        // @ts-ignore
        let newWindow = window.open( url, "_blank" );
        if ( newWindow )
        {
          newWindow.focus();
        }
      }
    } );
  }

  function createObjectUrl( blob: Blob )
  {
    if ( window.webkitURL )
    {
      return window.webkitURL.createObjectURL( blob );
    }
    else if ( window.URL && window.URL.createObjectURL )
    {
      return window.URL.createObjectURL( blob );
    }
    else
    {
      return null;
    }
  }

  function getConfiguration( configuration: ConfigurationAPI )
  {
    if ( configuration )
    {
      return (
        <>
          <b>configuration: </b>
          <div>{getSelectedSearchTerm( configuration.search_terms )}</div>
          <pre className={"configuration"}>{JSON.stringify( configuration, null, 2 )}</pre>
        </>
      );
    }
    return <div/>;
  }

  function getPostIdeaFormula( postIdeaFormula: PostIdeaFormulaAPI )
  {
    if ( postIdeaFormula )
    {
      return (
        <>
          <b>post idea formula: </b>
          <pre className={"configuration"}>{JSON.stringify( postIdeaFormula, null, 2 )}</pre>
        </>
      );
    }
    return <div/>;
  }

  function getSelectedSearchTerm( searchTerm: any )
  {
    if ( searchTerm && searchTerm.length > 0 )
    {
      const selectedSearchTerm = searchTerm[0]['selected_search_term'];
      return getStockSearchTermDisplay( selectedSearchTerm );
    }

    return <></>;
  }

  function getStockSearchTermDisplay( stockMediaSearchTerm: string )
  {
    if ( stockMediaSearchTerm )
    {
      const pexelsSearchUrl = "https://www.pexels.com/search/" + encodeURIComponent( stockMediaSearchTerm ) + "/?orientation=portrait"
      return (
        <div className={"stockTerm"}>Stock search term:
          <a target="_blank" href={pexelsSearchUrl} rel="noreferrer">{stockMediaSearchTerm}</a>
        </div>
      );
    }
    return <div/>;
  }

  const openDetails = () =>
  {
    setDetailsOpened( true );
  }

  const closeDetails = () =>
  {
    setDetailsOpened( false );
  }

  return (
    <Drawer
      anchor={"bottom"}
      open={props.drawerOpened}
      onClose={props.closeDrawer}
      sx={{ textAlign: 'center', m: 1, zIndex: 1500 }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", margin: "0 auto", width: "300px", justifyContent: 'center' }}>
        <ToggleButtonGroup
          value={props.showSafeArea}
          exclusive
          onChange={props.handleShowSafeAreaChange}
          aria-label="text alignment"
        >
          <ToggleButton value="false">Safe Area</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          value={props.showInstagramOverlay}
          exclusive
          onChange={props.handleShowInstagramOverlay}
          aria-label="text alignment"
        >
          <ToggleButton value="false">IG Reels UI</ToggleButton>
        </ToggleButtonGroup>
        <Stack sx={{ mt: 5, height: "fit-content" }}>
        </Stack>
        <Button onClick={openDetails}>Open Details</Button>
        <Dialog
          fullScreen
          open={detailsOpened}
          sx={{ zIndex: 1500 }}
        >
          <Container maxWidth="xs" sx={{ p: 10 }}>
            <Button onClick={closeDetails}>Close</Button>
            {post && getPostStyle()}
            {getPostIdeaId()}
            <p className={"stockTerm"}>Text styles: {getTextStyles()}</p>
            <p className={"stockTerm"}>Fonts: {getFonts()}</p>
            <p><b>headline:</b> {props.postIdea.headline}</p>
            <div className={"stockTerm"}>{getStockSearchTermDisplay( props.postIdea.headline_search_term )}</div>
            <p><b>caption:</b> {props.postIdea.caption}</p>
            <div>{getPostIdeaFormula( props.postIdea.post_idea_formula )}</div>
            <div>{getConfiguration( props.postIdea.configuration )}</div>
          </Container>
        </Dialog>
      </Box>
    </Drawer>
  );
}
