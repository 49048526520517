import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebookServices } from "../../services/facebook.services";
import { getFacebookAppId } from '../../constants';
import { businessServices, SocialNetworkAccountAPI } from "../../services/business.services";
import { logToConsole, logToConsoleError } from "../utils/devLoggingHelper";
import { eventTracker } from "../../helpers/eventTracker";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { RefreshSocialNetworkButton } from "./refreshSocialNetworkButton";
import { SocialNetworkConnectionSourcePageType } from "../../helpers/trackingConstants";

interface ReactFacebookRenderProps
{
  isSdkLoaded?: boolean;
  isProcessing?: boolean;
  isDisabled?: boolean;
  onClick( event: React.MouseEvent ): void;
}

interface RefreshFacebookButtonProps
{
  socialNetworkAccount: SocialNetworkAccountAPI;
  handleRefreshComplete?( succeeded: boolean ): void;
  source: SocialNetworkConnectionSourcePageType
  iconOnly?: boolean;
}

export function RefreshFacebookButton( props: RefreshFacebookButtonProps )
{
  const dispatch = useDispatch();
  const facebookPermissionsScope = facebookServices.getFacebookPagesScope();
  const [showLoader, setShowLoader] = React.useState( false );

  const handleClick = ( event: React.MouseEvent ) =>
  {
    eventTracker.logSocialNetworkAccountRefreshButtonClicked( props.socialNetworkAccount, props.source );
    setShowLoader( true );
    requestConnect();
  }

  function connectionComplete()
  {
    if ( !!props.handleRefreshComplete )
    {
      props.handleRefreshComplete( true );
    }
  }

  const onFacebookConnectSuccess = ( facebookId: number,
                                     name: string,
                                     facebookAccessToken: string ) =>
  {
    businessServices.clientFacebookConnect( facebookId, facebookAccessToken ).then( result =>
    {
      eventTracker.logSocialNetworkAccountRefreshSucceeded( props.socialNetworkAccount, props.source );
      connectionComplete();
    } ).catch( error =>
    {
      eventTracker.logSocialNetworkAccountRefreshFailed( props.socialNetworkAccount, props.source, error?.toString() );
      displayErrorAlert();
      logToConsoleError( "error facebook connected", error );
      setShowLoader( false );
      if ( props.handleRefreshComplete )
      {
        props.handleRefreshComplete( false );
      }
    } )
      .finally( () =>
      {
        setShowLoader( false );
      } );
  }

  const displayErrorAlert = ( errorText?: string ) =>
  {
    let errorMessageForUser = "We had a problem refreshing your connection. Please try again or contact support if the problem persists.";
    if ( errorText )
    {
      errorMessageForUser = errorText;
    }
    dispatch( setAlertMessage( errorAlert( errorMessageForUser ) ) );
  }

  const onFacebookConnectFailure = ( failureData?: { response?: string, errorText?: string, permissionsDenied?: boolean } ) =>
  {
    setShowLoader( false );
    logToConsoleError( "facebook connect failure", failureData );
    let errorText = failureData?.errorText;
    eventTracker.logSocialNetworkAccountRefreshFailed( props.socialNetworkAccount, props.source, errorText );
    displayErrorAlert( errorText );
  }

  const handleFacebookResponse = async ( response ) =>
  {
    if ( response && response.id && response.accessToken )
    {
      const accessToken = response.accessToken;
      const facebookIsAccessible = await facebookServices.verifyUserHasFacebookPagePermissions( accessToken );
      if ( facebookIsAccessible )
      {
        onFacebookConnectSuccess( response.id, response.name, accessToken );
      }
      else
      {
        onFacebookConnectFailure(
          { permissionsDenied: true, errorText: "Unable to refresh until grant required permissions." },
        );
      }
    }
    else
    {
      onFacebookConnectFailure( { response, errorText: "Facebook connect failed." } );
    }
  }

  function requestConnect()
  {
    logToConsole( "connect facebook page started" );
  }

  return (
    <FacebookLogin
      appId={getFacebookAppId()}
      className={""}
      autoLoad={false}
      reAuthenticate={true}
      disableMobileRedirect={true}
      scope={facebookPermissionsScope}
      fields="name,email,picture"
      version={facebookServices.GRAPH_API_VERSION}
      render={( renderProps: ReactFacebookRenderProps ) =>
      {
        return (<RefreshSocialNetworkButton handleClick={renderProps.onClick} showLoader={showLoader} iconOnly={props.iconOnly}/>)
      }}
      onClick={handleClick}
      callback={handleFacebookResponse}/>
  )
}
