import { Stack, Typography } from "@mui/material";

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { COLOR_PALETTE_BUTTON_HEIGHT_PX } from "../../constants";

export interface AddColorPaletteButtonProps
{
  handleAddColorPaletteClicked: () => void;
}

export function AddColorPaletteButton( props: AddColorPaletteButtonProps )
{
  return (
    <Stack onClick={props.handleAddColorPaletteClicked} justifyContent={"center"} alignItems={"center"} sx={{ mx: 4, justifyContent: "flex-start", height: COLOR_PALETTE_BUTTON_HEIGHT_PX, cursor: "pointer" }}>
        <span>
          <IconButton
            color={"primary"}
            sx={{ p: 0 }}
            ><AddCircleIcon sx={{ fontSize: "25px" }}/></IconButton>
        </span>
      <Typography variant={"caption"}>Add Color Palette</Typography>
    </Stack>
  )
}
