import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import * as React from "react";
import { useEffect, useRef } from "react";
import { ENV_CONFIG } from "../../constants";
import { loadStripe, Stripe } from "@stripe/stripe-js";

export interface StripeCheckoutFormProps
{
  clientSecret: string;
  stripePromise?: any;
  onCheckoutComplete: () => void;
}

export function StripeCheckoutForm( props: StripeCheckoutFormProps )
{
  const stripeRef = useRef<Stripe | null>( null );

  useEffect( () =>
  {
    loadStripe( ENV_CONFIG.stripePublicKey ).then( ( stripe ) =>
    {
      if ( stripe )
      {
        stripeRef.current = stripe;
      }
    } );
  }, [] );

  return (
    <div id="checkout">
      {props.clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripeRef.current}
          options={{ clientSecret: props.clientSecret, onComplete: props.onCheckoutComplete }}
        >
          <EmbeddedCheckout/>
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}

