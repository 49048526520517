import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getRemainingFreePosts } from "../user/userSlice";
import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { setUpsellShown } from "../ui/uiSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { UPSELL_SOURCE_EYEBROW_CLICKED } from "../../helpers/trackingConstants";

export function RemainingPostsBanner()
{
  const dispatch = useDispatch();
  const numberOfRemainingFreePosts = useSelector( ( state: RootState ) => getRemainingFreePosts( state ) ) || 0;

  const statusColors = {
    3: '#4F3AA8',
    2: '#864491',
    1: '#BD4F7B',
    0: '#FA585E',
  };

  function handleSubscribeNow()
  {
    dispatch( setUpsellShown( true ) );
    eventTracker.logUpsellShown( UPSELL_SOURCE_EYEBROW_CLICKED )
  }

  return (
    <Box sx={{
      color: 'primary.contrastText', m: 1, borderRadius: "5px",
      backgroundColor: statusColors[numberOfRemainingFreePosts],
    }}>
      <Typography variant={"body2"}>
        {numberOfRemainingFreePosts} free posts remaining.
        <Link sx={{ my: 4, mx: 2, pb: "1px", fontWeight: "bold" }} color={"primary.contrastText"} component="button" alignItems={"center"}
              variant="body2"
              onClick={handleSubscribeNow}>Subscribe now</Link>
      </Typography>
    </Box>
  );
}