import { ReactNode } from "react";
import { NavigateWithParams } from "../NavigateWithParams";
import { ROUTES } from "../constants";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isUserLoggedIn } from "../user/userSlice";

export interface PrivateRouteProps
{
  children: ReactNode;
}

export function PrivateRoute( props: PrivateRouteProps )
{
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  return userIsLoggedIn ? <>{props.children}</> : <NavigateWithParams to={ROUTES.SIGN_IN}/>;
}