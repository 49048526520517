import { curatedMediaSetServices } from "../../services/curatedMediaSet.services";
import { Stack, Typography } from "@mui/material";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import React, { useEffect, useState } from "react";
import { businessServices, MediaAssetAPI } from "../../services/business.services";
import { useDispatch } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import { map } from "lodash";
import UserMediaLibraryItem from "./userMediaLibraryItem";
import InfiniteScroll from "react-infinite-scroller";
import { MediaPreviewDialog } from "./mediaPreviewDialog";

export interface CuratedMediaSetGridProps
{
  curatedMediaSetSlug: string;
  onClose: () => void;
  onMediaSelected: ( mediaAsset: MediaAssetAPI ) => void;
}

export function CuratedMediaSetGrid( props: CuratedMediaSetGridProps )
{
  const dispatch = useDispatch();
  const [loading, setLoading] = useState( true );
  const [curatedMediaSetName, setCuratedMediaSetName] = useState<string>();
  const [curatedMediaAssets, setCuratedMediaAssets] = useState<MediaAssetAPI[]>( [] );
  const [showMediaPreviewDialog, setShowMediaPreviewDialog] = useState( false );
  const [mediaAssetToPreview, setMediaAssetToPreview] = useState<MediaAssetAPI>();

  async function onMount()
  {
    try
    {
      setLoading( true );
      const response = await curatedMediaSetServices.getCuratedMediaSet( props.curatedMediaSetSlug );
      setCuratedMediaSetName( response.curated_media_set.name );
      setCuratedMediaAssets( response.curated_media_set.media_assets || [] );
    }
    catch
    {
      dispatch( setAlertMessage( errorAlert( "Failed to load seasonal media collection", 'top' ) ) );
    }
    finally
    {
      setLoading( false );
    }
  }

  useEffect( () =>
  {
    onMount();
  }, [] );

  function handleMediaClicked( mediaAsset: MediaAssetAPI )
  {
    setMediaAssetToPreview( mediaAsset );
    setShowMediaPreviewDialog( true );
  }

  function handleCloseMediaPreview()
  {
    setShowMediaPreviewDialog( false );
    setMediaAssetToPreview( undefined );
  }

  async function handleMediaSelected()
  {
    if ( mediaAssetToPreview )
    {
      try
      {
        const response = await businessServices.addCuratedMediaAssetToLibrary( mediaAssetToPreview.id );
        props.onMediaSelected( response.media_asset );
      }
      catch
      {
        dispatch( setAlertMessage( errorAlert( "Failed to add media to business library", 'top' ) ) );
      }
    }
    setShowMediaPreviewDialog( false );
  }

  return <Stack spacing={1} sx={{ my: 5, mx: "auto", maxWidth: "350px", alignItems: "center" }}>
    {!loading && curatedMediaSetName && <Typography sx={{pb:5}}>{curatedMediaSetName}</Typography>}
    {!loading &&
     <InfiniteScroll
       pageStart={1}
       element={'ul'}
       style={{
         gridTemplateColumns: "repeat(3, 1fr)",
         gap: "4px",
         display: "grid",
         listStyle: "none",
         padding: 0,
         maxWidth: "321px",
         margin: "0 auto",
       }}
       loadMore={() =>
       {
       }}
       hasMore={false}
       loader={<CircularProgress key="progress" size={30}/>}
       useWindow={false}
     >
       {map( curatedMediaAssets, ( mediaAsset, index ) =>
       {
         return <UserMediaLibraryItem key={mediaAsset.id}
                                      mediaAsset={mediaAsset}
                                      selectedMediaAssets={[]}
                                      showDelete={false}
                                      handleMediaClicked={handleMediaClicked}
                                      renderForCuratedMediaSet={true}
         />;
       } )
       }
     </InfiniteScroll>}
    {loading && <CircularProgress/>}
    {showMediaPreviewDialog && <MediaPreviewDialog open={showMediaPreviewDialog}
                                                   onClose={handleCloseMediaPreview}
                                                   mediaUrl={mediaAssetToPreview?.url || ""}
                                                   onConfirm={handleMediaSelected}
                                                   showCancel={true}
                                                   confirmButtonTitle={"Add media to post"}/>}
  </Stack>
}