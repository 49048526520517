import { Stack } from "@mui/material";
import React from "react";

import { Helmet } from "react-helmet";
import { ROUTE_SEO } from "../constants";
import { SelectBusinessesDialog } from "./selectBusinessesDialog";

export function SelectBusinesses()
{
  return <Stack spacing={10} sx={{ textAlign: "left", px: 5, pt: 5 }} alignItems="start">
    <Helmet>
      <title>{ROUTE_SEO.SELECT_BUSINESSES.title}</title>
      <meta name="description" content={ROUTE_SEO.SELECT_BUSINESSES.description}/>
    </Helmet>
    <SelectBusinessesDialog manageOpenStateExternally={true}
                            externalStateOpen={true}
                            hideTopBar={true}
    />
  </Stack>
}