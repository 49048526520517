import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { ShareBatchAPI } from "../../services/postIdeaServices";
import * as React from "react";
import { useContext, useEffect } from "react";
import { SocialAccountProfileIcon } from "../ui/socialAccountProfileIcon";
import { FACEBOOK_INSTAGRAM, SocialNetworkAccountType } from "../../services/business.services";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { SocialMediaAccountRow } from "../userSettings/SocialMediaAccountRow";
import { ShareDestinationToggle } from "./ShareDestinationToggle";
import { ConnectFacebookInstagramButton } from "../buttons/connectFacebookInstagramButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { InstagramSharingSettings } from "../sharing/instagramSharingSettings";
import { EDIT_POST_PAGE_SOURCE } from "../../helpers/trackingConstants";

export interface InstagramDirectShareRowProps
{
  postIdea: PostIdeaDataAPI;
  previousShareBatch?: ShareBatchAPI;
  showProgress?: ( title: string, accountType: SocialNetworkAccountType ) => void;
  hideProgress?: () => void;
  updateSharingSettings?: ( settings: InstagramSharingSettings ) => void;
}

export function InstagramDirectShareRow( props: InstagramDirectShareRowProps )
{
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );
  const socialNetworkAccounts = socialNetworkAccountsContext.socialNetworkAccounts;
  const connectedInstagram = socialNetworkAccountHelper.getConnectedInstagram( socialNetworkAccounts );
  const instagramAccountName = connectedInstagram?.name;
  const instagramProfileImageUrl = connectedInstagram?.profile_image_url;
  const readyToShare = !!connectedInstagram && !socialNetworkAccountHelper.needsRefresh( connectedInstagram );
  const [instagramSelected, setInstagramSelected] = React.useState( false );

  useEffect( () =>
  {
    const sharingSettings: InstagramSharingSettings = {
      selectedToShare: instagramSelected,
      readyToShare: readyToShare,
    }

    if ( props.updateSharingSettings )
    {
      props.updateSharingSettings( sharingSettings );
    }
  }, [instagramSelected, readyToShare] )

  function hideLoadingOverlay()
  {
    if ( !!props.hideProgress )
    {
      props.hideProgress();
    }
  }

  function instagramStatusChanged( event: React.ChangeEvent<HTMLInputElement>, checked: boolean )
  {
    setInstagramSelected( !instagramSelected );
  }

  function getInstagramSwitch()
  {
    function handleInstagramConnected()
    {
      hideLoadingOverlay();
      setInstagramSelected( true );
    }

    function handleInstagramConnectionFailed()
    {
      hideLoadingOverlay();
    }

    function handleInstagramConnectionStarted()
    {
      if ( !!props.showProgress )
      {
        props.showProgress( "Connecting to Instagram...", FACEBOOK_INSTAGRAM );
      }
    }

    if ( !!connectedInstagram )
    {
      const accountIcon = <SocialAccountProfileIcon profileImageUrl={instagramProfileImageUrl} accountType={FACEBOOK_INSTAGRAM}/>;
      if ( socialNetworkAccountHelper.needsRefresh( connectedInstagram ) )
      {
        return <SocialMediaAccountRow socialNetworkAccount={connectedInstagram} source={EDIT_POST_PAGE_SOURCE}/>;
      }
      return <ShareDestinationToggle icon={accountIcon}
                                     label={instagramAccountName}
                                     checked={instagramSelected}
                                     onChange={instagramStatusChanged}/>;
    }
    return <ConnectFacebookInstagramButton source={EDIT_POST_PAGE_SOURCE}
                                           handleConnectionSucceeded={handleInstagramConnected}
                                           handleConnectionFailed={handleInstagramConnectionFailed}
                                           handleConnectionStarted={handleInstagramConnectionStarted}/>;
  }

  return getInstagramSwitch();

}