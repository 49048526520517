import { Button, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { MEDIA_TAB_ID_GENERATE, MEDIA_TAB_ID_SEARCH, MEDIA_TAB_ID_WEBSITE } from "../constants";

export interface SelectMediaSourceDialogProps
{
  open: boolean;
  handleClose: () => void;
  handleSourceSelected: ( tabId: number ) => void;
}

export function SelectMediaSourceDialog( props: SelectMediaSourceDialogProps )
{
  function selectStock()
  {
    props.handleSourceSelected( MEDIA_TAB_ID_SEARCH );
  }

  function selectAI()
  {
    props.handleSourceSelected( MEDIA_TAB_ID_GENERATE );
  }

  function selectWeb()
  {
    props.handleSourceSelected( MEDIA_TAB_ID_WEBSITE );
  }

  return (<Dialog
    fullWidth={false}
    maxWidth={"sm"}
    open={props.open}
  >
    <DialogTitle sx={{ pt: 20, textAlign: "center" }}>Choose a source</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={props.handleClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: ( theme ) => theme.palette.grey[500],
      }}
    >
      <CloseIcon/>
    </IconButton>
    <DialogContent>
      <Stack spacing={8}>
        <Button variant="contained" onClick={selectStock}>
          <Stack>
            <Typography variant="subtitle2" sx={{ textTransform: "initial" }}>Free stock images and videos</Typography>
            <Typography variant="caption" sx={{ textTransform: "initial" }}>Find media that fits with your brand</Typography>
          </Stack>
        </Button>
        <Button variant="contained" onClick={selectAI}>
          <Stack>
            <Typography variant="subtitle2" sx={{ textTransform: "initial" }}>Generate with AI</Typography>
            <Typography variant="caption" sx={{ textTransform: "initial" }}>Describe what you want to AI</Typography>
          </Stack>
        </Button>
        <Button variant="contained" onClick={selectWeb}>
          <Stack>
            <Typography variant="subtitle2" sx={{ textTransform: "initial" }}>From web</Typography>
            <Typography variant="caption" sx={{ textTransform: "initial" }}>Fetch images from website or URL</Typography>
          </Stack>
        </Button>
      </Stack>
    </DialogContent>
  </Dialog>)
}
