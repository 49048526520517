import { isError } from "lodash";
import * as Sentry from "@sentry/browser";
import { ENABLE_ERROR_REPORTING } from "../../constants";
import { getIsInternalUser, isGuestUser } from "../user/userSlice";
import { store } from "../../app/store";
import { currentUserBusinessId } from "../business/businessSlice";
import { logToConsoleError } from "../utils/devLoggingHelper";

export const errorReporter = {
  reportErrorToSentry
}

function reportErrorToSentry( error, extra?: any )
{
  if ( ENABLE_ERROR_REPORTING )
  {
    try
    {
      let additionalInfo = defaultUserData();

      if ( !!extra )
      {
        additionalInfo = {
          ...additionalInfo,
          ...extra,
        }
      }

      if ( isErrorReportableToSentry( error ) )
      {
        Sentry.captureException( error, additionalInfo );
      }
      else
      {
        const message = extractMessageFromError( error );
        Sentry.captureMessage( message, additionalInfo );
      }
    }
    catch (ex)
    {
      logToConsoleError("Exception encountered while reporting error to Sentry", ex);
    }
  }
}

function isErrorReportableToSentry( possibleError: any )
{
  if ( isError( possibleError ) )
  {
    return true;
  }
  else if ( typeof possibleError === 'object' )
  {
    return isError( possibleError.originalError ) || isError( possibleError.error );
  }
  return false;
}

function extractMessageFromError( error: any )
{
  if ( typeof error === 'string' )
  {
    return error;
  }
  else if ( typeof error === 'object' )
  {
    return JSON.stringify( error );
  }
  return "Error of " + typeof error + " type encountered";
}

function defaultUserData()
{
  const state = store.getState();
  return {
    user: {
      is_internal_user: getIsInternalUser( state ),
      business_id: currentUserBusinessId( state ),
      is_guest: isGuestUser( state )
    }
  }
}
