import React from 'react'
import { authenticationServices } from "../../services/authentication.services";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../constants";
import { userHelper } from "../user/userHelper";
import { eventTracker } from "../../helpers/eventTracker";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";
import { UserSettingsRow } from "../userSettings/userSettingsRow";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

export function SignOutButton()
{
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();

  const handleSignOut = async () =>
  {
    dispatch( setApplicationLoadingState( true ) );

    eventTracker.logSettingsSignOutClicked();
    setSearchParams( {} );
    await authenticationServices.signOut().finally( () =>
    {
      userHelper.signOutUser( dispatch );
      dispatch( setApplicationLoadingState( false ) );
      navigate( ROUTES.ROOT );
    } );
  }

  return (
    <UserSettingsRow icon={<LogoutOutlinedIcon sx={{ transform: "scaleX(-1)" }}/>}
                     label="Sign out"
                     onClick={handleSignOut}/>
  )
}
