import { getToApi, postJsonToApi, postToApi } from "./requestManager";
import { currentUserBusinessId } from "../features/business/businessSlice";
import { store } from "../app/store";
import { concat } from "lodash";

export const colorPaletteServices = {
  getColorPalettes,
  updateColorPaletteColors,
  updateColorPaletteName,
  favoriteColorPalette,
  unfavoriteColorPalette,
  copyColorPaletteToBusiness,
  shuffleColorPaletteColors,
}

const COLOR_PALETTES_BASE_PATH = "color_palettes";
const COLOR_PALETTE_LIST_ENDPOINT = "list"
const COLOR_PALETTE_UPDATE_ENDPOINT = "update"
const COLOR_PALETTE_SHUFFLE_ENDPOINT = "shuffle"
const COLOR_PALETTE_FAVORITE_ENDPOINT = "favorite"
const COLOR_PALETTE_COPY_ENDPOINT = "copy_to_business"
const COLOR_PALETTE_UNFAVORITE_ENDPOINT = "unfavorite"

export interface ColorPaletteAPI
{
  name: string,
  slug: string,
  colors_json: ColorAPI[],
  owner_type: ColorPaletteOwnerTypeAPI,
  status: ColorPaletteStatusAPI,
}

export interface ColorAPI
{
  hex: string,
  usage: string,
}

interface ColorPalettesIndexAPI
{
  business_color_palettes: ColorPaletteAPI[],
  color_palettes: ColorPaletteAPI[],
}

interface ColorPalettesFavoriteAPI
{
  color_palette: ColorPaletteAPI,
}

interface ColorPalettesUpdateAPI
{
  color_palette: ColorPaletteAPI,
}

interface ColorPalettesCopyToBusinessAPI
{
  color_palette: ColorPaletteAPI,
}

export enum ColorPaletteOwnerTypeAPI
{
  Business = "Business",
}

export enum ColorPaletteStatusAPI
{
  Live = "live",
  Retired = "retired",
}

async function getColorPalettes()
{
  try
  {
    const state = store.getState();
    const endpointUrl = buildUrl( [COLOR_PALETTE_LIST_ENDPOINT] );
    return await getToApi<ColorPalettesIndexAPI>( endpointUrl, { business_id: currentUserBusinessId( state ) } );
  }
  catch (error)
  {
    return Promise.reject( "Could not fetch color palettes" );
  }
}

async function updateColorPaletteColors( colorPalette: ColorPaletteAPI )
{
  try
  {
    let colorPaletteToEdit = colorPalette;
    if ( colorPalette.owner_type !== ColorPaletteOwnerTypeAPI.Business )
    {
      colorPaletteToEdit = await copyColorPaletteToBusiness( colorPalette, ColorPaletteStatusAPI.Retired );
    }

    const endPoint = buildMemberUrl( colorPaletteToEdit.slug, COLOR_PALETTE_UPDATE_ENDPOINT );
    const state = store.getState();
    const jsonBody = {
      name: colorPaletteToEdit.name,
      colors_json: colorPalette.colors_json,
    }
    return await postJsonToApi<ColorPalettesUpdateAPI>( endPoint, { business_id: currentUserBusinessId( state ) }, jsonBody );
  }
  catch (error)
  {
    return Promise.reject( "Could not update color palette" );
  }
}

async function shuffleColorPaletteColors( colorPalette: ColorPaletteAPI, isFirstShuffle: boolean)
{
  try
  {
    let colorPaletteToEdit = colorPalette;
    if ( colorPalette.owner_type !== ColorPaletteOwnerTypeAPI.Business )
    {
      colorPaletteToEdit = await copyColorPaletteToBusiness( colorPalette, ColorPaletteStatusAPI.Retired );
    }

    const endPoint = buildMemberUrl( colorPaletteToEdit.slug, COLOR_PALETTE_SHUFFLE_ENDPOINT );
    const state = store.getState();
    const jsonBody = {
      is_first_shuffle: isFirstShuffle,
    }
    return await postJsonToApi<ColorPalettesUpdateAPI>( endPoint, { business_id: currentUserBusinessId( state ) }, jsonBody );
  }
  catch (error)
  {
    return Promise.reject( "Could not shuffle color palette" );
  }
}

function buildUrl( pathPieces: string[] )
{
  return concat( [COLOR_PALETTES_BASE_PATH], pathPieces ).join( "/" );
}

function buildMemberUrl( slug, path )
{
  return buildUrl( [slug, path] );
}

async function updateColorPaletteName( colorPalette: ColorPaletteAPI )
{
  try
  {
    let colorPaletteToEdit = colorPalette;
    if ( colorPalette.owner_type !== ColorPaletteOwnerTypeAPI.Business )
    {
      colorPaletteToEdit = await copyColorPaletteToBusiness( colorPalette, ColorPaletteStatusAPI.Retired );
    }

    const endPoint = buildMemberUrl( colorPaletteToEdit.slug, COLOR_PALETTE_UPDATE_ENDPOINT );
    const state = store.getState();
    const jsonBody = {
      name: colorPalette.name,
      colors_json: colorPaletteToEdit.colors_json,
    }
    return await postJsonToApi<ColorPalettesUpdateAPI>( endPoint, { business_id: currentUserBusinessId( state ) }, jsonBody );
  }
  catch (error)
  {
    return Promise.reject( "Could not update color palette" );
  }
}

async function copyColorPaletteToBusiness( colorPalette: ColorPaletteAPI, status?: ColorPaletteStatusAPI )
{
  const state = store.getState();
  const endPoint = buildMemberUrl( colorPalette.slug, COLOR_PALETTE_COPY_ENDPOINT );

  const jsonBody = { ...colorPalette, status };
  const apiResponse = await postJsonToApi<ColorPalettesCopyToBusinessAPI>( endPoint, { business_id: currentUserBusinessId( state ) }, jsonBody );
  return apiResponse.color_palette;
}

async function favoriteColorPalette( colorPalette: ColorPaletteAPI )
{
  try
  {
    let colorPaletteToEdit = colorPalette;
    if ( colorPalette.owner_type !== ColorPaletteOwnerTypeAPI.Business )
    {
      colorPaletteToEdit = await copyColorPaletteToBusiness( colorPalette, ColorPaletteStatusAPI.Live );
    }

    const state = store.getState();
    const endPoint = buildMemberUrl( colorPaletteToEdit.slug, COLOR_PALETTE_FAVORITE_ENDPOINT );
    return await postToApi<ColorPalettesFavoriteAPI>( endPoint, { business_id: currentUserBusinessId( state ), ...colorPaletteToEdit } );
  }
  catch (error)
  {
    return Promise.reject( "Could not favorite color palette" );
  }
}

async function unfavoriteColorPalette( colorPalette: ColorPaletteAPI )
{
  try
  {
    const state = store.getState();
    const endPoint = buildMemberUrl( colorPalette.slug, COLOR_PALETTE_UNFAVORITE_ENDPOINT );
    return await postToApi<ColorPalettesFavoriteAPI>( endPoint, { business_id: currentUserBusinessId( state ), ...colorPalette } );
  }
  catch (error)
  {
    return Promise.reject( "Could not unfavorite color palette" );
  }
}
