import * as React from 'react';
import "./editTextTabContent.scss";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { BrandingContents } from "../brandStyle/brandingContents";
import { Box } from "@mui/material";
import { BRAND_CARD_PREVIEW_SOURCE_EDIT } from "../constants";

function BrandSettingsFullScreenEditor( props: WithFullScreenDialogProps )
{
  return (
    <FullPageMobileContainer>
      <Box sx={{ p: 8 }}>
        <BrandingContents source={BRAND_CARD_PREVIEW_SOURCE_EDIT} onCloseFromModel={props.handleClose}/>
      </Box>
    </FullPageMobileContainer>
  );
}

export const BrandSettingsFullScreenDialog = withFullScreenDialog( BrandSettingsFullScreenEditor )
