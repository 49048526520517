import * as React from 'react';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { BEAT_SYNC_ICON_TYPE, clearAlertMessage } from "./alertSlice";
import { Snackbar } from "@mui/material";
import { isUndefined } from "lodash";
import { BeatSyncIconImage } from "../music/BeatSyncIconImage";

export default function AlertBanner()
{
  const alertMessage = useSelector( ( state: RootState ) => state.alert.message );
  const alertPosition = alertMessage?.position || 'bottom';
  const alertShowCloseButton = isUndefined( alertMessage?.showCloseButton ) ? true : alertMessage?.showCloseButton;
  const [open, setOpen] = useState( !!alertMessage );
  const dispatch = useDispatch();
  const iconToUse = alertMessage?.icon_type === BEAT_SYNC_ICON_TYPE ? <BeatSyncIconImage/> : undefined;

  useEffect( () =>
  {
    setOpen( !!alertMessage );
  }, [alertMessage] );

  const onFinishShowingAlert = () =>
  {
    dispatch( clearAlertMessage() );
  }

  const onClose = () =>
  {
    setOpen( false );
  }

  return (
    <Snackbar open={open} anchorOrigin={{ vertical: alertPosition, horizontal: 'center' }} autoHideDuration={10000} onClose={onClose}
              sx={{ width: '100%' }} TransitionProps={{ onExited: onFinishShowingAlert }} onClick={onFinishShowingAlert}>
      <Alert severity={alertMessage?.severity}
             icon={iconToUse}
             action={
               alertShowCloseButton && <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={onFinishShowingAlert}
                                    >
                                      <CloseIcon fontSize="inherit"/>
                                    </IconButton>
             }
      >
        {alertMessage?.text}
      </Alert>
    </Snackbar>
  );
}
