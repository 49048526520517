import { forEach, round } from "lodash";
import { store } from "../app/store";
import { setProgressState } from "../features/ui/uiSlice";

export const progressTracker = {
  updateProgress,
  createProgressContext,
}

function createProgressContext(listOfItems: string[])
{
  const progressContext = {};
  forEach(listOfItems, (item) => progressContext[item] = 0);

  return progressContext;
}

function updateProgress(progressContext: any, itemName: string, progressValue: number)
{
  progressContext[itemName] = progressValue;
  let combinedProgress = 0;
  for ( let key in progressContext )
  {
    combinedProgress += progressContext[key];
  }

  const progressToReport = round( combinedProgress / Object.keys(progressContext).length )
  store.dispatch( setProgressState( { progress: progressToReport } ) );

}