import { Box, Tooltip } from "@mui/material";
import { merge } from "lodash";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { LAYOUT_BUTTON_HEIGHT_PX, LAYOUT_BUTTON_MIN_WIDTH_PX, LAYOUT_BUTTON_SELECTED_HEIGHT_PX } from "../../constants";
import * as React from "react";
import { LayoutAPI } from "../../../services/layout.services";
import { isDevModeEnabled } from "../../featureFlags/featureFlagsSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import CircularProgress from "@mui/material/CircularProgress";

export interface LayoutProps
{
  layout: LayoutAPI;
  handleLayoutSelected: ( layout: LayoutAPI ) => void;
  selected: boolean;
  showLoader?: boolean;
}

export function Layout( props: LayoutProps )
{
  const windowDimensions = useWindowDimensions();
  const windowWidth = windowDimensions.width;
  const { layout } = props;
  const devModeEnabled = useSelector( ( state: RootState ) => isDevModeEnabled( state ) );

  const handleOnClick = ( event: React.MouseEvent ) =>
  {
    props.handleLayoutSelected( layout );
  }
  const numberOfItemsToRender = Math.floor( windowWidth / LAYOUT_BUTTON_MIN_WIDTH_PX );
  const layoutWidth = Math.max( windowWidth / numberOfItemsToRender, LAYOUT_BUTTON_MIN_WIDTH_PX );
  const selectionScale = LAYOUT_BUTTON_SELECTED_HEIGHT_PX / LAYOUT_BUTTON_HEIGHT_PX;
  const className = props.selected ? "selected" : "";

  function layoutImageContainerAndLoader()
  {
    return <Box sx={{ height: "100%", position: "relative" }}>
      <Box component="img" sx={{ height: "100%" }} src={props.layout.image_url}></Box>
      {props.showLoader && <Box justifyContent="center" alignItems="center"><CircularProgress
        sx={{ position: "absolute", width: "100%", top: "calc(50% - 20px)", left: "calc(50% - 20px)" }}/></Box>}
    </Box>;
  }

  return (
    <Box
      className={className}
      sx={merge( {
        height: LAYOUT_BUTTON_HEIGHT_PX,
        flexBasis: layoutWidth,
        flexGrow: 0,
        flexShrink: 0,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        transform: props.selected ? `scale(${selectionScale})` : "unset",
        transition: "all 0.1s",
        px: 8,
        py: 4,
      } )} onClick={handleOnClick}>
      {devModeEnabled && <Tooltip title={layout.preset_name}>
        {layoutImageContainerAndLoader()}
      </Tooltip>}
      {!devModeEnabled && layoutImageContainerAndLoader()}
    </Box>
  )
}
