import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect, useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { UpsellBenefitRow } from "./upsellBenefitRow";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentDialogOpen, setUpsellShown } from "../ui/uiSlice";
import { PurchaseFlowCTA } from "../payment/purchaseFlowCTA";
import { PurchaseFlowHeader } from "../payment/purchaseFlowHeader";
import { CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT, UPSELL_GRADIENT } from "../constants";
import { isGuestUser } from "../user/userSlice";
import { RootState } from "../../app/store";
import { CreateAccountOrSignIn } from "../registration/createAccountOrSignIn";
import { eventTracker } from "../../helpers/eventTracker";
import UpsellSeal from "../../assets/upsell-seal.svg";
import { AlkaiWordmarkLogoWhite } from "../registration/alkaiWordmarkLogoWhite";

function Upsell( props: WithFullScreenDialogProps )
{
  const dispatch = useDispatch();
  const userIsGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const userIsGuestRef = useRef( userIsGuest );

  const [showCreateLogin, setShowCreateLogin] = React.useState<boolean>( false );

  useEffect( () =>
  {
    userIsGuestRef.current = userIsGuest;
  }, [userIsGuest] );

  const handleCloseUpsell = () =>
  {
    eventTracker.logUpsellCancelClicked( userIsGuest );
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  function handleStripeCheckout()
  {
    eventTracker.logUpsellContinueToPaymentClicked( userIsGuest );
    if ( userIsGuest )
    {
      setShowCreateLogin( true )
    }
    else
    {
      dispatch( setUpsellShown( false ) );
      dispatch( setPaymentDialogOpen( true ) );
    }
  }

  function handleAccountCreated()
  {
    setShowCreateLogin( false );
    if ( !userIsGuestRef.current )
    {
      dispatch( setUpsellShown( false ) );
      dispatch( setPaymentDialogOpen( true ) );
    }
  }

  function getPurchaseFlowCTA()
  {
    return "Save 50% for 3 months!"
  }

  function getUpsellSeal()
  {
    let monthlyPriceAfterDiscount = "$20/mo";
    let ongoingMonthlyPrice = "$40";

    return (<Box sx={{ position: "absolute", width: "90px", height: "90px", top: "-65px", right: "-55px" }}>
      <Box component="img" src={UpsellSeal} alt="save now" sx={{ width: "100%", position: "absolute", top: 0, left: 0, right: 0 }}/>
      <Stack sx={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, right: 0 }} alignItems="center"
             justifyContent="center">
        <Typography variant="subtitle2"
                    sx={{ textDecoration: "line-through", color: "white", fontSize: "16px", lineHeight: "18px" }}>{ongoingMonthlyPrice}</Typography>
        <Typography variant="subtitle2"
                    sx={{ color: "white", fontSize: "16px", lineHeight: "18px" }}>{monthlyPriceAfterDiscount}</Typography>
      </Stack>
    </Box>);
  }

  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <IconButton
        aria-label="close"
        color="secondary"
        size="small"
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          zIndex: 1,
        }}
        onClick={handleCloseUpsell}>
        <CloseIcon fontSize="inherit"/>
      </IconButton>
      <Stack sx={{ display: "flex", alignItems: "center", my: 12, mx: "auto" }}>
        <AlkaiWordmarkLogoWhite sx={{ width: "110px" }}/>
        <PurchaseFlowHeader sx={{ width: "280px" }}>Your AI Social Media Assistant</PurchaseFlowHeader>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Compelling post ideas</Box> tailored for your business
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          Keeps you on track with a <Box component="span" fontWeight='bold'>custom weekly plan</Box>
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          Automatically creates <Box component="span" fontWeight='bold'>eye-catching content</Box> when it's time to post
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Unlimited</Box> ideas, content, and cloud storage
        </UpsellBenefitRow>
        <UpsellBenefitRow>
          <Box component="span" fontWeight='bold'>Free stock</Box> photos, videos, and music
        </UpsellBenefitRow>
        <PurchaseFlowCTA onClick={handleStripeCheckout}>
          {getPurchaseFlowCTA()}
          {getUpsellSeal()}
        </PurchaseFlowCTA>
        <Typography variant="caption" sx={{ color: "primary.contrastText" }}>Discount available to first-time customers only.</Typography>
      </Stack>
      <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_CHECKOUT}
                             shouldShowSignIn={showCreateLogin}
                             onClose={handleAccountCreated}/>
    </FullPageMobileContainer>
  )
}

export const FullScreenUpsellDialog = withFullScreenDialog( Upsell )
