import * as React from "react";
import { Button, ButtonProps } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { merge } from "lodash";

export function DottedOutlineButton( props: ButtonProps )
{
  const { ...buttonProps } = props;

  function getCssStyle(): SxProps<Theme>
  {
    return {
      mb: 4,
      p: 0,
      textTransform: "none",
      borderStyle: "dashed",
      borderWidth: "2px",
      borderRadius: "15px",
      borderColor: "#3f50b5",
      width: ['100%', 175],
      height: ['80px', 150],
      '& > .MuiStack-root': {
        flexDirection: ['row', 'column']
      },
      '&:hover': {
        borderStyle: "dashed",
        borderWidth: "2px",
        borderRadius: "15px",
      }
    };
  }

  return (
    <Button component={"label"} {...buttonProps} sx={merge( getCssStyle(), props.sx )}
            variant="outlined">
      {props.children}
    </Button>
  )
}
