import React from "react";
import { Stack, Typography } from "@mui/material";

export interface PlanItemIconLabelProps
{
  label: string;
  children: React.ReactNode;
  color: string;
}

export function PlanItemIconLabel( props: PlanItemIconLabelProps )
{
  return <>
    {<Stack direction="row" gap="5px" alignItems="center" sx={{ backgroundColor: props.color, borderRadius: "20px", py: 1, px: 5 }}>
      {props.children}
      <Typography sx={{ color: "white", fontSize: "12px" }}>{props.label}</Typography>
    </Stack>}
  </>;
}