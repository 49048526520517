import React from 'react'
import { Box, ToggleButton } from "@mui/material";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

export interface AudioMutedButtonProps
{
  changeAudioMuted: () => void;
  audioMuted: boolean;
}

export function AudioButton( props: AudioMutedButtonProps )
{
  function changeAudioMuted()
  {
    props.changeAudioMuted();
  }

  return (
    <Box sx={{
      backgroundColor: "#00000066",
      position: "absolute",
      bottom: "3px",
      right: "3px"
    }}>
      <ToggleButton
        selected={props.audioMuted}
        onChange={() =>
        {
          changeAudioMuted();
        }}
        value={"muted"}>
        {props.audioMuted && <VolumeOffIcon style={{ fill: "white" }}/>}
        {!props.audioMuted && <VolumeUpIcon style={{ fill: "white" }}/>}
      </ToggleButton>
    </Box>
  )
}
