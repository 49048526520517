import * as React from 'react';
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { PostingStrategyQuiz } from "./postingStrategyQuiz";

function PostingStrategyQuizFullScreen( props: WithFullScreenDialogProps )
{
  return (
    <FullPageMobileContainer>
      <PostingStrategyQuiz onCloseFromModel={props.handleClose}/>
    </FullPageMobileContainer>
  );
}

export const PostingStrategyQuizFullScreenDialog = withFullScreenDialog( PostingStrategyQuizFullScreen )
