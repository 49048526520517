import { DialogContent, SxProps } from "@mui/material";
import * as React from "react";
import { merge } from "lodash";

export interface FullPageMobileContainerProps
{
  children: React.ReactNode;
  className?: string;
  sx?: SxProps;
}

export function FullPageMobileContainer( props: FullPageMobileContainerProps )
{
  return <DialogContent className={props.className} sx={merge( {
    p: 0,
    display: "flex",
    flexDirection: "column",
    WebkitOverflowScrolling: "unset", // fixes z-index issue on mobile safari https://stackoverflow.com/questions/37696746/ios-not-respecting-z-index-with-webkit-overflow-scrolling
  }, props.sx )}>
    {props.children}
  </DialogContent>
}