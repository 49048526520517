import React, { useCallback, useState } from "react";
import { Box, Grid, Link, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { authenticationServices, UserApiData } from "../../services/authentication.services";
import { store } from "../../app/store";
import { updateUserVerificationRequired } from "../user/userSlice";
import { throttle } from "lodash";
import { useDispatch } from "react-redux";
import { eventTracker } from "../../helpers/eventTracker";
import { setAlertMessage, warningAlert } from "../alert/alertSlice";
import { EMAIL_SIGN_IN_VERIFY_SUCCESS, PHONE_NUMBER_SIGN_IN_VERIFY_SUCCESS, SIGN_IN_PROVIDER_PHONE_NUMBER } from "../constants";
import { VerifyAndSignInData } from "./signInAndVerify";
import { getVerificationFailureMessage } from "../utils/errorMessageUtils";
import { logToConsole } from "../utils/devLoggingHelper";

export interface VerifySignInInputProps
{
  inputValue: string;
  signInProvider: string;
  isUserSettings?: boolean;
  onSignInVerified(): void;
  onExistingUserFound: ( existingUserData: UserApiData, currentUserData: UserApiData, verifyAndSignInData: VerifyAndSignInData ) => void;
  onCanceled(): void;
}

export function VerifySignInInput( props: VerifySignInInputProps )
{
  const SIGN_IN_FORM_VERIFICATION_CODE = "verification_code";
  const [verificationCode, setVerificationCode] = useState<string>( "" );
  const [showLoader, setShowLoader] = useState( false );
  const [error] = useState( false );
  const dispatch = useDispatch();

  const resendCodeWrapper = throttle( () =>
  {
    authenticationServices.resendCode( props.inputValue, props.signInProvider );
  }, 30000 );

  const throttledResendCode = useCallback( resendCodeWrapper, [resendCodeWrapper, props.inputValue] );

  const handleSubmit = async ( event: React.FormEvent<HTMLFormElement> ) =>
  {
    setShowLoader( true );
    event.preventDefault();
    const data = new FormData( event.currentTarget );
    const verificationCode = data.get( SIGN_IN_FORM_VERIFICATION_CODE ) as string;

    eventTracker.logVerifyClicked()
    authenticationServices.verifyAndSignIn( props.inputValue, props.signInProvider, verificationCode ).then( async ( response ) =>
    {
      if ( response.existing_user && response.data )
      {
        const verifyAndSignInData = { inputValue: props.inputValue, signInProvider: props.signInProvider, verificationCode };
        props.onExistingUserFound( response.existing_user, response.data, verifyAndSignInData );
      }
      else if ( response.data )
      {
        logToConsole( getVerificationSuccessMessage() );
        props.onSignInVerified();
      }
      else
      {
        dispatch( setAlertMessage( warningAlert( getVerificationFailureMessage( props.signInProvider ) ) ) );
      }
      setShowLoader( false );
    } ).catch( ( error ) =>
    {
      dispatch( setAlertMessage( warningAlert( getVerificationFailureMessage( props.signInProvider ) ) ) );
      setShowLoader( false );
    } );
  }

  const getVerificationSuccessMessage = () =>
  {
    if ( props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER )
    {
      return PHONE_NUMBER_SIGN_IN_VERIFY_SUCCESS;
    }

    return EMAIL_SIGN_IN_VERIFY_SUCCESS;
  }

  const handleVerificationCodeChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    setVerificationCode( event.target.value )
  }

  const onWrongInputClicked = ( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) =>
  {
    event.preventDefault();
    eventTracker.logWrongNumberClicked()
    authenticationServices.wrongSignInInput( props.inputValue, props.signInProvider ).then( () =>
    {
      store.dispatch( updateUserVerificationRequired( null ) );
      props.onCanceled();
    } );
  }

  const onResendCodeClicked = ( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) =>
  {
    event.preventDefault();
    eventTracker.logResendCodeClicked()
    throttledResendCode();
  }

  const renderWrongInputLink = () =>
  {
    const message = props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER ? "Wrong number?" : "Wrong email?"

    return <Link href="#" variant="body2" onClick={onWrongInputClicked}>
      {message}
    </Link>
  }

  const getVerificationCodeSentMessage = () =>
  {
    return props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER ? "A code has been sent to the phone number provided."
                                                                  : "A code has been sent to the email provided.";
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}>
      <Typography sx={{ mt: 8, mb: 2 }} variant="body2" color='darkviolet'>{getVerificationCodeSentMessage()}</Typography>
      <TextField fullWidth
                 type="number"
                 inputProps={{ pattern: "\\d*" }}
                 autoComplete={'off'}
                 name={SIGN_IN_FORM_VERIFICATION_CODE}
                 value={verificationCode}
                 placeholder={"Enter your verification code"}
                 error={error}
                 disabled={showLoader}
                 helperText={error ? "Verification failed" : null}
                 onChange={handleVerificationCodeChange}
                 sx={{
                   '& input[type=number]': {
                     MozAppearance: 'textfield'
                   },
                   '& input[type=number]::-webkit-outer-spin-button': {
                     WebkitAppearance: 'none',
                     margin: 0
                   },
                   '& input[type=number]::-webkit-inner-spin-button': {
                     WebkitAppearance: 'none',
                     margin: 0
                   }
                 }}
      />

      <LoadingButton type="submit"
                     fullWidth
                     variant="contained"
                     sx={{ mt: 3, mb: 2 }}
                     disabled={verificationCode === ""}
                     loading={showLoader}>
        Verify
      </LoadingButton>
      <Grid container textAlign="center">
        <Grid item xs>
          {renderWrongInputLink()}
        </Grid>
        <Grid item xs>
          <Link href="#" variant="body2" onClick={onResendCodeClicked}>
            Resend code
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

