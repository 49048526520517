import * as React from 'react';
import { useEffect, useRef } from 'react';
import { BrandStyleAPI } from "../../services/business.services";
import { FontSetAPI } from "../../services/fontSet.services";
import { FontSetDrawer } from "../editing/fonts/fontSetDrawer";
import { EDIT_FONT_SOURCE_EDIT_BRAND_KIT } from "../../helpers/trackingConstants";

export interface BrandSettingsFontSetDrawerProps
{
  brandStyle: BrandStyleAPI;
  handleDrawerClose: () => void;
  applyFontSetSlug: ( fontSetSlug: string ) => void;
}

export function BrandSettingsFontSetDrawer( props: BrandSettingsFontSetDrawerProps )
{
  const selectedFontSetSlug = props.brandStyle.font_set?.slug;
  const initialFontSetSlug = useRef<string | undefined>( undefined );

  useEffect( () =>
  {
    initialFontSetSlug.current = selectedFontSetSlug;
  }, [] );

  async function applyFontSet( fontSet: FontSetAPI )
  {
    props.applyFontSetSlug( fontSet.slug );
  }

  function onCancelClick()
  {
    if ( initialFontSetSlug.current && initialFontSetSlug.current !== selectedFontSetSlug )
    {
      props.applyFontSetSlug( initialFontSetSlug.current )
    }
    props.handleDrawerClose();
  }

  return (
    <FontSetDrawer applyFontSet={applyFontSet}
                   handleDrawerClose={props.handleDrawerClose}
                   handleCancelClicked={onCancelClick}
                   selectedFontSetSlug={selectedFontSetSlug}
                   source={EDIT_FONT_SOURCE_EDIT_BRAND_KIT}/>
  );
}
