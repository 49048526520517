import * as React from "react";
import { useCallback, useRef } from "react";
import { ButtonProps, Stack, Typography } from "@mui/material";
import { DottedOutlineButton } from "./dottedOutlineButton";
import UploadIcon from "@mui/icons-material/Upload";
import { UPLOAD_MEDIA_BUTTON_TEXT } from "../constants";

export interface SelectLocalMediaButtonProps extends ButtonProps
{
  accept: string;
  multiple?: boolean;
  onMediaSelected: ( inputFiles: FileList ) => void;
  handleClick: () => void;
}

export function SelectLocalMediaButton( props: SelectLocalMediaButtonProps )
{
  const inputButtonRef = useRef<HTMLInputElement>( null );
  const { accept, multiple, onMediaSelected, handleClick, ...buttonProps } = props;
  const onChanged = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async ( event ) =>
    {
      const selectedMediaFiles = event.currentTarget.files;
      if ( !!selectedMediaFiles )
      {
        if ( !!onMediaSelected )
        {
          onMediaSelected( selectedMediaFiles );
        }
      }

      if ( inputButtonRef && !!inputButtonRef.current )
      {
        inputButtonRef.current.value = "";
      }
    },
    [onMediaSelected]
  );

  return (
    <DottedOutlineButton {...buttonProps}>
      <Stack alignItems="center">
        <UploadIcon sx={{ height: [45, 64], width: [45, 64], p: 2 }}/>
        <Typography variant="subtitle2"
                    sx={{ lineHeight: 1.1, fontSize: ["14px"], textAlign: "left" }}>{UPLOAD_MEDIA_BUTTON_TEXT}</Typography>
      </Stack>
      <input ref={inputButtonRef} hidden accept={accept} type="file" id={"pick-media-input"} multiple={props.multiple} onChange={onChanged}
             onClick={handleClick}/>
    </DottedOutlineButton>
  )
}
