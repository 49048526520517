//https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect

import { useEffect, useRef } from "react";

export const usePrevious = <T extends any>( value: T ): T | undefined =>
{
  const ref = useRef<T>();
  useEffect( () =>
  {
    ref.current = value;
  } );
  return ref.current;
};