import React, { useEffect, useRef, useState } from 'react'
import "./postPreviewThumbnail.scss";
import { Post } from "./postsSlice";
import { clsx } from "clsx";
import { CanvasUpdater } from "./CanvasUpdater";
import { PlayCircleInContainer } from "../ui/playCircleInContainer";
import { join } from "lodash";

export interface PostPreviewThumbnailProps
{
  iframeId: string;
  post: Post;
}

export function PostPreviewThumbnail( props: PostPreviewThumbnailProps )
{
  const canvasUpdaterRef = useRef<CanvasUpdater | null>( null );
  const post = props.post;
  const [lastRefreshed, setLastRefreshed] = useState( new Date().getTime() );
  const aspectRatio = post.aspect_ratio;

  useEffect( () =>
  {
    return () =>
    {
      if ( !!canvasUpdaterRef.current )
      {
        canvasUpdaterRef.current.onRemove();
      }
    }
  }, [] );

  useEffect( () =>
  {
    setupCanvasListenerIfNeeded();
    if ( !!canvasUpdaterRef.current && !!post )
    {
      canvasUpdaterRef.current.updatePost( post )
      setLastRefreshed( new Date().getTime() ); // this will trigger refresh of iframe url
    }
  }, [post] );

  function setupCanvasListenerIfNeeded()
  {
    if ( !canvasUpdaterRef.current && !!post )
    {
      const canvasUpdater = new CanvasUpdater( props.iframeId, post, false );
      canvasUpdater.createDesignEventListener();
      canvasUpdaterRef.current = canvasUpdater;
    }
  }

  function renderPreview()
  {
    const iframeUrl = CanvasUpdater.getIframeUrl( props.iframeId, lastRefreshed );
    return <div className={clsx( "designCanvasWrapper" )}>
      <iframe id={props.iframeId} title={"designPreview"} className="designIframe" src={iframeUrl} allow="autoplay"/>
      <div className="clickTarget">
        <PlayCircleInContainer/>
      </div>
    </div>;
  }

  return (
    <div>
      <div className={join( ["previewThumbnail", aspectRatio], " " )}>
        {renderPreview()}
      </div>

    </div>
  )
}
