import React from 'react'
import "./postIdea.scss";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { Stack } from "@mui/material";
import "./postPreviewThumbnail.scss";
import { PostIdeaMessage } from "./postIdeaMessage";
import { map } from "lodash";

export interface PostIdeaMessageProps
{
  postIdeas: PostIdeaDataAPI[];
}

export function MultiplePostIdeaMessage( props: PostIdeaMessageProps )
{
  const postIdeas = props.postIdeas;
  const extraStyles = postIdeas.length > 1 ? { justifyContent: "center" } : { ml: "50px" };
  return (
    <Stack direction={"row"} sx={extraStyles} spacing={4}>
      {map( postIdeas, ( postIdea, index ) =>
      {
        return (
          <PostIdeaMessage key={postIdea.id} postIdea={postIdea} postIdeaGroupIndex={index} postIdeaGroupSize={postIdeas.length}/>
        )
      } )}
    </Stack>
  )
}
