import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { MediaItem } from "../mediaList/mediaItem";
import { BusinessMediaStyleAPI } from "../../services/business.services";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export interface BusinessMediaStylePreviewDialogProps
{
  mediaStyle: BusinessMediaStyleAPI;
  open: boolean;
  onClose: () => void;
}

function BusinessMediaStylePreviewDialog( props: BusinessMediaStylePreviewDialogProps )
{
  function handleMediaClicked()
  {
    props.onClose();
  }

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': { width: '90%', maxHeight: '100vh' },
        textAlign: "center",
        p:10,
        'img, video': {
          margin: "0 auto",
          maxWidth: "300px",
          maxHeight: "calc(100svh - 200px)",
          pointerEvents: "none"
        }
      }}
      fullWidth={false}
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle sx={{ position: "relative" }}>{props.mediaStyle.display_name}<IconButton
        aria-label="close"
        size="small"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          zIndex: 99999,
        }}
        onClick={props.onClose}>
        <CloseIcon fontSize="inherit"/>
      </IconButton></DialogTitle>

      <DialogContent sx={{ minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={props.onClose}>
        <MediaItem url={props.mediaStyle.preview_url} handleMediaClicked={handleMediaClicked}/>
      </DialogContent>
      <Typography variant="body1" sx={{ mb: 10,p:5}}>{props.mediaStyle.description}</Typography>
    </Dialog>
  );
}

export default BusinessMediaStylePreviewDialog;
