import { getToApi, makeAuthenticatedRequest } from "./requestManager";
import { ENV_CONFIG } from "../constants";
import { MusicAPI, setTracks } from "../features/music/musicSlice";
import { store } from "../app/store";

export const musicServices = {
  getEpidemicSoundCollection,
  getEpidemicSoundTrack,
}

export interface EpidemicSoundTrackDownloadAPI
{
  epidemic_id: string;
  url: string;
  expires: string;
  start_time_in_seconds: number;
}

async function getEpidemicSoundCollection(): Promise<MusicAPI>
{
  const baseUrl = ENV_CONFIG.baseUrl;
  const endPoint = "music/epidemic_sound_collection";

  return makeAuthenticatedRequest<MusicAPI>( { baseUrl, endPoint, methodType: "GET" } ).then(
    ( data ) =>
    {
      store.dispatch( setTracks( data ) );
      return data;
    }
  );
}

async function getEpidemicSoundTrack( epidemic_id: string ): Promise<EpidemicSoundTrackDownloadAPI>
{
  const endPoint = "music/epidemic_sound_track_download?";

  return getToApi<EpidemicSoundTrackDownloadAPI>( endPoint, { epidemic_track_id: epidemic_id } ).then(
    ( data ) =>
    {
      return data;
    }
  );
}
