import { Navigate, useLocation } from 'react-router-dom';

export const NavigateWithParams = ( { to, ...props } ) =>
{
  const { search } = useLocation();

  return (
    <Navigate to={to + search} {...props} />
  );
};
