import { SxProps, Typography } from "@mui/material";
import React from "react";
import { merge } from "lodash";

export interface AlkaiCaptionProps
{
  children: React.ReactNode;
  sx?: SxProps;
}

export function AlkaiCaption( props: AlkaiCaptionProps )
{
  return (<Typography variant="caption" sx={merge({ color: "#8B8B8B", fontSize: "12px", lineHeight: "14px", fontFamily: "roboto", width: "350px" }, props.sx)}>
    {props.children}
  </Typography>)
}