import React, { useCallback, useEffect, useState } from "react";
import { ToggleButtonGroup } from "@mui/material";
import { OutputFormatAPI, outputFormatServices } from "../../services/outputFormat.services";
import { chain, concat, debounce, filter, includes, map, size, uniq } from "lodash";
import { PlanOutputFormatSelectItem } from "./planOutputFormatSelectItem";
import { outputFormatHelper } from "../planner/outputFormatHelper";
import { businessServices } from "../../services/business.services";
import { useDispatch } from "react-redux";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { OUTPUT_FORMAT_SLUG_CAROUSEL } from "../constants";

export interface PlanOutputFormatsPickerProps
{
  businessOutputFormats: OutputFormatAPI[];
  forceSelectAtLeastOne?: boolean;
  customOutputFormatChangedHandler?: ( newOutputFormatSlugs: OutputFormatAPI[] ) => void;
}

export function PlanOutputFormatsPicker( props: PlanOutputFormatsPickerProps )
{
  const AUTO_SAVE_DELAY_MS = 1000;
  const dispatch = useDispatch();

  const [outputFormatChoices, setOutputFormatChoices] = useState<OutputFormatAPI[]>();
  const businessOutputFormatsSlugs = map( props.businessOutputFormats, ( outputFormat ) => outputFormat.slug );
  const [selectedSlugs, setSelectedSlugs] = useState<string[]>( businessOutputFormatsSlugs );
  const outputFormatChoicesSorted = chain( outputFormatChoices )
    .filter( ( outputFormat ) => outputFormatHelper.doesClientSupport( outputFormat.slug ) )
    .sortBy( ( outputFormat ) => outputFormat.coming_soon )
    .sortBy( ( outputFormat ) => outputFormat.slug === OUTPUT_FORMAT_SLUG_CAROUSEL )
    .value();
  const debounceUpdatePlanSettings = useCallback( debounce( updatePlanSettingsWithSelectedOutputFormatSlugs, AUTO_SAVE_DELAY_MS ),
    [outputFormatChoices] );

  useEffect( () =>
  {
    onMount();
  }, [] );

  async function onMount()
  {
    try
    {
      const response = await outputFormatServices.getOutputFormatsForPlanSettings();
      setOutputFormatChoices( response.output_formats )
    }
    catch (error)
    {
      return Promise.reject( "Could not fetch output formats" );
    }
  }

  function handleChange( event: React.MouseEvent<HTMLElement>, newOutputFormatSlug: string )
  {
    if ( props.forceSelectAtLeastOne && size( selectedSlugs ) === 1 && includes( selectedSlugs, newOutputFormatSlug ) )
    {
      dispatch( setAlertMessage( errorAlert( "You must select at least one post format", "top" ) ) );
    }
    else if ( includes( selectedSlugs, newOutputFormatSlug ) )
    {
      const newSlugs = selectedSlugs.filter( ( slug ) => slug !== newOutputFormatSlug );
      setSelectedSlugs( newSlugs );
      debounceUpdatePlanSettings( newSlugs );
    }
    else
    {
      const newSlugs = uniq( concat( selectedSlugs, newOutputFormatSlug ) );
      setSelectedSlugs( newSlugs );
      debounceUpdatePlanSettings( newSlugs );
    }
  }

  async function updatePlanSettingsWithSelectedOutputFormatSlugs( newOutputFormatSlugs: string[] )
  {
    try
    {
      if ( props.customOutputFormatChangedHandler )
      {
        const newOutputFormats = filter( outputFormatChoicesSorted, ( outputFormat ) => includes( newOutputFormatSlugs, outputFormat.slug ) )
        props.customOutputFormatChangedHandler( newOutputFormats );
      }
      else
      {
        await businessServices.updatePlanSettings( undefined, newOutputFormatSlugs );

        eventTracker.logOutputFormatSettingsChanged( newOutputFormatSlugs, outputFormatChoices );
      }
    }
    catch (error)
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem updating the output formats", "top" ) ) );
    }
  }

  return <ToggleButtonGroup
    color="primary"
    value={selectedSlugs}
    exclusive={true}
    onChange={handleChange}
    sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", gap: 10, mt: 10 }}>
    {map( outputFormatChoicesSorted, ( outputFormat ) =>
    {
      const selected = includes( selectedSlugs, outputFormat.slug );
      const border = selected ? "2px solid" : "1px solid";
      const borderColor = selected ? "primary.main" : "grey.400";
      return <PlanOutputFormatSelectItem key={outputFormat.slug} outputFormat={outputFormat} selected={selected}
                                         value={outputFormat.slug}
                                         sx={{
                                           outline: border,
                                           outlineColor: borderColor,
                                           borderRadius: 0,
                                           position: "relative",
                                           overflow: "hidden"
                                         }}/>;
    } )}
  </ToggleButtonGroup>
}