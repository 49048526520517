import { Box } from "@mui/material";
import React from "react";

export interface UserSettingsRowIconProps
{
  icon?: React.ReactNode;
}

export function UserSettingsRowIcon( props: UserSettingsRowIconProps )
{
  const sx = { width: "35px", color: "black" };

  return <Box sx={sx}>
    {props.icon}
  </Box>
}
