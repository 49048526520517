import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { businessServices, SocialNetworkAccountAPI } from "../../services/business.services";
import { currentUserBusinessId } from "../business/businessSlice";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { UserSettingsRowIcon } from "./userSettingsRowIcon";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { SocialAccountProfileIcon } from "../ui/socialAccountProfileIcon";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { apptimizeVariables } from "../apptimize/apptimizeVariables";

export interface SocialMediaAccountsSummaryProps
{
  onClick: () => void;
}

export function SocialMediaAccountsSummary( props: SocialMediaAccountsSummaryProps )
{
  const dispatch = useDispatch();
  const [socialNetworkAccounts, setSocialNetworkAccounts] = React.useState<SocialNetworkAccountAPI[]>( [] );
  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );
  const [loading, setLoading] = React.useState( true );
  const shouldAllowTiktok = apptimizeVariables.shouldAllowTiktok();
  const shouldAllowYoutube = apptimizeVariables.shouldAllowYoutube();

  useEffect( () =>
  {
    onMountAndCurrentBusinessSet();
  }, [isCurrentBusinessSet] );

  async function onMountAndCurrentBusinessSet()
  {
    if ( isCurrentBusinessSet )
    {
      setLoading( true );
      try
      {
        const response = await businessServices.listSocialNetworkAccounts();
        setSocialNetworkAccounts( response.social_network_accounts );
      }
      catch (error)
      {
        dispatch( setAlertMessage( errorAlert( "There was a problem loading your social media accounts. Please try again." ) ) );
      }
      finally
      {
        setLoading( false );
      }
    }
  }

  function updateSocialNetworkAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] )
  {
    setSocialNetworkAccounts( socialNetworkAccounts );
  }

  function getConnectedProfilesOrAdd()
  {
    const connectedFacebookAccount = socialNetworkAccountHelper.getConnectedFacebookPage( socialNetworkAccounts );
    const connectedInstagramAccount = socialNetworkAccountHelper.getConnectedInstagram( socialNetworkAccounts );
    const connectedTiktokAccount = socialNetworkAccountHelper.getConnectedTiktok( socialNetworkAccounts );
    const connectedYoutubeAccount = socialNetworkAccountHelper.getConnectedYoutube( socialNetworkAccounts );

    const needsToConnectYoutube = shouldAllowYoutube && !connectedYoutubeAccount;
    const needsToConnectTiktok = shouldAllowTiktok && !connectedTiktokAccount;

    const needsToConnectSocialNetworkAccounts = !connectedFacebookAccount ||
                                                !connectedInstagramAccount ||
                                                needsToConnectTiktok ||
                                                needsToConnectYoutube;
    return <Stack direction="row" spacing={5} sx={{ cursor: "pointer" }} alignItems="center" onClick={props.onClick}>
      {
        connectedFacebookAccount && <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                   <SocialAccountProfileIcon profileImageUrl={connectedFacebookAccount.profile_image_url}
                                                             accountType={connectedFacebookAccount.account_type}/>
                                 </Stack>
      }
      {
        connectedInstagramAccount && <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                    <SocialAccountProfileIcon profileImageUrl={connectedInstagramAccount.profile_image_url}
                                                              accountType={connectedInstagramAccount.account_type}/>
                                  </Stack>
      }
      {
        shouldAllowTiktok && connectedTiktokAccount && <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                            <SocialAccountProfileIcon profileImageUrl={connectedTiktokAccount.profile_image_url}
                                                      accountType={connectedTiktokAccount.account_type}/>
                          </Stack>
      }
      {
        shouldAllowYoutube && connectedYoutubeAccount && <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                             <SocialAccountProfileIcon profileImageUrl={connectedYoutubeAccount.profile_image_url}
                                                       accountType={connectedYoutubeAccount.account_type}/>
                           </Stack>
      }

      {needsToConnectSocialNetworkAccounts && <Stack direction="row" spacing={2}
                                                     sx={{ pl: 5 }}><GroupAddOutlinedIcon/><Typography>Connect</Typography></Stack>}
    </Stack>
  }

  return <>
    <SocialNetworkAccountsContext.Provider value={{ socialNetworkAccounts, updateSocialNetworkAccounts }}>
      <Stack sx={{ pb: 5 }}>
        <Stack direction="row">
          <UserSettingsRowIcon icon={<ShareOutlinedIcon/>}/>
          <Typography>Social media accounts</Typography>
        </Stack>
        <Stack sx={{ pl: "35px" }}>
          {loading && <CircularProgress/>}
          {!loading && getConnectedProfilesOrAdd()}
        </Stack>
      </Stack>
    </SocialNetworkAccountsContext.Provider>
  </>
}