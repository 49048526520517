import { Box, Button, Stack, TextField } from "@mui/material";
import { ColorAPI, ColorPaletteAPI, colorPaletteServices } from "../../../services/colorPalette.services";

import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { debounce, findIndex, map, replace, size, startsWith } from "lodash";
import { EditColorPaletteSwatchButton } from "./editColorPaletteSwatchButton";
import { usePrevious } from "../../hooks/usePrevious";
import { isColorPaletteFavorited } from "./colorPaletteHelper";
import ShuffleIcon from "@mui/icons-material/Shuffle";

export interface EditColorPaletteProps
{
  colorPalette: ColorPaletteAPI;
  updateColorPaletteAfterCopy: ( colorPalette: ColorPaletteAPI ) => void;
}

export function EditColorPalette( props: EditColorPaletteProps )
{
  const [localColorPalette, setLocalColorPalette] = useState( props.colorPalette );
  const [selectedColorObject, setSelectedColor] = useState( localColorPalette.colors_json[0] );
  const [localColorPaletteName, setLocalColorPaletteName] = useState( localColorPalette.name );
  const [hasShuffled, setHasShuffled] = useState( false );
  const prevProps = usePrevious( props );

  const updateColorPaletteColors = async () =>
  {
    const apiResponse = await colorPaletteServices.updateColorPaletteColors( localColorPalette );
    if ( apiResponse.color_palette )
    {
      props.updateColorPaletteAfterCopy( apiResponse.color_palette );
    }
  }

  const updateColorPaletteName = async () =>
  {
    const apiResponse = await colorPaletteServices.updateColorPaletteName( localColorPalette );
    if ( apiResponse.color_palette )
    {
      props.updateColorPaletteAfterCopy( apiResponse.color_palette );
    }
  }

  const debouncedUpdateColorPaletteColors = useCallback( debounce( updateColorPaletteColors, 300 ), [localColorPalette] );
  const debouncedUpdateColorPaletteName = useCallback( debounce( updateColorPaletteName, 300 ), [localColorPalette] );

  useEffect( () =>
    {
      const colorPaletteSlugSwitched = prevProps?.colorPalette && prevProps?.colorPalette.slug !== props.colorPalette.slug;
      const colorPaletteColorsJsonSwitched = prevProps?.colorPalette && prevProps?.colorPalette.colors_json !== props.colorPalette.colors_json;
      if ( colorPaletteSlugSwitched || colorPaletteColorsJsonSwitched )
      {
        const newVar = { ...props.colorPalette };
        setLocalColorPalette( newVar );

        const usage = selectedColorObject.usage;
        const localColorsJson = newVar.colors_json;
        const idxOfColorObject = findIndex( localColorsJson, ( colorObject ) => colorObject.usage === usage );
        if ( idxOfColorObject > -1 )
        {
          setSelectedColor( localColorsJson[idxOfColorObject] );
        }

        setLocalColorPaletteName( newVar.name );
      }
    }
    , [props.colorPalette] );

  const handleColorSwatchSelected = ( colorObject: ColorAPI ) =>
  {
    setSelectedColor( colorObject );
  }

  const handleShuffleClicked = async () =>
  {
    const apiResponse = await colorPaletteServices.shuffleColorPaletteColors( localColorPalette, !hasShuffled );
    if ( apiResponse.color_palette )
    {
      setHasShuffled( true );
      props.updateColorPaletteAfterCopy( apiResponse.color_palette );
    }
  }

  function isValidSixCharHex( colorHex: string )
  {
    const hexWithoutHash = replace( colorHex, "#", "" );
    return startsWith( colorHex, "#" ) && size( hexWithoutHash ) === 6;
  }

  const handleColorChange = ( colorHex: string ) =>
  {
    if ( isValidSixCharHex( colorHex ) )
    {
      const usage = selectedColorObject.usage;
      const localColorsJson = localColorPalette.colors_json;
      const idxOfColorObject = findIndex( localColorsJson, ( colorObject ) => colorObject.usage === usage );

      if ( idxOfColorObject > -1 )
      {
        const colorObjectToModify = localColorsJson[idxOfColorObject];
        const newColorObject = { ...colorObjectToModify, hex: colorHex };
        localColorsJson[idxOfColorObject] = newColorObject;

        setLocalColorPalette( localColorPalette );
        setSelectedColor( newColorObject );
        debouncedUpdateColorPaletteColors();
      }
    }
  }

  const handlePaletteNameChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    localColorPalette.name = event.target.value;
    setLocalColorPalette( localColorPalette );
    setLocalColorPaletteName( localColorPalette.name );
    debouncedUpdateColorPaletteName();
    return;
  }

  return (
    <Stack alignItems="center" sx={{ p: 4, px: 8, maxWidth: "400px", margin: "auto" }}>
      {isColorPaletteFavorited( props.colorPalette ) && <TextField
        label="Palette name"
        value={localColorPaletteName}
        onInput={handlePaletteNameChange}
        fullWidth/>}
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ mt: 4, width: "100%" }}>
        <Stack direction="column" style={{ marginRight: 8, width: "100%" }}>
          <HexColorPicker style={{ paddingBottom: 4, width: "100%" }} color={selectedColorObject.hex} onChange={handleColorChange}/>
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "center" }}>
            Hex <HexColorInput style={{ textTransform: "uppercase", textAlign: "center", alignSelf: "center", width: "90px", marginLeft: 4 }}
                               color={selectedColorObject.hex} onChange={handleColorChange}/>
          </Box>
        </Stack>
        <Stack>
          {map( localColorPalette.colors_json, ( color, index ) =>
          {
            const isSelected = selectedColorObject === color;
            return (
              <EditColorPaletteSwatchButton key={color.usage} colorObject={color} selected={isSelected}
                                            handleColorSelected={handleColorSwatchSelected}/>
            )
          } )}
          <Button variant="contained" color="primary" onClick={handleShuffleClicked}><ShuffleIcon/></Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
