import { ENV_CONFIG } from "../../constants";
import { chain, concat, lowerCase } from "lodash";
import { GIF, MOV, MP4, PNG, WEBP } from "../constants";

export class UrlUtils
{
  cdnBaseUrl = ENV_CONFIG.cdnBaseUrl;

  static isParamValueTrue( value?: string | boolean | null )
  {
    return lowerCase( value?.toString() ) === "true";
  }

  public convertAllUrlsToCorsFriendlyUrls( data: string )
  {
    return this.convertAllS3UrlsInJsonString( data, this.cdnBaseUrl );
  }

  public convertAllS3UrlsInJsonString( jsonString: string, baseUrl: string )
  {
    if ( !jsonString )
    {
      return jsonString;
    }

    jsonString = jsonString.replace( /http:/, "https:" );
    return jsonString;
  }

  public modifyUrlWithContextToFixCrossOriginIssues( src, context )
  {
    // https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome

    const url = new URL( src );
    url.searchParams.set( context, "true" );
    return url.toString();
  }

  public getFilenameFromUrlString( urlString: string )
  {
    try
    {
      const url = new URL( urlString );
      return chain( url.pathname ).split( "/" ).last().value();
    }
    catch (e)
    {
      return "";
    }
  };

  public isVideoUrl( urlString: string )
  {
    const filenameFromUrlString = this.getFilenameFromUrlString( urlString );

    return this.isMP4VideoFile( filenameFromUrlString ) || this.isMovVideoFile( filenameFromUrlString );
  }

  public isMP4VideoFile( fileName: string )
  {
    return chain( fileName ).toLower().endsWith( MP4 ).value();
  }

  public isMovVideoFile( fileName: string )
  {
    return chain( fileName ).toLower().endsWith( MOV ).value();
  }

  public buildUrl( basePath: string, pathPieces: string[] )
  {
    return concat( [basePath], pathPieces ).join( "/" );
  }

  public isPngFile( fileName: string )
  {
    return chain( fileName ).toLower().endsWith( PNG ).value();
  }

  public isWebpFile( fileName: string )
  {
    return chain( fileName ).toLower().endsWith( WEBP ).value();
  }

  public isGifFile( fileName: string )
  {
    return chain( fileName ).toLower().endsWith( GIF ).value();
  }

  public isFileTypeThatSupportsTransparency( fileName?: string )
  {
    if ( !fileName )
    {
      return false;
    }
    return this.isPngFile( fileName ) || this.isWebpFile( fileName ) || this.isGifFile( fileName );
  }
}

