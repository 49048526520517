import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";

export interface ApplyBrandKitDialogProps
{
  openApplyBrandKitDialog: boolean;
  closeApplyBrandKitDialog: ( applyBrandKit: boolean ) => void;
}

export function ApplyBrandKitDialog( props: ApplyBrandKitDialogProps )
{
  function applyBrandKitToPost()
  {
    props.closeApplyBrandKitDialog( true );
  }

  function closeWithoutApplyingBrandKit()
  {
    props.closeApplyBrandKitDialog( false );
  }

  return (<Dialog
    fullWidth={false}
    maxWidth={"sm"}
    open={props.openApplyBrandKitDialog}
  >
    <DialogTitle>Apply brand kit</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Do you want to apply your brand kit colors and fonts to the brand card for this post?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeWithoutApplyingBrandKit}>No</Button>
      <Button variant="contained" onClick={applyBrandKitToPost}>Apply</Button>
    </DialogActions>
  </Dialog>)
}
