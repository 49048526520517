import { RefreshBusinessAPI } from "../../services/business.services";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { BUSINESS_SWITCHER_TEXT_COLOR_CLASS_NAME } from "./businessSwitcher";
import { businessHelper } from "../../helpers/businessHelper";
import { BusinessAvatar } from "./businessAvatar";

export interface BusinessSwitcherRowProps
{
  business: RefreshBusinessAPI;
  selected: boolean;
}

export function BusinessSwitcherRow( props: BusinessSwitcherRowProps )
{
  return <Stack direction="row" spacing={4} alignItems="center">
    <BusinessAvatar business={props.business}/>
    <Typography variant="subtitle2" className={BUSINESS_SWITCHER_TEXT_COLOR_CLASS_NAME}
                color={props.selected ? "white" : "black"}>{businessHelper.getBusinessNameToDisplay( props.business )}</Typography>

  </Stack>

}