import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from "@mui/material";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { userHelper } from "../user/userHelper";
import useRemoveSearchParamByKey from "../hooks/useRemoveSearchParamByKey";

export function NewBusinessButton()
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const removeSearchParamByKey = useRemoveSearchParamByKey();

  async function handleAddBusiness()
  {
    await userHelper.clearChatAndMakeNewBusiness( dispatch, navigateWithSearchParams, removeSearchParamByKey );
  }

  return (
    <Button aria-label="start new business" variant="outlined" color="error" onClick={handleAddBusiness}>New Business</Button>
  )
}
