import * as React from "react";
import { Avatar, Button, List, ListItem, Stack, Typography } from "@mui/material";
import "./confirmFacebookAccount.scss";
import { LoadingButton } from "@mui/lab";

export interface ConfirmFacebookAccountProps
{
  profileUrl: string;
  profileName: string;
  onSwitchAccounts( event: React.MouseEvent );
  onConfirm( event: React.MouseEvent );
  showLoader: boolean;
}

export function ConfirmFacebookAccount( props: ConfirmFacebookAccountProps )
{
  function getAvatarSXProps()
  {
    return {
      width: "92px",
      height: "92px",
      display: "block",
      margin: "8px auto 8px auto",
      background: "none",
      border: "1px solid silver"
    }
  }

  return (
    <Stack sx={{display:"flex", alignItems:"center"}}>
      <Typography sx={{ fontWeight:"bold", fontSize:20, mt:5, mb:3 }}>Is this you?</Typography>
      <Typography>I want to connect you to the right account.</Typography>
        <Avatar sx={getAvatarSXProps()} src={props.profileUrl} role="presentation"/>
        <Typography>{props.profileName}</Typography>
        <List sx={{ textAlign: 'center', mx: 'auto' }}>
          <ListItem>
            <LoadingButton aria-label="yes that's me" variant="contained" color="primary" sx={{ mx: 'auto' }}
                           onClick={props.onConfirm} loading={props.showLoader}>
              Yes, that's me
            </LoadingButton>
          </ListItem>
          <ListItem>
            <Button aria-label="switch accounts" variant="text" disabled={props.showLoader} sx={{ mx: 'auto' }}
                    onClick={props.onSwitchAccounts}>Switch Accounts</Button>
          </ListItem>
        </List>
    </Stack>
  )
}
