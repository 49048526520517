import * as React from 'react';
import { DirectShare, ShareNowTabContentProps } from "./directShare";

export interface ScheduledShareProps extends ShareNowTabContentProps
{
  handleBackClicked: () => void;
}
export function ScheduledShare(props: ScheduledShareProps)
{
  return (
    <DirectShare isScheduling={true}
                 sharedFrom={props.sharedFrom}
                 initialDate={props.initialDate}
                 previousShareBatch={props.previousShareBatch}
                 handleShareComplete={props.handleShareComplete}
                 handleBackClicked={props.handleBackClicked}/>);
}

