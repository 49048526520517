import React from "react";
import { Slider, Stack, Typography } from "@mui/material";
import { isUndefined } from "lodash";

export interface PostingStrategyQuizContentMixProps
{
  id: string;
  onQuestionAnswered: ( id: string, value: number ) => void;
  value?: number;
}

export function PostingStrategyQuizContentMix( props: PostingStrategyQuizContentMixProps )
{
  const defaultValue = 50;
  const handleChange = ( event: Event, newValue: number | number[] ) =>
  {
    props.onQuestionAnswered( props.id, newValue as number );
  };

  return (<Stack>
    <Stack spacing={10}>
      <Typography variant="subtitle1">Use the slider to match your brand voice.</Typography>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle1"><b>Promote</b></Typography>
          <Typography variant="subtitle1"><b>Engage</b></Typography>
        </Stack>
        <Slider aria-label="choice" value={isUndefined( props.value ) ? defaultValue : props.value} onChange={handleChange}/>
      </Stack>
    </Stack>
    <Stack spacing={10} sx={{ mt: 20 }}>
      <Typography variant="subtitle1"><b>Promote:</b> I want my posts to acquire leads, highlight products, sale events, or services.</Typography>
      <Typography variant="subtitle1"><b>Engage:</b> I want my posts to be insightful, educational, ask questions, provide updates, showcase my
                                                     community.</Typography>
    </Stack>
  </Stack>);
}