import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getUserSlug, getUserWithoutAuthData, isUserLoggedIn } from "../user/userSlice";
import { isEqual } from "lodash";
import { getBusiness } from "../business/businessSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { useVisibilityChange } from "@uidotdev/usehooks";

export function EventTracking()
{
  // put events that need to be tracked on renders here so the full app component doesn't have to re-render

  const userWithoutAuthData = useSelector( ( state: RootState ) => getUserWithoutAuthData( state ), isEqual );
  const business = useSelector( ( state: RootState ) => getBusiness( state ), isEqual );
  const documentVisible = useVisibilityChange();
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const userSlug = useSelector( ( state: RootState ) => getUserSlug( state ) );

  useEffect( () =>
  {
    eventTracker.setUserPropertiesForCurrentUser()
  }, [userWithoutAuthData, business] );

  useEffect( () =>
  {
    if ( documentVisible )
    {
      eventTracker.logAlkaiAppVisible();
    }
    else
    {
      eventTracker.logAlkaiAppHidden();
    }
  }, [documentVisible] );

  useEffect( () =>
  {
    if ( userIsLoggedIn )
    {
      if ( userSlug )
      {
        eventTracker.setUserId( userSlug );
      }
    }
  }, [userIsLoggedIn] );
  return <></>
}

