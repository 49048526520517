import { FontSetAPI, FontSetOwnerTypeAPI, FontSetStatusAPI } from "../../../services/fontSet.services";

export function isFontSetFavorited( fontSet: FontSetAPI )
{
  return isFontSetOwnedByBusiness( fontSet ) &&
         fontSet?.status === FontSetStatusAPI.Live;
}

export function isFontSetOwnedByBusiness( fontSet: FontSetAPI )
{
  return fontSet?.owner_type === FontSetOwnerTypeAPI.Business;
}