import { getToApi } from "./requestManager";
import { concat } from "lodash";
import { OutputFormatSlug } from "../features/constants";

export const outputFormatServices = {
  getOutputFormats,
  getOutputFormatsForPlanSettings
}

const OUTPUT_FORMATS_BASE_PATH = "output_formats";
const OUTPUT_FORMATS_LIST_ENDPOINT = "list"
const OUTPUT_FORMATS_LIST_FOR_PLAN_SETTINGS_ENDPOINT = "list_for_plan_settings"

export interface OutputFormatAPI
{
  slug: OutputFormatSlug;
  display_name: string;
  coming_soon: boolean;
  extend_plan_value: string;
}

export interface OutputFormatsListAPI
{
  output_formats: OutputFormatAPI[],
}

async function getOutputFormats()
{
  try
  {
    const endpointUrl = buildUrl( [OUTPUT_FORMATS_LIST_ENDPOINT] );
    return await getToApi<OutputFormatsListAPI>( endpointUrl );
  }
  catch (error)
  {
    return Promise.reject( "Could not fetch output formats" );
  }
}

async function getOutputFormatsForPlanSettings()
{
  const endpointUrl = buildUrl( [OUTPUT_FORMATS_LIST_FOR_PLAN_SETTINGS_ENDPOINT] );
  return await getToApi<OutputFormatsListAPI>( endpointUrl );
}

function buildUrl( pathPieces: string[] )
{
  return concat( [OUTPUT_FORMATS_BASE_PATH], pathPieces ).join( "/" );
}
