import { Stack } from "@mui/material";
import React from "react";
import { map } from "lodash";
import { CurrentStreakAPI, StreakDayAPI } from "../../services/business.services";
import { DailyPostingDot } from "./dailyPostingDot";

export interface WeeklyPostingMeterProps
{
  streak: CurrentStreakAPI;
}

export function WeeklyPostingMeter( props: WeeklyPostingMeterProps )
{
  const streakDays = props.streak.streak_days;

  return <Stack direction="row" justifyContent="space-evenly" alignItems="center"
                sx={{ justifySelf: "center", boxShadow: "0 10px 10px -10px #333", px: 10, pb: 5 }}>
    {map( streakDays, ( streakDay: StreakDayAPI, index: number ) =>
    {
      return <DailyPostingDot dateString={streakDay.date} key={streakDay.date}
                              hasCompletedPost={streakDay.completed}/>
    } )}

  </Stack>
}