import * as React from "react";
import { BottomNavigationAction, Tooltip } from "@mui/material";

export function BottomNavigateActionWithTooltipWhenDisabled( props )
{
  const { disabled, sx, tooltipText, ...otherProps } = props
  const combinedSx = {
    ...sx,
    "&.Mui-disabled": {
      pointerEvents: "auto"
    }
  }

  if ( disabled )
  {
    return (<Tooltip title={"Disabled for completed post"} enterTouchDelay={50}>
      <BottomNavigationAction component="div" {...props} sx={{ ...combinedSx }} onChange={undefined}/>
    </Tooltip>)
  }

  return (<BottomNavigationAction {...otherProps} />)
}