import React from "react";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import { Button } from "@mui/material";

interface RefreshSocialNetworkButtonProps
{
  iconOnly?: boolean;
  handleClick: ( event: React.MouseEvent ) => void;
  showLoader: boolean;
}

export function RefreshSocialNetworkButton( props: RefreshSocialNetworkButtonProps )
{
  function handleClick( event: React.MouseEvent )
  {
    if ( !!props.handleClick )
    {
      props.handleClick( event );
    }
  }

  if ( props.iconOnly )
  {
    const loadingClass = props.showLoader ? "flipAndRotate" : "";
    return <IconButton onClick={handleClick}
                       sx={{ transform: "scaleX(-1)", color: "red", pointerEvents: props.showLoader ? "none" : "default" }} className={loadingClass}
    ><SyncIcon/></IconButton>
  }
  else
  {
    const buttonStyle = {
      ml: "auto", mr: 1, backgroundColor: "white", color: "#DB1B50", fontSize: "10px", fontWeight: "400",
      '&:hover': { backgroundColor: '#F2F2F2', color: '#CB0046' }, pointerEvents: props.showLoader ? "none" : "default"
    };
    const buttonText = props.showLoader ? "Refreshing..." : "Refresh";
    return <Button onClick={handleClick} variant={"contained"}
                   sx={buttonStyle}>{buttonText}</Button>
  }

}
