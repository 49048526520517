import { Box, SxProps } from "@mui/material";
import * as React from "react";
import { merge } from "lodash";

export interface FullPageMobileContentProps
{
  sx?: SxProps;
  children: React.ReactNode;
}

export function FullPageMobileContent( props: FullPageMobileContentProps )
{
  return <Box sx={merge({ overflowY: "auto", flexGrow: 10 }, props.sx)}>
    {props.children}
  </Box>
}