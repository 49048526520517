import { Button } from "@mui/material";

export interface PurchaseFlowCTAProps
{
  children?: React.ReactNode;
  onClick?(): void;
}

export function PurchaseFlowCTA( props: PurchaseFlowCTAProps )
{
  return (
    <Button
      variant="contained"
      color="alternate"
      sx={{
        display: "flex",
        mx: "auto",
        mt: 10,
        mb: 5,
        p: "12px 18px",
        borderRadius: "20px",
        textColor: "primary.dark",
        fontSize: "15px",
        lineHeight: "18px",
        fontFamily: "Montserrat",
        fontWeight: "bold",
        textTransform: "none"
      }}
      onClick={props.onClick}>
      {props.children}
    </Button>
  );
}


