import * as React from "react";
import { ReactNode } from "react";
import { Box, Stack } from "@mui/material";
import { PurchaseFlowBenefitText } from "./purchaseFlowBenefitText";

export interface PaymentConfirmationPointProps
{
  iconSrc: any;
  iconAlt: string;
  children: ReactNode;
}

export function PaymentConfirmationPoint( props: PaymentConfirmationPointProps )
{
  return (
    <Stack sx={{
      background: "rgba(255, 255, 255, .7)",
      borderRadius: "8px",
      padding: "9px 17px 9px 9px",
      marginTop: "6px",
      marginBottom: "6px"
    }} direction="row"
           alignItems="center">
      <Box component="img" sx={{
        height: "42px",
        marginRight: "9px",
      }}
           src={props.iconSrc}
           alt={props.iconAlt}/>
      <PurchaseFlowBenefitText sx={{ color: "#39156C", fontWeight: 600 }} small>{props.children}</PurchaseFlowBenefitText>
    </Stack>
  )
}


