import * as React from "react";
import { Box, SxProps } from "@mui/material";
import { concat, includes, merge } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { hasRemainingFreePosts } from "../user/userSlice";

interface TabPanelProps
{
  children?: React.ReactNode;
  index: number;
  additionalValidIndices?: number[];
  value: number;
  sx?: SxProps;
}

export function CustomTabPanel( props: TabPanelProps )
{
  const { children, value, index, additionalValidIndices, sx, ...other } = props;

  const remainingFreePosts = useSelector( ( state: RootState ) => hasRemainingFreePosts( state ) );
  const bannerVisibility = remainingFreePosts ? "bannerVisible" : "bannerHidden";

  const dynamicTopPadding =
    {
      bannerVisible: 0,
      bannerHidden: 5,
    };

  const defaultSxProps: SxProps = {
    height: "100%",
    px: 10,
    pt: dynamicTopPadding[bannerVisibility],
  };

  const mergedSx = merge( defaultSxProps, sx );

  const validIndices = concat( [index], additionalValidIndices );
  const shouldHide = !includes( validIndices, value );

  if ( shouldHide && mergedSx.display !== "none" )
  {
    mergedSx.display = "none";
  }

  return (
    <Box
      role="tabpanel"
      hidden={shouldHide}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={mergedSx}
      {...other}
    >
      {!shouldHide && (
        <>
          {children}
        </>
      )}
    </Box>
  );
}
