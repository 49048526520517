import React, { useCallback, useEffect } from "react";
import { getNotices, hasNotices } from "./inAppNoticeSlice";
import { debounce, map } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { isUserLoggedIn } from "../user/userSlice";
import { inAppNoticeServices } from "../../services/inAppNotice.services";
import { InAppNoticeBanner } from "./InAppNoticeBanner";

export function InAppNoticeBanners()
{
  const documentVisible = useVisibilityChange();
  const showInAppBanner = useSelector( ( state: RootState ) => hasNotices( state ) );
  const inAppNotices = useSelector( ( state: RootState ) => getNotices( state ) );
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );

  const DEBOUNCE_DELAY = 200;
  const debounceGetNotices = useCallback( debounce( getHealthOrUserNotices, DEBOUNCE_DELAY ), [] );

  async function getHealthOrUserNotices()
  {
    inAppNoticeServices.getNotices();
  }

  async function onUnmount()
  {
    if ( !!debounceGetNotices )
    {
      debounceGetNotices.cancel();
    }
  }

  useEffect( () =>
  {
    debounceGetNotices();
    return () =>
    {
      onUnmount();
    }
  }, [] );

  useEffect( () =>
  {
    if ( documentVisible )
    {
      debounceGetNotices();
    }
  }, [documentVisible] );

  useEffect( () =>
  {
    debounceGetNotices();
  }, [userIsLoggedIn] );

  return (<>
    {
      showInAppBanner &&
      map( inAppNotices, ( notice, index ) => <InAppNoticeBanner notice={notice} key={notice.id}/> )
    }
  </>);
}