import * as React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function TiktokIcon( props: SvgIconProps )
{
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24"
           fill="currentColor">
        <path
          d="M11.93.13C5.34.13,0,5.47,0,12.07s5.34,11.93,11.93,11.93,11.93-5.34,11.93-11.93S18.53.13,11.93.13ZM18.71,10.68c-1.33,0-2.63-.41-3.71-1.19v5.43c0,1.32-.53,2.59-1.48,3.52-1.94,1.9-5.06,1.86-6.95-.08-1.06-1.09-1.56-2.6-1.35-4.11.37-2.69,2.85-4.57,5.54-4.2v2.72c-.7-.22-1.46-.09-2.04.35-.99.75-1.18,2.16-.43,3.15.42.56,1.08.89,1.78.89,1.24,0,2.25-1,2.26-2.24V4.3h2.68v.34c.15,1.62,1.34,2.96,2.93,3.3.26.05.52.08.78.08v2.66h0Z"/>
      </svg>
    </SvgIcon>
  )
}
