import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MediaItem } from "../mediaList/mediaItem";
import CircularProgress from "@mui/material/CircularProgress";

export interface MediaPreviewDialogProps
{
  open: boolean;
  mediaUrl: string;
  onClose: () => void;
  onConfirm?: () => void;
  showCancel?: boolean;
  confirmButtonTitle?: string;
}

export function MediaPreviewDialog( props: MediaPreviewDialogProps )
{
  const { onClose, onConfirm, showCancel, confirmButtonTitle, open, mediaUrl, ...other } = props;

  function handleConfirm()
  {
    if ( onConfirm )
    {
      onConfirm();
    }
    else
    {
      onClose();
    }
  }

  function handleClose()
  {
    onClose();
  }

  function getConfirmButtonTitle()
  {
    return confirmButtonTitle || "Ok";
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: '100vh' } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogContent sx={{ minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {mediaUrl && <MediaItem url={mediaUrl} showControls={true}/>}
        {!mediaUrl && <CircularProgress/>}
      </DialogContent>
      <DialogActions>
        {showCancel && <Button variant={"outlined"} onClick={handleClose}>Cancel</Button>}
        <Button variant={"contained"} onClick={handleConfirm}>{getConfirmButtonTitle()}</Button>
      </DialogActions>
    </Dialog>
  );
}

