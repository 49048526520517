import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isUserLoggedIn } from "../user/userSlice";
import { eventTracker } from "../../helpers/eventTracker";
import {
  LANDING_WITHOUT_WEBSITE,
  LANDING_WITHOUT_WEBSITE_USER_MESSAGE,
  ROUTES,
  SEARCH_PARAM_KEY_SOURCE,
  SEARCH_PARAM_KEY_WEBSITE,
  SEARCH_PARAM_VALUE_UNKNOWN
} from "../constants";
import { useSearchParams } from "react-router-dom";
import useRemoveSearchParamByKey from "../hooks/useRemoveSearchParamByKey";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";
import { authenticationServices } from "../../services/authentication.services";
import { chatServices } from "../../services/chat.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { currentUserBusinessId } from "../business/businessSlice";

export function AfterAppInitializationSetup()
{
  // put setup that needs to wait until apptimize is initialized here, for example an onboarding test

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const removeSearchParamByKey = useRemoveSearchParamByKey();
  const [hasProcessedWebsite, setHasProcessedWebsite] = useState( false );

  useEffect( () =>
  {
    const website = searchParams.get( SEARCH_PARAM_KEY_WEBSITE );
    if ( hasProcessedWebsite )
    {
      return;
    }
    else if ( !userIsLoggedIn )
    {
      if ( !!website )
      {
        setHasProcessedWebsite( true );
        removeSearchParamByKey( SEARCH_PARAM_KEY_WEBSITE );
        signInAsGuestWithWebsite( website );
      }
      else
      {
        navigateWithSearchParams( ROUTES.SIGN_IN );
      }
    }
    else if ( userIsLoggedIn && !!website && isCurrentBusinessSet )
    {
      setHasProcessedWebsite( true );
      removeSearchParamByKey( SEARCH_PARAM_KEY_WEBSITE );
      sendWebsiteAsMessageIfAlreadyLoggedIn( website );
    }
    else
    {
      cleanUpSearchParams();
    }
  }, [userIsLoggedIn, isCurrentBusinessSet] );

  function signInAsGuestWithWebsite( website: string )
  {
    const source = searchParams.get( SEARCH_PARAM_KEY_SOURCE ) || SEARCH_PARAM_VALUE_UNKNOWN;
    dispatch( setApplicationLoadingState( true ) );
    eventTracker.logInitialRunShown( source, website );
    authenticationServices.signInAsGuest().then( ( data ) =>
    {
      if ( website === LANDING_WITHOUT_WEBSITE )
      {
        chatServices.sendMessage( LANDING_WITHOUT_WEBSITE_USER_MESSAGE );
      }
      else
      {
        chatServices.sendMessage( website );
      }
    } ).catch( ( e ) =>
    {
      dispatch( setAlertMessage( errorAlert( "Hmm, something failed on our end. Please try again." ) ) );
      navigateWithSearchParams( ROUTES.SIGN_IN );
    } ).finally( () =>
    {
      dispatch( setApplicationLoadingState( false ) );
    } );
  }

  function cleanUpSearchParams()
  {
    removeSearchParamByKey( SEARCH_PARAM_KEY_SOURCE );
  }

  function sendWebsiteAsMessageIfAlreadyLoggedIn( website: string )
  {
    if ( website !== LANDING_WITHOUT_WEBSITE )
    {
      chatServices.sendMessage( website );
    }
  }

  return <></>
}

