import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tooltip,
  Typography
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TIKTOK_PRIVACY_OPTIONS_DISPLAY_NAME_MAP, TIKTOK_PRIVACY_SELF_ONLY, TiktokPrivacyOption } from "../constants";
import { map } from "lodash";
import { TiktokBrandingAPI, TiktokInteractionOptionsAPI } from "../../services/business.services";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export interface TiktokPrivacySectionProps
{
  isStaticPost: boolean,
  privacy: TiktokPrivacyOption,
  privacyOptions: TiktokPrivacyOption[],
  showDisclosureSection: boolean,
  interactionOptions: TiktokInteractionOptionsAPI,
  interactionSettings: TiktokInteractionOptionsAPI,
  brandingSettings: TiktokBrandingAPI,
  handlePrivacyChanged: ( privacy: TiktokPrivacyOption ) => void,
  handleDisclosureSectionToggled: ( showDisclosureSection: boolean ) => void,
  handleInteractionSettingsChanged: ( interactionOptions: TiktokInteractionOptionsAPI ) => void
  handleBrandingSettingsChanged: ( branding: TiktokBrandingAPI ) => void
}

export function TiktokPrivacySection( props: TiktokPrivacySectionProps )
{
  const isPrivate = props.privacy === TIKTOK_PRIVACY_SELF_ONLY;

  function handlePrivacyChanged( event: SelectChangeEvent )
  {
    props.handlePrivacyChanged && props.handlePrivacyChanged( event.target.value as TiktokPrivacyOption )
  }

  function handleAllowCommentsChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    props.handleInteractionSettingsChanged( { ...props.interactionSettings, comment_disabled: !event.target.checked } )
  }

  function handleAllowDuetsChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    props.handleInteractionSettingsChanged( { ...props.interactionSettings, duet_disabled: !event.target.checked } )
  }

  function handleAllowStitchChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    props.handleInteractionSettingsChanged( { ...props.interactionSettings, stitch_disabled: !event.target.checked } )
  }

  function handleBrandOrganicToggleChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    props.handleBrandingSettingsChanged( { ...props.brandingSettings, brand_organic_toggle: event.target.checked } )
  }

  function handleBrandContentToggleChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    props.handleBrandingSettingsChanged( { ...props.brandingSettings, brand_content_toggle: event.target.checked } )
  }

  function handleDisclosureSwitchChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    props.handleDisclosureSectionToggled( event.target.checked )
    if ( !event.target.checked )
    {
      props.handleBrandingSettingsChanged( { brand_organic_toggle: false, brand_content_toggle: false } )
    }
  }

  function renderPrivacyAndInteractionsSection()
  {
    function getTooltipHintText( showStaticHint: boolean )
    {
      if ( showStaticHint )
      {
        return "This option is only available for video posts";
      }
      return "This option can be enabled via the privacy settings in the TikTok app.";
    }

    function enableDuetOption()
    {
      return !props.interactionOptions.duet_disabled && !props.isStaticPost;
    }

    function enableStitchOption()
    {
      return !props.interactionOptions.stitch_disabled && !props.isStaticPost;
    }

    return <>
      <Typography variant={"caption"} sx={{ alignSelf: "start", color: "black", textAlign: "center", mb: 2 }}>Who can view this post:</Typography>
      <FormControl fullWidth>
        <Select
          sx={{ mb: 6, fontSize: "12px" }}
          inputProps={{ sx: { p: "7px" } }}
          value={props.privacy}
          onChange={handlePrivacyChanged}
        >
          {
            map( props.privacyOptions, ( option ) =>
            {
              const displayName = TIKTOK_PRIVACY_OPTIONS_DISPLAY_NAME_MAP[option]
              const isPrivateOptionForBrandedContent = option === TIKTOK_PRIVACY_SELF_ONLY && props.brandingSettings.brand_content_toggle;
              if ( isPrivateOptionForBrandedContent )
              {
                return <Tooltip title={"Branded content visibility cannot be set to private"} enterTouchDelay={50}>
                  <Box component={"span"}>
                    <MenuItem key={`tiktok-privacy-option-${option}`} disabled={true} value={option}
                              sx={{ py: 0, minHeight: "28px" }}>{displayName}</MenuItem>
                  </Box>
                </Tooltip>;
              }
              return <MenuItem key={`tiktok-privacy-option-${option}`} value={option} sx={{ py: 0, minHeight: "28px" }}>{displayName}</MenuItem>
            } )
          }
        </Select>
        <Typography variant={"caption"} sx={{ alignSelf: "start", textAlign: "left" }}>Allow users to:</Typography>
        <Stack direction={"row"} spacing={2} sx={{ ml: 2, '.MuiTypography-root': { fontSize: "12px" } }}>
          <Tooltip disableHoverListener={!props.interactionOptions.comment_disabled}
                   disableTouchListener={!props.interactionOptions.comment_disabled}
                   title={getTooltipHintText( false )}
                   enterTouchDelay={50}>
            <Box component="span">
              <FormControlLabel control={<Checkbox key={"tiktok-comment"}
                                                   disabled={props.interactionOptions.comment_disabled}
                                                   checked={!props.interactionOptions.comment_disabled && !props.interactionSettings.comment_disabled}
                                                   onChange={handleAllowCommentsChanged}
                                                   sx={{ alignSelf: "start", textAlign: "center" }}/>} label={"Comment"}/>
            </Box>
          </Tooltip>
          <Tooltip disableHoverListener={enableDuetOption()}
                   disableTouchListener={enableDuetOption()}
                   title={getTooltipHintText( props.isStaticPost )}
                   enterTouchDelay={50}>
            <Box component="span">
              <FormControlLabel control={<Checkbox key={"tiktok-duet"}
                                                   disabled={props.interactionOptions.duet_disabled}
                                                   checked={!props.interactionSettings.duet_disabled}
                                                   onChange={handleAllowDuetsChanged}
                                                   sx={{ alignSelf: "start", textAlign: "center", fontSize: 10 }}/>} label={"Duet"}/>
            </Box>
          </Tooltip>
          <Tooltip disableHoverListener={enableStitchOption()}
                   disableTouchListener={enableStitchOption()}
                   title={getTooltipHintText( props.isStaticPost )}
                   enterTouchDelay={50}>
            <Box component="span">
              <FormControlLabel control={<Checkbox key={"tiktok-stitch"}
                                                   disabled={props.interactionOptions.stitch_disabled}
                                                   checked={!props.interactionSettings.stitch_disabled}
                                                   onChange={handleAllowStitchChanged}
                                                   sx={{ alignSelf: "start", textAlign: "center" }}/>} label={"Stitch"}/>
            </Box>
          </Tooltip>
        </Stack>
      </FormControl>
    </>;
  }

  function renderDisclosureToggle()
  {
    return <Stack direction={"row"}>
      <Typography variant={"caption"} sx={{ alignSelf: "center", justifySelf: "center" }}>Disclose video content</Typography>
      <Tooltip title="Turn on to disclose that this video promotes goods or services in exchange for something of value.
              Your post could promote yourself, a third party, or both." enterTouchDelay={50}>
        <Box component="span" sx={{ display: "flex", alignItems: "center", ml: 2 }}>
          <HelpOutlineIcon sx={{ height: "15px", width: "15px" }}/>
        </Box>
      </Tooltip>
      <Switch sx={{ ml: "auto", textAlign: "center" }} onChange={handleDisclosureSwitchChanged} checked={props.showDisclosureSection}/>
    </Stack>;
  }

  function renderDisclosureSection()
  {
    function renderOrganicToggle()
    {
      return <Stack direction={"row"}>
        <Typography variant={"caption"} sx={{ alignSelf: "center" }}>Your brand</Typography>
        <Tooltip title="You are promoting yourself or your own business. This post will be classified as Brand Organic." enterTouchDelay={50}>
          <Box component="span" sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            <HelpOutlineIcon sx={{ height: "15px", width: "15px" }}/>
          </Box>
        </Tooltip>
        <Checkbox key={"tiktok-allow-brand"} checked={props.brandingSettings.brand_organic_toggle}
                  onChange={handleBrandOrganicToggleChanged}
                  sx={{ ml: "auto" }}/>
      </Stack>;
    }

    function renderBrandedContentToggle()
    {
      return <Stack>
        <Stack direction={"row"}>
          <Typography variant={"caption"} sx={{ alignSelf: "center" }}>Branded content</Typography>
          <Tooltip title="You are promoting another brand or a third party. This post will be classified as Branded Content."
                   enterTouchDelay={50}>
            <Box component="span" sx={{ display: "flex", alignItems: "center", ml: 2 }}>
              <HelpOutlineIcon sx={{ height: "15px", width: "15px" }}/>
            </Box>
          </Tooltip>
          <Checkbox key={"tiktok-allow-brand"} checked={props.brandingSettings.brand_content_toggle}
                    disabled={isPrivate}
                    onChange={handleBrandContentToggleChanged}
                    sx={{ ml: "auto" }}/>
        </Stack>
        {isPrivate && <Typography variant={"caption"} sx={{ alignSelf: "start", fontSize: "10px", color: "red" }}>
          Change audience to public/friends to enable branded content</Typography>}
      </Stack>;
    }

    function getDisclosureWarningText()
    {
      if ( props.brandingSettings.brand_content_toggle )
      {
        return <> Your post will be labeled 'Paid Partnership'. This cannot be changed once published.</>;
      }
      return <> Your post will be labeled 'Promotional content'. This cannot be changed once published.</>;
    }

    return <Box>
      {(props.brandingSettings.brand_content_toggle || props.brandingSettings.brand_organic_toggle) &&
       <Stack direction={"row"} spacing={2} sx={{ borderRadius: '5px', backgroundColor: "#c1d3f5", p: 2 }}>
         <InfoOutlinedIcon sx={{ width: 15, height: 15, ml: 1, mt: 5 }}/>
         <Typography variant={"caption"} sx={{ alignSelf: "center", fontSize: "10px" }}>
           {getDisclosureWarningText()}
         </Typography>
       </Stack>}
      {renderOrganicToggle()}
      {renderBrandedContentToggle()}
    </Box>;
  }

  function renderTiktokAgreementsFinePrint()
  {
    let musicConfirmationLinkText = <><a href={"https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"}
                                         target={"_blank"} rel={"noreferrer"}>Music Usage Confirmation</a>.</>;
    let agreementsLinkText = musicConfirmationLinkText;
    if ( props.brandingSettings.brand_content_toggle )
    {
      agreementsLinkText = <><a href={"https://www.tiktok.com/legal/page/global/bc-policy/en"} target={"_blank"} rel={"noreferrer"}>
        Branded Content Policy</a> & {musicConfirmationLinkText}</>;
    }
    return <Typography variant={"caption"} sx={{ alignSelf: "start", fontSize: "10px", mt: 2 }}>
      By posting, you agree to TikTok's {agreementsLinkText}
    </Typography>;
  }

  return <Box sx={{ backgroundColor: "#FFFFFF", width: '87%', alignSelf: "self-end" }}>
    <Accordion defaultExpanded
               sx={{
                 backgroundColor: "#FFFFFF",
                 '.MuiAccordionSummary-content': { m: 0, py: 0 },
                 '.MuiButtonBase-root.MuiAccordionSummary-root': { minHeight: '36px', maxHeight: '36px' },
                 boxShadow: "none"
               }}>
      <AccordionSummary sx={{ px: 5 }}
                        expandIcon={<ArrowDropDownIcon/>}>
        <Typography variant={"subtitle2"} sx={{ fontSize: "13px", fontWeight: 400 }}>TikTok Privacy Settings</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 5, pb: 5 }}>
        <Stack sx={{ alignItems: "left", '.MuiButtonBase-root.MuiCheckbox-root': { padding: 2 } }}>
          {renderPrivacyAndInteractionsSection()}
          {renderDisclosureToggle()}
          {props.showDisclosureSection && renderDisclosureSection()}
          {renderTiktokAgreementsFinePrint()}
        </Stack>
      </AccordionDetails>
    </Accordion>
  </Box>
}