import { createSlice } from '@reduxjs/toolkit'
import { STOCK_MEDIA_SEARCH_TYPE_PHOTO, StockMediaSearchType } from "../constants";
import { concat, uniq } from "lodash";
import { RootState } from "../../app/store";

export interface ExternalMediaData
{
  external_media_id: string;
  thumbnail_url: string;
  original_media_url?: string;
  preview_video_url?: string;
  full_url?: string;
}

interface StockMediaState
{
  query: string;
  search_id: string;
  matches: ExternalMediaData[] | null;
  total_count: number;
  next_page?: number;
  error?: boolean;
  stockMediaSearchType: StockMediaSearchType;
  loading: boolean;
}

const initialState: StockMediaState = {
  matches: null,
  query: "",
  search_id: "",
  error: false,
  total_count: 0,
  stockMediaSearchType: STOCK_MEDIA_SEARCH_TYPE_PHOTO,
  loading: false,
};

export const stockMediaSlice = createSlice( {
  name: 'stockMedia',
  initialState,
  reducers: {
    changeStockMediaSearchType: ( state, action ) =>
    {
      return {
        ...state,
        stockMediaSearchType: action.payload,
      };
    },
    searchResultsCleared: ( state ) =>
    {
      return {
        ...state,
        query: "",
        matches: null,
        error: false,
        total_count: 0,
        next_page: undefined,
      };
    },
    searchPhotosLoaded: ( state, action ) =>
    {
      const matches = uniq( concat( state.matches || [], action.payload.matches ) );
      const next_page = action.payload.next_page ? action.payload.next_page : undefined;
      return {
        ...state,
        ...action.payload,
        matches,
        next_page,
        error: false,
        loading: false,
      };
    },
    searchPhotosFailed: ( state, action ) =>
    {
      return {
        ...state,
        error: true,
        next_page: undefined,
        loading: false,
      };
    },
    searchPhotosLoading: ( state ) =>
    {
      return {
        ...state,
        loading: true,
      };
    }
  },
} )

export const getSearchedStockMedia = ( state: RootState ) => state.stockMedia.matches;
export const getSearchedStockMediaNextPage = ( state: RootState ) => state.stockMedia.next_page;
export const getStockMediaSearchTerm = ( state: RootState ) => state.stockMedia.query;
export const getLastSearchId = ( state: RootState ) => state.stockMedia.search_id;
export const getStockMediaSearchError = ( state: RootState ) => state.stockMedia.error;
export const getStockMediaTotalMatchCount = ( state: RootState ) => state.stockMedia.total_count;
export const getStockMediaSearchType = ( state: RootState ) => state.stockMedia.stockMediaSearchType;
export const getStockMediaLoading = ( state: RootState ) => state.stockMedia.loading;

// Action creators are generated for each case reducer function
export const {
  changeStockMediaSearchType,
  searchResultsCleared,
  searchPhotosLoaded,
  searchPhotosFailed,
  searchPhotosLoading,
} = stockMediaSlice.actions

export default stockMediaSlice.reducer
