import * as React from "react";
import { Box, SxProps } from "@mui/material";
import { PlayCircleOutline } from "@mui/icons-material";
import { merge } from "lodash";

export interface PlayCircleInContainerProps
{
  sx?: SxProps;
}

export function PlayCircleInContainer( props: PlayCircleInContainerProps )
{
  const sx = merge( {
    display: "flex",
    background: "rgba(0,0,0,0.4)",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  }, props.sx );
  return (
    <Box
      sx={sx}>
      <PlayCircleOutline sx={{ width: 64, height: 64, color: "white" }}/>
    </Box>
  );
}