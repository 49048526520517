import * as React from 'react';
import { useContext, useState } from 'react';
import "./editTextTabContent.scss";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import { EditPostView } from "./editPostView";
import { SharePostView } from "./sharePostView";
import AlertBanner from "../alert/alertBanner";
import { eventTracker } from "../../helpers/eventTracker";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { PostIdeaContext } from "../context/postIdeaContext";
import { ShareBatchAPI } from "../../services/postIdeaServices";

export interface EditAndSharePostFlowProps extends WithFullScreenDialogProps
{
  editable?: boolean;
  shouldSuppressFeedback?: boolean;
  initialScheduleForDate?: Date;
  shareBatch?: ShareBatchAPI;
}

function EditAndSharePostFlow( props: EditAndSharePostFlowProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const EDIT_POST_VIEW_ID = "edit_post_view";
  const SHARE_POST_VIEW_ID = "share_post_view";
  const [currentView, setCurrentView] = useState( EDIT_POST_VIEW_ID );

  function switchToEditView()
  {
    setCurrentView( EDIT_POST_VIEW_ID )
  }

  function switchToShareView()
  {
    setCurrentView( SHARE_POST_VIEW_ID )
  }

  function handleEditPostClose()
  {
    eventTracker.logEditPostCancelClicked( postIdea );
    handleClose();
  }

  function handleClose()
  {
    if ( !!props.handleClose )
    {
      props.handleClose();
    }
  }

  return (
    <FullPageMobileContainer>
      {currentView === EDIT_POST_VIEW_ID && <EditPostView editable={props.editable} switchToShareView={switchToShareView}
                                                          handleClose={handleEditPostClose}/>}
      {currentView === SHARE_POST_VIEW_ID && <SharePostView switchToEditView={switchToEditView}
                                                            initialScheduleForDate={props.initialScheduleForDate}
                                                            shareBatch={props.shareBatch}/>}
      <AlertBanner/>
    </FullPageMobileContainer>
  );
}

export const FullScreenEditor = withFullScreenDialog( EditAndSharePostFlow )
