import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, Typography } from "@mui/material";

export interface MediaPreviewDialogProps
{
  open: boolean;
  onClose: () => void;
}

export function WebImagesAutomaticallyAddedDialog( props: MediaPreviewDialogProps )
{
  const { onClose, open, ...other } = props;

  const handleOk = () =>
  {
    onClose();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: '100vh' } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogContent sx={{ minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="subtitle1">Alkai has started importing the images from this website.  This process may take a few minutes to complete.  Once finished, they will be automatically added to your Media Library.</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleOk}>Back to Media Library</Button>
      </DialogActions>
    </Dialog>
  );
}

