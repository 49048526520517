import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { postIdeaServices } from "../../../services/postIdeaServices";
import { eventTracker } from "../../../helpers/eventTracker";
import { addPost } from "../../postIdea/postsSlice";
import { updateChatPostIdeaMessage } from "../../assistantChat/assistantChatSlice";
import { errorAlert, setAlertMessage } from "../../alert/alertSlice";
import { useDispatch } from "react-redux";
import { FontSetAPI } from "../../../services/fontSet.services";
import { addOrUpdatePostIdeaFromMessage, getFontSetSlug } from "../../postIdea/postIdeaHelper";
import { FontSetDrawer } from "./fontSetDrawer";
import { EDIT_FONT_SOURCE_EDIT_POST } from "../../../helpers/trackingConstants";
import { PostIdeaContext } from "../../context/postIdeaContext";

export interface PostIdeaFontSetDrawerProps
{
  handleDrawerClose: () => void;
}

export function PostIdeaFontSetDrawer( props: PostIdeaFontSetDrawerProps )
{
  const dispatch = useDispatch();
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const selectedFontSetSlug = getFontSetSlug( postIdea );
  const initialFontSetSlug = useRef( "" );

  useEffect( () =>
  {
    initialFontSetSlug.current = selectedFontSetSlug;
  }, [] );

  async function applyFontSet( fontSet: FontSetAPI )
  {
    await requestNewFontVariant( fontSet.slug );
  }

  function requestNewFontVariant( fontSetSlug: string )
  {
    postIdeaServices.generateNewFontVariant( postIdea.id, fontSetSlug ).then( ( response ) =>
    {
      eventTracker.logEditPostFontSetSwitched( postIdea );
      dispatch( addPost( response.post_json ) );
      dispatch( updateChatPostIdeaMessage( response.post_idea_message ) );
      addOrUpdatePostIdeaFromMessage( response.post_idea_message );
    } ).catch( ( error ) =>
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem switching fonts. Please try again." ) ) );
    } );
  }

  function onCancelClick()
  {
    if ( initialFontSetSlug && initialFontSetSlug.current !== selectedFontSetSlug )
    {
      requestNewFontVariant( initialFontSetSlug.current );
    }
    props.handleDrawerClose();
  }

  return (
    <FontSetDrawer applyFontSet={applyFontSet}
                   handleDrawerClose={props.handleDrawerClose}
                   handleCancelClicked={onCancelClick}
                   selectedFontSetSlug={selectedFontSetSlug}
                   postIdeaId={postIdea.id}
                   source={EDIT_FONT_SOURCE_EDIT_POST}
    />
  );
}
