import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import * as React from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { STREAK_SUCCESS_GRADIENT } from "../constants";
import RocketLaunch from "../../assets/rocket-launch.svg";
import Starburst from "../../assets/starburst.svg";
import { useDispatch } from "react-redux";
import { businessServices, CurrentStreakAPI } from "../../services/business.services";
import { clearAlertMessage } from "../alert/alertSlice";
import { SparkleFieldBackground } from "./sparkleFieldBackground";
import { PostSuccessSafeArea } from "./postSuccessSafeArea";
import { PostSuccessTitle } from "./postSuccessTitle";
import { PostSuccessFlexContentWrap } from "./postSuccessFlexContentWrap";
import { PostSuccessContinueButton } from "./postSuccessContinueButton";
import { eventTracker } from "../../helpers/eventTracker";

export interface StreakDialogProps extends WithFullScreenDialogProps
{
  streak: CurrentStreakAPI,
  workedAhead?: boolean
}

function StreakDialog( props: StreakDialogProps )
{
  const dispatch = useDispatch();

  useEffect( () =>
  {
    dispatch( clearAlertMessage() );
    eventTracker.logPostSuccessDialogShown( props.streak )
    if ( props.streak && props.streak.posting_streak )
    {
      businessServices.markPostingStreakAsRead( props.streak.posting_streak );
    }
  }, [] );

  function handleBackToPlan()
  {
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  function getMessage()
  {
    if ( props.workedAhead )
    {
      return <>
        Working ahead earned you a
      </>;
    }
    return <>
      This post earned you a
    </>;

  }

  if ( props.streak.posting_streak )
  {
    return (<FullPageMobileContainer
      sx={{ background: `url(${Starburst}) center center no-repeat, ` + STREAK_SUCCESS_GRADIENT, backgroundSize: "cover", position: "relative" }}>
      <SparkleFieldBackground/>
      <PostSuccessSafeArea>
        <PostSuccessTitle>NICE WORK!</PostSuccessTitle>
        <PostSuccessFlexContentWrap>
          <Box component="img" src={RocketLaunch} alt="rocket" sx={{ width: "230px" }}/>

          <Typography variant="h1"
                      sx={{ fontSize: 18, lineHeight: "21px", fontWeight: 500, color: "white", opacity: 0.6 }}>{getMessage()}</Typography>
          <Typography variant="h1"
                      sx={{
                        fontSize: 150,
                        lineHeight: "150px",
                        fontWeight: 900,
                        color: "white"
                      }}>{props.streak.posting_streak.streak_from_previous_weeks
                          + 1}</Typography>
          <Typography variant="h1" sx={{ fontSize: 36, lineHeight: "43px", fontWeight: 900, color: "white" }}>WEEK STREAK</Typography>
        </PostSuccessFlexContentWrap>
        <PostSuccessContinueButton onClick={handleBackToPlan} title={props.workedAhead ? "Awesome" : undefined}/>
      </PostSuccessSafeArea>
    </FullPageMobileContainer>)
  }
  else
  {
    return (<></>);
  }
}

export const StreakDialogFullScreen = withFullScreenDialog( StreakDialog )
