import React from "react";
import { PREVIEW_NAV_ID_NEXT_VARIANT, PREVIEW_NAV_ID_PREVIOUS_VARIANT } from "../constants";
import { Box } from "@mui/material";
import { browserUtils } from "../utils/browserUtils";

interface SkewedGradientRectangleProps
{
  variantDirection: typeof PREVIEW_NAV_ID_PREVIOUS_VARIANT | typeof PREVIEW_NAV_ID_NEXT_VARIANT,
}

export function SkewedGradientRectangle( props: SkewedGradientRectangleProps )
{

  function isNextVariant()
  {
    return props.variantDirection === PREVIEW_NAV_ID_NEXT_VARIANT;
  }

  function getSkewBoxSxProps()
  {
    const height = browserUtils.isMobileBrowser() ? '526px' : '463px';
    let baseBoxSxProps: any = {
      perspective: '400px',
      height: height,
      width: '32px',
      position: 'absolute',
    };

    if ( isNextVariant() )
    {
      const right = browserUtils.isMobileBrowser() ? '0px' : '26px';
      return {
        ...baseBoxSxProps,
        right: right,
      }
    }
    else
    {
      const right = browserUtils.isMobileBrowser() ? '0px' : '144px';
      return {
        ...baseBoxSxProps,
        right: right,
      }
    }
  }

  function getGradientSxProps()
  {
    const width = browserUtils.isMobileBrowser() ? '32px' : '176px';
    const baseGradientSxProps: any = {
      borderRadius: '10px',
      width: width,
      height: '100%',
    }

    if ( isNextVariant() )
    {
      return {
        ...baseGradientSxProps,
        transform: 'rotateY(45deg)',
        background: 'linear-gradient(90deg, rgba(183, 188, 214, 0.52) 0%, rgba(60, 78, 180, 0.00) 95.31%)'
      }
    }
    else
    {
      return {
        ...baseGradientSxProps,
        transform: 'rotateY(-45deg)',
        background: 'linear-gradient(270deg, rgba(183, 188, 214, 0.52) 0%, rgba(60, 78, 180, 0.00) 95.31%)',
      }
    }
  }

  return <Box
    sx={getSkewBoxSxProps()}>
    <Box sx={getGradientSxProps()}>
    </Box>
  </Box>;
}
