import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { map } from "lodash";
import { eventTracker } from "../../helpers/eventTracker";

export function OnboardingQuickActionPanel( { loading, sendChatMessage } )
{
  const quickActionsToRender = {
    "onboarding_quick_action_create_a_weekly_plan": "I need a stress-free social plan ASAP! 🗓",
    "onboarding_quick_action_create_a_post": "I have an idea 💡 Let's make a post!",
    "onboarding_quick_action_give_me_a_post_idea": "I need inspo ✨ Create a post for me!"
  };

  function sendQuickActionMessage( messageId )
  {
    sendChatMessage( messageId );
    eventTracker.logOnboardingQuickActionClicked( messageId );
  }

  return (<Box sx={{
    overflowX: "auto",
    width: "100%",
    flex: "0 0 auto",
    p: 2,
    mb: 20
  }}>
    <Stack direction={"column"} spacing={5} alignItems={"center"}>
      {map( Object.entries( quickActionsToRender ), ( [actionId, actionMessage] ) =>
        {
          return (<Button
            key={actionId}
            variant='contained'
            color='primary'
            sx={{ whiteSpace: "nowrap", width: "95%", textTransform: "none", borderRadius: 10 }}
            onClick={() => sendQuickActionMessage( actionId )}
            disabled={loading}
          >
            <Typography align='center' variant='subtitle1'>
              {actionMessage}
            </Typography>
          </Button>)
        }
      )}
    </Stack>
  </Box>);
}