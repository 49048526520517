import React from "react";
import { Link } from "@mui/material";

export interface CreateAccountSwitchFormLinkProps
{
  onClick: () => void;
  children: React.ReactNode;
}

export function CreateAccountSwitchFormLink( props: CreateAccountSwitchFormLinkProps )
{

  return <Link href="#" variant="body2" sx={{ mt: 3, mb: 5 }} onClick={props.onClick}>
    {props.children}
  </Link>;
}
