import { concat } from "lodash";
import { store } from "../app/store";
import { getUserSlug } from "../features/user/userSlice";
import { postJsonToApi } from "./requestManager";
import { detect } from "detect-browser";
import { ALKAI_VERSION } from "../constants";
import { getBusinessSlug } from "../features/business/businessSlice";

const USER_BASE_PATH = "users";
const NEW_SUPPORT_TICKET_PATH = "submit_ticket";

const browser = detect();

export const freshdeskServices = {
  submitTicket
}

export interface FreshdeskNewTicketAPI
{
  success: boolean;
}

function submitTicket( emailAddress: string, description: string, subject: string )
{
  const state = store.getState();

  if ( !!state.user.slug )
  {
    const jsonBody = {
      user_slug: getUserSlug( state ),
      user_email: emailAddress,
      business_slug: getBusinessSlug( state ),
      message: description,
      subject: "Feedback for Alkai - " + subject,
      feedback_text: getFeedbackText(),
    }

    const endpoint = buildUserMemberUrl( state.user.slug, NEW_SUPPORT_TICKET_PATH );
    return postJsonToApi<FreshdeskNewTicketAPI>( endpoint, {}, jsonBody ).then( ( response ) =>
    {
      if ( response.success )
      {
        return response;
      }
      else
      {
        throw new Error( "Failed to submit ticket" );
      }
    } );
  }
}

function getFeedbackText()
{
  let browserName = "unknown";
  if ( browser )
  {
    browserName = browser.name;
  }
  // @ts-ignore userAgentData is not in the types but does exist
  const platform = navigator?.userAgentData?.platform || navigator?.platform;

  return "client_id: app.alkai.ai<br>" +
         "version: " + ALKAI_VERSION + "<br>" +
         "user: " + store.getState().user.slug + "<br>" +
         "currentBusiness: " + store.getState().business.name + "<br>" +
         "currentBusinessId: " + store.getState().business.business_id + "<br>" +
         "subscription?: " + store.getState().user.is_subscribed + "<br>" +
         "browserName: " + browserName + "<br>" +
         "browserPlatform: " + platform + "<br>" +
         "dataCookiesEnabled: " + navigator.cookieEnabled + "<br>" +
         "sizeScreenW: " + window.screen.width + "<br>" +
         "sizeScreenH: " + window.screen.height;
}

function buildUserMemberUrl( userSlugOrId: string, path: string )
{
  return buildUrl( [userSlugOrId, path] );
}

function buildUrl( pathPieces: string[] )
{
  return concat( [USER_BASE_PATH], pathPieces ).join( "/" );
}