import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "../../app/store";
import { uniq } from "lodash";

export interface ApptimizeState
{
  isInitialized: boolean;
  featureFlags?: {},
  dynamicVariables?: {},
  participatingExperimentAndVariantNames?: string[],
}

export const initialState: ApptimizeState = {
  isInitialized: false,
}

export const apptimizeSlice = createSlice( {
  name: 'apptimize',
  initialState,
  reducers: {
    setApptimizeInitialized: ( state, action ) =>
    {
      return {
        ...state,
        isInitialized: action.payload,
      }
    },
    cacheApptimizeParticipatingExperimentAndVariantName: ( state, action ) =>
    {
      const updatedParticipatingExperimentAndVariantNames = [
        ...(state.participatingExperimentAndVariantNames || []),
        action.payload,
      ];

      return {
        ...state,
        participatingExperimentAndVariantNames: uniq( updatedParticipatingExperimentAndVariantNames ),
      };
    },
    clearApptimizeParticipationState: ( state ) =>
    {
      return {
        ...initialState,
        isInitialized: state.isInitialized,
      };
    }
  },
} )

export const isApptimizeInitialized = ( state: RootState ) => state.apptimize.isInitialized;
export const getApptimizeParticipatingExperimentAndVariantNamesCache = ( state: RootState ) => state.apptimize.participatingExperimentAndVariantNames;

export const {
  setApptimizeInitialized,
  cacheApptimizeParticipatingExperimentAndVariantName,
  clearApptimizeParticipationState,
} = apptimizeSlice.actions

export default apptimizeSlice.reducer
