import React from 'react'
import { clsx } from "clsx";
import "./message.scss";
import { isUserMessage, MessageAPI, PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import {
  MESSAGE_FADE_IN_DURATION,
  MESSAGE_ROLE_ASSISTANT,
  MESSAGE_TYPE_DISPLAY,
  MESSAGE_TYPE_MULTIPLE_POST_IDEAS,
  MESSAGE_TYPE_REEL_APPROVAL
} from "../constants";
import { Box, Fade } from "@mui/material";
import { DisplayMessage } from "./displayMessage";
import { MessageWithAvatar } from "./messageWithAvatar";
import { MultiplePostIdeaMessage } from "../postIdea/multiplePostIdeaMessage";

export interface MessageProps
{
  message: MessageAPI;
}

export function Message( props: MessageProps )
{
  let parsedMessage = props.message.text || "";
  let imageUrl = props.message.image_url;

  if ( props.message.type === MESSAGE_TYPE_DISPLAY || props.message.role === MESSAGE_ROLE_ASSISTANT )
  {
    return (
      <Box sx={{width: "100%"}}>
        <Fade
          in
          appear
          timeout={MESSAGE_FADE_IN_DURATION}
        >
          <div> { /* This div is necessary to allow Fade to forward the ref*/}
            <MessageWithAvatar message={props.message} clsx={{ alki: true }}>
              <DisplayMessage parsedMessage={parsedMessage} renderDotsForEmptyString={true} useMarkdownRenderer={true}/>
            </MessageWithAvatar>
          </div>
        </Fade>
      </Box>
    )
  }
  else if ( props.message.type === MESSAGE_TYPE_REEL_APPROVAL )
  {
    return (
      <MessageWithAvatar message={props.message}>
        <MultiplePostIdeaMessage postIdeas={[props.message.data as PostIdeaDataAPI]}/>
      </MessageWithAvatar>
    )
  }
  else if ( props.message.type === MESSAGE_TYPE_MULTIPLE_POST_IDEAS )
  {
    return (
      <MessageWithAvatar message={props.message}>
        <MultiplePostIdeaMessage postIdeas={props.message.data as PostIdeaDataAPI[]}/>
      </MessageWithAvatar>
    )
  }

  return (
    <div className={clsx( "message", { "user": isUserMessage( props.message ) } )}>
      <DisplayMessage parsedMessage={parsedMessage} useMarkdownRenderer={false}/>
      {imageUrl &&
       <img src={imageUrl} alt="" style={{
         maxHeight: "300px",
       }}/>
      }
    </div>
  )
}
