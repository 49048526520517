import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import * as React from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { POST_SUCCESS_GRADIENT } from "../constants";
import SuccessFire from "../../assets/successFire.svg";
import Starburst from "../../assets/starburst.svg";
import { useDispatch } from "react-redux";
import { clearAlertMessage } from "../alert/alertSlice";
import { SparkleFieldBackground } from "./sparkleFieldBackground";
import { PostSuccessSafeArea } from "./postSuccessSafeArea";
import { PostSuccessTitle } from "./postSuccessTitle";
import { PostSuccessFlexContentWrap } from "./postSuccessFlexContentWrap";
import { PostSuccessContinueButton } from "./postSuccessContinueButton";
import { eventTracker } from "../../helpers/eventTracker";
import { CurrentStreakAPI } from "../../services/business.services";

export interface PostSuccessDialogProps extends WithFullScreenDialogProps
{
  streak: CurrentStreakAPI,
}

function PostSuccessDialog( props: PostSuccessDialogProps )
{
  const dispatch = useDispatch();

  useEffect( () =>
  {
    dispatch( clearAlertMessage() );
    eventTracker.logPostSuccessDialogShown( props.streak );
  }, [] );

  function handleBackToPlan()
  {
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  return (
    <FullPageMobileContainer
      sx={{ background: `url(${Starburst}) center center no-repeat ,` + POST_SUCCESS_GRADIENT, backgroundSize: "cover", position: "relative" }}>
      <SparkleFieldBackground/>
      <PostSuccessSafeArea>
        <PostSuccessTitle>WAY TO GO!</PostSuccessTitle>
        <PostSuccessFlexContentWrap>
          <Box component="img" src={SuccessFire} alt="rocket" sx={{ width: "230px" }}/>
          <Typography variant="h1" sx={{ fontSize: 18, lineHeight: "21px", fontWeight: 500, color: "white" }}>Your post activity is</Typography>
          <Typography variant="h1" sx={{ fontSize: 36, lineHeight: "43px", fontWeight: 900, color: "white" }}>ON FIRE!</Typography>
        </PostSuccessFlexContentWrap>
        <PostSuccessContinueButton onClick={handleBackToPlan}/>
      </PostSuccessSafeArea>
    </FullPageMobileContainer>
  )
}

export const PostSuccessDialogFullScreen = withFullScreenDialog( PostSuccessDialog )
