import React, { useCallback } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Stack } from "@mui/material";
import { businessServices, WeeklyScheduleAPI } from "../../services/business.services";
import { eventTracker } from "../../helpers/eventTracker";
import { debounce } from "lodash";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";

const MONDAY_KEY = "monday";
const TUESDAY_KEY = "tuesday";
const WEDNESDAY_KEY = "wednesday";
const THURSDAY_KEY = "thursday";
const FRIDAY_KEY = "friday";
const SATURDAY_KEY = "saturday";
const SUNDAY_KEY = "sunday";

export interface WeeklyScheduleProps
{
  weeklySchedule: WeeklyScheduleAPI;
}

export function WeeklySchedulePicker( props: WeeklyScheduleProps )
{
  const AUTO_SAVE_DELAY_MS = 1000;
  const dispatch = useDispatch();
  const [weeklyScheduleForm, setWeeklyScheduleForm] = React.useState<WeeklyScheduleAPI>( props.weeklySchedule );
  const debounceUpdatePlanSettings = useCallback( debounce( updatePlanSettingsWithForm, AUTO_SAVE_DELAY_MS ), [] );

  const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    const newSchedule = {
      ...weeklyScheduleForm,
      [event.target.name]: event.target.checked,
    }

    setWeeklyScheduleForm( newSchedule );
    debounceUpdatePlanSettings( newSchedule );
  };

  async function updatePlanSettingsWithForm( newSchedule )
  {
    try
    {
      await businessServices.updatePlanSettings( newSchedule );
      eventTracker.logSettingsWeeklyPlanUpdated();
    }
    catch (error)
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem updating the weekly schedule", "top" ) ) );
    }
  }

  return <Stack>
    <FormControl component="fieldset" sx={{ display: "flex", alignItems: "center", my: 5 }}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="Monday"
          sx={{ mx: 3, width: "35px" }}
          control={<Checkbox checked={weeklyScheduleForm[MONDAY_KEY]} onChange={handleChange} name={MONDAY_KEY}/>}
          label="Mon"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Tuesday"
          sx={{ mx: 3, width: "35px" }}
          control={<Checkbox checked={weeklyScheduleForm[TUESDAY_KEY]} onChange={handleChange} name={TUESDAY_KEY}/>}
          label="Tue"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Wednesday"
          sx={{ mx: 3, width: "35px" }}
          control={<Checkbox checked={weeklyScheduleForm[WEDNESDAY_KEY]} onChange={handleChange} name={WEDNESDAY_KEY}/>}
          label="Wed"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Thursday"
          sx={{ mx: 3, width: "35px" }}
          control={<Checkbox checked={weeklyScheduleForm[THURSDAY_KEY]} onChange={handleChange} name={THURSDAY_KEY}/>}
          label="Thu"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Friday"
          sx={{ mx: 3, width: "35px" }}
          control={<Checkbox checked={weeklyScheduleForm[FRIDAY_KEY]} onChange={handleChange} name={FRIDAY_KEY}/>}
          label="Fri"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Saturday"
          sx={{ mx: 3, width: "35px" }}
          control={<Checkbox checked={weeklyScheduleForm[SATURDAY_KEY]} onChange={handleChange} name={SATURDAY_KEY}/>}
          label="Sat"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Sunday"
          sx={{ mx: 3, width: "35px" }}
          control={<Checkbox checked={weeklyScheduleForm[SUNDAY_KEY]} onChange={handleChange} name={SUNDAY_KEY}/>}
          label="Sun"
          labelPlacement="bottom"
        />
      </FormGroup>
    </FormControl>
  </Stack>;
}