import { replace } from "lodash";
import { DESIGN_HTML_NEWLINE_SEQUENCE } from "../constants";

export const stringUtils = {
  modifyHtmlNewlineBreakRestWithNewlineCharacter,
  replaceFileExtension,
  normalizeEmailForGoogleAnalytics,
  countHashtags,
}

function modifyHtmlNewlineBreakRestWithNewlineCharacter( caption )
{
  const regexp = new RegExp( DESIGN_HTML_NEWLINE_SEQUENCE, "g" );
  return replace( caption, regexp, "\n" )
}

function replaceFileExtension( fileName, newExtension )
{
  const lastDotIndex = fileName.lastIndexOf( "." );
  if ( lastDotIndex === -1 )
  {
    return fileName + newExtension;
  }
  return fileName.substring( 0, lastDotIndex ) + newExtension;
}

function normalizeEmailForGoogleAnalytics(emailAddress)
{
  let normalizedEmail = emailAddress.toLowerCase().replace(/\s+/g, "");

  const tokens = normalizedEmail.split("@");

  if (tokens.length === 2 && (tokens[1] === "gmail.com" || tokens[1] === "googlemail.com"))
  {
    const sanitizedUserName = tokens[0].replace(".", "");
    normalizedEmail = sanitizedUserName + "@" + tokens[1];
  }

  return normalizedEmail;
}

function countHashtags( text: string )
{
  const matchedText = text?.match( /#[^#\s]/g );
  return matchedText?.length || 0;
}
