import { Box, Button } from "@mui/material";
import * as React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export interface PostEditingDrawerCancelApplyProps
{
  onCancelClick: () => void;
  onApplyClick: () => void;
}

export function PostEditingDrawerCancelApply( props: PostEditingDrawerCancelApplyProps )
{
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 5, gap: 6 }}>
      <Button startIcon={<HighlightOffIcon/>} variant="contained" color="negative" onClick={props.onCancelClick}>Cancel</Button>
      <Button startIcon={<CheckCircleOutlineIcon/>} variant="contained" color="positive" onClick={props.onApplyClick}>Apply</Button>
    </Box>
  );
}
