import React from 'react'
import "./mediaList.scss";
import { Post } from "../postIdea/postsSlice";
import { Stack, Tooltip, Typography } from "@mui/material";
import { maxMediaReached } from "../postIdea/postDataHelper";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';

export interface MediaListProps
{
  loading: boolean;
  post: Post;
  handleAddMediaClicked();
}

export function AddMediaButton( props: MediaListProps )
{
  const hasReachedMaxMedia = () => maxMediaReached( props.post );
  const isNotAllowedToAddOrRemoveMediaAccordingToPost = () => !props.post.allow_add_or_remove_media;

  const getMostImportantTooltip = () =>
  {
    if ( isNotAllowedToAddOrRemoveMediaAccordingToPost() )
    {
      return "This template does not allow adding media"
    }
    else if ( hasReachedMaxMedia() )
    {
      return "Max number of media reached";
    }
    return "Add media"
  };

  const isButtonDisabled = () =>
  {
    return props.loading || isNotAllowedToAddOrRemoveMediaAccordingToPost() || hasReachedMaxMedia();
  }

  return (
    <Tooltip title={getMostImportantTooltip()} enterTouchDelay={50}>
      <Stack justifyContent={"center"} alignItems={"center"}>
        <span>
          <IconButton
            color={"primary"}
            disabled={isButtonDisabled()}
            onClick={props.handleAddMediaClicked}><AddCircleIcon sx={{ fontSize: "75px" }}/></IconButton>
        </span>
        <Typography variant={"subtitle2"}>Add Media</Typography>
      </Stack>
    </Tooltip>
  );
}

