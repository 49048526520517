import * as React from "react";
import { Stack, Typography } from "@mui/material";
import GearIcon from "../../assets/gear.png";
import MessageBubbleIcon from "../../assets/messageBubble.png";
import CalendarIcon from "../../assets/calendar.png";
import HeartIcon from "../../assets/heart.png";
import { PaymentConfirmationPoint } from "./paymentConfirmationPoint";
import { PurchaseFlowCTA } from "./purchaseFlowCTA";
import { PurchaseFlowHeader } from "./purchaseFlowHeader";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { UPSELL_GRADIENT } from "../constants";

export function AddBusinessPaymentConfirmation( props: WithFullScreenDialogProps )
{
  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>

      <Stack alignItems="center">
        <PurchaseFlowHeader>Your new business is ready!</PurchaseFlowHeader>
        <Stack sx={{ ml: "30px", mr: "30px" }}>
          <Typography sx={{ fontSize: "14px", lineHeight: "14px", fontFamily: "Montserrat", fontWeight: 600, color: "#fff", mb: 2 }}>To set up your
                                                                                                                                     new
                                                                                                                                     business:</Typography>
          <PaymentConfirmationPoint iconSrc={GearIcon}
                                    iconAlt={"gear icon"}>Switch between businesses at the top of the screen</PaymentConfirmationPoint>
          <PaymentConfirmationPoint iconSrc={MessageBubbleIcon}
                                    iconAlt={"message bubble icon"}>Onboard your new business by talking to Alkai in Chat</PaymentConfirmationPoint>
          <PaymentConfirmationPoint iconSrc={CalendarIcon}
                                    iconAlt={"calendar icon"}>Review your business's plan in the Plan tab</PaymentConfirmationPoint>
          <PaymentConfirmationPoint iconSrc={HeartIcon}
                                    iconAlt={"heart icon"}>Connect social accounts for the new business</PaymentConfirmationPoint>

        </Stack>
        <PurchaseFlowCTA onClick={props.handleClose}>Get Started!</PurchaseFlowCTA>
      </Stack>
    </FullPageMobileContainer>
  )
}

export const AddBusinessPaymentConfirmationDialog = withFullScreenDialog( AddBusinessPaymentConfirmation )

