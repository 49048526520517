import { getToApi } from "./requestManager";
import { UrlUtils } from "../features/utils/urlUtils";

export const layoutServices = {
  getLayouts,
}

const LAYOUTS_BASE_PATH = "layouts";
const LAYOUTS_LIST_ENDPOINT = "list"

export interface LayoutAPI
{
  preset_name: string,
  slug: string,
  image_url: string,
}

export interface LayoutsListAPI
{
  layouts: LayoutAPI[],
}

async function getLayouts( postIdeaId: string )
{
  try
  {
    const endpointUrl = new UrlUtils().buildUrl( LAYOUTS_BASE_PATH, [LAYOUTS_LIST_ENDPOINT] );
    const queryParams = {
      post_idea_id: postIdeaId,
    }
    return await getToApi<LayoutsListAPI>( endpointUrl, queryParams );
  }
  catch (error)
  {
    return Promise.reject( "Could not fetch layouts" );
  }
}
