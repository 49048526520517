import { Box, Typography } from "@mui/material";

export interface RotatingMessageProps
{
  messages: string[];
}

export function RotatingMessage( props: RotatingMessageProps )
{
  return (
    <Box sx={{ position: "relative", width: "100%", height: "30px" }}>
      {props.messages.map( ( message, index ) =>
      {
        let itemDuration = 3;
        const messagesLength = props.messages.length;
        let totalDuration = messagesLength * itemDuration;
        let startTime = index * itemDuration;
        let animateInTimeInPercent = startTime / totalDuration * 100;
        let transitionTimeInPercent = 0.5 / totalDuration * 100;
        let animateOutTimeInPercent = (startTime + itemDuration) / totalDuration * 100;
        const animationName = `rotating-message-${index}-${messagesLength}`;
        return (
          <Typography key={message} sx={{
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            animationName: animationName,
            animationDuration: `${totalDuration}s`,
            animationIterationCount: "infinite",
            animationDelay: "1s",
            [`@keyframes ${animationName}`]: {
              [`${Math.max( animateInTimeInPercent, 0 )}%`]: {
                opacity: 0,
                transform: "translate3d(0, 20px, 0)",
              },
              [`${animateInTimeInPercent + transitionTimeInPercent}%, ${animateOutTimeInPercent - transitionTimeInPercent}%`]: {
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
              },
              [`${Math.min( animateOutTimeInPercent, 100 )}%`]: {
                opacity: 0,
                transform: "translate3d(0, -15px, 0)",
              }
            }
          }} variant="body2" color="secondary.contrastText">
            {message}
          </Typography>
        );
      } )}
    </Box>
  )
}
