import { Stack } from "@mui/material";
import CheckCircleTaskIcon from '@mui/icons-material/TaskAlt';
import { ReactNode } from "react";
import { PurchaseFlowBenefitText } from "../payment/purchaseFlowBenefitText";

interface UpsellBenefitRowProps
{
  children: ReactNode;
}

export function UpsellBenefitRow( props: UpsellBenefitRowProps )
{

  return (
    <Stack direction={"row"} alignItems={"center"} sx={{ my: 2 }}>
      <CheckCircleTaskIcon color={"secondary"} sx={{ mr: 7 }}/>
      <PurchaseFlowBenefitText>{props.children}</PurchaseFlowBenefitText>
    </Stack>
  )

}