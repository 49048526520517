import { SocialNetworkAccountAPI, YOUTUBE } from "../../services/business.services";
import { ShareBatchAPI, YoutubeSettingsAPI } from "../../services/postIdeaServices";
import { find } from "lodash";
import { YOUTUBE_PRIVACY_PUBLIC } from "../constants";

export interface YoutubeSharingSettings
{
  youtubeSettings: YoutubeSettingsAPI,
  selectedToShare: boolean,
  readyToShare: boolean,
}

export function getPreviousYoutubePost( shareBatch: ShareBatchAPI | undefined, youtubeAccount: SocialNetworkAccountAPI | undefined)
{
  return find( shareBatch?.social_network_posts,
    post => post.account_type === YOUTUBE && post.social_network_account_id === youtubeAccount?.id );
}

export function getInitialYoutubeSharingSettings(): YoutubeSharingSettings
{
  return {
    youtubeSettings: {
      privacy: YOUTUBE_PRIVACY_PUBLIC,
      video_title: "",
    },
    selectedToShare: false,
    readyToShare: true
  }
}

