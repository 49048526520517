import * as React from "react";
import { Typography } from "@mui/material";

export interface PostSuccessTitleProps
{
  children: React.ReactNode;
}

export function PostSuccessTitle( props: PostSuccessTitleProps )
{
  return (
    <Typography variant="h1" sx={{ fontSize: 45, lineHeight: "53px", fontWeight: 900, color: "white" }}>
      {props.children}
    </Typography>
  )
}
