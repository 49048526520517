import { Box, Button, CircularProgress, Divider, Stack } from "@mui/material";

import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { concat, debounce, findIndex, isNil, map, size, uniqBy } from "lodash";
import * as InfiniteScroll from "react-infinite-scroller";
import { useDispatch } from "react-redux";
import { EditFontCategorySelector } from "./editFontCategorySelector";
import { FontAPI, FontCategoryAPI, fontServices } from "../../../services/font.services";
import { errorAlert, setAlertMessage } from "../../alert/alertSlice";
import { BOTTOM_NAV_EDIT_DRAWER_HEIGHT_PX } from "../../constants";
import { FontOption } from "./fontOption";
import { FontSetAPI, fontSetServices } from "../../../services/fontSet.services";
import ProgressOverlay from "../../loadingIndicator/progressOverlay";
import { eventTracker } from "../../../helpers/eventTracker";
import { EditFontSourceType } from "../../../helpers/trackingConstants";

export interface EditFontProps
{
  fontSet: FontSetAPI;
  selectedFont: FontAPI;
  updateFontSetAfterCopy: ( fontSet: FontSetAPI ) => void;
  source: EditFontSourceType;
}

export function EditFont( props: EditFontProps )
{
  const dispatch = useDispatch();
  const [fontOptions, setFontOptions] = useState<FontAPI[]>( [] )
  const [nextPage, setNextPage] = useState<number>()
  const [categoryFilter, setCategoryFilter] = useState<FontCategoryAPI>()
  const [localFontSet, setLocalFontSet] = useState( props.fontSet );
  const [localSelectedFont, setLocalSelectedFont] = useState<FontAPI>( props.selectedFont )
  const inputButtonRef = useRef<HTMLInputElement>( null );
  const [showLoading, setShowLoading] = useState( false );
  const handleFileSelection = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async ( event ) =>
    {
      setShowLoading( true );

      const selectedFontFiles = event.currentTarget.files;
      const validSelectedFontFiles = await fontServices.convertToFontFileData( selectedFontFiles );

      if ( !!validSelectedFontFiles && size( validSelectedFontFiles ) > 0 )
      {
        await fontServices.uploadFonts( validSelectedFontFiles ).then( ( data ) =>
        {
          eventTracker.logUploadFontCompleted(props.source, data);
          setFontOptions( [] );
          setNextPage( 1 );
          handleSelectFont( data );
        } ).catch(
          (error) =>
          {
            dispatch( setAlertMessage( errorAlert( "Could not upload font. Please try again." ) ) );
            eventTracker.logUploadFontFailed(props.source, error);
          }
        )
      }
      else if ( size( selectedFontFiles ) > size( validSelectedFontFiles ) )
      {
        dispatch( setAlertMessage( errorAlert( "Invalid file. Please upload a valid .ttf or .otf file." ) ) );
      }

      setShowLoading( false );

      if ( inputButtonRef && !!inputButtonRef.current )
      {
        inputButtonRef.current.value = "";
      }
    },
    []
  );

  const updateFontSetFonts = async () =>
  {
    const apiResponse = await fontSetServices.updateFontSetFonts( localFontSet );
    if ( apiResponse.font_set )
    {
      props.updateFontSetAfterCopy( apiResponse.font_set );
    }
  }

  const debouncedUpdateFontSetFonts = useCallback( debounce( updateFontSetFonts, 300 ), [localSelectedFont] );

  useEffect( () =>
  {
    loadFontList( 1, categoryFilter )
  }, [categoryFilter] );

  async function loadFontList( page = 1, categoryFilter?: FontCategoryAPI )
  {
    const response = await fontServices.getFontList( page, categoryFilter );
    const newFonts = uniqBy( concat( fontOptions, response.fonts ), ( fontOption ) => fontOption.slug );
    setFontOptions( newFonts );
    setNextPage( response.next_page );
    setCategoryFilter( isNil( response.category ) ? undefined : response.category );
  }

  async function loadMore()
  {
    try
    {
      await loadFontList( nextPage, categoryFilter )
    }
    catch (error)
    {
      dispatch( setAlertMessage( errorAlert( "Could not load next page. Please try again." ) ) );
    }
  }

  function hasMore()
  {
    return nextPage !== null;
  }

  function handleCategorySelected( category: FontCategoryAPI | undefined )
  {
    setFontOptions( [] );
    setNextPage( 1 );
    setCategoryFilter( category );
  }

  function handleSelectFont( font: FontAPI )
  {
    setLocalSelectedFont( font );
    const idxOfFont = findIndex( localFontSet.fonts, ( fontSetFont ) => props.selectedFont.usage === fontSetFont.usage );
    if ( idxOfFont > -1 )
    {
      const newFont = { ...font, usage: props.selectedFont.usage };
      localFontSet.fonts[idxOfFont] = newFont;
      setLocalFontSet( localFontSet );
      debouncedUpdateFontSetFonts();
    }
  }

  return (
    <Stack>
      {showLoading && <ProgressOverlay/>}
      <Divider/>
      <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "center" }}>
        <EditFontCategorySelector selectedCategory={categoryFilter}
                                  categoryFilters={Object.values( FontCategoryAPI )}
                                  handleCategorySelected={handleCategorySelected}/>
        <Button component="label" variant="outlined" sx={{ width: "131px", height: "31px", mb: "3px", textTransform: "none" }}>Upload font
          <input ref={inputButtonRef} hidden type="file" accept=".ttf, .otf, .woff" id={"pick-font-input"} onChange={handleFileSelection}/>
        </Button>
      </Stack>
      <Divider/>
      <Box sx={{ height: BOTTOM_NAV_EDIT_DRAWER_HEIGHT_PX, overflow: "auto" }}>
        {
          // @ts-ignore
          <InfiniteScroll
            pageStart={1}
            loadMore={loadMore}
            hasMore={hasMore()}
            loader={<CircularProgress key="0" className="spinner small" id="spinner"/>}
            useWindow={false}>
            {map( fontOptions, ( fontOption, idx ) =>
            {
              const isSelected = fontOption.slug === localSelectedFont.slug;
              const backgroundColor = isSelected ? "primary.light" : "none";

              return <Box key={fontOption.slug} sx={{ width: "100%", backgroundColor, display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: "200px" }}>
                  <FontOption key={fontOption.slug} font={fontOption} selected={isSelected} handleSelectFont={handleSelectFont}/>
                </Box>
              </Box>
            } )}
          </InfiniteScroll>
        }
      </Box>
    </Stack>
  )
}
