import { Stack } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { BRAND_CARD_PREVIEW_SOURCE_BRAND_SETTINGS, ROUTE_SEO, ROUTES } from "../constants";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { BrandingContents } from "./brandingContents";
import { Helmet } from "react-helmet";

export function Branding()
{
  const navigateWithSearchParams = useNavigateWithSearchParams();

  async function handleBackClicked()
  {
    navigateWithSearchParams( ROUTES.USER_SETTINGS );
  }

  return <Stack spacing={10} sx={{ textAlign: "left", px: 5, pt: 5 }} alignItems="start">
    <Helmet>
      <title>{ROUTE_SEO.BRANDING.title}</title>
      <meta name="description" content={ROUTE_SEO.BRANDING.description}/>
    </Helmet>
    <IconButton onClick={handleBackClicked} sx={{ padding: "unset" }}>
      <ArrowBackIcon/>
    </IconButton>
    <BrandingContents source={BRAND_CARD_PREVIEW_SOURCE_BRAND_SETTINGS}/>
  </Stack>
}