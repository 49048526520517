import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { stripeServices } from "../../services/stripe.services";

export function ManageSubscriptionBanner()
{
  function handleManageSubscriptionClicked()
  {
    stripeServices.customerPortalManageSubscription();
  }

  return (
    <Stack direction="column" sx={{
      color: 'primary.contrastText', m: 1, borderRadius: "5px",
      backgroundColor: "#FA585E",
    }}>
      <Typography variant={"body2"}>
        Your subscription is no longer active.<br/> Please
        renew to continue using Alkai.
      </Typography>
      <Link sx={{ m: 2, pb: "1px", fontWeight: "bold" }} color={"primary.contrastText"} component="button" alignItems={"center"}
            variant="body2"
            onClick={handleManageSubscriptionClicked}>Manage subscription</Link>
    </Stack>
  );
}
