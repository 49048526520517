import * as React from 'react';
import { Box, LinearProgress, Typography } from "@mui/material";
import "./dateTimePicker.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { totalRecordingProgress } from "../loadingIndicator/loadingSlice";

export interface RecordingProgressBarProps
{
  label: string;
}

export function RecordingProgressBar( props: RecordingProgressBarProps )
{
  const totalProgress = useSelector( ( state: RootState ) => totalRecordingProgress( state ) );
  return (
    <Box sx={{ display: "flex", position: "relative", alignItems: "center", mt: 5, borderRadius: "3px", p: 0 }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ height: 36.5, borderRadius: "5px" }} variant="determinate" value={totalProgress}/>
      </Box>
      <Box sx={{
        width: "100%",
        position: "absolute",
        left: 0,
        justifyContent: "center",
        textColor: "white",
        display: "flex",
        flexDirection: "row",
        mr: 1
      }}>
        <Typography variant="body2" color="primary.contrastText">{props.label}</Typography>
        <Typography variant="body2" color="primary.contrastText">{`${Math.round(
          totalProgress,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
