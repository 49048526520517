import React from "react";

export function MessageDots()
{
  return (
    <div className="message-dots">
      <div/>
      <div/>
      <div/>
    </div>
  )
}