import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { hasEmail, hasPhoneNumber } from "../user/userSlice";
import { UserSettingsRow } from "./userSettingsRow";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

export interface NotificationSubscriptionToggleProps
{
  isToggleOn: boolean;
  onChange: ( event: React.ChangeEvent<HTMLInputElement> ) => void;
}

export function NotificationSubscriptionToggle( props: NotificationSubscriptionToggleProps )
{
  const hasPhoneChannel = useSelector( ( state: RootState ) => hasPhoneNumber( state ) );
  const hasEmailChannel = useSelector( ( state: RootState ) => hasEmail( state ) );

  function getSubscribedNotificationsLabel()
  {
    if ( hasPhoneChannel )
    {
      return "Receive text notifications";
    }
    else if ( hasEmailChannel )
    {
      return "Receive email notifications";
    }
    return "Receive notifications";
  }

  return <UserSettingsRow sx={{ alignItems: "center" }}
                          icon={<NotificationsNoneOutlinedIcon/>}>
    <Stack direction="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
      <Typography>{getSubscribedNotificationsLabel()}</Typography>
      <Switch sx={{ justifySelf: "flex-end" }} checked={props.isToggleOn} onChange={props.onChange}/>
    </Stack>
  </UserSettingsRow>
}