import * as React from "react";
import { Button } from "@mui/material";

export interface PostSuccessContinueButtonProps
{
  onClick: () => void;
  title?: string;
}

export function PostSuccessContinueButton( props: PostSuccessContinueButtonProps )
{
  function getButtonTitle()
  {
    if( !!props.title )
    {
      return props.title;
    }
    return <> Continue to your plan</>;
  }

  return (
    <Button variant="contained" color="alternate" onClick={props.onClick} sx={{ mt: 10, mx: "auto", fontWeight: 700, borderRadius: "20px" }}>
      {getButtonTitle()}
    </Button>
  )
}
