import { getToApi } from "./requestManager";
import { STOCK_MEDIA_SEARCH_TYPE_PHOTO, StockMediaSearchType } from "../features/constants";
import { store } from "../app/store";
import { searchPhotosFailed, searchPhotosLoaded } from "../features/stock_media/stockMediaSlice";
import { currentUserBusinessId } from "../features/business/businessSlice";

export const stockMediaServices = {
  search,
}
const STOCK_MEDIA_BASE_PATH = "stock_media";
const STOCK_MEDIA_SEARCH_PATH = "search";

async function search( search_term: string, page: number = 1, media_type: StockMediaSearchType = STOCK_MEDIA_SEARCH_TYPE_PHOTO )
{
  try
  {
    const endpointUrl = STOCK_MEDIA_BASE_PATH + "/" + STOCK_MEDIA_SEARCH_PATH;
    const state = store.getState();

    const queryParams = {
      search_term: search_term,
      media_type: media_type,
      page: page,
      business_id: currentUserBusinessId( state ),
    }

    const response = await getToApi( endpointUrl, queryParams );
    if ( !!response )
    {
      return await store.dispatch( searchPhotosLoaded( response ) );
    }
    else
    {
      await store.dispatch( searchPhotosFailed( response ) );

      return Promise.reject( "Failed to get search results for search_term: id=" + search_term );
    }
  }
  catch (error)
  {
    return Promise.reject( "Failed to get search results for search_term: id=" + search_term );
  }
}
