import {
  SocialNetworkAccountAPI,
  SocialNetworkPostAPI,
  TIKTOK,
  TiktokBrandingAPI,
  TiktokInteractionOptionsAPI
} from "../../services/business.services";
import { ShareBatchAPI, TiktokSettingsAPI } from "../../services/postIdeaServices";
import { find, head, includes } from "lodash";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { TIKTOK_PRIVACY_PUBLIC, TiktokPrivacyOption } from "../constants";

export interface TiktokSharingSettings
{
  tiktokSettings: TiktokSettingsAPI,
  selectedToShare: boolean,
  readyToShare: boolean,
}

export function getPreviousTiktokPost(shareBatch: ShareBatchAPI | undefined, tiktokAccount: SocialNetworkAccountAPI | undefined)
{
  return find( shareBatch?.social_network_posts,
    post => post.account_type === TIKTOK && post.social_network_account_id === tiktokAccount?.id );
}


export function getInitialTiktokInteractionSettings( referenceTiktokPost: SocialNetworkPostAPI | undefined,
                                              tiktokAccount: SocialNetworkAccountAPI | undefined): TiktokInteractionOptionsAPI
{
  if ( !!referenceTiktokPost && !!referenceTiktokPost.tiktok_settings )
  {
    return {
      comment_disabled: referenceTiktokPost.tiktok_settings.comment_disabled,
      duet_disabled: referenceTiktokPost.tiktok_settings.duet_disabled,
      stitch_disabled: referenceTiktokPost.tiktok_settings.stitch_disabled
    };
  }
  return {
    comment_disabled: false,
    duet_disabled: true,
    stitch_disabled: true
  };
}

export function getInitialTiktokBrandingDefaults( tiktokAccount: SocialNetworkAccountAPI | undefined): TiktokBrandingAPI
{
  return socialNetworkAccountHelper.getConnectedTiktokBrandingDefaults( tiktokAccount ) || {
    brand_content_toggle: false,
    brand_organic_toggle: false,
  }
}

export function getTiktokAccountLevelInteractionSettings( tiktokAccount: SocialNetworkAccountAPI | undefined)
{
  return socialNetworkAccountHelper.getConnectedTiktokInteractionOptions( tiktokAccount ) || {
    comment_disabled: false,
    duet_disabled: true,
    stitch_disabled: true
  };
}

export function getInitialTiktokPrivacyOption( referenceTiktokPost: SocialNetworkPostAPI | undefined, privacyOptions: TiktokPrivacyOption[] ): TiktokPrivacyOption
{
  if ( !!referenceTiktokPost &&
       !!referenceTiktokPost.tiktok_settings?.privacy_level &&
       includes( privacyOptions, referenceTiktokPost.tiktok_settings.privacy_level ) )
  {
    return referenceTiktokPost.tiktok_settings.privacy_level;
  }
  //@ts-ignore
  return includes( privacyOptions, TIKTOK_PRIVACY_PUBLIC ) ? TIKTOK_PRIVACY_PUBLIC : head( privacyOptions );
}

export function getInitialTiktokSharingSettings(referenceTiktokPost: SocialNetworkPostAPI | undefined, tiktokAccount: SocialNetworkAccountAPI | undefined)
{
  const brandingDefaults = getInitialTiktokBrandingDefaults(tiktokAccount);
  const interactionDefaults = getInitialTiktokInteractionSettings(referenceTiktokPost, tiktokAccount);
  const availablePrivacyOptions = socialNetworkAccountHelper.getConnectedTiktokPrivacyOptions( tiktokAccount ) || [];
  const initialSettingsAPI: TiktokSettingsAPI = {
    privacy_level: getInitialTiktokPrivacyOption( referenceTiktokPost, availablePrivacyOptions ),
    brand_organic_toggle: brandingDefaults.brand_organic_toggle,
    brand_content_toggle: brandingDefaults.brand_content_toggle,
    comment_disabled: interactionDefaults.comment_disabled,
    duet_disabled: interactionDefaults.duet_disabled,
    stitch_disabled: interactionDefaults.stitch_disabled
  }
  const initialSharingSettings: TiktokSharingSettings = {
    tiktokSettings: initialSettingsAPI,
    selectedToShare: false,
    readyToShare: true
  };

  return initialSharingSettings;
}


