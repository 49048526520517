import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getBusinessName } from "../business/businessSlice";

interface InstagramUIOverlayProps
{
  caption?: string,
  hasAudioInPost?: boolean,
}

export function InstagramUIOverlay( props: InstagramUIOverlayProps )
{
  const businessName = useSelector( ( state: RootState ) => getBusinessName( state ) ) || "Business Name";
  const caption = props.caption
                  || "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
  const heightOfSpaceBelowLastIcon = props.hasAudioInPost ? "50px" : "32px";
  return <Box sx={{
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0px",
    left: "0px",
    p: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    gap: 2,
    color: "rgba(255, 255, 255, 0.7)",
  }}>
    <Stack direction="column" sx={{ width: "220px", height: "72px" }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <AccountCircleIcon/>
        <Typography variant="caption">{businessName}</Typography>
      </Stack>
      <Typography noWrap variant="caption">{caption}</Typography>
    </Stack>
    <Stack direction="column"
           alignItems="center"
           width="60px"
           gap={2}
    >
      <Stack sx={{ width: "32px" }} alignItems="center">
        <FavoriteBorderOutlinedIcon/>
        <Typography variant="caption">234</Typography>
      </Stack>
      <Stack sx={{ width: "32px" }} alignItems="center">
        <ChatBubbleOutlineOutlinedIcon/>
        <Typography variant="caption">45</Typography>
      </Stack>
      <Stack sx={{ width: "32px" }} alignItems="center">
        <SendOutlinedIcon/>
      </Stack>
      <Stack sx={{ width: "32px" }} alignItems="center">
        <MoreHorizOutlinedIcon/>
      </Stack>
      <Stack sx={{ width: "32px", height: heightOfSpaceBelowLastIcon }}>
      </Stack>
    </Stack>
  </Box>;
}
