import * as React from "react";
import { ChangeEvent } from "react";
import { map, size, upperFirst } from "lodash";
import { STOCK_MEDIA_SEARCH_TYPE_PHOTO, STOCK_MEDIA_SEARCH_TYPE_VIDEO, StockMediaSearchType } from "../constants";
import { ExternalMediaData } from "./stockMediaSlice";
import { clsx } from "clsx";
import { Box, Button, Chip, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import Search from "@mui/icons-material/Search";
import { ExternalMediaCell } from "./ExternalMediaCell";
import * as InfiniteScroll from "react-infinite-scroller";
import { StockMediaContainerOwnProps } from "./StockMediaPicker.container";
import "./stockMedia.scss";
import AlertBanner from "../alert/alertBanner";

export interface StockMediaPickerProps extends StockMediaContainerOwnProps
{
  searchResults: ExternalMediaData[];
  nextPage?: number;
  totalCount: number;
  externalMediaUploadStatus: { [id: string]: boolean };
  designSupportsVideo: boolean;
  hasError?: boolean;
  isLoadingSearchResults: boolean;
  searchTerm?: string;
  defaultStockMediaSearchTerm: string;
  shouldShowDefaultStockMediaSearchResults: boolean;
  selectedMediaType: StockMediaSearchType;
  stockMediaAttribution?: string;
  stockMediaAttributionLink?: string;
}

export interface StockMediaPickerDispatchProps
{
  onSearchSubmitted: ( selectedMediaType: StockMediaSearchType, searchTerm: string, isUserInitiatedSearch: boolean ) => void;
  onStockMediaClicked: ( stockMediaData: ExternalMediaData ) => void;
  loadMore: ( selectedMediaType: StockMediaSearchType, searchTerm: string, page: number ) => void;
  updateSelectedMediaType: ( stockMediaSearchType: StockMediaSearchType ) => void;
  updateShowDefaultSearchTerm: () => void;
}

export interface StockMediaPickerState
{
  searchTerm: string | undefined;
  lastSearchTerm: string | undefined;
}

export class StockMediaPicker extends React.PureComponent<StockMediaPickerProps & StockMediaPickerDispatchProps, StockMediaPickerState>
{
  constructor( props: StockMediaPickerProps & StockMediaPickerDispatchProps )
  {
    super( props );
    this.state = {
      searchTerm: this.props.searchTerm,
      lastSearchTerm: this.props.searchTerm,
    };
  }

  public componentDidMount(): void
  {
    if ( this.props.shouldShowDefaultStockMediaSearchResults )
    {
      this.initialSearch();
    }
  }

  public componentDidUpdate( previousProps: StockMediaPickerProps ): void
  {
    if ( this.props.shouldShowDefaultStockMediaSearchResults !== previousProps.shouldShowDefaultStockMediaSearchResults )
    {
      if ( this.props.shouldShowDefaultStockMediaSearchResults )
      {
        this.initialSearch();
      }
    }
  }

  public render()
  {
    return (
      <div className={clsx( "stockMediaContainer" )}>
        <>
          {this.createSearchBar()}
          {this.createSuggestedSearchTerms()}
          {this.createNoResults()}
          {this.createSearchResults()}
          <AlertBanner/>
        </>
      </div>
    );
  }

  private handleSuggestedSearchTermClick = ( searchTerm: string ) =>
  {
    this.setState( { searchTerm: searchTerm }, () =>
    {
      this.search();
    } );
  }

  private createSearchBar = () =>
  {
    return <Stack sx={{ display: "flex", justifyContent: "center", width: "100%", mx: "auto" }}>
      <form className="searchBarContainer" style={{ width: "100%", margin: "0 auto" }} onSubmit={this.handleOnSubmit}>
        <Stack display="flex" flexDirection="column" alignItems="flex-start" width="100%" maxWidth="725px" margin="0 auto">
          <Stack display="flex" flexDirection="row" justifyContent="center" width="100%">
            <TextField
              id="search-bar"
              className="searchBar"
              inputProps={{ sx: { px: 4, py: 4 } }}
              sx={{ width: "100%", maxWidth: "650px" }}
              placeholder="Search Stock Media"
              value={this.state.searchTerm}
              onChange={this.handleOnChange}/>
            <Button variant={"contained"} sx={{ ml: 4 }} onClick={this.handleSearchClick}><Search className="search"/></Button>
          </Stack>
          <Box className="stockMediaAttribution" sx={{ alignSelf: "flex-start" }}>
            <a className="standardLink" href={this.props.stockMediaAttributionLink} rel="noreferrer"
               target="_blank">{this.props.stockMediaAttribution}</a>
          </Box>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={STOCK_MEDIA_SEARCH_TYPE_PHOTO}
              name="radio-buttons-group"
              value={this.props.selectedMediaType}
              onChange={this.handleOnSelect
              }
            >
              <FormControlLabel disabled={this.props.isLoadingSearchResults} value={STOCK_MEDIA_SEARCH_TYPE_PHOTO} control={<Radio/>}
                                label={upperFirst( STOCK_MEDIA_SEARCH_TYPE_PHOTO )}/>
              <FormControlLabel disabled={this.props.isLoadingSearchResults} value={STOCK_MEDIA_SEARCH_TYPE_VIDEO} control={<Radio/>}
                                label={upperFirst( STOCK_MEDIA_SEARCH_TYPE_VIDEO )}/>
            </RadioGroup>
          </FormControl>
        </Stack>
      </form>
    </Stack>;
  }

  private loadMore = ( page: number ) =>
  {
    if ( !!this.props.nextPage )
    {
      this.props.loadMore( this.props.selectedMediaType, this.state.searchTerm || "", this.props.nextPage );
    }
  }

  private createSearchResults = () =>
  {
    if ( this.shouldDisplaySearchResults() )
    {
      const hasMore = this.props.searchResults && !!this.props.nextPage;
      return <div className="stockMediaImageContainer externalMediaGridContainer">
        {this.isSearchTermUpToDate() &&
         // @ts-ignore
         <InfiniteScroll
           pageStart={1}
           loadMore={this.loadMore}
           hasMore={hasMore}
           loader={<CircularProgress key="0" className="spinner small" id="spinner"/>}
           useWindow={false}>
           {this.shouldShowProgressSpinner() && <CircularProgress className="searchSpinner small" id="spinner"/>}
           {
             map( this.props.searchResults, ( item, index ) =>
             {
               if ( !item )
               {
                 return null;
               }
               const itemKey = index + "-" + item.external_media_id
               return <ExternalMediaCell key={itemKey}
                                         externalMediaData={item}
                                         onExternalMediaClicked={this.props.onStockMediaClicked}
                                         externalMediaUploadStatus={this.props.externalMediaUploadStatus}/>;
             } )
           }
           {
             this.shouldShowNoMoreSearchResults() &&
             <div className="endResults">End of results, search again</div>
           }
         </InfiniteScroll>
        }
      </div>;
    }
  }

  private shouldShowNoMoreSearchResults()
  {
    const results = this.props.searchResults;
    return results && results.length > 0 && !this.props.nextPage;
  }

  private shouldShowProgressSpinner = () =>
  {
    return !!this.state.lastSearchTerm && this.props.isLoadingSearchResults && !this.props.hasError;
  };

  private shouldDisplaySearchResults = () =>
  {
    return this.hasSearchResults() && ((this.props.designSupportsVideo && this.isVideoTypeSelected()) || this.isPhotoTypeSelected());
  }

  private isPhotoTypeSelected = () =>
  {
    return this.props.selectedMediaType === STOCK_MEDIA_SEARCH_TYPE_PHOTO;
  }

  private isVideoTypeSelected = () =>
  {
    return this.props.selectedMediaType === STOCK_MEDIA_SEARCH_TYPE_VIDEO;
  }

  private hasSearchResults = () =>
  {
    return this.props.searchResults && !!this.state.lastSearchTerm;
  }

  private handleSearchClick = ( e: React.MouseEvent ) =>
  {
    this.search();
  }

  private handleOnChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    const searchTerm = event.target.value
    this.props.updateShowDefaultSearchTerm();
    this.setState( { searchTerm: searchTerm as string } );
  }

  private isSearchTermUpToDate = () =>
  {
    return this.state.searchTerm === this.state.lastSearchTerm || this.state.lastSearchTerm === "";
  }

  private handleOnSubmit = ( e: React.FormEvent<HTMLFormElement> ) =>
  {
    e.preventDefault();
    const activeElement = document.activeElement;
    if ( activeElement )
    {
      const elementToBlur = activeElement as HTMLInputElement;
      elementToBlur.blur();
    }

    if ( this.state.lastSearchTerm !== this.state.searchTerm )
    {
      this.search();
    }
  }

  private handleOnSelect = async ( event: ChangeEvent<HTMLInputElement>, value: string ) =>
  {
    const selectedMediaType = event.target.value
    await this.props.updateSelectedMediaType( selectedMediaType as StockMediaSearchType );
    if ( this.state.searchTerm !== "" )
    {
      this.search();
    }
  }

  private search = () =>
  {
    if ( !!this.state.searchTerm )
    {
      this.props.onSearchSubmitted( this.props.selectedMediaType, this.state.searchTerm || "", true );

      this.setState( {
        lastSearchTerm: this.state.searchTerm,
      } );
    }
  }

  private initialSearch = () =>
  {
    if ( !!this.props.defaultStockMediaSearchTerm )
    {
      this.props.onSearchSubmitted( this.props.selectedMediaType, this.props.defaultStockMediaSearchTerm, false );
      this.setState( {
        lastSearchTerm: this.props.defaultStockMediaSearchTerm,
        searchTerm: this.props.defaultStockMediaSearchTerm,
      } );
    }
  }

  private handleClearClicked = () =>
  {
    this.setState( { searchTerm: "" } );
  }

  private createSuggestedSearchTerms = () =>
  {
    return (<Stack>
      {size( this.props.suggestedSearchTerms ) > 0 && <Typography variant={"subtitle1"}>Suggested search terms</Typography>}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flex: "1 1 auto" }}>
        {
          this.props.suggestedSearchTerms &&
          map( this.props.suggestedSearchTerms,
            ( term: string ) => <Chip sx={{ mr: 2, mb: 2 }} className="suggestedSearchTerm" key={term} label={term}
                                      onClick={() => this.handleSuggestedSearchTermClick( term )}/> )
        }
      </Box>
    </Stack>);
  }

  private createNoResults = () =>
  {
    if ( this.props.hasError )
    {
      const headline = "Unable to get data. Please try again.";
      return <span>{headline}</span>;
    }
    else if ( this.hasSearchResults() && this.props.searchResults.length === 0 && !this.props.isLoadingSearchResults )
    {
      const headline = `No results for "${this.state.lastSearchTerm}"`;
      return <span>{headline}</span>;
    }
  }
}
