import React from "react";
import { Box, Slider, Stack, Typography } from "@mui/material";
import { isUndefined } from "lodash";

export interface StyleQuizContinuumChoiceProps
{
  title: string;
}

export interface StyleQuizContinuumQuestionProps
{
  id: string;
  pageTitle: string;
  questionIndex?: number;
  questionTitle: string;
  imageUrl: string;
  leftChoice: StyleQuizContinuumChoiceProps;
  rightChoice: StyleQuizContinuumChoiceProps;
  onQuestionAnswered: ( id: string, value: number ) => void;
  value?: number;
}

export function StyleQuizContinuumQuestion( props: StyleQuizContinuumQuestionProps )
{
  const defaultValue = 50;
  const handleChange = ( event: Event, newValue: number | number[] ) =>
  {
    props.onQuestionAnswered( props.id, newValue as number );
  };

  return <Stack textAlign="left" spacing={20}>
    <Typography variant="h5">{props.pageTitle}</Typography>
    <Stack spacing={4}>
      <Stack direction="row">
        <Box component="img" src={props.imageUrl} alt={props.pageTitle} sx={{ width: "100%" }}/>
      </Stack>
      <Typography variant="subtitle1">{(props.questionIndex || 0) + 1}. {props.questionTitle}</Typography>
    </Stack>

    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2">{props.leftChoice.title}</Typography>
        <Typography variant="subtitle2">{props.rightChoice.title}</Typography>
      </Stack>
      <Slider aria-label="choice" value={isUndefined( props.value ) ? defaultValue : props.value} onChange={handleChange}/>
    </Stack>
  </Stack>;
}
