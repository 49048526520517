import React from "react";
import { Box, SxProps } from "@mui/material";
import { merge } from "lodash";

export interface QuizBreadCrumbDotProps
{
  breadCrumbIndex: number;
  currentQuestionIndex: number;
}

export function QuizBreadCrumbDot( props: QuizBreadCrumbDotProps )
{
  const size = 30
  const circle: SxProps = { borderRadius: "100%", width: size, height: size, border: "2px solid darkgray" };

  function getFillColor( breadCrumbIndex: number )
  {
    const shouldFill = breadCrumbIndex <= props.currentQuestionIndex;
    const fillColor = shouldFill ? ( theme ) => theme.palette.primary.main : "white";
    return { background: fillColor };
  }

  return <Box sx={merge( circle, getFillColor( props.breadCrumbIndex ) )}/>
}