import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { BOTTOM_NAV_LAYOUTS_CONTENT_HEIGHT } from "../../constants";
import { map } from "lodash";
import { updateChatPostIdeaMessage } from "../../assistantChat/assistantChatSlice";
import { useDispatch } from "react-redux";
import { PostEditingDrawerContainer } from "../edtingDrawer/postEditingDrawerContainer";
import { PostEditingDrawerCancelApply } from "../edtingDrawer/postEditingDrawerCancelApply";
import { PostEditingDrawerScrollableContainer } from "../edtingDrawer/postEditingDrawerScrollableContainer";
import { LayoutAPI, layoutServices } from "../../../services/layout.services";
import { addPost } from "../../postIdea/postsSlice";
import { BEAT_SYNC_ICON_TYPE, errorAlert, infoAlert, setAlertMessage } from "../../alert/alertSlice";
import { postIdeaServices } from "../../../services/postIdeaServices";
import { Layout } from "./layout";
import { eventTracker } from "../../../helpers/eventTracker";
import { addOrUpdatePostIdeaFromMessage, getLayoutPresetName } from "../../postIdea/postIdeaHelper";
import { DrawerTitle } from "../../typography/DrawerTitle";
import { PostIdeaContext } from "../../context/postIdeaContext";

export interface LayoutDrawerProps
{
  handleDrawerClose: () => void;
}

export function LayoutDrawer( props: LayoutDrawerProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const [lastTimeScrollToSelected, setLastTimeScrollToSelected] = useState<number>( 0 );
  const [lastTimeRemoveScrollHint, setLastTimeRemoveScrollHint] = useState<number>( 0 );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastTimePreventScrollHintAnimation, setLastTimePreventScrollHintAnimation] = useState<number>( 0 );

  const [showLoader, setShowLoader] = useState( false );
  const [selectedLayoutLoading, setSelectedLayoutLoading] = useState( false );
  const [requestingNewLayout, setRequestingNewLayout] = useState<boolean>( false );
  const dispatch = useDispatch();

  const [layouts, setLayouts] = useState<LayoutAPI[]>( [] );
  const actualPresetNameInPostIdea = getLayoutPresetName( postIdea );
  const initialPresetName = useRef( "" );
  const [selectedPresetName, setSelectedPresetName] = useState<string>( actualPresetNameInPostIdea )
  useEffect( () =>
  {
    initialPresetName.current = actualPresetNameInPostIdea;
    setSelectedPresetName( actualPresetNameInPostIdea );
  }, [] );

  useEffect( () =>
  {
    setSelectedPresetName( actualPresetNameInPostIdea );
  }, [actualPresetNameInPostIdea] );

  function loadLayoutVariants()
  {
    layoutServices.getLayouts( postIdea.id ).then( ( response ) =>
    {
      setLayouts( response.layouts );
    } ).finally( () =>
    {
      setShowLoader( false );
    } );
  }

  useEffect( () =>
  {
    setShowLoader( true );
    loadLayoutVariants();
  }, [] );

  useEffect( () =>
  {
    setLastTimeScrollToSelected( new Date().getTime() )
  }, [layouts] );

  const onApplyClick = () =>
  {
    props.handleDrawerClose();
  }

  const onCancelClick = () =>
  {
    if ( initialPresetName && initialPresetName.current !== selectedPresetName )
    {
      requestNewLayout( initialPresetName.current );
    }
    props.handleDrawerClose();
  }

  const requestNewLayout = ( presetName: string ) =>
  {
    setRequestingNewLayout( true );
    return postIdeaServices.generateSpecificLayoutVariant( postIdea.id, presetName ).then( ( response ) =>
    {
      dispatch( addPost( response.post_json ) );
      dispatch( updateChatPostIdeaMessage( response.post_idea_message ) );
      addOrUpdatePostIdeaFromMessage( response.post_idea_message );

      if ( !!response.added_music_for_beat_sync )
      {
        dispatch( setAlertMessage(
          infoAlert( "This layout is better with music, so I picked some music for you. You can change it if you want",
            'top',
            true,
            BEAT_SYNC_ICON_TYPE ) ) );
      }
    } ).catch( ( error ) =>
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem switching layouts. Please try again." ) ) );
    } ).finally( () =>
      {
        setRequestingNewLayout( false );
      }
    );
  }
  const handleLayoutSelected = async ( layout: LayoutAPI ) =>
  {
    setSelectedLayoutLoading( true );
    setSelectedPresetName( layout.preset_name );
    setLastTimeRemoveScrollHint( new Date().getTime() );
    await applyLayout( layout );
    setSelectedLayoutLoading( false );
  }

  const applyLayout = async ( layout: LayoutAPI ) =>
  {
    eventTracker.logEditPostLayoutSwitched( postIdea, layout );
    await requestNewLayout( layout.preset_name );
  }

  return (
    <PostEditingDrawerContainer sx={{ height: "auto" }}>
      <DrawerTitle>Layouts</DrawerTitle>
      <PostEditingDrawerScrollableContainer height={BOTTOM_NAV_LAYOUTS_CONTENT_HEIGHT}
                                            showLoader={showLoader}
                                            disableSelection={requestingNewLayout}
                                            lastTimeScrollToSelected={lastTimeScrollToSelected}
                                            lastTimeRemoveScrollHint={lastTimeRemoveScrollHint}
                                            lastTimePreventScrollHintAnimation={lastTimePreventScrollHintAnimation}>

        {map( layouts, ( layout ) =>
        {
          const selected = layout.preset_name === selectedPresetName;
          return (
            <Layout key={layout.slug}
                    layout={layout}
                    handleLayoutSelected={handleLayoutSelected}
                    selected={selected}
                    showLoader={selected && selectedLayoutLoading}
            />
          )
        } )}
      </PostEditingDrawerScrollableContainer>

      <PostEditingDrawerCancelApply onCancelClick={onCancelClick} onApplyClick={onApplyClick}/>

    </PostEditingDrawerContainer>
  );
}
