import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface NoticeAPI
{
  id: string,
  message: string,
  show_customer_support_contact_info: boolean,
  color?: string,
}

interface InAppNoticeState
{
  notices: NoticeAPI[],
}

const initialState: InAppNoticeState =
  {
    notices: [],
  }

export const inAppNoticeSlice = createSlice( {
  name: 'inAppNotices',
  initialState,
  reducers: {
    setInAppNotices: ( state, action: PayloadAction<NoticeAPI[]> ) =>
    {
      return {
        ...state,
        notices: action.payload
      }
    }
  }
} )

export const hasNotices = ( state: RootState ) => state.inAppNotices.notices.length > 0;
export const getNotices = ( state: RootState ) => state.inAppNotices.notices;

export const {
  setInAppNotices,
} = inAppNoticeSlice.actions

export default inAppNoticeSlice.reducer