import * as React from 'react';
import { useEffect, useState } from 'react';
import { BOTTOM_NAV_SCROLLABLE_CONTENT_HEIGHT_ONE_ROW } from "../../constants";
import { Divider } from "@mui/material";
import { concat, filter, find, map } from "lodash";
import { ColorPalette } from "./colorPalette";
import { ColorPaletteAPI, colorPaletteServices, ColorPaletteStatusAPI } from "../../../services/colorPalette.services";
import { CurrentColorPaletteSection } from "./currentColorPaletteSection";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { EditColorPalette } from "./editColorPalette";
import { AddColorPaletteButton } from "./addColorPaletteButton";
import { PostEditingDrawerContainer } from "../edtingDrawer/postEditingDrawerContainer";
import { PostEditingDrawerCancelApply } from "../edtingDrawer/postEditingDrawerCancelApply";
import { PostEditingDrawerScrollableContainer } from "../edtingDrawer/postEditingDrawerScrollableContainer";
import { DrawerTitle } from "../../typography/DrawerTitle";
import { eventTracker } from "../../../helpers/eventTracker";
import { EditColorPaletteSourceType } from "../../../helpers/trackingConstants";

export interface ColorPaletteDrawerProps
{
  selectedColorPaletteSlug?: string;
  applyColorPalette: ( colorPalette: ColorPaletteAPI ) => void;
  handleDrawerClose: () => void;
  handleCancelClicked: ( selectedColorPaletteSlug?: string ) => void;
  source: EditColorPaletteSourceType;
}

export function ColorPaletteDrawer( props: ColorPaletteDrawerProps )
{
  const [lastTimeScrollToSelected, setLastTimeScrollToSelected] = useState<number>( 0 );
  const [lastTimeRemoveScrollHint, setLastTimeRemoveScrollHint] = useState<number>( 0 );
  const [lastTimePreventScrollHintAnimation, setLastTimePreventScrollHintAnimation] = useState<number>( 0 );

  const [showLoader, setShowLoader] = useState( false );

  const [colorPalettes, setColorPalettes] = useState<ColorPaletteAPI[]>( [] );
  const [colorPaletteInEditMode, setColorPaletteInEditMode] = useState<ColorPaletteAPI>();
  const selectedColorPalette = find( colorPalettes, ( colorPalette ) => colorPalette.slug === props.selectedColorPaletteSlug );

  function loadColorPalettes()
  {
    colorPaletteServices.getColorPalettes().then( ( response ) =>
    {
      setColorPalettes( concat( response.business_color_palettes || [], response.color_palettes ) );
    } ).finally( () =>
    {
      setShowLoader( false );
    } );
  }

  useEffect( () =>
  {
    setShowLoader( true );
    loadColorPalettes();
  }, [] );

  useEffect( () =>
  {
    setLastTimeScrollToSelected( new Date().getTime() )
  }, [colorPalettes, colorPaletteInEditMode] );

  const handleColorPaletteSelected = ( colorPalette: ColorPaletteAPI ) =>
  {
    setLastTimeRemoveScrollHint( new Date().getTime() );
    props.applyColorPalette( colorPalette );
  }

  const onApplyClick = () =>
  {
    props.handleDrawerClose();
  }

  const onCancelClick = () =>
  {
    props.handleCancelClicked( props.selectedColorPaletteSlug )
  }

  const updateColorPaletteAfterCopy = ( colorPalette: ColorPaletteAPI ) =>
  {
    props.applyColorPalette( colorPalette );
    setColorPaletteInEditMode( colorPalette );
    loadColorPalettes();
  }

  const handleFavoriteColorPalette = async ( colorPalette: ColorPaletteAPI ) =>
  {
    setLastTimePreventScrollHintAnimation( new Date().getTime() );
    const response = await colorPaletteServices.favoriteColorPalette( colorPalette );
    loadColorPalettes();
    const responseColorPalette = response.color_palette;
    if ( responseColorPalette && responseColorPalette.slug !== props.selectedColorPaletteSlug )
    {
      await props.applyColorPalette( responseColorPalette );
    }
  }

  const handleUnfavoriteColorPalette = async ( colorPalette: ColorPaletteAPI ) =>
  {
    setLastTimePreventScrollHintAnimation( new Date().getTime() );
    await colorPaletteServices.unfavoriteColorPalette( colorPalette );
    loadColorPalettes();
  }

  const handleAddColorPaletteClicked = async () =>
  {
    eventTracker.logAddColorPaletteClicked(props.source);

    if ( selectedColorPalette )
    {
      const newColorPalette = await colorPaletteServices.copyColorPaletteToBusiness( selectedColorPalette, ColorPaletteStatusAPI.Live );

      eventTracker.logNewColorPaletteAdded(props.source, newColorPalette );
      await props.applyColorPalette( newColorPalette );
      const updatedColorPaletteResponse = await colorPaletteServices.updateColorPaletteName( { ...newColorPalette, name: "My colors" } );
      const updatedColorPalette = updatedColorPaletteResponse.color_palette;
      setColorPaletteInEditMode( updatedColorPalette );
    }
    loadColorPalettes();
  }

  const handleStartEditingColorPalette = ( colorPalette: ColorPaletteAPI ) =>
  {
    setLastTimePreventScrollHintAnimation( new Date().getTime() );
    setColorPaletteInEditMode( colorPalette );
  }

  const handleFinishEditingColorPalette = () =>
  {
    setColorPaletteInEditMode( undefined );
  }

  return (
    <PostEditingDrawerContainer sx={{ height: "auto", textAlign: "center"}}>
      {colorPaletteInEditMode && <IconButton onClick={handleFinishEditingColorPalette} sx={{ position: "absolute", top: 0, left: 0 }}>
        <ArrowBackIcon/>
      </IconButton>}
      <DrawerTitle>Colors</DrawerTitle>
      {
        !colorPaletteInEditMode && selectedColorPalette && <>
                                  <CurrentColorPaletteSection colorPalette={selectedColorPalette}
                                                              favoriteColorPalette={handleFavoriteColorPalette}
                                                              unfavoriteColorPalette={handleUnfavoriteColorPalette}
                                                              startEditingColorPalette={handleStartEditingColorPalette}/>
                                  <Divider/>
                                </>
      }
      {colorPaletteInEditMode && <EditColorPalette colorPalette={colorPaletteInEditMode}
                                                   updateColorPaletteAfterCopy={updateColorPaletteAfterCopy}/>}
      {!colorPaletteInEditMode &&
       <PostEditingDrawerScrollableContainer height={BOTTOM_NAV_SCROLLABLE_CONTENT_HEIGHT_ONE_ROW}
                                             showLoader={showLoader}
                                             lastTimeScrollToSelected={lastTimeScrollToSelected}
                                             lastTimeRemoveScrollHint={lastTimeRemoveScrollHint}
                                             lastTimePreventScrollHintAnimation={lastTimePreventScrollHintAnimation}>

         {selectedColorPalette && <AddColorPaletteButton handleAddColorPaletteClicked={handleAddColorPaletteClicked}/>}
         {map( filter( colorPalettes, ( colorPalette ) => colorPalette.status === ColorPaletteStatusAPI.Live ),
           ( colorPalette ) =>
           {
             const selected = colorPalette.slug === props.selectedColorPaletteSlug;
             return (
               <ColorPalette key={colorPalette.slug}
                             colorPalette={colorPalette}
                             handleColorPaletteSelected={handleColorPaletteSelected}
                             selected={selected}/>
             )
           } )}
       </PostEditingDrawerScrollableContainer>}

      {!colorPaletteInEditMode && <PostEditingDrawerCancelApply onCancelClick={onCancelClick} onApplyClick={onApplyClick}/>}

    </PostEditingDrawerContainer>
  );
}
