import { Stack, Typography } from "@mui/material";
import { eventTracker } from "../../helpers/eventTracker";
import { getBusinessId } from "../business/businessSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Variant } from "@mui/material/styles/createTypography";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { UserSettingsRow, UserSettingsRowProps } from "./userSettingsRow";
import React from "react";

export interface SurveyLinkProps extends UserSettingsRowProps
{
  variant?: Variant;
  surveyId: string;
}

export function SurveyLink( props: SurveyLinkProps )
{
  const businessId = useSelector( ( state: RootState ) => getBusinessId( state ) ) || "";

  function getSurveyUrl()
  {
    return `https://www.research.net/r/${props.surveyId}?businessId=${businessId}`;
  }

  function handleSurveyLinkClicked()
  {
    eventTracker.logSurveyLinkClicked( businessId, props.surveyId );
    window.open( getSurveyUrl(), '_blank', 'noopener' );
  }

  return (
    <UserSettingsRow icon={<AssessmentOutlinedIcon/>}
                     onClick={handleSurveyLinkClicked} sx={{ justifyContent: "flex-start" }}>
      <Stack>
        <Typography>Help us make Alkai better by</Typography>
        <Typography>taking our survey</Typography>
      </Stack>
    </UserSettingsRow>)
}