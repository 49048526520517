import { ENABLE_DEV_CONSOLE_LOGGING } from "../../constants";

export function logToConsole( message: string, ...args )
{
  if ( ENABLE_DEV_CONSOLE_LOGGING )
  {
    // tslint:disable-next-line:no-console
    console.debug( message, ...args );
  }
}

export function logToConsoleError( message: string, ...args )
{
  if ( ENABLE_DEV_CONSOLE_LOGGING )
  {
    // tslint:disable-next-line:no-console
    console.error( message, ...args );
  }
}
