import { FontSetAPI } from "../../../services/fontSet.services";
import { Box, Stack } from "@mui/material";
import * as React from "react";
import { FONT_SET_BUTTON_HEIGHT_PX, FONT_SET_BUTTON_MIN_WIDTH_PX } from "../../constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { map } from "lodash";
import { FontAPI } from "../../../services/font.services";
import { Favorite } from "@mui/icons-material";
import { isFontSetFavorited } from "./fontSetHelper";
import { ExternalFontName } from "./externalFontName";

export interface FontSetButtonProps
{
  selected: boolean;
  fontSet: FontSetAPI;
  handleFontSetSelected: ( fontSet: FontSetAPI ) => void;
}

export function FontSetButton( props: FontSetButtonProps )
{
  const windowDimensions = useWindowDimensions();
  const windowWidth = windowDimensions.width;
  const outline = props.selected ? "2px solid" : "none";
  const numberOfButtonsToRender = Math.floor( windowWidth / FONT_SET_BUTTON_MIN_WIDTH_PX );
  const buttonWidth = Math.max( windowWidth / numberOfButtonsToRender, FONT_SET_BUTTON_MIN_WIDTH_PX );
  const className = props.selected ? "selected" : "";
  const isFavorite = isFontSetFavorited( props.fontSet );

  function handleOnClick()
  {
    props.handleFontSetSelected( props.fontSet );
  }

  return (
    <Box className={className}
         sx={{
           display: "flex",
           height: FONT_SET_BUTTON_HEIGHT_PX,
           width: buttonWidth,
           flexShrink: 0,
           flexDirection: "column",
           justifyContent: "center",
           alignItems: "center",
           cursor: "pointer",
           border: outline,
           borderColor: ( theme ) => theme.palette.primary.main,
           position: "relative"
         }} onClick={handleOnClick}>
      {
        props.fontSet.image_url &&
        <Box sx={{
          height: FONT_SET_BUTTON_HEIGHT_PX - 10,
          width: "100%",
          backgroundImage: `url(${props.fontSet.image_url})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          position: "absolute"
        }}/>
      }
      {
        !props.fontSet.image_url &&
        <Stack sx={{ height: "100%", alignItems: "center", width: "100%" }}>
          {map( props.fontSet.fonts, ( font: FontAPI, index ) =>
          {
            return <Box key={font.font_family + index}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50%", width: "100%" }}>
              {!!font.image_url && <img alt={font.font_family} src={font.image_url} style={{ height: "100%" }}/>}
              {!font.image_url && <ExternalFontName font={font}
                                                    key={font.font_family + index}
                                                    sx={{
                                                      justifyContent: "center",
                                                      alignItems: "top",
                                                      overflow: "hidden",
                                                      textAlign: "center",
                                                      lineHeight: "2",
                                                      mb: 4
                                                    }}/>}
            </Box>
          } )}
        </Stack>
      }
      {isFavorite ? <Favorite sx={{ fontSize: "8px", ml: 1, mt: 1, color: "red", position: "absolute" }}/> : null}
    </Box>);
}
