import { getToApi } from "./requestManager";
import { MediaAssetAPI } from "./business.services";
import { UrlUtils } from "../features/utils/urlUtils";
import { compact } from "lodash";

const CURATED_MEDIA_SETS_BASE_PATH = "curated_media_sets";
const LIST_CURATED_MEDIA_SETS_PATH = CURATED_MEDIA_SETS_BASE_PATH + "/list";

export const curatedMediaSetServices = {
  getCuratedMediaSets,
  getCuratedMediaSet,
}

export interface CuratedMediaSetsListResponse
{
  curated_media_sets: CuratedMediaSetAPI[];
}

interface CuratedMediaSetResponse
{
  curated_media_set: CuratedMediaSetAPI;
}

export interface CuratedMediaSetAPI
{
  slug: string;
  name: string;
  cover_image_url: string;
  media_assets?: MediaAssetAPI[]
}

function getCuratedMediaSets()
{
  return getToApi<CuratedMediaSetsListResponse>( LIST_CURATED_MEDIA_SETS_PATH, {} );
}

function getCuratedMediaSet( slug: string )
{
  const url = buildMemberUrl( slug );
  return getToApi<CuratedMediaSetResponse>( url, {} );
}

function buildMemberUrl( curatedMediaSetSlug: string, path?: string )
{
  return new UrlUtils().buildUrl( CURATED_MEDIA_SETS_BASE_PATH, compact([curatedMediaSetSlug, path]) )
}
