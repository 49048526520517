import { filter, isEmpty, isUndefined } from "lodash";
import { BUSINESS_SWITCHER_DEFAULT_BUSINESS_NAME } from "../features/constants";
import { RefreshBusinessAPI } from "../services/business.services";

export const businessHelper = {
  getBusinessNameToDisplay,
  getDisabledBusinesses,
}

function getDisabledBusinesses( businesses: RefreshBusinessAPI[] )
{
  return filter( businesses, ( business ) => !business.enabled );
}

function getBusinessNameToDisplay( business: RefreshBusinessAPI )
{
  const businessName = business.name;
  if ( isEmpty( businessName ) || isUndefined( businessName ) )
  {
    return BUSINESS_SWITCHER_DEFAULT_BUSINESS_NAME;
  }
  return businessName;
}