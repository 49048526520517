import { useSearchParams } from "react-router-dom";
import { IS_DEVELOPMENT } from "../../constants";

export default function useRemoveSearchParamByKey()
{
  const [searchParams, setSearchParams] = useSearchParams();

  return ( queryParamKey ) =>
  {
    if ( searchParams.has( queryParamKey ) )
    {
      searchParams.delete( queryParamKey );

      if ( IS_DEVELOPMENT )
      {
        console.info( `Removing ${queryParamKey} from searchParams` );
      }
      setSearchParams( searchParams );
    }
  };
}