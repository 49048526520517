import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { eventTracker } from "../../helpers/eventTracker";

export function QuickActionPanel( { loading, sendChatMessage } )
{
  let QUICK_ACTION_ONE_MESSAGE = "I have media for a post";
  let QUICK_ACTION_TWO_MESSAGE = "I have a post idea";
  let QUICK_ACTION_THREE_MESSAGE = "Give me a post idea";
  let QUICK_ACTION_FOUR_MESSAGE = "Let's review my plan";

  function sendIHaveAnIdeaMessage()
  {
    sendChatMessage( QUICK_ACTION_ONE_MESSAGE );
    eventTracker.logQuickActionClicked( QUICK_ACTION_ONE_MESSAGE );
  }

  function sendGiveMeAPostIdeaMessage()
  {
    sendChatMessage( QUICK_ACTION_TWO_MESSAGE );
    eventTracker.logQuickActionClicked( QUICK_ACTION_TWO_MESSAGE );
  }

  function sendIHaveMediaForAPostMessage()
  {
    sendChatMessage( QUICK_ACTION_THREE_MESSAGE );
    eventTracker.logQuickActionClicked( QUICK_ACTION_THREE_MESSAGE );
  }

  function sendLetsReviewMyPlanMessage()
  {
    sendChatMessage( QUICK_ACTION_FOUR_MESSAGE );
    eventTracker.logQuickActionClicked( QUICK_ACTION_FOUR_MESSAGE );
  }

  return (<Box sx={{
    overflowX: "auto",
    width: "100%",
    flex: "0 0 auto",
    p: 2,
  }}>
    <Stack direction={"row"}>
      <Button
        variant='contained'
        color='primary'
        sx={{ alignSelf: 'center', whiteSpace: "nowrap", minWidth: "170px", textTransform: "none", mr: 2 }}
        onClick={sendIHaveAnIdeaMessage}
        disabled={loading}
      >
        {QUICK_ACTION_ONE_MESSAGE}
      </Button>
      <Button
        variant='contained'
        color='primary'
        sx={{ alignSelf: 'center', whiteSpace: "nowrap", minWidth: "150px", textTransform: "none", mr: 2 }}
        onClick={sendGiveMeAPostIdeaMessage}
        disabled={loading}
      >
        {QUICK_ACTION_TWO_MESSAGE}
      </Button>
      <Button
        variant='contained'
        color='primary'
        sx={{ alignSelf: 'center', whiteSpace: "nowrap", minWidth: "160px", textTransform: "none", mr: 2 }}
        onClick={sendIHaveMediaForAPostMessage}
        disabled={loading}
      >
        {QUICK_ACTION_THREE_MESSAGE}
      </Button>
      <Button
        variant='contained'
        color='primary'
        sx={{ alignSelf: 'center', whiteSpace: "nowrap", minWidth: "170px", textTransform: "none", mr: 2 }}
        onClick={sendLetsReviewMyPlanMessage}
        disabled={loading}
      >
        {QUICK_ACTION_FOUR_MESSAGE}
      </Button>
    </Stack>
  </Box>);
}