import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { merge } from "lodash";

export interface ColorSwatchProps
{
  color: string;
  size: number;
  sxProps?: SxProps;
}

export function ColorSwatch( props: ColorSwatchProps )
{
  const { color, size, sxProps } = props;

  let defaultSxProps = {
    width: size,
    height: size,
    background: color,
  };
  const mergedSxProps = merge( defaultSxProps, sxProps );

  return (
    <Box sx={mergedSxProps}/>
  )
}