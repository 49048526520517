import { Box, Stack, Typography } from "@mui/material";
import { MediaItem } from "../mediaList/mediaItem";
import React from "react";
import { MediaAssetAPI } from "../../services/business.services";
import TransparentGridTile from "../../assets/transparent-grid.png";
import { UrlUtils } from "../utils/urlUtils";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "../../assets/settings.png";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export interface LogoButtonProps
{
  mediaAsset?: MediaAssetAPI;
  handleLogoPickerClicked: () => void;
}

export default function LogoButton( props: LogoButtonProps )
{
  const background = logoPotentiallyHasTransparency() ? `url(${TransparentGridTile}) repeat` : "white";

  function logoPotentiallyHasTransparency()
  {
    return new UrlUtils().isFileTypeThatSupportsTransparency( props.mediaAsset?.url )
  }

  return (
    <Stack alignItems="center" sx={{ mb: 4, mr: 5 }}>
      <Box
        sx={{
          width: "90px",
          height: "90px",
          border: "1px solid #9D9D9D",
          borderRadius: "5px",
          p: 4,
          background: background,
          position: "relative",
          cursor: "pointer"
        }}
        onClick={props.handleLogoPickerClicked}
      >
        {
          props.mediaAsset &&
          <>
            <Stack alignItems="center" height="100%" sx={{ pointerEvents: "none" }}>
              <MediaItem url={props.mediaAsset.poster_url} additionalStyle={{ width: "100%" }}
                         handleMediaClicked={() =>
                         {
                         }}/>
            </Stack>
            <IconButton sx={{ position: "absolute", right: 0, top: 0, p: 1, color: "white" }}>
              <Box component="img" src={SettingsIcon} alt="edit logo"/>
            </IconButton>
          </>
        }

        {!props.mediaAsset &&
         <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
           <IconButton>
             <AddCircleOutlineIcon sx={{ color: "#BBBBBB" }}/>
           </IconButton>
         </Stack>
        }
      </Box>
      <Typography variant="subtitle2" sx={{ mt: 2 }}>Logo</Typography>
    </Stack>
  );
}