import { includes, toLower } from "lodash";
import { MEDIA_ASSET_FILE_TYPE_PHOTO, MEDIA_ASSET_FILE_TYPE_UNSUPPORTED, MEDIA_ASSET_FILE_TYPE_VIDEO } from "../features/constants";

export const mediaUtils = {
  isHeicFile,
  isImageFile,
  isSupportedFileType,
  isVideoFile,
  getMediaAssetFileType,
}

const HEIC_FILE_HEADER_MAGIC_NUMBERS = "66747970";

function getMediaAssetFileType( fileName: string )
{
  if ( isVideoFileName( fileName ) )
  {
    return MEDIA_ASSET_FILE_TYPE_VIDEO;
  }
  else if ( isImageFileName( fileName ) )
  {
    return MEDIA_ASSET_FILE_TYPE_PHOTO;
  }
  else
  {
    return MEDIA_ASSET_FILE_TYPE_UNSUPPORTED;
  }
}

function isSupportedFileType( file: File )
{
  return isImageFile( file ) || isVideoFile( file );
}

function isVideoFile( file: File )
{
  return isVideoFileName( file.name );
}

function isVideoFileName( name: string )
{
  const lowercasedFileName = toLower( name );

  return includes( lowercasedFileName, ".mp4" ) || includes( lowercasedFileName, ".mov" )
}

function isImageFile( file: File )
{
  return isImageFileName( file.name )
}

function isImageFileName( name: string )
{
  const lowercasedFileName = toLower( name );
  return includes( lowercasedFileName, ".jpg" ) ||
         includes( lowercasedFileName, ".jpeg" ) ||
         includes( lowercasedFileName, ".png" ) ||
         includes( lowercasedFileName, ".webp" ) ||
         includes( lowercasedFileName, ".jfif" ) ||
         includes( lowercasedFileName, ".gif" ) ||
         includes( lowercasedFileName, ".heic" );
}

function isHeicFile( file: File )
{
  return new Promise<boolean>( ( resolve, reject ) =>
  {
    const reader = new FileReader()

    reader.onload = ( event ) =>
    {
      try
      {
        if ( !!event.target && !!event.target.result )
        {
          // source/inspiration: https://github.com/alexcorvi/heic2any/blob/master/src/heic2any.ts
          const buffer = event.target.result as ArrayBuffer;
          Object.assign( buffer, { fileStart: 0 } )
          // heic file header is offset by 4 bytes
          const arr = new Uint8Array( buffer ).subarray( 4, 8 );
          let header = "";
          for ( let i = 0; i < arr.length; i++ )
          {
            header = header + arr[i].toString( 16 );
          }

          if ( header === HEIC_FILE_HEADER_MAGIC_NUMBERS )
          {
            resolve( true );
          }
          else
          {
            resolve( false );
          }
        }
      }
      catch
      {
        reject( "Error reading file" );
      }
    }

    reader.readAsArrayBuffer( file );
  } );

}