import { Box, Button, Stack } from "@mui/material";
import React, { ReactNode } from "react";
import { SocialShareStatusBarTypography } from "./socialShareStatusBarTypography";

export interface SocialNetworkPostStatusBarProps
{
  content: string | ReactNode;
  buttonText?: string;
  onButtonClick?: ( event: React.MouseEvent<HTMLButtonElement> ) => void;
  rightButton?: ReactNode;
}

export function SocialShareStatusBar( props: SocialNetworkPostStatusBarProps )
{
  function handleStatusBarClicked( event: React.MouseEvent<HTMLDivElement> )
  {
    event.stopPropagation();
  }

  return <Stack>
    <Box sx={{ width: "100%", backgroundColor: "#DB1B50", cursor: "default" }} onClick={handleStatusBarClicked}>
      <Stack direction="row" alignItems="center" justifyContent="start" sx={{ textAlign: "center", py: 2, px: 1, ml: 5 }}>

        {typeof props.content === 'string' && <SocialShareStatusBarTypography>{props.content}</SocialShareStatusBarTypography>}
        {typeof props.content !== 'string' && props.content}

        {!!props.rightButton && props.rightButton}
        {!props.rightButton && !!props.buttonText && <Button variant={"contained"} onClick={props.onButtonClick} sx={{
          ml: "auto", mr: 1, backgroundColor: "white", color: "#DB1B50", fontSize: "10px", fontWeight: "400",
          '&:hover': { backgroundColor: '#F2F2F2', color: '#CB0046' },
        }}>{props.buttonText}</Button>}
      </Stack>
    </Box>
  </Stack>
}