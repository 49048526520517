import { useRouteError } from "react-router-dom";
import { logToConsoleError } from "../utils/devLoggingHelper";

export default function ErrorPage()
{
  const error = useRouteError();
  logToConsoleError( "error page", error );

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}
