import { createSlice } from '@reduxjs/toolkit'

export interface TrackAPI
{
  epidemic_id: string,
  image: string,
  display_name: string,
  genre: string,
  artist_name: string,
  highlights: string[],
  is_beat_sync_recommended: boolean,
}

export interface MusicAPI
{
  tracks: TrackAPI[]
}

const initialState: MusicAPI = {
  tracks: []
};

export const musicSlice = createSlice( {
  name: 'music',
  initialState,
  reducers: {
    setTracks: ( state, action ) =>
    {
      return {
        ...state,
        ...action.payload
      };
    },
  },
} )

export const {
  setTracks,
} = musicSlice.actions

export default musicSlice.reducer