import { Box, SxProps } from "@mui/material";
import React, { ReactNode } from "react";
import { merge } from "lodash";

export interface PlanIdeaTitleTypographyProps
{
  children: ReactNode;
  sx?: SxProps;
}

export function PlanIdeaTitleTypography( props: PlanIdeaTitleTypographyProps )
{
  return (<Box sx={merge( {
    flex: "0 1 auto",
    fontSize: "15px",
    fontFamily: "roboto",
    width: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflowY: "hidden",
  }, props.sx )}>{props.children}</Box>);
}