import { FormControl, FormGroup, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { StyleQuizLink } from "../userSettings/styleQuizLink";
import { BusinessMediaStyleAPI, businessServices, MediaStylesAPI } from "../../services/business.services";
import { find, map, size } from "lodash";
import { MediaStyleCheckboxRow } from "./mediaStyleCheckboxRow";
import { eventTracker } from "../../helpers/eventTracker";

export function ImageStylePreferences()
{
  const [mediaStyles, setMediaStyles] = useState<BusinessMediaStyleAPI[]>( [] )
  const hasMediaStyles = size( mediaStyles ) > 0;

  useEffect( () =>
  {
    loadBusinessMediaStyles();
  }, [] );

  function loadBusinessMediaStyles()
  {
    businessServices.getMediaStyles().then( ( data: MediaStylesAPI ) =>
    {
      const mediaStyles = data.media_styles;
      setMediaStyles( mediaStyles )

    } );
  }

  async function handleChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    const checkboxId = event.target.id;
    const checked = event.target.checked;
    const mediaStyleNumber = parseInt( checkboxId );
    const mediaStyleToUpdate = find( mediaStyles, ( mediaStyle ) => mediaStyle.id === mediaStyleNumber );
    if ( !!mediaStyleToUpdate )
    {
      if ( checked )
      {
        eventTracker.logMediaStyleEnabled( mediaStyleToUpdate.preset_name );
      }
      else
      {
        eventTracker.logMediaStyleDisabled( mediaStyleToUpdate.preset_name );
      }

      await businessServices.updateMediaStyle( mediaStyleToUpdate.id, { active: checked } )
      loadBusinessMediaStyles();
    }
  }

  return (
    <Stack spacing={15}>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="left">
          <CropOriginalIcon sx={{ mr: 6 }}/>
          <Typography variant="h6">Image Styles</Typography>
        </Stack>
        {hasMediaStyles && <Typography variant="subtitle1">Your matched custom styles. Select to enable</Typography>}
      </Stack>

      {hasMediaStyles && <FormControl component="fieldset" variant="standard">
        <FormGroup>
          {map( mediaStyles, ( mediaStyle ) =>
          {
            return <MediaStyleCheckboxRow key={mediaStyle.id} mediaStyle={mediaStyle} handleChange={handleChange}/>
          } )}
        </FormGroup>
      </FormControl>}

      <StyleQuizLink/>
    </Stack>)
}
