import { Box, MenuItem, Select, Stack } from "@mui/material";

import * as React from "react";
import { FontCategoryAPI } from "../../../services/font.services";
import { concat, map } from "lodash";
import AllStyles from "../../../assets/all-styles-icon.svg";
import SansSerif from "../../../assets/sans-serif-icon.svg";
import Serif from "../../../assets/serif-icon.svg";
import Display from "../../../assets/display-icon.svg";
import Script from "../../../assets/script-icon.svg";
import Handwriting from "../../../assets/handwriting-icon.svg";

export interface EditFontCategorySelectorProps
{
  selectedCategory: FontCategoryAPI | undefined;
  categoryFilters: FontCategoryAPI[];
  handleCategorySelected: ( category: FontCategoryAPI | undefined ) => void;
}

type FontCategoryAPIWithUnfilteredOption = FontCategoryAPI | "All Styles";

export function EditFontCategorySelector( props: EditFontCategorySelectorProps )
{
  const removeFilterCategory = "All Styles";
  const removeFilter = [removeFilterCategory] as FontCategoryAPIWithUnfilteredOption[];
  const allFilters = concat( removeFilter, props.categoryFilters );

  function onChange( e )
  {
    const newCategory = e.target.value as FontCategoryAPIWithUnfilteredOption;
    if ( newCategory === removeFilterCategory )
    {
      props.handleCategorySelected( undefined );
    }
    else
    {
      props.handleCategorySelected( newCategory );
    }
  }

  function getIconForCategory( category: FontCategoryAPIWithUnfilteredOption )
  {
    switch ( category )
    {
      case FontCategoryAPI.Serif:
        return <img src={SansSerif} alt="SansSerif"/>;
      case FontCategoryAPI.SansSerif:
        return <img src={Serif} alt="Serif"/>;
      case FontCategoryAPI.Display:
        return <img src={Display} alt="Display"/>;
      case FontCategoryAPI.Handwriting:
        return <img src={Handwriting} alt="Handwriting"/>;
      case FontCategoryAPI.Script:
        return <img src={Script} alt="Script"/>;
      case removeFilterCategory:
      default:
        return <img src={AllStyles} alt="All Styles"/>;
    }
  }

  return (
    <Box sx={{ p: 4 }}>
      <Select
        value={props.selectedCategory || removeFilterCategory}
        onChange={onChange}
        inputProps={{
          sx: { py: 2 }
        }}>
        {map( allFilters, ( category ) =>
        {
          const icon = getIconForCategory( category );
          return (
            <MenuItem key={category} value={category}><Stack direction="row" spacing={6}>{icon}<span>{category}</span></Stack></MenuItem>
          );
        } )}
      </Select>
    </Box>
  );
}
