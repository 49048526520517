import { Box, SxProps } from "@mui/material";
import * as React from "react";
import { merge } from "lodash";

export interface FullPageMobileHeaderProps
{
  children: React.ReactNode;
  sx?: SxProps;
}

export function FullPageMobileHeader( props: FullPageMobileHeaderProps )
{
  return <Box sx={merge( { pt: 2, px: 10, borderBottom: 1, borderColor: 'divider', flexBasis: "1 1 auto" }, props.sx )}>
    {props.children}
  </Box>
}