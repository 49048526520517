import { concat, difference, filter, includes, inRange, join, map } from "lodash";
import { handleResponse } from "./requestManager";

export const LOGIN_PERMISSIONS = ["public_profile"];
export const OPTIONAL_LOGIN_PERMISSIONS = ["email"];
export const PAGE_PUBLISH_PERMISSIONS = ["business_management",
                                         "pages_read_engagement",
                                         "pages_manage_metadata",
                                         "pages_read_user_content",
                                         "pages_manage_posts",
                                         "pages_manage_engagement"];
export const PAGE_PERMISSIONS = LOGIN_PERMISSIONS.concat( PAGE_PUBLISH_PERMISSIONS );

export const INSTAGRAM_BUSINESS_PERMISSIONS = PAGE_PERMISSIONS.concat( ["instagram_basic", "instagram_content_publish"] );

const GRAPH_API_VERSION = "17.0";

declare type FacebookPermissionStatus = "granted" | "declined";

interface FacebookPermission
{
  permission: string;
  status: FacebookPermissionStatus;
}

interface FacebookErrorResponse
{
  message: string;
  type: string;
  code: number;
  error_subcode?: number;
}

export const facebookServices = {
  verifyUserHasInstagramBusinessPermissions,
  verifyUserHasFacebookPagePermissions,
  GRAPH_API_VERSION,
  getInstagramScope,
  getFacebookPagesScope,
  getFacebookProfileImageUrl,
};

function getInstagramScope()
{
  return join( concat( INSTAGRAM_BUSINESS_PERMISSIONS, OPTIONAL_LOGIN_PERMISSIONS ), "," );
}

function getFacebookPagesScope()
{
  return join( concat( PAGE_PERMISSIONS, OPTIONAL_LOGIN_PERMISSIONS ), "," );
}

async function verifyUserHasInstagramBusinessPermissions( aFacebookAccessToken: string ): Promise<boolean>
{
  return await verifyUserHasPermissions( aFacebookAccessToken, INSTAGRAM_BUSINESS_PERMISSIONS );
}

async function verifyUserHasFacebookPagePermissions( aFacebookAccessToken: string ): Promise<boolean>
{
  return await verifyUserHasPermissions( aFacebookAccessToken, PAGE_PERMISSIONS );
}

async function verifyUserHasPermissions( aFacebookAccessToken: string, desiredPermissions: string[] )
{
  try
  {
    const facebookPermissions = await getFacebookPermissions( aFacebookAccessToken );
    if ( hasGrantedPermissions( facebookPermissions, desiredPermissions ) )
    {
      return true;
    }
  }
  catch (error)
  {
    // Ignore error
  }
  return false;
}

// Do the supplied Facebook permissions grant all the desired permissions?
function hasGrantedPermissions( facebookPermissions: FacebookPermission[], desiredPermissions: string[] ): boolean
{
  const granted = map( filter( facebookPermissions, ["status", "granted"] ), ( facebookPermission ) =>
  {
    return facebookPermission.permission;
  } );
  const missing = difference( desiredPermissions, granted );
  return missing.length === 0;
}

function getFacebookPermissions( accessToken: string ): Promise<FacebookPermission[]>
{
  return new Promise<FacebookPermission[]>( ( resolve, reject ) =>
  {
    if ( !accessToken )
    {
      reject( { message: "Empty FB access token" } );
      return;
    }

    return getGraphAPI( accessToken, "me/permissions" ).then(
      ( res ) => resolve( res.data ),
      ( error: FacebookErrorResponse ) =>
      {
        if ( isFacebookSessionOrTokenExpired( error ) || isFacebookMissingAppPermission( error ) )
        {
          resolve( [] );
        }
        else
        {
          reject( error );
        }
      },
    );
  } );
}

function isFacebookSessionOrTokenExpired( error: FacebookErrorResponse )
{
  if ( includes( [102, 190], error.code ) || error.type === "OAuthException" )
  {
    return true;
  }
  return false;
}

function isFacebookMissingAppPermission( error: FacebookErrorResponse )
{
  if ( includes( [3, 10], error.code ) || inRange( error.code, 200, 300 ) )
  {
    return true;
  }

  return false;
}

function getGraphAPI( accessToken: string, endpoint: string )
{
  const url = `https://graph.facebook.com/v${GRAPH_API_VERSION}/${endpoint}?access_token=${accessToken}`;
  return getExternalJson( url );
}

function getExternalJson( url: string ): Promise<any>
{
  return fetch( url, {
    method: "GET",
  } ).then( ( response ) =>
  {
    return handleResponse( response, true );
  } );
}

export function getFacebookProfileImageUrl( facebookUserId: string ): string
{
  return `https://graph.facebook.com/${facebookUserId}/picture?type=square&width=200&height=200`;
}
