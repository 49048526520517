import * as React from "react";
import { Stack, Typography } from "@mui/material";
import GearIcon from "../../assets/gear.png";
import MessageBubbleIcon from "../../assets/messageBubble.png";
import CalendarIcon from "../../assets/calendar.png";
import CameraIcon from "../../assets/camera.png";
import { PaymentConfirmationPoint } from "./paymentConfirmationPoint";
import { PurchaseFlowCTA } from "./purchaseFlowCTA";
import { PurchaseFlowHeader } from "./purchaseFlowHeader";

export interface PaymentConfirmationProps
{
  handleCloseCheckout(): void;
}

export function PaymentConfirmation( props: PaymentConfirmationProps )
{
  return (
    <Stack alignItems="center">
      <PurchaseFlowHeader>You are on your way to social media success!</PurchaseFlowHeader>
      <Stack sx={{ ml: "30px", mr: "30px" }}>
        <Typography sx={{ fontSize: "14px", lineHeight: "14px", fontFamily: "Montserrat", fontWeight: 600, color: "#fff", mb: 2 }}>To get the most out
                                                                                                                                   of
                                                                                                                                   Alkai:</Typography>
        <PaymentConfirmationPoint iconSrc={GearIcon}
                                  iconAlt={"gear icon"}>Set up your colors & fonts</PaymentConfirmationPoint>
        <PaymentConfirmationPoint iconSrc={MessageBubbleIcon}
                                  iconAlt={"message bubble icon"}>Ask Alkai to make changes to the post text and caption</PaymentConfirmationPoint>
        <PaymentConfirmationPoint iconSrc={CameraIcon}
                                  iconAlt={"calendar icon"}>Take photos and videos for upcoming posts</PaymentConfirmationPoint>
        <PaymentConfirmationPoint iconSrc={CalendarIcon}
                                  iconAlt={"contact book icon"}>Have Alkai add upcoming events to your plan</PaymentConfirmationPoint>

      </Stack>
      <PurchaseFlowCTA onClick={props.handleCloseCheckout}>Say "Hey Alkai"</PurchaseFlowCTA>
    </Stack>
  )
}


