import { Link, Stack, Typography } from "@mui/material";
import { eventTracker } from "../../helpers/eventTracker";
import { getBusinessId } from "../business/businessSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Variant } from "@mui/material/styles/createTypography";
import { ROUTES } from "../constants";
import useNavigateWithSearchParams from "../../features/hooks/useNavigateWithSearchParams";
import React, { useEffect, useState } from "react";
import { businessServices, MediaStylesAPI } from "../../services/business.services";

export interface StyleQuizLinkProps
{
  variant?: Variant;
}

export function StyleQuizLink( props: StyleQuizLinkProps )
{
  const businessId = useSelector( ( state: RootState ) => getBusinessId( state ) ) || "";
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [hasMediaStyles, setHasMediaStyles] = useState<boolean>( false );
  const styleQuizLinkText = hasMediaStyles ? "Retake our style quiz" : "Take our style quiz";
  const aboveStyleQuizLinkText = hasMediaStyles ? "Want to replace these with new styles?" : undefined;

  useEffect( () =>
  {
    businessServices.getMediaStyles().then( ( data: MediaStylesAPI ) =>
    {
      const mediaStyles = data.media_styles;
      setHasMediaStyles( !!mediaStyles && mediaStyles.length > 0 )
    } );
  }, [] );

  function sendStyleQuizClickedEvent()
  {
    eventTracker.logStyleQuizClicked( businessId );
  }

  function navigateToStyleQuiz()
  {
    navigateWithSearchParams( ROUTES.STYLE_QUIZ );
  }

  return (<Stack>
    {aboveStyleQuizLinkText && <Typography variant="subtitle1">{aboveStyleQuizLinkText}</Typography>}
    <Typography variant={props.variant || "body1"} onClick={sendStyleQuizClickedEvent}>
      <Link onClick={navigateToStyleQuiz} sx={{ cursor: "pointer" }}>{styleQuizLinkText}</Link>
    </Typography>
  </Stack>)
}