import { Box } from "@mui/material";
import { ColorPaletteAPI } from "../../../services/colorPalette.services";
import { CURRENT_COLOR_PALETTE_SECTION_HEIGHT_PX } from "../../constants";
import { ColorPalette } from "./colorPalette";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import * as React from "react";
import { Favorite } from "@mui/icons-material";
import { isColorPaletteFavorited } from "./colorPaletteHelper";

export interface CurrentColorPaletteSectionProps
{
  colorPalette: ColorPaletteAPI;
  startEditingColorPalette: ( colorPalette: ColorPaletteAPI ) => void;
  favoriteColorPalette: ( colorPalette: ColorPaletteAPI ) => void;
  unfavoriteColorPalette: ( colorPalette: ColorPaletteAPI ) => void;
}

export function CurrentColorPaletteSection( props: CurrentColorPaletteSectionProps )
{
  const handleEditButtonClick = ( event: React.MouseEvent ) =>
  {
    props.startEditingColorPalette( props.colorPalette );
  }

  const handleFavoriteButtonClick = ( event: React.MouseEvent ) =>
  {
    if ( isColorPaletteFavorited( props.colorPalette ) )
    {
      props.unfavoriteColorPalette( props.colorPalette );
    }
    else
    {
      props.favoriteColorPalette( props.colorPalette );
    }
  }

  const isFavoritedColorPalette = isColorPaletteFavorited( props.colorPalette );
  return (
    <Box sx={{
      height: CURRENT_COLOR_PALETTE_SECTION_HEIGHT_PX,
      display: "inline-block",
      textAlign: "center",
      position: "relative",
    }}>
      <ColorPalette colorPalette={props.colorPalette} selected={false} hideName={true} size={30} sx={{ mx: "auto" }} borderWidth={2}/>
      <Box sx={{
        position: "absolute",
        height: "100%",
        top: `calc(50% - ${CURRENT_COLOR_PALETTE_SECTION_HEIGHT_PX / 2}px)`,
        left: "100%",
        display: "flex",
        alignItems: "center"
      }}>
        <IconButton onClick={handleEditButtonClick} sx={{ p: 2, color: "black" }}><EditIcon/></IconButton>
        <IconButton onClick={handleFavoriteButtonClick}
                    sx={{ p: 2, color: isFavoritedColorPalette ? "red" : "black" }}>
          {isFavoritedColorPalette ? <Favorite/> : <FavoriteBorderIcon/>}
        </IconButton>
      </Box>
    </Box>)
}