import { Box, Stack, SxProps, Typography } from "@mui/material";
import { ColorPaletteAPI } from "../../../services/colorPalette.services";
import { ColorSwatch } from "./colorSwatch";
import { map, merge } from "lodash";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { COLOR_PALETTE_BUTTON_HEIGHT_PX, COLOR_PALETTE_BUTTON_MIN_WIDTH_PX } from "../../constants";
import { Favorite } from "@mui/icons-material";
import * as React from "react";
import { isColorPaletteFavorited } from "./colorPaletteHelper";

export interface ColorPaletteProps
{
  colorPalette: ColorPaletteAPI;
  handleColorPaletteSelected?: ( colorPalette: ColorPaletteAPI ) => void;
  selected: boolean;
  hideName?: boolean;
  size?: number;
  sx?: SxProps;
  borderWidth?: number;
}

export function ColorPalette( props: ColorPaletteProps )
{
  const windowDimensions = useWindowDimensions();
  const windowWidth = windowDimensions.width;
  const { colorPalette, selected } = props;

  const handleOnClick = ( event: React.MouseEvent ) =>
  {
    if ( props.handleColorPaletteSelected )
    {
      props.handleColorPaletteSelected( colorPalette );
    }
  }
  const className = props.selected ? "selected" : "";

  const fontWeight = selected ? "bold" : "normal";

  const numberOfSwatchesToRender = Math.floor( windowWidth / COLOR_PALETTE_BUTTON_MIN_WIDTH_PX );
  const swatchWidth = Math.max( windowWidth / numberOfSwatchesToRender, COLOR_PALETTE_BUTTON_MIN_WIDTH_PX );
  const outline = props.selected ? "2px solid" : "none";
  const isFavorite = isColorPaletteFavorited( props.colorPalette );
  return (
    <Box
      className={className}
      sx={merge( {
        display: "flex",
        height: COLOR_PALETTE_BUTTON_HEIGHT_PX,
        width: swatchWidth,
        flexShrink: 0,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: props.handleColorPaletteSelected ? "pointer" : "default",
        border: outline,
        borderColor: ( theme ) => theme.palette.primary.main
      }, props.sx )} onClick={handleOnClick}>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
        {map( colorPalette.colors_json, ( color, index ) =>
        {
          let isFirstElement = index === 0;
          let borderTopLeftRadius = isFirstElement ? "5px" : "0px";
          let borderBottomLeftRadius = isFirstElement ? "5px" : "0px";

          let isLastElement = index === colorPalette.colors_json.length - 1;
          let borderTopRightRadius = isLastElement ? "5px" : "0px";
          let borderBottomRightRadius = isLastElement ? "5px" : "0px";
          const borderWidth = props.borderWidth || 0.5
          const border = `${borderWidth}px solid black`;

          return (
            <ColorSwatch key={color.hex + index}
                         color={color.hex}
                         size={props.size || 20}
                         sxProps={{
                           borderTopLeftRadius,
                           borderBottomLeftRadius,
                           borderTopRightRadius,
                           borderBottomRightRadius,
                           borderLeft: isFirstElement ? border : "none",
                           borderBottom: border,
                           borderTop: border,
                           borderRight: isLastElement ? border : "none",
                         }}/>
          )
        } )}
      </Box>
      {!props.hideName &&
       <Stack direction={"row"} sx={{ width: "100%", justifyContent: "center" }}>
         <Typography variant={"caption"}
                     sx={{
                       textOverflow: "ellipsis",
                       overflow: "hidden",
                       whiteSpace: "nowrap",
                       fontWeight: fontWeight,
                     }}>{colorPalette.name}
         </Typography>
         {isFavorite ? <Favorite sx={{ fontSize: "8px", ml: 1, mt: 1, color: "red" }}/> : null}
       </Stack>}
    </Box>
  )
}
