import { Box, Button, Card, Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { PLAN_TAB_BLURRED_BACKDROP_Z_INDEX } from "../constants";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ProgressOverlay from "../loadingIndicator/progressOverlay";

export interface FullScreenDialogWallWithBlurredBackgroundProps
{
  handleCloseXClicked: () => void;
  handlePrimaryCTAClicked: () => void;
  primaryCTAText: string;
  header: string;
  body: ReactNode;
  loading: boolean;
}

export function FullScreenDialogWallWithBlurredBackground( props: FullScreenDialogWallWithBlurredBackgroundProps )
{
  return (<Box sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    background: "rgba(0,0,0,.3)",
    backdropFilter: "blur(10px)",
    zIndex: PLAN_TAB_BLURRED_BACKDROP_Z_INDEX,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <Card sx={{ width: "80%", margin: "auto", top: "50%" }}>

      {props.loading && <Stack justifyContent="center" alignItems="center" width="100%" minHeight="200px"><ProgressOverlay hideBackDrop={true} wheelColor="primary.main" message="Loading plan..."/></Stack>}
      {
        !props.loading &&
        <Stack position="relative" alignItems="center" sx={{ textAlign: "left", py: 11, px: 15,pt: 19 }}>
          <Stack  position="absolute" direction="row" sx={{right: 7, top: 7}}>
            <IconButton onClick={props.handleCloseXClicked}>
              <ClearIcon/>
            </IconButton>
          </Stack>
          <Typography variant="h6" sx={{ textAlign: "center",  textWrap: "balance", width: "80%", fontSize: "20px", lineHeight: "23px", mb: 10 }}>{props.header}</Typography>
          {props.body}
          <Button variant="contained" onClick={props.handlePrimaryCTAClicked}>{props.primaryCTAText}</Button>
        </Stack>
      }
    </Card>
  </Box>)
}

