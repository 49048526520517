import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setAudioPlayerPlaying, setAudioPlayerTrack } from "../ui/uiSlice";
import { VolumeOff } from "@mui/icons-material";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";

interface NoMusicItemProps
{
  postIdea: PostIdeaDataAPI;
  selected: boolean;
  handleTrackSelected: () => void;
}

export function NoMusicItem( props: NoMusicItemProps )
{
  const dispatch = useDispatch();

  function handleOnClick()
  {
    stopPlayingMusic();
    props.handleTrackSelected();
  }

  const stopPlayingMusic = () =>
  {
    dispatch( setAudioPlayerTrack( undefined ) );
    dispatch( setAudioPlayerPlaying( false ) );
  };

  const border = props.selected ? "2px solid #3f51b5" : "2px solid transparent";
  return (
    <Box sx={{ display: "flex", m: 5, border, borderRadius: "5px", alignItems: "center" }} onClick={handleOnClick}>
      <VolumeOff sx={{ height: "56px", width: "56px", borderRadius: "2px", color: "primary.main" }}/>
      <Typography variant={"h6"} sx={{ ml: 5, }}>No music</Typography>
    </Box>
  );
}
