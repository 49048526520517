import { getToApi, postJsonToApi, postToApi } from "./requestManager";
import { FontAPI, FontUsageAPI } from "./font.services";
import { store } from "../app/store";
import { currentUserBusinessId } from "../features/business/businessSlice";
import { concat, find } from "lodash";
import { isFontSetOwnedByBusiness } from "../features/editing/fonts/fontSetHelper";

export const fontSetServices = {
  getFontSets,
  favoriteFontSet,
  unfavoriteFontSet,
  updateFontSetFonts,
  addNewFontSetForBusiness,
}

const FONT_SETS_BASE_PATH = "font_sets";
const FONT_SETS_LIST_ENDPOINT = "list"
const FONT_SETS_UPDATE_ENDPOINT = "update"
const FONT_SETS_FAVORITE_ENDPOINT = "favorite"
const FONT_SETS_ADD_NEW_ENDPOINT = "add_new"
const FONT_SETS_COPY_ENDPOINT = "copy_to_business"
const FONT_SETS_UNFAVORITE_ENDPOINT = "unfavorite"

export interface FontSetAPI
{
  name: string,
  slug: string,
  image_url: string,
  fonts: FontAPI[],
  owner_type: FontSetOwnerTypeAPI,
  status: FontSetStatusAPI,
}

export enum FontSetOwnerTypeAPI
{
  Business = "Business",
}

export enum FontSetStatusAPI
{
  Live = "live",
  Retired = "retired",
  Development = "development",
}

interface FontSetsAPI
{
  business_font_sets: FontSetAPI[],
  font_sets: FontSetAPI[],
}

interface FontSetsAddNewAPI
{
  font_set: FontSetAPI,
}

interface FontSetsFavoriteAPI
{
  font_set: FontSetAPI,
}

interface FontSetsUpdateAPI
{
  font_set: FontSetAPI,
}

interface FontSetsCopyToBusinessAPI
{
  font_set: FontSetAPI,
}

async function getFontSets( postIdeaId?: string)
{
  try
  {
    const state = store.getState();
    const endpointUrl = buildUrl( [FONT_SETS_LIST_ENDPOINT] );
    return await getToApi<FontSetsAPI>( endpointUrl, { business_id: currentUserBusinessId( state ), post_idea_id: postIdeaId } );
  }
  catch (error)
  {
    return Promise.reject( "Could not fetch font sets" );
  }
}

async function addNewFontSetForBusiness()
{
  try
  {
    const state = store.getState();
    const endPoint = buildUrl( [FONT_SETS_ADD_NEW_ENDPOINT] );
    return await postToApi<FontSetsAddNewAPI>( endPoint, { business_id: currentUserBusinessId( state ) } );
  }
  catch (error)
  {
    return Promise.reject( "Could not add new font set" );
  }
}

async function favoriteFontSet( fontSet: FontSetAPI )
{
  try
  {
    let fontSetToEdit = fontSet;
    if ( !isFontSetOwnedByBusiness( fontSet ) )
    {
      fontSetToEdit = await copyFontSetToBusiness( fontSet, FontSetStatusAPI.Live );
    }

    const state = store.getState();
    const endPoint = buildMemberUrl( fontSetToEdit.slug, FONT_SETS_FAVORITE_ENDPOINT );
    return await postToApi<FontSetsFavoriteAPI>( endPoint, { business_id: currentUserBusinessId( state ), ...fontSetToEdit } );
  }
  catch (error)
  {
    return Promise.reject( "Could not favorite font set" );
  }
}

async function unfavoriteFontSet( fontSet: FontSetAPI )
{
  try
  {
    const state = store.getState();
    const endPoint = buildMemberUrl( fontSet.slug, FONT_SETS_UNFAVORITE_ENDPOINT );
    return await postToApi<FontSetsFavoriteAPI>( endPoint, { business_id: currentUserBusinessId( state ), ...fontSet } );
  }
  catch (error)
  {
    return Promise.reject( "Could not unfavorite font set" );
  }
}

// TODO: remove this linting exception when we actually use this function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function updateFontSetFonts( fontSet: FontSetAPI )
{
  try
  {
    let fontSetToEdit = fontSet;
    if ( !isFontSetOwnedByBusiness( fontSet ) )
    {
      fontSetToEdit = await copyFontSetToBusiness( fontSet, FontSetStatusAPI.Retired );
    }

    const endPoint = buildMemberUrl( fontSetToEdit.slug, FONT_SETS_UPDATE_ENDPOINT );
    const state = store.getState();
    const primaryFont = find( fontSet.fonts, ( font ) => font.usage === FontUsageAPI.Primary );
    const secondaryFont = find( fontSet.fonts, ( font ) => font.usage === FontUsageAPI.Secondary );
    const jsonBody = {
      primary_font_slug: primaryFont?.slug,
      secondary_font_slug: secondaryFont?.slug,
    }
    return await postJsonToApi<FontSetsUpdateAPI>( endPoint, { business_id: currentUserBusinessId( state ) }, jsonBody );
  }
  catch (error)
  {
    return Promise.reject( "Could not update font set" );
  }
}

async function copyFontSetToBusiness( fontSet: FontSetAPI, status?: FontSetStatusAPI )
{
  const state = store.getState();
  const endPoint = buildMemberUrl( fontSet.slug, FONT_SETS_COPY_ENDPOINT );

  const jsonBody = { ...fontSet, status };
  const apiResponse = await postJsonToApi<FontSetsCopyToBusinessAPI>( endPoint, { business_id: currentUserBusinessId( state ) },
    jsonBody );
  return apiResponse.font_set;
}

function buildUrl( pathPieces: string[] )
{
  return concat( [FONT_SETS_BASE_PATH], pathPieces ).join( "/" );
}

function buildMemberUrl( slug, path )
{
  return buildUrl( [slug, path] );
}
