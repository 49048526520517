import * as React from "react";
import "./externalMediaCell.scss"
import { CircularProgress } from "@mui/material";
import { ExternalMediaData } from "./stockMediaSlice";

export interface ExternalMediaCellProps
{
  externalMediaData: ExternalMediaData;
  onExternalMediaClicked: ( data: ExternalMediaData ) => void;
  externalMediaUploadStatus: { [id: string]: boolean };
}

export class ExternalMediaCell extends React.PureComponent<ExternalMediaCellProps>
{
  private playPromise: Promise<any> | null | undefined;
  private pausePromise: Promise<any> | null | undefined;

  public render()
  {
    const { thumbnail_url } = this.props.externalMediaData;
    const shouldShowProgress = this.props.externalMediaUploadStatus
                               && this.props.externalMediaUploadStatus[this.props.externalMediaData.external_media_id];
    return (
      <div className="externalMediaCellContainer" onContextMenu={this.handleOnContextMenu} onClick={this.onExternalMediaClicked}>
        {shouldShowProgress && this.createProgressSpinner()}
        <img draggable={false}
             src={thumbnail_url}
             alt={"stock"}/>
      </div>
    );
  }

  private createProgressSpinner = () =>
  {
    return (
      <>
        <CircularProgress className="cellSpinner" id="spinner"/>
        <div className="overlay"/>
      </>
    );
  }

  private handleOnContextMenu = ( e: React.MouseEvent<HTMLDivElement> ) => e.preventDefault();

  private onExternalMediaClicked = ( e: React.MouseEvent<HTMLElement> ) =>
  {
    return this.props.onExternalMediaClicked( this.props.externalMediaData );
  }

  private onMouseEnter = () =>
  {
    const { videoRef } = this.refs;
    if ( videoRef )
    {
      const htmlVideo = videoRef as HTMLVideoElement;
      htmlVideo.currentTime = 0;
      if ( this.pausePromise )
      {
        this.playPromise = this.pausePromise.then( () => htmlVideo && htmlVideo.play() );
        this.pausePromise = null;
      }
      else
      {
        this.playPromise = htmlVideo.play();
      }
    }
  }

  private onMouseLeave = () =>
  {
    if ( this.refs.videoRef )
    {
      this.stopVideo();
    }
  }

  private stopVideo = () =>
  {
    const { videoRef } = this.refs;
    const htmlVideo = videoRef as HTMLVideoElement;
    if ( htmlVideo )
    {
      htmlVideo.currentTime = 0;
      if ( this.playPromise )
      {
        this.pausePromise = this.playPromise.then( () => htmlVideo && htmlVideo.pause() );
      }
      this.playPromise = null;
    }
  }

}
