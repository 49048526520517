import { Box, SxProps } from "@mui/material";
import Logo from "../../assets/alkai-wordmark-logo-gradient.svg";

export interface AlkaiWordmarkLogoGradientProps
{
  sx?: SxProps;
}

export function AlkaiWordmarkLogoGradient( props: AlkaiWordmarkLogoGradientProps )
{
  return (<Box component="img" alt="Alkai" width="75%" src={Logo} sx={props.sx}/>);
}
