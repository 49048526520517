import React from 'react'
import { ImageListItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import "./mediaList.scss";
import { merge } from "lodash";
import { SxProps } from "@mui/system";
import { UrlUtils } from "../utils/urlUtils";

export interface MediaItemProps
{
  id?: string;
  url: string;
  isVideo?: boolean;
  showControls?: boolean;
  removable?: boolean;
  handleMediaClicked?( url: string ): void;
  handleRemoveRequested?( id: string ): void;
  additionalStyle?: React.CSSProperties;
  aspectRatio?: string;
  sxProps?: SxProps;
  className?: string;
  endItem?: React.ReactNode;
}

export function MediaItem( props: MediaItemProps )
{
  const url = props.url;

  function handleMediaClicked( e: React.MouseEvent<HTMLLIElement> )
  {
    e.stopPropagation();
    if ( props.handleMediaClicked )
    {
      props.handleMediaClicked( url );
    }
  }

  function handleRemoveButtonClicked()
  {
    if ( props.handleRemoveRequested && props.id )
    {
      props.handleRemoveRequested( props.id );
    }
  }

  let isVideo = !!props.isVideo || new UrlUtils().isVideoUrl( props.url );
  const removeButtonStyles = {
    background: "rgba(0, 0, 0, 0.1)",
    height: 16,
    width: 16,
    alignSelf: "end"
  };

  return (
    <ImageListItem style={merge( { height: "100%", width: "auto" }, props.additionalStyle )}
                   sx={props.sxProps}
                   className={props.className}
                   classes={{ img: "mediaListItem" }}
                   onClick={handleMediaClicked} key={url}>
      {props.removable && <IconButton aria-label="remove" sx={removeButtonStyles} onClick={handleRemoveButtonClicked}><ClearIcon
        sx={{ width: 14, height: 14 }}/></IconButton>}
      {!isVideo && <img
        alt={"media"}
        src={url}
        draggable="false"
      />}
      {isVideo && <video
        className={"mediaListItem mediaListItemVideo"}
        playsInline
        controls={props.showControls}
        draggable="false"
        disableRemotePlayback
        muted={true}>
        <source src={url + "#t=0.1"} type="video/mp4"/>
      </video>}
      {props.endItem}
    </ImageListItem>
  );
}
