import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogTitle, Link, Typography } from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { ALKAI_FAQ_URL } from "../constants";

export interface ConfirmDialogProps
{
  title: string;
  message?: string;
  dialogContent?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  open: boolean;
  showNeedHelpLink?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export function ConfirmDialog( props: ConfirmDialogProps )
{

  function getConfirmText()
  {
    return props.confirmText || "Yes";
  }

  function getCancelText()
  {
    return props.cancelText || "No";
  }

  return (
    <>
      {props.open && <Dialog
        sx={{
          '& .MuiDialog-paper': { width: '90%', maxHeight: '100vh' },
          textAlign: "center",
        }}
        open={props.open}
      >
        {props.onClose && <IconButton
          aria-label="close"
          size="small"
          onClick={props.onClose}
          sx={{ flex: "0 0 auto", position: "absolute", right: 8, top: 8, color: "black" }}>
          <CloseIcon fontSize="inherit"/>
        </IconButton>}
        <DialogTitle color={"black"}>{props.title}</DialogTitle>
        {!!props.dialogContent && props.dialogContent}
        {!!props.message && !props.dialogContent && <Typography sx={{ px: "24px", pb: "16px" }}>{props.message}</Typography>}
        <DialogActions sx={{ justifyContent: "center", px: "24px", pb: "16px" }}>
          {props.onCancel && <Button autoFocus variant="outlined" onClick={props.onCancel}>
            {getCancelText()}
          </Button>}
          <Button
            variant="contained"
            onClick={props.onConfirm}>{getConfirmText()}</Button>
        </DialogActions>
        {!!props.showNeedHelpLink && <Typography sx={{ pb: 10 }} variant="body2">
          <Link href={ALKAI_FAQ_URL} target={"_blank"}>Need help?</Link>
        </Typography>}
      </Dialog>}
    </>
  );

}