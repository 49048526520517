import { RecordingStatusAPI } from "../../services/postIdeaServices";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import {
  HOLIDAY_PRESET_NAME,
  MESSAGE_TYPE_MULTIPLE_POST_IDEAS,
  OUTPUT_FORMAT_PROPERTIES_BY_SLUG,
  OUTPUT_FORMAT_SLUG_PORTRAIT_STATIC,
  OUTPUT_FORMAT_SLUG_SQUARE_STATIC,
  OutputFormatSlug,
  USER_FEEDBACK_STATUS_CANCELED,
  USER_FEEDBACK_STATUS_SUBMITTED
} from "../constants";
import { getPostForPostIdea } from "./postsSlice";
import { store } from "../../app/store";
import { findKey, map, startsWith } from "lodash";
import { addPostIdea } from "./postIdeaSlice";

export function addOrUpdatePostIdeaFromMessage( postIdeaMessage )
{
  if ( postIdeaMessage.type === MESSAGE_TYPE_MULTIPLE_POST_IDEAS )
  {
    map( postIdeaMessage.data, ( postIdea: PostIdeaDataAPI ) => store.dispatch( addPostIdea( postIdea ) ) );
  }
  else
  {
    store.dispatch( addPostIdea( postIdeaMessage.data as PostIdeaDataAPI ) )
  }
}

export function hasCompletedPostIdea( postIdea: PostIdeaDataAPI )
{
  return (!!postIdea.downloaded_at || !!postIdea.first_completed_at);
}

export function needsRecording( postIdea: PostIdeaDataAPI )
{
  const hasNoRecording = !postIdea.recording
  const recordingIsDirty = !!postIdea.recording && postIdea.recording.recording_status === RecordingStatusAPI.DIRTY;

  return hasNoRecording || recordingIsDirty;
}

export const getColorPaletteSlug = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.configuration.config_bundle.color_palette_slug;
}

export const getFontSetSlug = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.configuration.config_bundle.font_set_slug;
}

export const getLayoutPresetName = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.configuration.config_bundle.preset_name;
}

export const getHolidayLayoutSlug = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.configuration.config_bundle.holiday?.holiday_layout_slug;
}

export const isHolidayPostIdea = ( postIdea: PostIdeaDataAPI ) =>
{
  return startsWith( getLayoutPresetName( postIdea ), HOLIDAY_PRESET_NAME );
}

export const hasUserReactedToPostIdeaFeedback = ( postIdea: PostIdeaDataAPI ) =>
{
  const feedback = getPostIdeaUserFeedback( postIdea );
  return !!feedback &&
         (feedback.status === USER_FEEDBACK_STATUS_SUBMITTED || feedback.status === USER_FEEDBACK_STATUS_CANCELED);
}

export const getPostIdeaUserFeedback = ( postIdea: PostIdeaDataAPI ) =>
{
  return postIdea.user_feedback;
}

export function getLabelForPreparingOutput( postIdea: PostIdeaDataAPI )
{
  const isStatic = isStaticPostIdea( postIdea );
  return isStatic ? "Preparing..." : "Recording...";
}

export function isStaticPostIdea( postIdea: PostIdeaDataAPI )
{
  return postIdea.output_format_slug === OUTPUT_FORMAT_SLUG_SQUARE_STATIC || postIdea.output_format_slug === OUTPUT_FORMAT_SLUG_PORTRAIT_STATIC;
}

export function getOutputFormatDisplayNameFromSlug( slug: OutputFormatSlug | undefined )
{
  if ( !!slug && !!OUTPUT_FORMAT_PROPERTIES_BY_SLUG[slug] )
  {
    return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[slug].displayName;
  }
  return "";
}

export function getAspectRatioFromOutputFormat(slug: string)
{
  return OUTPUT_FORMAT_PROPERTIES_BY_SLUG[slug].aspectRatio;
}

export function hasBrandCardInPostJson( postIdea: PostIdeaDataAPI )
{
  const post = getPostForPostIdea( store.getState(), postIdea.id );
  if ( !!post )
  {
    const globalSettingsJson = JSON.parse( post.global_settings_data );
    const slideStore = globalSettingsJson.slideStore;
    const hasBrandSlide = !!findKey( slideStore, function ( slide )
    {
      return slide.slide_config_type === "brand_slide";
    } );
    return hasBrandSlide;
  }
  return false;
}

export function hasUseBrandInBrandSlideInPostJson( postIdea: PostIdeaDataAPI )
{
  const configuration = postIdea.configuration;
  const configBundle = configuration.config_bundle;
  const brandStyle = configBundle.brand_style;
  return !!brandStyle && brandStyle.use_brand_in_brand_slide;
}

export function getFontSetTrackingData( postIdea: PostIdeaDataAPI )
{
  return postIdea.tracking_data?.font_set;
}
