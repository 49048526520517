import { Helmet } from "react-helmet";
import { ROUTE_SEO, ROUTES } from "../constants";
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { StyleQuizContinuumQuestion, StyleQuizContinuumQuestionProps } from "./styleQuizContinuumQuestion";
import StyleQuiz1 from "../../assets/styleQuiz/1.png";
import StyleQuiz2 from "../../assets/styleQuiz/2.png";
import StyleQuiz3 from "../../assets/styleQuiz/3.png";
import StyleQuiz4 from "../../assets/styleQuiz/4.png";
import StyleQuiz5 from "../../assets/styleQuiz/5.png";
import StyleQuiz6 from "../../assets/styleQuiz/6.png";
import StyleQuiz7 from "../../assets/styleQuiz/7.png";
import { BusinessMediaStyleAPI, businessServices, MediaStylesAPI } from "../../services/business.services";
import { StyleQuizSuccessDialogFullScreen } from "./styleQuizSuccessDialog";
import { isUndefined, size } from "lodash";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { eventTracker } from "../../helpers/eventTracker";

export function StyleQuiz()
{
  const [questionIndex, setQuestionIndex] = useState( -1 );
  const [quizAnswers, setQuizAnswers] = useState( {} )
  const [mediaStylesAfterQuizSubmit, setMediaStylesAfterQuizSubmit] = useState<BusinessMediaStyleAPI[]>( [] )
  const response = useRef<{}>( {} );
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [hasMediaStyles, setHasMediaStyles] = useState<boolean>( false );
  const [loading, setLoading] = useState<boolean>( false );

  useEffect( () =>
  {
    setLoading( true );
    businessServices.getMediaStyles().then( ( data: MediaStylesAPI ) =>
    {
      const mediaStyles = data.media_styles;
      const hasMediaStylesOnFirstEnter = !!mediaStyles && mediaStyles.length > 0;
      setHasMediaStyles( hasMediaStylesOnFirstEnter );

      eventTracker.logMediaStyleQuizStarted( hasMediaStylesOnFirstEnter );
    } ).finally( () =>
    {
      setLoading( false );
    } );
  }, [] );

  const styleQuizQuestions: StyleQuizContinuumQuestionProps[] = [
    {
      id: "style",
      pageTitle: "Style",
      questionTitle: "Preferred Level of Realism",
      imageUrl: StyleQuiz1,
      leftChoice: { title: "Photo-realistic" },
      rightChoice: { title: "Very Stylized" },
      onQuestionAnswered: handleQuestionResponse,
    },
    {
      id: "detail",
      pageTitle: "Detail",
      questionTitle: "Preferred Level of Detail",
      imageUrl: StyleQuiz2,
      leftChoice: { title: "Minimalist" },
      rightChoice: { title: "Highly Detailed" },
      onQuestionAnswered: handleQuestionResponse,
    },
    {
      id: "color",
      pageTitle: "Color",
      questionTitle: "Preferred Number of Colors and Vividness",
      imageUrl: StyleQuiz3,
      leftChoice: { title: "Mono-chrome" },
      rightChoice: { title: "Colorful" },
      onQuestionAnswered: handleQuestionResponse,
    },
    {
      id: "era",
      pageTitle: "Time Period",
      questionTitle: "Preferred Artistic Era & Time Period",
      imageUrl: StyleQuiz4,
      leftChoice: { title: "Past" },
      rightChoice: { title: "Future" },
      onQuestionAnswered: handleQuestionResponse,
    },
    {
      id: "depth",
      pageTitle: "Depth",
      questionTitle: "Preferred Level of depth",
      imageUrl: StyleQuiz5,
      leftChoice: { title: "Flat - 2D" },
      rightChoice: { title: "Deep - 3D" },
      onQuestionAnswered: handleQuestionResponse,
    },
    {
      id: "technique",
      pageTitle: "Technique",
      questionTitle: "Preferred Artistic Medium",
      imageUrl: StyleQuiz6,
      leftChoice: { title: "Handmade Art" },
      rightChoice: { title: "Digital Design" },
      onQuestionAnswered: handleQuestionResponse,
    },
    {
      id: "mood",
      pageTitle: "Mood",
      questionTitle: "Preferred Tone",
      imageUrl: StyleQuiz7,
      leftChoice: { title: "Serious" },
      rightChoice: { title: "Playful" },
      onQuestionAnswered: handleQuestionResponse,
    }
  ];

  const shouldShowIntro = questionIndex === -1;
  const currentQuestion = styleQuizQuestions[questionIndex];

  function disablePreviousButton()
  {
    return questionIndex === 0;
  }

  function previousPage()
  {
    if ( questionIndex > 0 )
    {
      setQuestionIndex( questionIndex - 1 )
    }
  }

  function disableNextButton()
  {
    if ( isUndefined( quizAnswers[currentQuestion?.id]?.answer ) )
    {
      return true;
    }
    return questionIndex === styleQuizQuestions.length - 1
  }

  function nextPage()
  {
    setQuestionIndex( questionIndex + 1 )
  }

  function handleQuestionResponse( id: string, answer: number )
  {
    let copyOfQuizAnswers = { ...quizAnswers };
    copyOfQuizAnswers[id] = { answer }
    setQuizAnswers( copyOfQuizAnswers );
    response.current[id] = answer;
  }

  function allQuestionsAnswered()
  {
    return Object.keys( response.current ).length === styleQuizQuestions.length;
  }

  async function submitStyleQuiz()
  {
    const submitStyleQuizResponse = await businessServices.submitStyleQuizResponse( response.current );
    if ( !!submitStyleQuizResponse && submitStyleQuizResponse.media_styles )
    {
      businessServices.updateBusinessInState( submitStyleQuizResponse.business );
      setMediaStylesAfterQuizSubmit( submitStyleQuizResponse.media_styles );
    }
  }

  function handleStyleQuizSuccessClose()
  {
    setMediaStylesAfterQuizSubmit( [] );
  }

  function getNextButtonTooltip()
  {
    if ( allQuestionsAnswered() )
    {
      return "Click submit to see the results";
    }
    return disableNextButton() ? "Please move the slider to make a selection" : "";
  }

  function handleBackClicked()
  {
    navigateWithSearchParams( ROUTES.BRANDING );
  }

  function onStartSurveyClicked()
  {
    setQuestionIndex( 0 )
  }

  const hasMediaStylesAfterSubmit = !!mediaStylesAfterQuizSubmit && size( mediaStylesAfterQuizSubmit ) > 0;
  return (<>
    <Helmet>
      <title>{ROUTE_SEO.STYLE_QUIZ.title}</title>
      <meta name="description" content={ROUTE_SEO.STYLE_QUIZ.description}/>
    </Helmet>
    {!loading &&
     <Stack sx={{ px: 5, pt: 5 }}>
       <Stack direction="row" justifyContent="flexStart">
         <IconButton onClick={handleBackClicked} sx={{ padding: "unset" }}>
           <ArrowBackIcon/>
         </IconButton>
       </Stack>

       {shouldShowIntro && <Stack spacing={10}>
         <Typography variant="h6">Image Style Survey</Typography>
         <Stack textAlign="left" spacing={8}>
           <Typography variant="h5">Introduction</Typography>
           {hasMediaStyles && <Typography variant="subtitle1">Retaking this short quiz will update your image style preferences, enabling Alkai to
                                                              create and recommend images that reinforce your brand's unique style.</Typography>}
           {!hasMediaStyles && <Typography variant="subtitle1">Taking this short quiz will help Alkai create and recommend images that reinforce your
                                                               brand's unique
                                                               style.</Typography>}
           <Typography variant="subtitle1">For each question choose your preference on a scale between two different visual styles. Pick wherever on
                                           the
                                           slider feels most correct for you brand.</Typography>
           <Typography variant="subtitle1">Have fun!</Typography>
           <Button variant="contained" onClick={onStartSurveyClicked}>Next</Button>
         </Stack>
       </Stack>}
       {!shouldShowIntro &&

        <Stack spacing={10}>
          <Typography variant="h6">Image Style Survey</Typography>
          {currentQuestion && <StyleQuizContinuumQuestion {...currentQuestion}
                                                          questionIndex={questionIndex}
                                                          onQuestionAnswered={handleQuestionResponse}
                                                          value={response.current[currentQuestion.id]}
          />}
          <Stack direction="row" textAlign="center" spacing={4} width="100%" justifyContent="center">
            <Button variant="contained" onClick={previousPage} disabled={disablePreviousButton()}>Prev</Button>

            <Tooltip title={getNextButtonTooltip()} enterTouchDelay={1000} enterDelay={1000} enterNextDelay={1000}>
              <Box component="span">
                <Button variant="contained" onClick={nextPage} disabled={disableNextButton()}>Next</Button>
              </Box>
            </Tooltip>
          </Stack>
          <Tooltip title={!allQuestionsAnswered() ? "You must answer all questions before submitting" : ""} enterTouchDelay={1000} enterDelay={1000}
                   enterNextDelay={1000}>
            <Box component="span">
              <Button variant="contained" onClick={submitStyleQuiz} disabled={!allQuestionsAnswered()}>Submit</Button>
            </Box>
          </Tooltip>
          {mediaStylesAfterQuizSubmit && <StyleQuizSuccessDialogFullScreen manageOpenStateExternally={true}
                                                                           externalStateOpen={(hasMediaStylesAfterSubmit)}
                                                                           hideTopBar={true}
                                                                           handleClose={handleStyleQuizSuccessClose}
                                                                           mediaStyles={mediaStylesAfterQuizSubmit}/>}
        </Stack>}
     </Stack>}
  </>);
}
