import { includes } from "lodash";
import { SOURCES_FROM_ALKAI_CRM } from "../constants";

export function isCrmSource( source: string | null )
{
  return includes( SOURCES_FROM_ALKAI_CRM, source );
}

export function isEmailCrmSource( source: string | null )
{
  const emailSources = [SOURCES_FROM_ALKAI_CRM.INTRO_EMAIL_CRM_SOURCE, SOURCES_FROM_ALKAI_CRM.DAY_ONE_NUDGE_EMAIL_CRM_SOURCE];
  return includes( emailSources, source );
}

