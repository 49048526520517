import Streak from "../../assets/streak-icon.svg"
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { WeeklyPostingMeter } from "./weeklyPostingMeter";
import { CurrentStreakAPI } from "../../services/business.services";

export interface StreakHeaderProps
{
  streak: CurrentStreakAPI;
}

export function StreakHeader( props: StreakHeaderProps )
{
  return <Box>
    <Stack direction="column" spacing={5} sx={{ mb: 5 }}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={8} sx={{ px: 10 }}>
        <img src={Streak} alt="Posting Streak"/>
        <Typography variant="h5" sx={{ fontWeight: 500, pt: "7px", textAlign: "left" }}>{props.streak.posting_streak_message}</Typography>
      </Stack>
      <WeeklyPostingMeter streak={props.streak}/>
    </Stack>
  </Box>
}