import { Stack, Typography } from "@mui/material";
import React from "react";
import { AlkaiWordmarkLogoGradient } from "./alkaiWordmarkLogoGradient";
import { apptimizeVariables } from "../apptimize/apptimizeVariables";

export function CheckoutSignInHeader()
{
  const shouldShowSocialProofLoginVariant = apptimizeVariables.shouldShowSocialProofLogin();

  const loginText = () =>
  {
    return standardLogin();
  }

  const standardLogin = () =>
  {
    return <Stack direction="column" textAlign="center" spacing={4}>
      <Typography variant="h4" textAlign="center">Your Own AI Social Media Assistant</Typography>
      {shouldShowSocialProofLoginVariant ? socialProofSubtitle() : standardSubtitle()}
    </Stack>
  }

  const standardSubtitle = () =>
  {
    return <Typography variant="subtitle1">
      Alkai creates custom videos for you,<br/>
      helping you regularly post engaging content and grow your business.
    </Typography>
  }

  const socialProofSubtitle = () =>
  {
    return <Typography variant="subtitle1">
      Alkai creates custom posts for you <br/>
      helping you regularly share engaging content and grow your business.
    </Typography>
  }

  return (
    <Stack direction="column" alignItems="center" spacing={12}>
      <AlkaiWordmarkLogoGradient/>
      {loginText()}
    </Stack>
  );
}
