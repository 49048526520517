import { FullPageMobileHeader } from "../layout/fullPageMobileHeader";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { FullPageMobileContent } from "../layout/fullPageMobileContent";
import { FullPostPreview } from "../postIdea/FullPostPreview";
import { FullPageMobileFooter } from "../layout/fullPageMobileFooter";
import * as React from "react";
import { useContext } from "react";
import { getPostReadyCTAText } from "./downloadText";
import { ShareNowTabContentSources } from "./directShare";
import { ShareDrawer } from "../planner/shareDrawer";
import { PostIdeaContext } from "../context/postIdeaContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RecordingProgressBar } from "./recordingProgressBar";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { shouldShowRecordingProgress } from "../loadingIndicator/loadingSlice";
import { ShareBatchAPI } from "../../services/postIdeaServices";
import { getLabelForPreparingOutput } from "../postIdea/postIdeaHelper";
import { getHasFinishedPosts } from "../user/userSlice";

export interface SharePostViewProps
{
  switchToEditView: () => void;
  initialScheduleForDate?: Date;
  shareBatch?: ShareBatchAPI;
}

export function SharePostView( props: SharePostViewProps )
{
  const hasUserFinishedPost = useSelector( ( state: RootState ) => getHasFinishedPosts( state ) );
  const shouldSkipShareChoice = !hasUserFinishedPost;
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const [showShareDrawer, setShowShareDrawer] = React.useState( true );
  const showRecordingProgress = useSelector( ( state: RootState ) => shouldShowRecordingProgress( state ) );

  function handleBackClick()
  {
    props.switchToEditView();
  }

  function handleCloseDrawerFromModal()
  {
    if ( shouldSkipShareChoice )
    {
      props.switchToEditView();
    }
    else
    {
      setShowShareDrawer( false );
    }
  }

  function openShareDrawer()
  {
    setShowShareDrawer( true );
  }

  function getViewTitle()
  {
    return getPostReadyCTAText();
  }

  return (
    <>
      <FullPageMobileHeader>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon/>
          </IconButton>
          <Typography variant="h6" component="div" alignSelf="center">{getViewTitle()}</Typography>
        </Stack>
      </FullPageMobileHeader>

      <FullPageMobileContent>
        <Box sx={{ px: 10, py: 5, display: "flex", height: "100%" }}>
          <FullPostPreview/>
        </Box>
      </FullPageMobileContent>

      <FullPageMobileFooter>
        <Paper elevation={3}>
          <Stack sx={{ px: 5, py: 5 }} spacing={2}>
            {showRecordingProgress && <RecordingProgressBar label={getLabelForPreparingOutput( postIdea )}/>}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={openShareDrawer}
              sx={{ borderRadius: "unset" }}>Share</Button>

            {showShareDrawer && <ShareDrawer postIdea={postIdea}
                                             sharedFrom={ShareNowTabContentSources.EDIT}
                                             showShareDrawer={showShareDrawer}
                                             closeDrawer={handleBackClick}
                                             previousShareBatch={props.shareBatch}
                                             onCloseFromBackdropClick={handleCloseDrawerFromModal}
                                             initialScheduleForDate={props.initialScheduleForDate}/>}
          </Stack>
        </Paper>
      </FullPageMobileFooter>
    </>
  );
}
