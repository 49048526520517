import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { ShareBatchAPI } from "../../services/postIdeaServices";
import * as React from "react";
import { useContext, useEffect } from "react";
import { FacebookPageSharingSettings } from "../sharing/facebookPageSharingSettings";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { SocialMediaAccountRow } from "../userSettings/SocialMediaAccountRow";
import { SocialAccountProfileIcon } from "../ui/socialAccountProfileIcon";
import { FACEBOOK_PAGE, SocialNetworkAccountType } from "../../services/business.services";
import { ShareDestinationToggle } from "./ShareDestinationToggle";
import { ConnectFacebookPagesButton } from "../buttons/connectFacebookPagesButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { InstagramSharingSettings } from "../sharing/instagramSharingSettings";
import { EDIT_POST_PAGE_SOURCE } from "../../helpers/trackingConstants";

export interface FacebookPageDirectShareRowProps
{
  postIdea: PostIdeaDataAPI;
  previousShareBatch?: ShareBatchAPI;
  showProgress?: ( title: string, accountType: SocialNetworkAccountType ) => void;
  hideProgress?: () => void;
  updateSharingSettings?: ( settings: FacebookPageSharingSettings ) => void;
}

export function FacebookPageDirectShareRow( props: FacebookPageDirectShareRowProps )
{
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );
  const socialNetworkAccounts = socialNetworkAccountsContext.socialNetworkAccounts;
  const connectedFacebookPage = socialNetworkAccountHelper.getConnectedFacebookPage( socialNetworkAccounts );

  const facebookPageName = connectedFacebookPage?.name;
  const facebookProfileImageUrl = connectedFacebookPage?.profile_image_url;
  const [facebookPageSelected, setFacebookPageSelected] = React.useState( false );
  const readyToShare = !!connectedFacebookPage && !socialNetworkAccountHelper.needsRefresh( connectedFacebookPage );

  useEffect( () =>
  {
    const sharingSettings: InstagramSharingSettings = {
      selectedToShare: facebookPageSelected,
      readyToShare: readyToShare,
    }

    if ( props.updateSharingSettings )
    {
      props.updateSharingSettings( sharingSettings );
    }
  }, [facebookPageSelected, readyToShare] )

  function facebookPageStatusChanged( event: React.ChangeEvent<HTMLInputElement>, checked: boolean )
  {
    setFacebookPageSelected( !facebookPageSelected );
  }

  function hideLoadingOverlay()
  {
    if ( !!props.hideProgress )
    {
      props.hideProgress();
    }
  }

  function getFacebookPageSwitch()
  {
    function handleFacebookPageConnected()
    {
      hideLoadingOverlay();
      setFacebookPageSelected( true );
    }

    function handleFacebookConnectionFailed()
    {
      hideLoadingOverlay();
    }

    function handleFacebookConnectionStarted()
    {
      if ( !!props.showProgress )
      {
        props.showProgress( "Connecting to Facebook...", FACEBOOK_PAGE );
      }
    }

    if ( !!connectedFacebookPage )
    {
      if ( socialNetworkAccountHelper.needsRefresh( connectedFacebookPage ) )
      {
        return <SocialMediaAccountRow socialNetworkAccount={connectedFacebookPage} source={EDIT_POST_PAGE_SOURCE}/>;
      }
      const accountIcon = <SocialAccountProfileIcon profileImageUrl={facebookProfileImageUrl} accountType={FACEBOOK_PAGE}/>;
      return <ShareDestinationToggle icon={accountIcon}
                                     label={facebookPageName}
                                     checked={facebookPageSelected}
                                     onChange={facebookPageStatusChanged}/>
    }
    return <ConnectFacebookPagesButton source={EDIT_POST_PAGE_SOURCE}
                                       handleConnectionSucceeded={handleFacebookPageConnected}
                                       handleConnectionFailed={handleFacebookConnectionFailed}
                                       handleConnectionStarted={handleFacebookConnectionStarted}/>;
  }

  return getFacebookPageSwitch();
}

