/*
Borrowed from https://dev.to/kirteshbansal/bouncing-dots-loader-in-react-4jng
*/

import React, { useEffect, useRef, useState } from "react";
import "./bouncingDotsLoader.scss";
import { Stack, Typography } from "@mui/material";
import { RotatingMessage } from "../assistantChat/rotatingMessage";

export interface BouncingDotsLoaderProps
{
  longWaitingDuration?: number;
  longWaitingMessages?: string[];
}

const DEFAULT_LONG_WAITING_DURATION = 4000;

export function BouncingDotsLoader( props: BouncingDotsLoaderProps )
{
  const [showLongWaitingMessage, setShowLongWaitingMessage] = useState( false );
  const longWaitingTimeout = useRef<any>( undefined );

  useEffect( () =>
  {
    if ( !!props.longWaitingMessages )
    {
      longWaitingTimeout.current = setTimeout( () =>
      {
        setShowLongWaitingMessage( true );

        return () =>
        {
          if ( !!longWaitingTimeout.current )
          {
            clearTimeout( longWaitingTimeout.current );
          }
        }
      }, props.longWaitingDuration || DEFAULT_LONG_WAITING_DURATION )
    }
  }, [] );

  function getLongWaitingMessage()
  {
    if ( !!props.longWaitingMessages && props.longWaitingMessages.length > 0 )
    {
      return <RotatingMessage messages={props.longWaitingMessages}/>
    }
    else
    {
      return <Typography variant="body2" color="secondary.contrastText" sx={{ mb: 8, fontSize: "16px" }}>Thinking - just a moment...</Typography>
    }
  }

  return (
    <Stack sx={{ width: "100%", textAlign: "center" }}>
      <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
      {showLongWaitingMessage && getLongWaitingMessage()}
    </Stack>
  );
}



