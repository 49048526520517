import { Box, CardMedia, Stack, Typography } from "@mui/material";
import React from "react";
import { START_VIDEO_TIME_TO_SHOW_FRAME_BEFORE_TAP } from "../constants";

interface SharedVideoAndCaptionProps
{
  videoUrl?: string;
  caption?: string;
  imageUrl?: string;
}

export function SharedVideoAndCaption( props: SharedVideoAndCaptionProps )
{
  const videoAndCaptionWidth = "80%";
  const urlForVideoElement = props.videoUrl + START_VIDEO_TIME_TO_SHOW_FRAME_BEFORE_TAP;

  return (<Stack>
    {(!!props.videoUrl || !!props.imageUrl) &&
     <Box sx={{ borderRadius: "5px", mb: 5 }}>
       {props.imageUrl && <CardMedia src={props.imageUrl} component={"img"}
                                     sx={{ m: "0 auto", width: videoAndCaptionWidth }}/>}
       {props.videoUrl && <CardMedia src={urlForVideoElement} component={"video"} controls={true}
                                     sx={{ m: "0 auto", width: videoAndCaptionWidth }}/>}
     </Box>
    }
    {!!props.caption && <Typography variant={"body1"}
                                    sx={{
                                      mx: "auto",
                                      my: 5,
                                      mb: 25,
                                      width: videoAndCaptionWidth,
                                      color: "text.primary",
                                      textAlign: "left"
                                    }}>{props.caption}</Typography>}
  </Stack>)
}
