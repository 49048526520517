import { browserUtils } from "../utils/browserUtils";
import { upperFirst } from "lodash";

export function getPostReadyCTAText()
{
  return browserUtils.isNativeShareSupported() ? "Share post" : "Download post";
}

export function getVideoReadyToastText()
{
  return `Your video is ready! ${upperFirst( browserUtils.getActionTextForMobileOrWeb() )} ${getPostReadyCTAText()} to continue.`
}
