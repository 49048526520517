import { TrackAPI } from "./musicSlice";
import { Box, CardMedia, Stack, Typography } from "@mui/material";
import { musicServices } from "../../services/music.services";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentMusicTrack, getCurrentMusicTrackPlaying, MusicTrack, setAudioPlayerPlaying, setAudioPlayerTrack } from "../ui/uiSlice";
import { RootState } from "../../app/store";
import { PlayArrow, Stop } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./beatSyncIcon.scss"
import { BeatSyncIconImage } from "./BeatSyncIconImage";

interface EpidemicMusicItemProps
{
  track: TrackAPI;
  handleTrackSelected: ( url: string, epidemic_id: string, expires: string, startTimeInSeconds: number ) => void;
  selected: boolean;
  current_layout_supports_beat_sync: boolean;
}

export function EpidemicMusicItem( props: EpidemicMusicItemProps )
{
  const selected = props.selected;
  const [trackUrl, setTrackUrl] = useState<string>();
  const [trackExpires, setTrackExpires] = useState<string>();
  const [trackStartTimeInSeconds, setTrackStartTimeInSeconds] = useState<number>();
  const dispatch = useDispatch();
  const currentAudioPlayerMusicTrack = useSelector( ( state: RootState ) => getCurrentMusicTrack( state ) );
  const currentAudioPlayerPlaying = useSelector( ( state: RootState ) => getCurrentMusicTrackPlaying( state ) );
  const doesTrackDownloadDataMatchAudioPlayer = currentAudioPlayerMusicTrack?.url === trackUrl;
  const hasTrackDownloadData = !!trackUrl;
  const shouldShowBeatSyncIcon = props.current_layout_supports_beat_sync && props.track.is_beat_sync_recommended
  const [loading, setLoading] = useState( false );

  useEffect( () =>
  {
    if ( selected )
    {
      if ( !hasTrackDownloadData )
      {
        musicServices.getEpidemicSoundTrack( props.track.epidemic_id ).then( ( track ) =>
        {
          setTrackUrl( track.url );
          setTrackExpires( track.expires );
          const startTimeInSeconds = track.start_time_in_seconds ? track.start_time_in_seconds : 0;
          setTrackStartTimeInSeconds( startTimeInSeconds )
        } )
      }
    }
  }, [selected] );

  function handleOnClick()
  {
    if ( !loading )
    {
      togglePlayingMusic();
    }
  }

  const togglePlayingMusic = () =>
  {
    if ( currentAudioPlayerMusicTrack && doesTrackDownloadDataMatchAudioPlayer )
    {
      dispatch( setAudioPlayerPlaying( !currentAudioPlayerPlaying ) );
    }
    else
    {
      setLoading( true );
      if ( hasTrackDownloadData && trackExpires )
      {
        const startTimeInSeconds = trackStartTimeInSeconds ? trackStartTimeInSeconds : 0;
        props.handleTrackSelected( trackUrl, props.track.epidemic_id, trackExpires, startTimeInSeconds );
        const musicTrack: MusicTrack = { url: trackUrl, startTimeInSeconds: startTimeInSeconds }
        playTrack( musicTrack );
        setLoading( false );
      }
      else
      {
        musicServices.getEpidemicSoundTrack( props.track.epidemic_id ).then( ( track ) =>
        {
          const startTimeInSeconds = track.start_time_in_seconds ? track.start_time_in_seconds : 0;
          props.handleTrackSelected( track.url, props.track.epidemic_id, track.expires, startTimeInSeconds );
          setTrackUrl( track.url );
          setTrackExpires( track.expires );
          setTrackStartTimeInSeconds( startTimeInSeconds )
          const musicTrack: MusicTrack = { url: track.url, startTimeInSeconds: startTimeInSeconds }
          playTrack( musicTrack );
        } ).finally(
          () =>
          {
            setLoading( false );
          }
        );
      }

    }
  };
  const playTrack = ( trackToPlay?: MusicTrack ) =>
  {
    dispatch( setAudioPlayerTrack( trackToPlay ) );
    dispatch( setAudioPlayerPlaying( true ) );
  };

  const border = selected ? "2px solid #3f51b5" : "2px solid transparent";
  return (
    <Box sx={{ display: "flex", m: 5, border, borderRadius: "5px", position: "relative" }} onClick={handleOnClick}>
      <CardMedia sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "56px", width: "56px", borderRadius: "2px" }}
                 image={props.track.image}>
        {selected && doesTrackDownloadDataMatchAudioPlayer && currentAudioPlayerPlaying && <Stop
          sx={{ height: "45px", width: "45px", color: "white" }}/>}
        {selected && (!doesTrackDownloadDataMatchAudioPlayer || (doesTrackDownloadDataMatchAudioPlayer && !currentAudioPlayerPlaying)) && <PlayArrow
          sx={{ height: "45px", width: "45px", color: "white" }}/>}
      </CardMedia>

      <Stack sx={{ ml: 5 }}>
        <Stack direction="row" sx={{ width: "250px" }} gap={4} alignItems={"center"}>
          <Typography sx={{ fontWeight: "bold" }} noWrap>{props.track.display_name}</Typography>
          {shouldShowBeatSyncIcon && <BeatSyncIconImage/>}
        </Stack>
        <Typography sx={{ width: "250px" }} noWrap>{props.track.artist_name} | {props.track.genre}</Typography>
      </Stack>

      {loading && <Box sx={{
        position: "absolute",
        background: "rgba(0,0,0,.15)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "5px",
        pointerEvents: "none"
      }}>
        <CircularProgress sx={{ position: "absolute", top: "calc(50% - 20px)", left: "calc(50% - 20px)" }}/>
      </Box>}
    </Box>
  );
}
