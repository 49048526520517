import React, { useCallback, useContext, useState } from "react";
import { updateChatPostIdeaMessage } from "../assistantChat/assistantChatSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { addPost, getPostForPostIdea } from "./postsSlice";
import { Box, Button } from "@mui/material";
import { isDevModeEnabled } from "../featureFlags/featureFlagsSlice";
import { postIdeaServices } from "../../services/postIdeaServices";
import { PostPreview } from "./postPreview";
import { DevModePostIdeaContent } from "./DevModePostIdeaContent";
import { NAVIGATE_NEXT, NAVIGATE_PREVIOUS, NavigateDirection, PREVIEW_NAV_ID_NEXT_VARIANT, PREVIEW_NAV_ID_PREVIOUS_VARIANT } from "../constants";
import { eventTracker } from "../../helpers/eventTracker";
import { browserUtils } from "../utils/browserUtils";
import { PostVariationPanel } from "./postVariationPanel";
import { PostIdeaContext } from "../context/postIdeaContext";
import { debounce, isEqual } from "lodash";
import { addOrUpdatePostIdeaFromMessage } from "./postIdeaHelper";
import { logToConsoleError } from "../utils/devLoggingHelper";

interface FullPostPreviewProps
{
  showVariantSwitching?: boolean,
}

export function FullPostPreview( props: FullPostPreviewProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, postIdea.id ), isEqual );
  const [showSafeArea, setShowSafeArea] = useState( false );
  const [showInstagramOverlay, setShowInstagramOverlay] = useState( false );
  const shouldShowDevMode = useSelector( ( state: RootState ) => isDevModeEnabled( state ) );
  const [devDrawerOpened, setDevDrawerOpened] = useState( false );
  const [showLoader, setShowLoader] = useState( false );
  const dispatch = useDispatch();
  // logToConsole( "getDifferencesBetweenObjects", "variantIndex", variantIndex, getDifferencesBetweenObjects( originalPost, variantPost ) );
  // if ( !!originalPost.global_settings_data && !!variantPost?.global_settings_data )
  // {
  //   logToConsole( "global settings diff", "variantIndex", variantIndex,
  //     getDifferencesBetweenObjects( JSON.parse( originalPost.global_settings_data ), JSON.parse( variantPost?.global_settings_data ) ) );
  // }

  function handleShowSafeAreaChange( event: React.MouseEvent<HTMLElement>,
                                     newValue: boolean )
  {
    setShowSafeArea( newValue );
  }

  function handleShowIgReelsUi( event: React.MouseEvent<HTMLElement>,
                                newValue: boolean )
  {
    setShowInstagramOverlay( newValue );
  }

  const requestPreviousVariant = () =>
  {
    requestNewVariant( NAVIGATE_PREVIOUS );
  }
  const debounceRequestPreviousVariant = useCallback( debounce( requestPreviousVariant, 300 ), [] )

  const handlePreviousVariantRequested = ( event: React.MouseEvent ) =>
  {
    debounceRequestPreviousVariant();
  }

  const requestNextVariant = () =>
  {
    requestNewVariant( NAVIGATE_NEXT );
  }
  const debounceRequestNextVariant = useCallback( debounce( requestNextVariant, 300 ), [] )

  const handleNextVariantRequested = ( event: React.MouseEvent ) =>
  {
    debounceRequestNextVariant();
  }

  const requestNewVariant = ( direction: NavigateDirection ) =>
  {
    setShowLoader( true );
    postIdeaServices.generateNewLayoutVariant( postIdea.id, direction ).then( ( response ) =>
    {
      eventTracker.logEditPostPresetSwitched( postIdea );
      dispatch( addPost( response.post_json ) );
      dispatch( updateChatPostIdeaMessage( response.post_idea_message ) );
      addOrUpdatePostIdeaFromMessage( response.post_idea_message );
    } ).catch( ( error ) =>
    {
      logToConsoleError( "generate variant error", error );
    } ).finally( () =>
    {
      setShowLoader( false );
    } );

  }

  const justifyContent = (browserUtils.isMobileBrowser() && props.showVariantSwitching) ? 'space-between' : 'center';
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0 auto", width: "100%", justifyContent: 'center', flexGrow: 1 }}>
      <Box sx={{ display: "flex", margin: "0 auto", justifyContent: justifyContent, gap: 1, flexGrow: 1 }}>
        {props.showVariantSwitching && <PostVariationPanel showLoader={showLoader}
                                                           variantDirection={PREVIEW_NAV_ID_PREVIOUS_VARIANT}
                                                           handleArrowClick={handlePreviousVariantRequested}
        />}

        {!!post && <PostPreview post={post}
                                iframeId="sharePreview"
                                className={"editor-preview"}
                                showSafeArea={showSafeArea}
                                caption={postIdea.caption}
                                showInstagramOverlay={showInstagramOverlay}/>

        }
        {props.showVariantSwitching && <PostVariationPanel showLoader={showLoader}
                                                           variantDirection={PREVIEW_NAV_ID_NEXT_VARIANT}
                                                           handleArrowClick={handleNextVariantRequested}/>}
      </Box>
      {
        shouldShowDevMode && <Box>
                            <Button onClick={() => setDevDrawerOpened( true )}
                                    sx={{
                                      position: 'absolute',
                                      top: '44px',
                                      left: '0px',
                                    }}>Open Dev Drawer</Button>
                            <DevModePostIdeaContent drawerOpened={devDrawerOpened}
                                                    closeDrawer={() => setDevDrawerOpened( false )}
                                                    postIdea={postIdea}
                                                    showSafeArea={showSafeArea}
                                                    showInstagramOverlay={showInstagramOverlay}
                                                    handleShowInstagramOverlay={handleShowIgReelsUi}
                                                    handleShowSafeAreaChange={handleShowSafeAreaChange}/>
                          </Box>
      }
    </Box>
  );
}
