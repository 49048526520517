import React from 'react'
import { Button, Drawer, Stack, Typography } from "@mui/material";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebookServices } from "../../services/facebook.services";
import { getFacebookAppId } from '../../constants';
import { ConfirmFacebookAccount } from '../confirmSocialNetworkAccount/confirmFacebookAccount';
import { businessServices, FACEBOOK_INSTAGRAM } from "../../services/business.services";
import { ConfirmInstagramAccount } from "../confirmSocialNetworkAccount/confirmInstagramAccount";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { eventTracker } from "../../helpers/eventTracker";
import InstagramIcon from "@mui/icons-material/Instagram";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { ShareDestinationToggle } from "../editing/ShareDestinationToggle";
import { logToConsole, logToConsoleError } from "../utils/devLoggingHelper";
import { SETTINGS_PAGE_SOURCE, SocialNetworkConnectionSourcePageType } from "../../helpers/trackingConstants";

interface ReactFacebookRenderProps
{
  isSdkLoaded?: boolean;
  isProcessing?: boolean;
  isDisabled?: boolean;
  onClick( event: React.MouseEvent ): void;
}

interface ConnectFacebookInstagramButtonProps
{
  source: SocialNetworkConnectionSourcePageType;
  handleConnectionSucceeded?(): void;
  handleConnectionFailed?(): void;
  handleConnectionStarted?(): void;
}

export function ConnectFacebookInstagramButton( props: ConnectFacebookInstagramButtonProps )
{
  const dispatch = useDispatch();
  const facebookPermissionsScope = facebookServices.getInstagramScope();
  const [drawerOpened, setDrawerOpened] = React.useState( false );
  const [profileUrl, setProfileUrl] = React.useState( "" );
  const [profileName, setProfileName] = React.useState( "" );
  const [profileAccessToken, setProfileAccessToken] = React.useState( "" );
  const [profileId, setProfileId] = React.useState( 0 );
  const [showLoader, setShowLoader] = React.useState( false );
  const [confirmingInstagram, setConfirmingInstagram] = React.useState( false );
  const socialNetworkAccountsContext = React.useContext( SocialNetworkAccountsContext );
  const fromSettings = props.source === SETTINGS_PAGE_SOURCE;

  const handleClick = ( event: React.MouseEvent ) =>
  {
    requestConnect();
  }

  const onSwitchAccounts = ( event: React.MouseEvent ) =>
  {
    logToConsole( "switch accounts facebook instagram" );
    window.FB.logout();
    handleCancelConnectionAttempt( { errorText: "Please log out of Facebook and Instagram in your browser before trying to connect again." } );
  }

  function instagramConnectionComplete()
  {
    const accountType = FACEBOOK_INSTAGRAM;
    const context = socialNetworkAccountsContext.socialNetworkAccounts;

    eventTracker.sendFirstSocialNetworkAccountConnectedIfNecessary( context, accountType, props.source );

    eventTracker.logInstagramConnected( props.source );
    if ( !!props.handleConnectionSucceeded )
    {
      props.handleConnectionSucceeded();
    }
  }

  function closedAsFailed()
  {
    setShowLoader( false );
    closeDrawer();
    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  const onConfirm = async ( event: React.MouseEvent ) =>
  {
    setShowLoader( true );
    try
    {
      const result = await businessServices.clientFacebookConnect( profileId, profileAccessToken );
      logToConsole( "result facebook connected", result );

      if ( result.has_instagram_accounts )
      {
        const socialNetworkAccountsResponseAPI = await businessServices.listSocialNetworkAccounts();
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
        setShowLoader( false );
        setConfirmingInstagram( true );
      }
      else
      {
        eventTracker.logInstagramConnectFailed( props.source, "The user does not have instagram account linked." );
        displayErrorAlert();
        closedAsFailed();
      }
    }
    catch (error)
    {
      eventTracker.logInstagramConnectFailed( props.source, error?.toString() );
      displayErrorAlert();
      logToConsoleError( "error instagram connected", error );
      closedAsFailed();
    }
  }

  const onFacebookConnectSuccess = ( facebookId: number,
                                     name: string,
                                     facebookAccessToken: string ) =>
  {
    const profileImageUrl = facebookServices.getFacebookProfileImageUrl( facebookId.toString() );
    setProfileUrl( profileImageUrl );
    setProfileName( name );
    setProfileAccessToken( facebookAccessToken );
    setProfileId( facebookId );
    openDrawer();
  }

  const displayErrorAlert = ( errorText?: string ) =>
  {
    let errorMessageForUser = "We had a problem connecting Instagram. Please try again or contact support if the problem persists.";
    if ( errorText )
    {
      errorMessageForUser = errorText;
    }
    dispatch( setAlertMessage( errorAlert( errorMessageForUser ) ) );
  }

  const onFacebookConnectFailure = ( failureData?: { response?: string, errorText?: string, permissionsDenied?: boolean } ) =>
  {
    const errorText = failureData?.errorText;
    eventTracker.logInstagramConnectFailed( props.source, errorText );
    displayErrorAlert( errorText );
    logToConsoleError( "instagram facebook connect failure", failureData );
    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  const handleFacebookResponse = async ( response ) =>
  {
    if ( response && response.id && response.accessToken )
    {
      const accessToken = response.accessToken;
      const facebookIsAccessible = await facebookServices.verifyUserHasInstagramBusinessPermissions( accessToken );
      if ( facebookIsAccessible )
      {
        onFacebookConnectSuccess( response.id, response.name, accessToken );
      }
      else
      {
        onFacebookConnectFailure(
          { permissionsDenied: true, errorText: "Unable to connect until grant required permissions." },
        );
      }
    }
    else
    {
      onFacebookConnectFailure( { response, errorText: "Facebook connect failed." } );
    }
  }

  function requestConnect()
  {
    logToConsole( "connect facebook instagram started" );
    eventTracker.logAcceptConnectInstagramClicked( props.source );
    if ( !!props.handleConnectionStarted )
    {
      props.handleConnectionStarted();
    }
  }

  function reloadSocialNetworkAccounts()
  {
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
      {
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
      }
    );
  }

  return (
    <FacebookLogin
      appId={getFacebookAppId()}
      className={""}
      autoLoad={false}
      reAuthenticate={true}
      disableMobileRedirect={true}
      scope={facebookPermissionsScope}
      fields="name,email,picture"
      version={facebookServices.GRAPH_API_VERSION}
      render={( renderProps: ReactFacebookRenderProps ) =>
      {
        const { onClick } = renderProps;
        return (
          <>
            <Drawer
              anchor={"bottom"}
              open={drawerOpened}
              onClose={handleCancelConnectionAttempt}
              sx={{ textAlign: 'center', m: 1, zIndex: 1500 }}
            >
              {!confirmingInstagram && <ConfirmFacebookAccount profileUrl={profileUrl}
                                                               profileName={profileName}
                                                               onSwitchAccounts={onSwitchAccounts}
                                                               onConfirm={onConfirm}
                                                               showLoader={showLoader}/>}

              {confirmingInstagram && <ConfirmInstagramAccount handleCancel={handleCancelConfirmingInstagram}
                                                               handleSelectionComplete={handleFinishedConfirmingInstagram}
                                                               isInitialConnect={true}/>}
            </Drawer>
            {!fromSettings && <ShareDestinationToggle icon={<InstagramIcon sx={{ width: 30, height: 30, mt: 3, color: "black" }}/>}
                                                      label={"Connect an Instagram Account*"}
                                                      onClick={onClick}
                                                      checked={false}
            />
            }
            {
              fromSettings && <Stack
                             sx={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                             <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                               <InstagramIcon sx={{ width: 30, height: 30, color: "black" }}/>
                               <Typography>Instagram*</Typography>
                             </Stack>
                             <Button variant={"contained"} onClick={onClick} sx={{ minWidth: "100px" }}>Connect</Button>
                           </Stack>
            }
          </>)
          ;
      }}
      onClick={handleClick}
      callback={handleFacebookResponse}/>
  )

  function handleCancelConfirmingInstagram()
  {
    eventTracker.logInstagramConnectFailed( props.source, "user cancelled confirming instagram account to use" );
    reloadSocialNetworkAccounts();
    closeDrawer();
    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  function handleFinishedConfirmingInstagram()
  {
    reloadSocialNetworkAccounts();
    instagramConnectionComplete();
    closeDrawer();
  }

  function handleCancelConnectionAttempt( failureData?: {} )
  {
    onFacebookConnectFailure( failureData || { errorText: "Connecting an Instagram was canceled." } );
    closeDrawer();
  }

  function closeDrawer()
  {
    setDrawerOpened( false );
  }

  function openDrawer()
  {
    setDrawerOpened( true );
  }

}
