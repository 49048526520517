import * as React from "react";
import { Box, SxProps } from "@mui/material";
import SparkleField from "../../assets/sparkle_field.png";
import { merge } from "lodash";

export interface SparkleFieldBackgroundProps
{
  sx?: SxProps;
}

export function SparkleFieldBackground( props: SparkleFieldBackgroundProps )
{
  const defaultSx = {
    background: `url(${SparkleField}) 700px center repeat`,
    backgroundSize: "1400px",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  };

  return (
    <Box sx={merge( defaultSx, props.sx )}/>
  )
}
