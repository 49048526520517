import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "../../assets/settings.png";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FontSetAPI } from "../../services/fontSet.services";
import { FontSetButton } from "../editing/fonts/fontSetButton";

export interface BrandFontSetButtonProps
{
  fontSet?: FontSetAPI;
  handleFontSetPickerClicked: () => void;
}

export default function BrandFontSetButton( props: BrandFontSetButtonProps )
{

  return (
    <Stack alignItems="center" sx={{ mb: 4, mr: 5 }}>
      <Box
        sx={{
          minWidth: "90px",
          height: "90px",
          border: "1px solid #9D9D9D",
          borderRadius: "5px",
          p: 4,
          position: "relative",
          cursor: "pointer",
          backgroundColor: "white",
        }}
        onClick={props.handleFontSetPickerClicked}
      >
        {
          props.fontSet &&
          <>
            <Stack alignItems="center" justifyContent="center" height="100%" sx={{ pointerEvents: "none" }}>
              <FontSetButton
                             fontSet={props.fontSet}
                             selected={false}
                             handleFontSetSelected={() =>
                             {
                             }}/>
            </Stack>
            <IconButton sx={{ position: "absolute", right: 0, top: 0, p: 1, color: "white" }}>
              <Box component="img" src={SettingsIcon} alt="edit colors"/>
            </IconButton>
          </>
        }

        {!props.fontSet &&
         <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
           <IconButton>
             <AddCircleOutlineIcon sx={{ color: "#BBBBBB" }}/>
           </IconButton>
         </Stack>
        }
      </Box>
      <Typography variant="subtitle2" sx={{ mt: 2 }}>Fonts</Typography>
    </Stack>
  );
}