import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { SettingsSuggest } from "@mui/icons-material";

export interface BrandKitSettingsSectionProps
{
  handleModifyBrandSettingsClicked: () => void;
}

export function BrandKitSettingsSection( props: BrandKitSettingsSectionProps )
{
  return (
    <Stack sx={{ mb: 4 }}>
      <Stack direction="row" alignItems="center">
        <SettingsSuggest sx={{ mr: 6 }}/>
        <Typography variant="h6">Settings</Typography>
      </Stack>
      <Typography variant="subtitle1" sx={{mb: 10}}>Default settings for posts and brand card</Typography>
      <Button variant="outlined" onClick={props.handleModifyBrandSettingsClicked}>
        Modify brand settings
      </Button>
    </Stack>)
}
