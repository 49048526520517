import { TextField } from "@mui/material";
import { SIGN_IN_PROVIDER_EMAIL } from "../constants";
import React, { useState } from "react";
import validator from "validator";

export interface InputProps
{
  handleInputChanged( value: string, valid: boolean ): void;
}

export function EmailInput( props: InputProps )
{
  const [emailAddress, setEmailAddress] = useState<string>( "" );
  const [emailAddressError, setEmailAddressError] = useState( false );

  const handleEmailChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    setEmailAddress( event.target.value );
    const emailIsValid = validator.isEmail( event.target.value );
    setEmailAddressError( !emailIsValid );
    props.handleInputChanged( event.target.value, emailIsValid );
  }

  return <TextField
    fullWidth
    name={SIGN_IN_PROVIDER_EMAIL}
    placeholder="Your email address"
    inputProps={{
      autoCapitalize: 'off',
      autoCorrect: 'off'
    }}
    value={emailAddress}
    error={emailAddressError}
    onChange={handleEmailChange}
    helperText={emailAddressError ? "Enter a valid email address" : null}/>

}