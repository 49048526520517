import { createSlice } from '@reduxjs/toolkit'
import { FEATURE_FLAG_DEV_MODE } from "../constants";
import { RootState } from "../../app/store";

type FeatureFlagKey = typeof FEATURE_FLAG_DEV_MODE;

type FeatureFlagsState =
  {
    [K in FeatureFlagKey]?: boolean | string
  }

const initialState: FeatureFlagsState = {
  [`${FEATURE_FLAG_DEV_MODE}`]: false,
}

export function isDevModeEnabled( state: RootState )
{
  return isBooleanFeatureEnabled( state, FEATURE_FLAG_DEV_MODE )
}

export function isBooleanFeatureEnabled( state: RootState, booleanFeatureFlag: FeatureFlagKey )
{
  return !!state.featureFlags[`${booleanFeatureFlag}`];
}

export const featureFlagsSlice = createSlice( {
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlagBoolean: ( state, action ) =>
    {
      return {
        ...state,
        ...action.payload
      }
    },
  },
} )

// Action creators are generated for each case reducer function
export const {
  setFeatureFlagBoolean
} = featureFlagsSlice.actions

export default featureFlagsSlice.reducer
