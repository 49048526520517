import { PLAN_SUGGESTION_STATUS_QUEUED, PlannerDateAPI, PostingPlanDateAPI } from "../../services/business.services";
import { filter, flatten, map, size } from "lodash";
import { isAfter, parseISO } from "date-fns";

export class PlannerDateAPIUtils
{
  static getFutureDates( dates: PlannerDateAPI[] )
  {
    return filter( dates, ( date ) => isAfter( parseISO( date.date_with_timezone ), new Date() ) );
  }

  static futureDatesWithDraftsOrPostingPlanDates( dates: PlannerDateAPI[] )
  {
    const futureDates: PlannerDateAPI[] = PlannerDateAPIUtils.getFutureDates( dates );
    return filter( futureDates, ( date ) => size(date.suggestions) > 0 || size(date.posting_plan_dates) > 0 );
  }

  static futurePostingPlanDatesWithoutDrafts( dates: PlannerDateAPI[] ): PostingPlanDateAPI[]
  {
    const futureDates: PlannerDateAPI[] = PlannerDateAPIUtils.getFutureDates( dates );

    return flatten(map( futureDates, ( date ) => PlannerDateAPIUtils.doesPostingPlanDateNotHaveDraftedPosts(date) ));
  }

  static doesPostingPlanDateNotHaveDraftedPosts( plannerDateAPI: PlannerDateAPI )
  {
    return filter( plannerDateAPI.posting_plan_dates, ( postingPlanDate ) =>
    {
      if ( !postingPlanDate.plan_suggestion )
      {
        return true;
      }
      return !!postingPlanDate.plan_suggestion && postingPlanDate.plan_suggestion.status !== PLAN_SUGGESTION_STATUS_QUEUED
             && !postingPlanDate.plan_suggestion.post_idea_id
    } );
  }

  static futureDatesWithCompletedPosts( dates: PlannerDateAPI[] )
  {
    const futureDates: PlannerDateAPI[] = PlannerDateAPIUtils.getFutureDates( dates );
    return filter( futureDates, ( date ) => date.share_batches.length > 0 );
  }

}