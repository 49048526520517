import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BusinessMediaStyleAPI } from "../../services/business.services";
import { ROUTES } from "../constants";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import { PostSuccessSafeArea } from "../planner/postSuccessSafeArea";
import { chain, map } from "lodash";
import { SparkleFieldBackground } from "../planner/sparkleFieldBackground";
import { eventTracker } from "../../helpers/eventTracker";

export interface StyleQuizSuccessDialogProps extends WithFullScreenDialogProps
{
  mediaStyles: BusinessMediaStyleAPI[];
}

export function StyleQuizSuccessDialog( props: StyleQuizSuccessDialogProps )
{
  const navigateWithSearchParams = useNavigateWithSearchParams();

  useEffect( () =>
  {
    const mediaStylePresetNames = map( props.mediaStyles, ( mediaStyle ) => mediaStyle.preset_name );
    const topMediaStylePresetNames = chain( props.mediaStyles ).map( ( mediaStyle ) => mediaStyle.active ? mediaStyle.preset_name : undefined )
      .compact().value();
    eventTracker.logMediaStyleQuizResultsShown( mediaStylePresetNames, topMediaStylePresetNames );
  }, [] );

  function backToBrandKit()
  {
    navigateWithSearchParams( ROUTES.BRANDING );
  }

  return (
    <FullPageMobileContainer
      sx={{ p: 10 }}>
      <SparkleFieldBackground sx={{ filter: "invert(30%)" }}/>
      <PostSuccessSafeArea>
        <Typography sx={{
          fontSize: "36px",
          lineHeight: "40px",
          fontFamily: "Montserrat",
          fontWeight: 900,
          color: "primary.main",
          mb: 2,
          width: "250px",
          textAlign: "center"
        }}>The results are in!</Typography>
        <Typography variant="subtitle1"
                    sx={{ fontWeight: 600, fontSize: "14px", lineHeight: "18px", fontFamily: "Montserrat", color: "primary.main", mt: 4 }}>Your style
                                                                                                                                           matches
                                                                                                                                           are:</Typography>
        <Stack spacing={4} sx={{ mt: 8 }}>
          {map( props.mediaStyles, ( mediaStyle, idx ) =>
          {
            return <Stack direction="row" key={mediaStyle.id} alignItems="center">
              <Typography sx={{ width: "24px", fontSize: "12px" }}>{mediaStyle.active ? "✅" : ""}</Typography>
              <Typography variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontFamily: "Montserrat",
                            color: "primary.main"
                          }}>{`${idx
                                 + 1}. ${mediaStyle.display_name}`}</Typography>
            </Stack>;
          } )}
        </Stack>

        <Typography variant="subtitle2"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "18px",
                      fontFamily: "Montserrat",
                      color: "primary.main",
                      width: "200px",
                      my: 4,
                      textAlign: "center",
                    }}>The top 5
                       will be used
                       for your
                       future
                       posts!</Typography>

        <Typography variant="caption"
                    sx={{ fontSize: "12px", lineHeight: "14px", fontFamily: "Montserrat", color: "#838383", my: 4, width: "250px" }}>Add and remove
                                                                                                                                     individual
                                                                                                                                     styles from
                                                                                                                                     your
                                                                                                                                     account in
                                                                                                                                     Brand
                                                                                                                                     Settings.</Typography>

        <Button variant="contained" onClick={backToBrandKit}>Awesome!</Button>
      </PostSuccessSafeArea>
    </FullPageMobileContainer>
  )
}

export const StyleQuizSuccessDialogFullScreen = withFullScreenDialog( StyleQuizSuccessDialog )