import { Box, MenuItemProps, Stack, Typography } from "@mui/material";
import React from "react";
import { outputFormatHelper } from "./outputFormatHelper";
import { map, merge } from "lodash";
import {
  OUTPUT_FORMAT_BEST_FOR_FEED_ICON_NAME,
  OUTPUT_FORMAT_BEST_FOR_REELS_ICON_NAME,
  OUTPUT_FORMAT_BEST_FOR_STORIES_ICON_NAME
} from "../constants";

interface OutputFormatSelectItemProps extends MenuItemProps
{
  outputFormatSlug: string;
}

export function OutputFormatSelectItem( props: OutputFormatSelectItemProps )
{
  function getIconOrLabelForIconName( iconName: string )
  {
    if ( iconName === OUTPUT_FORMAT_BEST_FOR_FEED_ICON_NAME )
    {
      return <Typography key="feed" variant="caption">Feed</Typography>
    }
    else if ( iconName === OUTPUT_FORMAT_BEST_FOR_REELS_ICON_NAME )
    {
      return <Typography key="reels" variant="caption">Reels</Typography>
    }
    else if ( iconName === OUTPUT_FORMAT_BEST_FOR_STORIES_ICON_NAME )
    {
      return <Typography key="stories" variant="caption">Stories</Typography>
    }
    return <Box component="img" key={iconName} sx={{ width: outputFormatHelper.getSocialIconWidth(iconName) }} src={outputFormatHelper.getSocialIcon( iconName )}></Box>
  }

  return (<Stack direction="row" sx={{ mt: 4, alignItems: "flexStart", py: 4 }}>
    <Box sx={{ width: "75px", textAlign: "center" }}>
      <Box sx={merge( { mr: 4 }, outputFormatHelper.getLargeSxProps( props.outputFormatSlug ) )} component="img"
           src={outputFormatHelper.getIcon( props.outputFormatSlug )}/>
    </Box>
    <Stack textAlign="left" sx={{ mr: 4 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{outputFormatHelper.getDisplayName( props.outputFormatSlug )}</Typography>
      <Typography variant="caption">{outputFormatHelper.getDisplayResolution( props.outputFormatSlug )}</Typography>
    </Stack>
    <Stack textAlign="left" sx={{ flex: "1 1 auto", background: "white", border: "1px solid lightgray", p: 4 }}>
      <Typography variant="caption" sx={{ lineHeight: 1, textDecoration: "underline", fontStyle: "italic" }}>Recommended for:</Typography>
      <Stack direction="row" justifyContent="center" spacing={2} sx={{ my: 2 }}>
        {
          map( outputFormatHelper.getBestForIconsLine1( props.outputFormatSlug ), ( iconName ) =>
          {
            return getIconOrLabelForIconName( iconName );
          } )
        }
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={2}>
        {
          map( outputFormatHelper.getBestForIconsLine2( props.outputFormatSlug ), ( iconName ) =>
          {
            return getIconOrLabelForIconName( iconName );
          } )
        }
      </Stack>
    </Stack>
  </Stack>);
}
