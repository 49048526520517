import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { map } from "lodash";
import { CuratedMediaSetAPI } from "../../services/curatedMediaSet.services";
import { CuratedMediaSetPreview } from "./curatedMediaSetPreview";

interface CuratedMediaSetsCarouselProps
{
  curatedMediaSets: CuratedMediaSetAPI[];
  handleCuratedMediaSetClicked: ( curatedMediaSetSlug: string ) => void;
}

export function CuratedMediaSetsCarousel( props: CuratedMediaSetsCarouselProps )
{
  return <Box sx={{
    my: 5,
    mx: "auto",
    textAlign: "center",
    maxWidth: "350px",
  }}>
    <Typography variant={"subtitle1"}>Seasonal shortcuts</Typography>
    <Typography sx={{ fontSize: "12px" }}>Save time by picking stock images and videos from curated sets</Typography>
    <Stack spacing={5} direction={"row"} sx={{
      alignItems: "flexStart",
      overflow: "auto",
      my: 5,
      px: 5,
    }}>
      {
        map( props.curatedMediaSets, ( curatedMediaSet, index ) =>
        {
          return <CuratedMediaSetPreview curatedMediaSet={curatedMediaSet} key={curatedMediaSet.name}
                                         handleCuratedMediaSetClicked={props.handleCuratedMediaSetClicked}/>
        } )
      }
    </Stack>
  </Box>
}
