import { SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { merge } from "lodash";

interface PurchaseFlowBenefitTextProps
{
  children: ReactNode;
  small?: boolean;
  sx?: SxProps;
}

export function PurchaseFlowBenefitText( props: PurchaseFlowBenefitTextProps )
{
  const fontSize = props.small ? "13px" : "15px";
  const lineHeight = props.small ? "15px" : "18px";
  return (
    <Typography variant={"body1"}
                sx={merge( {
                  color: "primary.contrastText",
                  width: 230,
                  wordWrap: "break-word",
                  fontFamily: "Montserrat",
                  fontSize,
                  lineHeight
                }, props.sx )}>{props.children}</Typography>
  )
}