import { Button, Link, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { useDispatch } from "react-redux";
import { setConversationLastYieldToDrawerTime } from "../ui/uiSlice";
import { MAX_NUMBER_OF_MEDIA, MAX_NUMBER_OF_MEDIA_ERROR, MediaAssetSource, MediaPickingMode, NOT_NOW } from "../constants";
import { map, take, uniqueId } from "lodash";
import { MediaPickerFullScreenDialog } from "../mediaPicker/mediaPicker";
import { UrlUtils } from "../utils/urlUtils";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { MediaAssetAPI } from "../../services/business.services";
import { maxMediaReachedForUrls } from "../postIdea/postDataHelper";
import { eventTracker } from "../../helpers/eventTracker";
import { MediaSelection } from "../assistantChat/assistantChatSlice";
import { logToConsoleError } from "../utils/devLoggingHelper";

export interface AssistantChooseMediaProps
{
  onComplete?( selectedMedia: MediaSelection[] ): void;
  handleGoBack?(): void;
  onChangeSelection?( selectedMedia: MediaSelection[] ): void;
}

export function AssistantChooseMedia( props: AssistantChooseMediaProps )
{
  const dispatch = useDispatch();
  const [loading, setLoading] = useState( false );
  const [showMediaPicker, setShowMediaPicker] = useState<boolean>( false );

  useEffect( () =>
  {
    eventTracker.logOwnerChooseMediaUIShown();
  }, [] );

  async function handleSelectedMediaUrl( mediaUrl: string, mediaSource: MediaAssetSource )
  {
    const mediaSelections = [createMediaSelectionWithTemporaryIdentifier( mediaUrl, mediaSource )];
    await addMediaSelectionsClampedToMax( mediaSelections );
  }

  function createMediaSelectionWithTemporaryIdentifier( mediaUrl: string, mediaSource: MediaAssetSource )
  {
    const urlUtil = new UrlUtils();

    const temporaryIdentifier = uniqueId();
    const mediaSelection = {
      id: temporaryIdentifier,
      url: mediaUrl,
      isVideo: urlUtil.isVideoUrl( mediaUrl ),
      source: mediaSource
    }
    return mediaSelection;
  }

  async function handleSelectedMediaAssets( mediaAssets: MediaAssetAPI[] )
  {
    const mediaSelections: MediaSelection[] = map( mediaAssets, ( mediaAsset ) =>
    {
      return createMediaSelectionWithTemporaryIdentifier( mediaAsset.url, mediaAsset.source );
    } );

    await addMediaSelectionsClampedToMax( mediaSelections );
  }

  const addMediaSelectionsClampedToMax = async ( mediaSelections: MediaSelection[] ) =>
  {
    let resultingMedia = mediaSelections;
    const potentialUrls = map( resultingMedia, ( media ) => media.url );
    if ( maxMediaReachedForUrls( potentialUrls ) )
    {
      resultingMedia = take( resultingMedia, MAX_NUMBER_OF_MEDIA );
      dispatch( setAlertMessage( errorAlert( MAX_NUMBER_OF_MEDIA_ERROR ) ) );
    }
    eventTracker.logMakeAPostUploadMedia( resultingMedia );

    if ( props.onChangeSelection )
    {
      props.onChangeSelection( resultingMedia );
    }
    dispatch( setConversationLastYieldToDrawerTime( new Date().getTime() ) );
    await requestNewPostIdea( resultingMedia );
  }

  const handleAddMediaButtonClicked = () =>
  {
    setShowMediaPicker( true );
  }

  const handleClosedMediaPicker = () =>
  {
    setShowMediaPicker( false );
  }

  async function requestNewPostIdea( mediaSelections: MediaSelection[] )
  {
    setLoading( true )
    try
    {
      if ( props.onComplete )
      {
        props.onComplete( mediaSelections );
      }
    }
    catch (error)
    {
      logToConsoleError( "failed when generating new idea", error );
      dispatch( setAlertMessage( errorAlert( "Failed to finish choosing media, please try again." ) ) );
    }

    setLoading( false );
  }

  return (
    <>
      <Stack>
        <Button variant="contained" onClick={handleAddMediaButtonClicked} sx={{ m: "0 auto" }}>
          <Stack alignItems="center" flexDirection="row">
            <AddAPhotoOutlinedIcon sx={{ mr: 4 }}/>
            Choose media
          </Stack>
        </Button>
        {loading && <ProgressOverlay/>}
      </Stack>
      <Link sx={{ my: 4 }} component="button" variant="body2" onClick={props.handleGoBack}>{NOT_NOW}</Link>
      <MediaPickerFullScreenDialog title="Add media"
                                   manageOpenStateExternally={true}
                                   externalStateOpen={showMediaPicker}
                                   handleClose={handleClosedMediaPicker}
                                   processSelectedMediaUrl={handleSelectedMediaUrl}
                                   processSelectedMediaAssets={handleSelectedMediaAssets}
                                   currentMediaUrls={[]}
                                   hideTopBar={true}
                                   mediaPickingMode={MediaPickingMode.Overwrite}/>
    </>
  );

}
