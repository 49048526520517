import { Box } from "@mui/material";
import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";
import { PostEditingDrawerHorizontalList } from "./postEditingDrawerHorizontalList";
import { BOTTOM_NAV_SCROLLABLE_CONTENT_HEIGHT_TWO_ROWS } from "../../constants";
import { isNil } from "lodash";
import "./drawerAnimations.scss";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export interface PostEditingScrollableContainerProps
{
  children: ReactNode;
  height?: number;
  showLoader: boolean;
  disableSelection?: boolean;
  lastTimeScrollToSelected: number;
  lastTimeRemoveScrollHint: number;
  lastTimePreventScrollHintAnimation?: number;
}

export function PostEditingDrawerScrollableContainer( props: PostEditingScrollableContainerProps )
{
  const scrollContentClass = useRef( "scrollHintAnimation" );

  const scrollContainerDivRef = useRef<HTMLDivElement>();
  const scrollContentDivRef = useRef<HTMLDivElement>();

  useEffect( () =>
  {
    const current = scrollContainerDivRef.current;
    if ( current )
    {
      current.addEventListener( "scroll", removeAnimations, false )
    }
  }, [] );

  useEffect( () =>
  {
    setTimeout( () =>
    {
      if ( props.lastTimeScrollToSelected !== 0 )
      {
        const scrollContentDiv = scrollContentDivRef.current;
        let contentScrollWidth = scrollContentDiv?.scrollWidth;
        let containerOffsetWidth = scrollContainerDivRef.current?.offsetWidth;
        if ( contentScrollWidth && containerOffsetWidth )
        {
          contentScrollWidth <= containerOffsetWidth && removeScrollHintAnimation();
        }
        scrollToSelected();
      }
    }, 300 )
  }, [props.lastTimeScrollToSelected] );

  useEffect( () =>
  {
    if ( !isNil( props.lastTimeScrollToSelected ) && props.lastTimeScrollToSelected !== 0 )
    {
      removeAnimations();
    }
  }, [props.lastTimeRemoveScrollHint] );

  useEffect( () =>
  {
    if ( !isNil( props.lastTimePreventScrollHintAnimation ) && props.lastTimePreventScrollHintAnimation !== 0 )
    {
      preventScrollHintAnimation();
    }
  }, [props.lastTimePreventScrollHintAnimation] );

  const removeAnimations = () =>
  {
    removeScrollHintAnimation();
  }

  const scrollToSelected = () =>
  {
    const scrollContentDiv = scrollContentDivRef.current;
    if ( scrollContentDiv )
    {
      const elementsByClassName = scrollContentDiv.getElementsByClassName( "selected" );
      if ( elementsByClassName && elementsByClassName.length > 0 )
      {
        elementsByClassName[0].scrollIntoView( { behavior: "smooth", block: "center" } );
      }
    }
  }

  const removeScrollHintAnimation = () =>
  {
    scrollContentDivRef.current?.classList.remove( "scrollHintAnimation" );
  }

  const preventScrollHintAnimation = () =>
  {
    scrollContentClass.current = "";
  }

  return (
    <Box ref={scrollContainerDivRef}
         sx={{ height: props.height || BOTTOM_NAV_SCROLLABLE_CONTENT_HEIGHT_TWO_ROWS, overflowX: "auto", overflowY: "none", margin: "auto" }}>
      {props.disableSelection && <Backdrop invisible={true}
                                     sx={{ position: 'absolute', top: '0', bottom: '0', zIndex: ( theme ) => theme.zIndex.drawer + 1 }}
                                     open/>}
      {props.showLoader && <Box justifyContent="center" alignItems="center"><CircularProgress
        sx={{ position: "absolute", width: "100%", top: "32%", left: "calc(50% - 20px)" }}/></Box>}
      <PostEditingDrawerHorizontalList scrollContentDivRef={scrollContentDivRef} scrollContentClass={scrollContentClass}>
        {!props.showLoader && props.children}
      </PostEditingDrawerHorizontalList>
    </Box>
  );
}
