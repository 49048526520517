import { Checkbox, FormControlLabel, Link, Stack } from "@mui/material";
import React from "react";
import { BusinessMediaStyleAPI } from "../../services/business.services";
import BusinessMediaStylePreviewDialog from "./mediaStylePreviewDialog";
import { eventTracker } from "../../helpers/eventTracker";

export interface MediaStyleCheckboxRowProps
{
  mediaStyle: BusinessMediaStyleAPI;
  handleChange: ( event: React.ChangeEvent<HTMLInputElement> ) => void;
}

export function MediaStyleCheckboxRow( props: MediaStyleCheckboxRowProps )
{
  const [open, setOpen] = React.useState( false );

  function handlePreviewClick()
  {
    eventTracker.logMediaStylePreviewed( props.mediaStyle.preset_name );
    setOpen( true );
  }

  function handleClose()
  {
    setOpen( false );
  }

  return (
    <Stack key={props.mediaStyle.id} direction="row" alignItems="center">
      <FormControlLabel
        control={
          <Checkbox checked={props.mediaStyle.active}
                    onChange={props.handleChange}
                    id={`${props.mediaStyle.id}`}/>
        }
        label={props.mediaStyle.display_name}
        sx={{ mr: 2 }}
      />
      <Link sx={{ cursor: "pointer" }} onClick={handlePreviewClick}>(preview)</Link>
      {open && <BusinessMediaStylePreviewDialog mediaStyle={props.mediaStyle} open={open} onClose={handleClose}/>}
    </Stack>);
}
