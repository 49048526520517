import React from "react";
import { Box, Stack, SxProps } from "@mui/material";
import { map, range } from "lodash";
import { QuizBreadCrumbDot } from "./quizBreadCrumbDot";

export interface QuizBreadCrumbsProps
{
  currentQuestionIndex: number;
  totalQuestions: number;
}

export function QuizBreadCrumbs( props: QuizBreadCrumbsProps )
{
  const line: SxProps = { width: 18, height: "2px", border: "1px solid darkgray" };
  const spacing = 3;
  return (<Stack direction="row" alignItems="center" justifyContent="center" spacing={spacing}>
    {map( range( props.totalQuestions - 1 ), ( breadCrumbIndex ) =>
    {
      return <Stack direction="row" key={breadCrumbIndex} alignItems="center" spacing={spacing}>
        <QuizBreadCrumbDot breadCrumbIndex={breadCrumbIndex} currentQuestionIndex={props.currentQuestionIndex}/>
        <Box sx={line}/>
      </Stack>
    } )}
    <QuizBreadCrumbDot breadCrumbIndex={props.totalQuestions - 1} currentQuestionIndex={props.currentQuestionIndex}/>
  </Stack>);
}