import moment from "moment/moment";
import { Stack, Typography } from "@mui/material";
import DayWithPostDot from "../../assets/day-with-post-dot.svg";
import PastDayWithNoPostDot from "../../assets/past-day-with-no-post-dot.svg";
import TodayWithNoPostDot from "../../assets/today-with-no-post-dot.svg";
import FutureDayWithNoPostDot from "../../assets/future-day-with-no-post-dot.svg";
import React from "react";

export interface DailyPostingDotProps
{
  dateString: string;
  hasCompletedPost: boolean;
}

export function DailyPostingDot( props: DailyPostingDotProps )
{
  const date = moment( props.dateString )
  const startOfToday = moment().startOf( 'day' )
  const endOfToday = moment().endOf( 'day' )
  const weekDayInitial = date.format( 'dd' ).charAt( 0 )

  const isPastDay = date < startOfToday
  const isFutureDay = date > endOfToday
  const isToday = !isPastDay && !isFutureDay

  const showPastDayWithNoPostDot = isPastDay && !props.hasCompletedPost;
  const showFutureDayWithNoPostDot = isFutureDay && !props.hasCompletedPost;
  const showTodayWithNoPostDot = isToday && !props.hasCompletedPost;

  const weekDayTextStyle = {
    fontWeight: isToday ? 500 : 400,
    color: ""
  }

  if ( props.hasCompletedPost )
  {
    weekDayTextStyle.color = "planCompleted.main"
  }
  else if ( isPastDay )
  {
    weekDayTextStyle.color = "text.disabled"
  }
  else
  {
    weekDayTextStyle.color = "text.primary"
  }

  return <Stack direction="column">
    {props.hasCompletedPost && <img src={DayWithPostDot} alt="Posted"/>}
    {showPastDayWithNoPostDot && <img src={PastDayWithNoPostDot} alt="Past day no post"/>}
    {showTodayWithNoPostDot && <img src={TodayWithNoPostDot} alt="Today no post"/>}
    {showFutureDayWithNoPostDot && <img src={FutureDayWithNoPostDot} alt="Future day no post"/>}
    <Typography sx={weekDayTextStyle}>{weekDayInitial}</Typography>
  </Stack>
}