import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { APPTIMIZE_INITIALIZATION_MAX_WAIT_MILLIS, ENABLE_APPTIMIZE, ENV_CONFIG } from "../../constants";
import { apptimizeWrapper } from "./apptimizeWrapper";
import { useDispatch, useSelector } from "react-redux";
import { isApptimizeInitialized, setApptimizeInitialized } from "./apptimizeSlice";
import { RootState } from "../../app/store";
import { Stack } from "@mui/material";
import { eventTracker } from "../../helpers/eventTracker";

interface ApptimizeProviderProps
{
  children?: ReactNode;
}

export function ApptimizeProvider( props: ApptimizeProviderProps )
{
  const dispatch = useDispatch();
  const isApptimizeInitializedReady = useSelector( ( state: RootState ) => isApptimizeInitialized( state ) );
  const initializationTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect( () =>
  {
    if ( ENABLE_APPTIMIZE )
    {
      dispatch( setApptimizeInitialized( false ) );

      apptimizeWrapper.setOnApptimizeInitializedCallback( onApptimizeInitialized );
      apptimizeWrapper.setup( ENV_CONFIG.apptimizeKey );

      initializationTimeoutRef.current = setTimeout( onApptimizeInitialized, APPTIMIZE_INITIALIZATION_MAX_WAIT_MILLIS );
    }
    return () =>
    {
      stopInitializationTimeoutTimer();
    }
  }, [] );

  if ( ENABLE_APPTIMIZE && !isApptimizeInitializedReady )
  {
    return (
      <Stack justifyContent="center" alignItems="center" width="100%">
        <CircularProgress/>
      </Stack>
    );
  }
  else
  {
    return <>{props.children}</>;
  }

  function onApptimizeInitialized()
  {
    stopInitializationTimeoutTimer();

    const userProperties = eventTracker.getPropertiesForUser();
    apptimizeWrapper.setCustomAttributes( userProperties );

    forceMetadataUpdateAfterInitialization();
    dispatch( setApptimizeInitialized( true ) );
  }

  function forceMetadataUpdateAfterInitialization()
  {
    apptimizeWrapper.updateApptimizeMetadataOnce();
  }

  function stopInitializationTimeoutTimer()
  {
    const initializationTimer = initializationTimeoutRef.current;
    if ( initializationTimer )
    {
      clearTimeout( initializationTimer );
      initializationTimeoutRef.current = undefined;
    }
  }
}
