import * as React from "react";
import { Box } from "@mui/material";
import AlkaiLogo192 from "../../assets/logo192.png";
import { SxProps } from "@mui/system";
import { merge } from "lodash";

export interface AlkaiLogoProps
{
  size?: number;
  sx?: SxProps,
}

export function AlkaiLogo( props: AlkaiLogoProps )
{
  return (<Box
    component="img"
    sx={merge( {
      height: props.size || 100,
      width: props.size || 100,
      userSelect: "none",
    }, props.sx )}
    alt="Alkai"
    src={AlkaiLogo192}
  />);
}