import { createSlice } from '@reduxjs/toolkit'
import { RecordingStatusAPI } from "../../services/postIdeaServices";
import { includes } from "lodash";

const initialState = {
  application: {
    loading: false,
  },
  recording: {
    recording_status: null,
    recording_phase_progress: 0,
  },
}
export const loadingSlice = createSlice( {
  name: 'loading',
  initialState,
  reducers: {
    setApplicationLoadingState: ( state, action ) =>
    {
      state.application.loading = action.payload;
    },
    setRecordingState: ( state, action ) =>
    {
      state.recording = action.payload;
    },
    clearRecordingState: ( state ) =>
    {
      return {
        ...state,
        recording: {
          recording_status: null,
          recording_phase_progress: 0,
        }
      }
    }
  },
} )

export const isApplicationLoading = ( state ) => state.loading.application.loading;

export const shouldShowRecordingProgress = ( state ) =>
{
  const status = state.loading.recording.recording_status || null;
  return includes( [RecordingStatusAPI.LOADING, RecordingStatusAPI.RECORDING, RecordingStatusAPI.UPLOADING], status );
}

export const totalRecordingProgress = ( state ) =>
{
  const progress = state.loading.recording.recording_phase_progress || 0;
  const status = state.loading.recording.recording_status || null;

  if ( status === RecordingStatusAPI.LOADING )
  {
    return progress * 10;
  }
  else if ( status === RecordingStatusAPI.RECORDING )
  {
    return 10 + progress * 60;
  }
  else if ( status === RecordingStatusAPI.UPLOADING )
  {
    return 70 + progress * 30;
  }
  else if ( status === RecordingStatusAPI.READY )
  {
    return 100;
  }
  else if ( status === RecordingStatusAPI.FAILED )
  {
    return 0;
  }
  else
  {
    return 0;
  }
}
// Action creators are generated for each case reducer function
export const {
  setApplicationLoadingState,
  setRecordingState,
  clearRecordingState,
} = loadingSlice.actions

export default loadingSlice.reducer
