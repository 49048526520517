import React from 'react'
import "./message.scss";
import Markdown from "markdown-to-jsx";
import { isEmpty } from "lodash";
import { MessageDots } from "./messageDots";

export interface DisplayMessageProps
{
  parsedMessage: string;
  useMarkdownRenderer: boolean;
  renderDotsForEmptyString?: boolean;
}

export function DisplayMessage( props: DisplayMessageProps )
{
  const rootClassName = "displayMessage";
  if ( props.parsedMessage === "" && props.renderDotsForEmptyString )
  {
    return (<div className={rootClassName}><MessageDots/></div>);
  }
  else if ( isEmpty( props.parsedMessage ) )
  {
    return null;
  }
  else if ( props.useMarkdownRenderer )
  {
    return (
      <div className={rootClassName}>
        <Markdown options={{
          enforceAtxHeadings: true, // https://github.com/quantizor/markdown-to-jsx#optionsenforceatxheadings
        }}>{props.parsedMessage}</Markdown>
      </div>
    )
  }
  else
  {
    return (<div className={rootClassName}>
      {props.parsedMessage}
    </div>)
  }
}
