import React from "react";
import { PREVIEW_NAV_ID_NEXT_VARIANT, PREVIEW_NAV_ID_PREVIOUS_VARIANT } from "../constants";
import CaretLeft from "../../assets/CaretLeft.svg";
import CaretRight from "../../assets/CaretRight.svg";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { browserUtils } from "../utils/browserUtils";

interface PostVariationArrowProps
{
  variantDirection: typeof PREVIEW_NAV_ID_PREVIOUS_VARIANT | typeof PREVIEW_NAV_ID_NEXT_VARIANT,
  showLoader: boolean,
}

export function PostVariationArrow( props: PostVariationArrowProps )
{
  let mobileSxProps = {
    display: 'inline-flex',
    padding: '16px 0px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: "20px",
    borderRadius: '10px 0px 0px 10px',
    background: '#3C4EB4',
    zIndex: 10,
  };

  const desktopButtonSxProps = {
    padding: '16px, 24px, 16px, 8px',
    position: 'absolute',
    borderRadius: '10px'
  };

  function isNextVariant()
  {
    return props.variantDirection === PREVIEW_NAV_ID_NEXT_VARIANT;
  }

  function isPreviousVariant()
  {
    return props.variantDirection === PREVIEW_NAV_ID_PREVIOUS_VARIANT;
  }

  if ( isNextVariant() )
  {
    if ( browserUtils.isMobileBrowser() )
    {
      return <Box sx={{
        ...mobileSxProps,
        borderRadius: '10px 0px 0px 10px'
      }}
      ><img alt={"next"} src={CaretRight}/></Box>
    }

    return <LoadingButton aria-label={"next"}
                          variant="contained"
                          loading={props.showLoader}
                          endIcon={<img alt={"next"} src={CaretRight}/>}
                          sx={{
                            ...desktopButtonSxProps,
                            left: '7px',
                          }}
    >NEXT</LoadingButton>;
  }
  else if ( isPreviousVariant() )
  {
    if ( browserUtils.isMobileBrowser() )
    {
      return <Box sx={{
        ...mobileSxProps,
        borderRadius: '0px 10px 10px 0px'
      }}
      ><img alt={"previous"} src={CaretLeft}/></Box>
    }
    return <LoadingButton aria-label={"previous"}
                          variant="contained"
                          loading={props.showLoader}
                          startIcon={<img alt={"previous"} src={CaretLeft}/>}
                          sx={{
                            ...desktopButtonSxProps,
                            right: '7px',
                          }}
    >PREV</LoadingButton>;
  }
  else
  {
    return null;
  }
}
