import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AlertState
{
  message?: AlertMessageAPI,
}

type AlertPosition = 'top' | 'bottom';
export const BEAT_SYNC_ICON_TYPE = 'beat_sync_icon';
type AlertIconType = typeof BEAT_SYNC_ICON_TYPE;

export interface AlertMessageAPI
{
  text: string,
  severity: 'error' | 'warning' | 'info' | 'success',
  position?: AlertPosition,
  showCloseButton?: boolean,
  icon_type?: AlertIconType,
}

const initialState: AlertState = {}

export const alertSlice = createSlice( {
  name: 'alert',
  initialState,
  reducers: {
    setAlertMessage: ( state, action: PayloadAction<AlertMessageAPI> ) =>
    {
      return {
        ...state,
        message: action.payload,
      }
    },
    clearAlertMessage: ( state ) =>
    {
      return {
        ...initialState
      }
    },
  },
} )

export const errorAlert = ( message: string, position?: AlertPosition, showCloseButton?: boolean ): AlertMessageAPI =>
{
  return { text: message, severity: 'error', position, showCloseButton }
}

export const warningAlert = ( message: string, position?: AlertPosition, showCloseButton?: boolean ): AlertMessageAPI =>
{
  return { text: message, severity: 'warning', position, showCloseButton }
}

export const infoAlert = ( message: string, position?: AlertPosition, showCloseButton?: boolean, icon_type?: AlertIconType ): AlertMessageAPI =>
{
  return { text: message, severity: 'info', position, showCloseButton, icon_type }
}

export const successAlert = ( message: string, position?: AlertPosition, showCloseButton?: boolean ): AlertMessageAPI =>
{
  return { text: message, severity: 'success', position, showCloseButton }
}

// Action creators are generated for each case reducer function
export const {
  setAlertMessage,
  clearAlertMessage,
} = alertSlice.actions

export default alertSlice.reducer
