import * as React from 'react';
import { useEffect, useRef } from 'react';
import { ColorPaletteAPI } from "../../services/colorPalette.services";
import { ColorPaletteDrawer } from "../editing/colors/colorPaletteDrawer";
import { BrandStyleAPI } from "../../services/business.services";
import { EDIT_COLOR_PALETTE_SOURCE_EDIT_BRAND_KIT } from "../../helpers/trackingConstants";

export interface BrandSettingsColorPaletteDrawerProps
{
  brandStyle: BrandStyleAPI;
  handleDrawerClose: () => void;
  applyColorPaletteSlug: ( colorPaletteSlug: string ) => void;
}

export function BrandSettingsColorPaletteDrawer( props: BrandSettingsColorPaletteDrawerProps )
{
  const initialColorPaletteSlug = useRef<string | undefined>( undefined );

  useEffect( () =>
  {
    initialColorPaletteSlug.current = props.brandStyle.color_palette?.slug;
  }, [] );

  function applyColorPalette( colorPalette: ColorPaletteAPI )
  {
    props.applyColorPaletteSlug( colorPalette.slug );
  }

  function handleCancelClicked( selectedColorPaletteSlug?: string )
  {
    if ( initialColorPaletteSlug.current && initialColorPaletteSlug.current !== selectedColorPaletteSlug )
    {
      props.applyColorPaletteSlug( initialColorPaletteSlug.current );
    }
    props.handleDrawerClose();
  }

  return (<ColorPaletteDrawer applyColorPalette={applyColorPalette}
                              selectedColorPaletteSlug={props.brandStyle.color_palette?.slug}
                              handleDrawerClose={props.handleDrawerClose}
                              handleCancelClicked={handleCancelClicked}
                              source={EDIT_COLOR_PALETTE_SOURCE_EDIT_BRAND_KIT}/>)

}
