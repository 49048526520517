import React from 'react'
import { Button, Drawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { SyncAlt } from "@mui/icons-material";
import { SETTINGS_PAGE_SOURCE, SocialNetworkConnectionSourcePageType } from "../../helpers/trackingConstants";

export interface SwitchSocialNetworkAccountButtonProps
{
  component: React.ComponentType<ConnectSocialNetworkAccountDrawerProps>;
  onDrawerClosed: () => void;
  source: SocialNetworkConnectionSourcePageType
}

export interface ConnectSocialNetworkAccountDrawerProps
{
  handleCancel: () => void;
  handleSelectionComplete: () => void;
  isInitialConnect: boolean;
}

export function SwitchSocialNetworkAccountButton( props: SwitchSocialNetworkAccountButtonProps )
{
  const [drawerOpened, setDrawerOpened] = React.useState( false );
  const Component = props.component;
  const fromSettings = props.source === SETTINGS_PAGE_SOURCE;

  function openDrawer()
  {
    setDrawerOpened( true );
  }

  function closeDrawer()
  {
    props.onDrawerClosed();
    setDrawerOpened( false );
  }

  function handleSwitchAccounts()
  {
    openDrawer();
  }

  return (
    <>
      {fromSettings && <Button variant="contained" sx={{ minWidth: "100px" }} onClick={handleSwitchAccounts}>Switch</Button>}
      {!fromSettings && <IconButton sx={{ flexBasis: 40, height: 40, flexGrow: 0, flexShrink: 0 }}
                                    onClick={handleSwitchAccounts}><SyncAlt/></IconButton>}
      <>
        <Drawer
          anchor={"bottom"}
          open={drawerOpened}
          onClose={closeDrawer}
          sx={{ textAlign: 'center', m: 1, zIndex: 1500 }}
        >
          <Component handleCancel={closeDrawer} handleSelectionComplete={closeDrawer} isInitialConnect={false}/>
        </Drawer>
      </>
    </>
  )
}
