import { FontAPI } from "../../../services/font.services";
import { Box, SxProps } from "@mui/material";
import { FONT_OPTION_HEIGHT_PX } from "../../constants";
import { merge } from "lodash";
import { ExternalFontName } from "./externalFontName";

export interface FontOptionProps
{
  font: FontAPI;
  selected: boolean;
  handleSelectFont: ( font: FontAPI ) => void;
  sx?: SxProps;
}

export function FontOption( props: FontOptionProps )
{
  const backgroundColor = props.selected ? "primary.light" : "none";
  const color = props.selected ? "white" : "black";
  const height = FONT_OPTION_HEIGHT_PX;

  function onClick()
  {
    props.handleSelectFont( props.font );
  }

  return (
    <Box sx={merge( { backgroundColor, color, height, display: "flex", justifyContent: "flex-start", alignItems: "center" }, props.sx )}
         onClick={onClick}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        {!!props.font.image_url && <img alt={props.font.font_family} src={props.font.image_url} style={{ height: "100%" }}/>}
        {!props.font.image_url && <ExternalFontName font={props.font}
                                                    sx={{
                                                      justifyContent: "start",
                                                      alignItems: "center",
                                                      textWrap: "nowrap",
                                                      overflow: "clip"
                                                    }}/>}
      </Box>
    </Box>
  );
}