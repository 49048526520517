import * as React from "react";
import { FormControlLabel, Stack, Switch } from "@mui/material";

export interface ShareDestinationToggleProps
{
  checked: boolean,
  onChange?: ( event: React.ChangeEvent<HTMLInputElement>, checked: boolean ) => void,
  onClick?: ( event: React.MouseEvent ) => void,
  label: any
  icon: React.ReactNode
  disabled?: boolean
}

export function ShareDestinationToggle( props: ShareDestinationToggleProps
 )
{
  return <Stack direction={"row"} sx={{ width: 320 }}>
    {props.icon}
    <FormControlLabel value="facebook-page"
                      control={<Switch value="facebook-page-switch" checked={props.checked} onChange={props.onChange}/>}
                      label={props.label}
                      disabled={props.disabled}
                      labelPlacement="start"
                      onClick={props.onClick}
                      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}/>
  </Stack>;
}