import { getToApi, postJsonToApi } from "./requestManager";
import { currentUserBusinessId } from "../features/business/businessSlice";
import { store } from "../app/store";
import { concat, map, without } from "lodash";
import { RequestPresignedUploadAPIResponse, s3UploadServices } from "./s3Upload.services";
import { businessServices } from "./business.services";
import { clearProgressState, setProgressState } from "../features/ui/uiSlice";
import { Font, parse } from "opentype.js";

export const fontServices = {
  getFontList,
  uploadFonts,
  convertToFontFileData,
}

const FONTS_BASE_PATH = "fonts";
const FONT_LIST_ENDPOINT = "list"
const REQUEST_PRESIGNED_UPLOAD_ENDPOINT = "request_presigned_upload";

export interface FontListAPI
{
  fonts: FontAPI[],
  category: FontCategoryAPI,
  next_page?: number,
}

export interface FontAPI
{
  slug: string,
  font_family: string,
  display_name?: string,
  usage?: FontUsageAPI,
  image_url?: string,
  category: FontCategoryAPI,
  status: FontStatusAPI,
  custom_font_url?: string,
}

export enum FontStatusAPI
{
  Live = "live",
  Retired = "retired",
}

export enum FontUsageAPI
{
  Primary = "primary",
  Secondary = "secondary",
}

export enum FontCategoryAPI
{
  Serif = "Serif",
  SansSerif = "Sans-Serif",
  Display = "Display",
  Handwriting = "Handwriting",
  Script = "Script",
  MyFonts = "My Fonts",
}

export interface FontFileData
{
  fontFamily: string;
  file: File;
  displayName: string;
}

async function getFontList( page = 1, categoryFilter?: FontCategoryAPI )
{
  try
  {
    const state = store.getState();
    const endpointUrl = buildUrl( [FONT_LIST_ENDPOINT] );
    return await getToApi<FontListAPI>( endpointUrl, { business_id: currentUserBusinessId( state ), page, category: categoryFilter } );
  }
  catch (error)
  {
    return Promise.reject( "Could not fetch font list" );
  }
}

function buildUrl( pathPieces: string[] )
{
  return concat( [FONTS_BASE_PATH], pathPieces ).join( "/" );
}

async function uploadFonts( fontFiles: FontFileData[] ): Promise<FontAPI>
{
  const fontFile = fontFiles[0];
  store.dispatch( clearProgressState() );
  store.dispatch( setProgressState( { message: "Uploading font..." } ) );
  const endPointUrl = buildUrl( [REQUEST_PRESIGNED_UPLOAD_ENDPOINT] );

  const presignParams = {
    font_family: fontFile.fontFamily,
    file_name: fontFile.file.name
  }
  const presignedUpload: RequestPresignedUploadAPIResponse = await postJsonToApi( endPointUrl, {}, presignParams );
  await s3UploadServices.uploadFileToS3( presignedUpload.url, presignedUpload.fields, fontFile.file );

  return await businessServices.addFontToLibrary( presignedUpload.s3_direct_url, fontFile.fontFamily, fontFile.displayName );
}

async function convertToFontFileData( files: FileList | null ): Promise<FontFileData[]>
{
  const validFileList = await Promise.all( map( files, async ( file ): Promise<FontFileData | undefined> =>
  {

    try
    {
      const font = await loadFontFromFile( file );
      const bestFontFamily = font.names.postScriptName.en || font.names.fontFamily.en;
      const bestDisplayName = font.names.fullName.en || font.names.postScriptName.en || font.names.fontFamily.en;

      return {
        fontFamily: bestFontFamily,
        displayName: bestDisplayName,
        file: file
      }
    }
    catch (error)
    {
      return undefined;
    }
  } ) );

  return without( validFileList, undefined ) as FontFileData[];

}

async function loadFontFromFile( file: File ): Promise<Font>
{
  const readFilePromise = new Promise<ArrayBuffer>( ( resolve, reject ) =>
  {
    const reader = new FileReader();
    reader.onload = () =>
    {
      const result = reader.result as ArrayBuffer;
      resolve( result );
    };
    reader.onerror = () =>
    {
      reject( "error reading font" );
    };
    reader.onabort = () =>
    {
      reject( "error reading font" );
    };
    reader.readAsArrayBuffer( file );
  } );

  const fontFileBuffer = await readFilePromise;
  return parse( fontFileBuffer );
}