import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { stripeServices } from "../../services/stripe.services";
import * as React from "react";
import { useEffect, useState } from "react";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { StripeCheckoutSessionStatus, UPSELL_GRADIENT } from "../constants";
import { authenticationServices } from "../../services/authentication.services";
import { PaymentConfirmation } from "./paymentConfirmation";
import DiamondsBackground from "../../assets/diamonds-bg.svg";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { eventTracker } from "../../helpers/eventTracker";
import { StripeCheckoutForm } from "./StripeCheckoutForm";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isSubscribedUser } from "../user/userSlice";

function PaymentView( props: WithFullScreenDialogProps )
{
  const [clientSecret, setClientSecret] = useState( '' );
  const [checkoutSessionId, setCheckoutSessionId] = useState( "" );
  const [checkoutSessionStatus, setCheckoutSessionStatus] = useState( '' );
  const shouldShowCheckoutForm = checkoutSessionStatus !== StripeCheckoutSessionStatus.Complete;
  const isCheckoutCompleted = checkoutSessionStatus === StripeCheckoutSessionStatus.Complete;
  const [showLoader, setShowLoader] = useState( false );
  const documentVisible = useVisibilityChange();
  const userIsSubscribed = useSelector( ( state: RootState ) => isSubscribedUser( state ) );

  useEffect( () =>
  {
    setShowLoader( true );
    eventTracker.logStripeCheckoutStarted();
    stripeServices.checkout().then( ( data ) =>
    {
      setClientSecret( data.client_secret );
      setCheckoutSessionId( data.session_id );
      eventTracker.logStripeCheckoutSessionCreated( data.session_id );
    } ).catch( ( error ) =>
    {
      eventTracker.logStripeCheckoutFailed( error );
    } )
      .finally( () => setShowLoader( false ) );
  }, [] );

  useEffect( () =>
  {
    if ( documentVisible )
    {
      authenticationServices.refreshUser().then( () =>
      {
        if ( userIsSubscribed )
        {
          eventTracker.logPaymentDialogClosedUserAlreadySubscribed();
          if ( props.handleClose )
          {
            props.handleClose();
          }
        }
      } );
    }
  }, [documentVisible] );

  // Create a Checkout Session as soon as the page loads
  const handleCheckoutComplete = async () =>
  {
    setTimeout( updateCheckoutSessionStatus, 2000 );
  }

  const updateCheckoutSessionStatus = async () =>
  {
    const data = await stripeServices.checkoutSessionStatus( checkoutSessionId );

    if ( data.status === StripeCheckoutSessionStatus.Complete )
    {
      await authenticationServices.refreshUser();
    }
    setCheckoutSessionStatus( data.status );
    eventTracker.logStripeCheckoutComplete( checkoutSessionId, data.status );
  }

  const handleCloseCheckout = () =>
  {
    if ( isCheckoutCompleted )
    {
      eventTracker.logPaymentDialogCloseAfterCompleteClicked();
    }
    else
    {
      eventTracker.logPaymentDialogCancelClicked();
    }
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  const confirmationBackground = { backgroundImage: "url(" + DiamondsBackground + ")," + UPSELL_GRADIENT, backgroundSize: "contain" };
  const checkoutFormBackground = { background: "#B54C7C" };
  const background = isCheckoutCompleted ? confirmationBackground : checkoutFormBackground;
  return (
    <FullPageMobileContainer sx={{ ...background }}>
      <IconButton
        aria-label="close"
        size="small"
        onClick={handleCloseCheckout}
        sx={{ flex: "0 0 auto", position: "absolute", left: 8, top: 8, color: "white" }}>
        <CloseIcon fontSize="inherit"/>
      </IconButton>

      {shouldShowCheckoutForm && <StripeCheckoutForm clientSecret={clientSecret}
                                                     onCheckoutComplete={handleCheckoutComplete}/>}
      {isCheckoutCompleted && <PaymentConfirmation handleCloseCheckout={handleCloseCheckout}/>}
      {showLoader && <Box sx={{ display: "flex", width: "100%", height: "100%" }} justifyContent="center"
                          alignItems="center"><CircularProgress sx={{ color: "white" }}/></Box>}
    </FullPageMobileContainer>
  );
}

export const FullScreenPaymentDialog = withFullScreenDialog( PaymentView )

