import * as React from 'react';
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { CalendarIcon } from "@mui/x-date-pickers";

export interface ShareChoiceProps
{
  handleShareNowTapped: () => void;
  handleScheduleTapped: () => void;
}

export function ShareChoice( props: ShareChoiceProps )
{
  return (
    <Box sx={{ position: "relative", p: 3 }}>
      <Box>
        <Typography sx={{ my: 5, textAlign: "center", width: 320}}>Do you want to share now or schedule to auto-publish in the future?</Typography>
        <Divider sx={{ mb: 15 }}/>
        <Stack direction={"row"} spacing={10} justifyContent={"center"} sx={{ width: 320, mx: "auto", my: 10 }}>
          <Button variant={"outlined"} startIcon={<SendIcon/>} onClick={props.handleShareNowTapped}>Share Now</Button>
          <Button variant={"contained"} startIcon={<CalendarIcon/>} onClick={props.handleScheduleTapped}>Schedule</Button>
        </Stack>
      </Box>
    </Box>);
}

