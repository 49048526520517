import { Button, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import LoopIcon from "@mui/icons-material/Loop";
import { MediaItem } from "../mediaList/mediaItem";
import { UrlUtils } from "../utils/urlUtils";

export interface ManageSingleMediaDialogProps
{
  open: boolean;
  handleClose: () => void;
  mediaUrl: string;
  mediaIndex?: number;
  allowDeleteMedia: boolean;
  handleDeleteMediaClicked: ( url: string, index: number ) => void;
  handleReplaceMediaClicked: () => void;
}

export function ManageSingleMediaDialog( props: ManageSingleMediaDialogProps )
{
  const modifiedUrlWithContext = new UrlUtils().modifyUrlWithContextToFixCrossOriginIssues( props.mediaUrl, "mediaList" );
  const buttonPanelJustifyContent = props.allowDeleteMedia ? "space-between" : "flex-end";

  function handleDelete()
  {
    if ( props.mediaIndex !== undefined )
    {
      props.handleDeleteMediaClicked( props.mediaUrl, props.mediaIndex );
    }
  }

  function handleReplace()
  {
    props.handleReplaceMediaClicked();
  }

  return (<Dialog
    fullWidth={false}
    maxWidth={"sm"}
    open={props.open}
  >
    <DialogTitle sx={{ pb: 0, pt: 10, textAlign: "center" }}>Manage Media</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={props.handleClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: ( theme ) => theme.palette.grey[500],
      }}
    >
      <CloseIcon/>
    </IconButton>
    <DialogContent sx={{ width: "300px", height: "auto" }}>
      <Stack>
        <MediaItem url={modifiedUrlWithContext} showControls={true}/>
        <Stack direction={"row"} spacing={2} justifyContent={buttonPanelJustifyContent} sx={{ mt: 5 }}>
          {props.allowDeleteMedia && <Button startIcon={<DeleteIcon/>} variant={"outlined"} color={"error"} onClick={handleDelete}>Delete</Button>}
          <Button startIcon={<LoopIcon/>} variant={"contained"} onClick={handleReplace}>Replace</Button>
        </Stack>
      </Stack>
    </DialogContent>
  </Dialog>)
}
