import { Box } from "@mui/material";
import * as React from "react";

export interface FullPageMobileFooterProps
{
  children: React.ReactNode;
}

export function FullPageMobileFooter( props: FullPageMobileFooterProps )
{
  return <Box sx={{ borderBottom: 1, borderColor: 'divider', flexBasis: "1 1 auto" }}>
    {props.children}
  </Box>
}