import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import * as React from "react";
import { useEffect, useState } from "react";
import { ROUTES, UPSELL_GRADIENT } from "../constants";
import { businessServices, RefreshBusinessAPI } from "../../services/business.services";
import {
  FormControl,
  FormControlLabel,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { isEmpty, map } from "lodash";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import AlertBanner from "../alert/alertBanner";
import { BusinessAvatar } from "../businessSwitcher/businessAvatar";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import { businessHelper } from "../../helpers/businessHelper";
import { userHelper } from "../user/userHelper";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { eventTracker } from "../../helpers/eventTracker";

interface SelectFromDisabledBusinessesDialogContentProps extends WithFullScreenDialogProps
{
  handleCloseSelectBusinessAndMakeNewBusiness: () => void;
}

function SelectFromDisabledBusinessesDialogContent( props: SelectFromDisabledBusinessesDialogContentProps )
{
  const RADIO_OPTION_MAKE_NEW_BUSINESS = "makeNewBusiness";
  const RADIO_OPTION_RESTORE_BUSINESS = "restoreBusiness";

  const dispatch = useDispatch();
  const [businesses, setBusinesses] = useState<RefreshBusinessAPI[]>( [] )
  const [confirmButtonLoading, setConfirmButtonLoading] = useState( false );
  const [newBusinessButtonLoading, setNewBusinessButtonLoading] = useState( false );
  const [businessSlugToEnable, setBusinessSlugToEnable] = useState<string>();
  const navigateWithSearchParams = useNavigateWithSearchParams();

  const [radioOptionValue, setRadioOptionValue] = useState<string>( RADIO_OPTION_MAKE_NEW_BUSINESS );

  useEffect( () =>
  {
    if ( props.externalStateOpen )
    {
      onMount()
    }
  }, [props.externalStateOpen] );

  async function onMount()
  {
    eventTracker.logMakeOrRestoreBusinessShown();
    const businessesResponse = await businessServices.listBusinesses( true );
    const disabledBusinesses = businessesResponse.businesses;
    setBusinesses( disabledBusinesses )
  }

  async function handleConfirmRestorePriorBusiness()
  {
    eventTracker.logRestoreBusinessClicked()
    setConfirmButtonLoading( true );

    try
    {
      if ( !!businessSlugToEnable )
      {
        const response = await businessServices.enableBusiness( businessSlugToEnable );
        eventTracker.logStripeUpgradeComplete();
        await userHelper.switchBusinesses( response.business.slug, dispatch );
        navigateWithSearchParams( ROUTES.CHAT );
      }
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "Please try again or contact support" ) ) );
    }
    finally
    {
      setConfirmButtonLoading( false );
    }
  }

  async function handleMakeNewBusiness()
  {
    eventTracker.logMakeNewBusinessClicked();
    setNewBusinessButtonLoading( true );
    props.handleCloseSelectBusinessAndMakeNewBusiness();
    setNewBusinessButtonLoading( false );
  }

  function shouldEnable()
  {
    return !!businessSlugToEnable;
  }

  function handleListItemClick( event: React.MouseEvent<HTMLDivElement, MouseEvent>, businessSlug: string )
  {
    setBusinessSlugToEnable( businessSlug );
  }

  function handleChange( event: React.ChangeEvent<HTMLInputElement>, value: string )
  {
    setRadioOptionValue( value );
    if ( value === RADIO_OPTION_MAKE_NEW_BUSINESS )
    {
      setBusinessSlugToEnable( undefined );
    }
  }

  function restoreBusinessSelected()
  {
    return RADIO_OPTION_RESTORE_BUSINESS === radioOptionValue;
  }

  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <Stack spacing={4} sx={{ display: "flex", alignItems: "center", my: 12, mx: "auto", maxWidth: 360, bgcolor: 'background.paper', p: 10 }}>
        <Typography sx={{ textAlign: "left", color: "#464DE1", fontFamily: "Montserrat", fontWeight: 700, lineHeight: "24px", fontSize: 22 }}
        >Would you like to make a new business or restore an inactive one?</Typography>
        <FormControl>
          <RadioGroup
            defaultValue={RADIO_OPTION_MAKE_NEW_BUSINESS}
            value={radioOptionValue}
            onChange={handleChange}>
            <FormControlLabel value={RADIO_OPTION_MAKE_NEW_BUSINESS} control={<Radio/>} label="Make a new business"/>
            <FormControlLabel value={RADIO_OPTION_RESTORE_BUSINESS} control={<Radio/>} label="Restore an inactive business"/>
          </RadioGroup>
        </FormControl>


        {restoreBusinessSelected() && <Stack width={"100%"}>
          {isEmpty( businesses ) && <CircularProgress/>}
          {!isEmpty( businesses ) && <List dense sx={{ width: '100%' }}>
            {map( businesses, ( business ) =>
            {
              const labelId = `checkbox-list-secondary-label-${business.slug}`;
              return (

                <ListItemButton key={business.slug}
                                selected={businessSlugToEnable === business.slug}
                                onClick={( event ) => handleListItemClick( event, business.slug )}
                                sx={{ "&.Mui-selected ": { border: "2px solid black", borderColor: ( theme ) => "primary.main" } }}>
                  <ListItemAvatar>
                    <BusinessAvatar business={business}/>
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={businessHelper.getBusinessNameToDisplay( business )}/>
                </ListItemButton>
              );
            } )}
          </List>}
        </Stack>}

        {!restoreBusinessSelected() && <LoadingButton variant="contained" color="primary"
                                                      onClick={handleMakeNewBusiness}
                                                      loading={newBusinessButtonLoading || confirmButtonLoading}
                                                      disabled={newBusinessButtonLoading}>
          Confirm
        </LoadingButton>}

        {restoreBusinessSelected() && <LoadingButton variant="contained" color="primary"
                                                     onClick={handleConfirmRestorePriorBusiness}
                                                     loading={newBusinessButtonLoading || confirmButtonLoading}
                                                     disabled={!shouldEnable()}>
          Confirm
        </LoadingButton>}
      </Stack>
      <AlertBanner/>
    </FullPageMobileContainer>
  )
}

export const SelectFromDisabledBusinessesDialog = withFullScreenDialog( SelectFromDisabledBusinessesDialogContent )
