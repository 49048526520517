import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import "./withFullScreenDialog.scss";
import { SxProps } from "@mui/material";

const Transition = React.forwardRef( function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
)
{
  return <Slide direction="up" ref={ref} {...props} children={props.children}/>;
} );

export interface WithFullScreenDialogProps
{
  buttonName?: string;
  handleClose?(): void;
  manageOpenStateExternally?: boolean;
  externalStateOpen?: boolean;
  hideTopBar?: boolean;
  hideBackdrop?: boolean;
  modalDialogSxProps?: SxProps;
}

const withFullScreenDialog = <P extends object>( Component: React.ComponentType<P> ) =>
{
  return ( props: P & WithFullScreenDialogProps ) =>
  {
    const [open, setOpen] = React.useState( false );

    const handleClickOpen = () =>
    {
      setOpen( true );
    };

    const handleClose = () =>
    {
      if ( props.handleClose )
      {
        props.handleClose();
      }
      setOpen( false );
    };

    return (
      <>
        {props.buttonName && <Button variant="outlined" onClick={handleClickOpen}>
          {props.buttonName}
        </Button>}
        <Dialog
          fullScreen
          open={(props.manageOpenStateExternally && props.externalStateOpen) || open}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={props.modalDialogSxProps}
        >
          {!props.hideTopBar && <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close">
                <CloseIcon/>
              </IconButton>
            </Toolbar>
          </AppBar>}
          <Component {...props} handleClose={handleClose}/>
        </Dialog>
      </>
    );
  }
}

export default withFullScreenDialog;
