import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { NoticeAPI } from "./inAppNoticeSlice";
import { ALKAI_FAQ_NEW_TICKET_URL, ROUTES } from "../constants";
import Markdown from "markdown-to-jsx";
import { eventTracker } from "../../helpers/eventTracker";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isUserLoggedIn } from "../user/userSlice";

export interface InAppNoticeBannerProps
{
  notice: NoticeAPI;
}

export function InAppNoticeBanner( props: InAppNoticeBannerProps )
{
  const color = props.notice.color || '#FF0000'
  const location = useLocation();
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );

  function getCustomerSupportLink()
  {
    if ( !props.notice.show_customer_support_contact_info )
    {
      return;
    }

    if ( userIsLoggedIn )
    {
      return <Link sx={csLinkStyling} href={ROUTES.NEW_SUPPORT_TICKET}>Contact support</Link>
    }
    return <Link sx={csLinkStyling} href={ALKAI_FAQ_NEW_TICKET_URL} target="_blank" rel="noopener">Contact support</Link>
  }

  function handleBannerClick()
  {
    eventTracker.logInAppBannerClicked( props.notice, location.pathname );
  }

  const csLinkStyling = {
    fontWeight: "bold",
    fontSize: "14px",
    color: "white",
    marginBottom: "8px",
    marginLeft: "4px"
  };
  return (<Box key={props.notice.id} sx={{
    color: 'primary.contrastText', m: 1,
    borderRadius: "5px",
    backgroundColor: color,
    display: "flex",
    justifyContent: "center",
    p: 4,
    a: {
      fontWeight: 800,
      color: "#fff",
      cursor: "pointer"
    }
  }}
               onClick={handleBannerClick}
               flexDirection="column">
    <Typography variant="body2" component="div">
      {!!props.notice.message && <Markdown options={{
        enforceAtxHeadings: true, // https://github.com/quantizor/markdown-to-jsx#optionsenforceatxheadings
      }}>{props.notice.message}</Markdown>}
    </Typography>
    { getCustomerSupportLink() }
  </Box>);
}