import * as React from 'react';
import { useState } from 'react';
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { Stack, Typography } from "@mui/material";
import { CURATED_MEDIA_SOURCE_MEDIA_ASSET, MediaAssetSource } from "../constants";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import { FullPageMobileHeader } from "../layout/fullPageMobileHeader";
import { FullPageMobileContent } from "../layout/fullPageMobileContent";
import { MediaAssetAPI } from "../../services/business.services";
import { eventTracker } from "../../helpers/eventTracker";
import { useDispatch } from "react-redux";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { setAlertMessage, successAlert } from "../alert/alertSlice";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { CuratedMediaSetGrid } from "./curatedMediaSetGrid";

interface CuratedMediaPickerProps extends WithFullScreenDialogProps
{
  curatedMediaSetSlug: string;
  handleClose?: () => void;
  processSelectedMediaAssets?: ( mediaAssets: MediaAssetAPI[],
                                 mediaAssetSource: MediaAssetSource,
                                 closeAddAfterHandlingSection: boolean,
                                 onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[],
                                                       mediaAssetSource: MediaAssetSource ) => void ) => Promise<void>;
}

export function CuratedMediaPicker( props: CuratedMediaPickerProps )
{
  const dispatch = useDispatch();
  const [processingSelectedMedia, setProcessingSelectedMedia] = useState<boolean>( false );

  function onAddSuccessForStock( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[], mediaAssetSource: MediaAssetSource )
  {
    logEditPostMediaAdded( postIdea, mediaAssets, mediaAssetSource, props.curatedMediaSetSlug );
    dispatch( setAlertMessage( successAlert( `Media added to post`, 'top' ) ) );
  }

  function logEditPostMediaAdded( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[], mediaAssetSource: MediaAssetSource,
                                  curatedCollectionSlug?: string )
  {
    if ( !!postIdea )
    {
      const hasVideo = !!mediaAssets.find( ( asset ) => asset.is_video );
      const hasPhoto = !!mediaAssets.find( ( asset ) => !asset.is_video );
      eventTracker.logEditPostMediaAdded( postIdea, mediaAssetSource, mediaAssets.length, hasVideo, hasPhoto, curatedCollectionSlug );
    }
  }

  async function chooseMediaAndMaybeClose( mediaAssets: MediaAssetAPI[], mediaAssetSource: MediaAssetSource, closeAddAfterHandlingSection: boolean,
                                           onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[],
                                                                 mediaAssetSource: MediaAssetSource ) => void )
  {
    if ( !!mediaAssets && !!props.processSelectedMediaAssets )
    {
      if ( props.processSelectedMediaAssets )
      {
        setProcessingSelectedMedia( true );
        await props.processSelectedMediaAssets( mediaAssets, mediaAssetSource, closeAddAfterHandlingSection, onAddMediaSuccess )
          .finally( () => setProcessingSelectedMedia( false ) );
      }
    }
  }

  const handleBackClicked = () =>
  {
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  async function handleCuratedMediaSelected( mediaAsset: MediaAssetAPI )
  {
    await chooseMediaAndMaybeClose( [mediaAsset], CURATED_MEDIA_SOURCE_MEDIA_ASSET, false, onAddSuccessForStock );
  }

  function handleCloseCuratedMediaSetGrid()
  {
    handleBackClicked();
  }

  function getTitle()
  {
    return "Add media to post";
  }

  return (
    <FullPageMobileContainer>
      <FullPageMobileHeader sx={{ px: 10 }}>
        <Stack>
          <Stack direction="row" alignItems="center">
            <IconButton onClick={handleBackClicked}>
              <ArrowBackIcon/>
            </IconButton>
            <Typography variant="h6" sx={{ lineHeight: "40px" }}>{getTitle()}</Typography>
          </Stack>
        </Stack>
      </FullPageMobileHeader>
      <FullPageMobileContent>
        <CuratedMediaSetGrid curatedMediaSetSlug={props.curatedMediaSetSlug}
                             onClose={handleCloseCuratedMediaSetGrid}
                             onMediaSelected={handleCuratedMediaSelected}/>
        {processingSelectedMedia && <ProgressOverlay/>}
      </FullPageMobileContent>
    </FullPageMobileContainer>
  )
}

export const CuratedMediaPickerFullScreenDialog = withFullScreenDialog( CuratedMediaPicker )
