import { SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { merge } from "lodash";

export interface FinePrintProps
{
  sx?: SxProps;
  children?: ReactNode;
}

export function FinePrint( props: FinePrintProps )
{
  return <Typography variant="caption" sx={merge( { fontSize: 9, lineHeight: "13px" }, props.sx )}>{props.children}</Typography>
}

