import { CONTENT_GOAL_PROPERTIES_BY_ENUM } from "../constants";
import { join } from "lodash";

export const contentGoalHelper = {
  doesClientSupport,
  getDisplayName,
  getDescriptionOfGoal,
  getIcon,
  getSmallSxProps,
}

function doesClientSupport( contentGoalEnum: string )
{
  return !!CONTENT_GOAL_PROPERTIES_BY_ENUM[contentGoalEnum];
}

function getDisplayName( contentGoalEnum: string )
{
  return CONTENT_GOAL_PROPERTIES_BY_ENUM[contentGoalEnum].displayName;
}

function getSmallSxProps( contentGoalEnum: string )
{
  return CONTENT_GOAL_PROPERTIES_BY_ENUM[contentGoalEnum].smallSxProps;
}

function getDescriptionOfGoal( contentGoalEnum: string )
{
  return CONTENT_GOAL_PROPERTIES_BY_ENUM[contentGoalEnum].getDescriptionOfGoal;
}

function getIcon( contentGoalEnum: string )
{
  return join( ["/content_goals", CONTENT_GOAL_PROPERTIES_BY_ENUM[contentGoalEnum].icon_file_name], "/" );
}