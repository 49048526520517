import { Box } from "@mui/material";
import { ReactNode } from "react";

export interface AppContainerProps
{
  children: ReactNode;
}

export function AppContainer( props: AppContainerProps )
{
  return <Box sx={{ p: 0, display: "flex", flexDirection: "column", width: "100%" }}>
    {props.children}
  </Box>
}