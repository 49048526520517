import { Stack } from "@mui/material";
import React from "react";

interface PlanItemCtaFooterContainerProps
{
  children: React.ReactNode;
}

export function PlanItemCtaFooterContainer( props: PlanItemCtaFooterContainerProps )
{
  return <Stack direction="row" justifyContent="flex-end" gap={4} sx={{ mb: 8, mr: 5 }}>
    {props.children}
  </Stack>;
}
