export interface FacebookPageSharingSettings
{
  selectedToShare: boolean,
  readyToShare: boolean,
}

export function getInitialFacebookPageSharingSettings()
{
  return {
    selectedToShare: false,
    readyToShare: true
  }
}