import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "../../app/store";

type CommandFromDesignCanvas =
  "onJavascriptReady"
  | "onInitializationComplete"
  | "onDesignReloadSucceeded"
  | "saveDesignSettings"
  | "saveGlobalSettings"
  | "onSnapImageRecieved"
  | "onRequestEditModelText"
  | "onRequestEditText"
  | "onRequestEditTextObjects"
  | "onRequestPickPhoto"
  | "textObjectsResponse"
  | "onWatermarkTapped"
  | "onReadyForSnap"
  | "updatePageControls"
  | "updateActiveElementControls"
  | "onAnimationComplete"
  | "startedPlayingInputVideo"
  | "stoppedPlayingInputVideo"
  | "updateSlideIndexData"
  | "designJsError"
  | "displayTextStyles"
  | "designElementSelectionChanged"
  | "elementPropertiesResponse"
  | "mediaObjectsResponse"
  | "presetsResponse"
  | "updateCommonInputDataText"
  | "slideSummaryResponse";

export type CommandToDesignCanvas =
  "postBackDesignSettings"
  | "postBackGlobalSettings"
  | "saveAndPostBackAllSettings"
  | "prepareForSnap"
  | "getSnapByMessage"
  | "finishedSnap"
  | "clearSnap"
  | "setRemixInputData"
  | "updateRemixInputData"
  | "templateDataUpdateSucceeded"
  | "templateDataUpdateFailed"
  | "onRequestEditText"
  | "editTextChanged"
  | "requestTextObjectsForActivePage"
  | "requestMediaObjectsForActivePage"
  | "scaleItem"
  | "afterItemScaled"
  | "handleWindowResize"
  | "showNextSlide"
  | "showPreviousSlide"
  | "changeAudioMute"
  | "reloadDesignWithAnimation"
  | "reloadDesignWithoutAnimation"
  | "setFeatureFlags"
  | "handleAddTextField"
  | "duplicateTextItem"
  | "deleteItem"
  | "setSelectedItem"
  | "requestTextStyles"
  | "styleTextItem"
  | "requestElementProperties"
  | "elementPropertyChanged"
  | "replaceMedia"
  | "requestStopAnimation"
  | "requestPresets"
  | "updatePresets"
  | "requestSlideSummary"
  | "handleAddSlide"
  | "handleSelectSlide"
  | "handleRemoveSlide"
  | "moveSlideToIndex";

export interface DesignCanvasMessage
{
  messageName: CommandFromDesignCanvas;
  arguments: any;
  iframeId: string;
}
export enum AspectRatio
{
  Square = "square",
  Portrait = "9:16",
  Landscape = "16:9",
}

export type Post = {
  aspect_ratio: AspectRatio;
  // catalog_version_id: string;
  // content: string;
  global_settings_data: string;
  design_settings_data: string;
  design_slug: string;
  // id: number;
  // input_body_text: string;
  // input_headline_text: string;
  // input_image_url: string;
  // input_media_url_list: string[];
  // input_media_files?: PostInputMediaFile[];
  // output_type: string;
  template_input_data: string;
  post_idea_id: string;
  allow_add_or_remove_media: boolean;
}

export interface PostsState
{
  posts: { [key: string]: Post };
}

const initialState: PostsState = {
  posts: {},
}

export interface PostAPIWrapper
{
  custom_message_for_copy?: string;
  element: PostAPI;
  element_time: string;
  id: number;
  poster_url?: string;
  type: string;
  user_id: string;
  post_idea_id: string;
  allow_add_or_remove_media: boolean;
}

interface PostAPI
{
  content: string;
  aspect_ratio: AspectRatio;
  created_at?: string;
  design_global_data?: string;
  design_settings_data: string;
  design_slug: string;
  gif_url?: string;
  global_settings_data: string;
  input_body_text: string;
  input_headline_text: string;
  input_image_url?: string;
  input_media_url_list: string[];
  output_type: string;
  template_input_data: string;
}

export function convertPost( postWrapper: PostAPIWrapper ): Post
{
  const { element, ...wrapper } = postWrapper;

  const { ...postData } = element;

  const post = {
    ...wrapper,
    ...postData,
  };

  return post;
}

export function getPostForPostIdea( state: RootState, postIdeaId: string )
{
  return state.posts.posts[postIdeaId];
}

export const postsSlice = createSlice( {
  name: 'posts',
  initialState,
  reducers: {
    addPost: ( state, action ) =>
    {
      let postData: Post = convertPost( action.payload );
      state.posts[postData.post_idea_id] = postData;
    },
    clearPosts: () =>
    {
      return {
        ...initialState
      }
    },
  },
} )

// Action creators are generated for each case reducer function
export const {
  addPost,
  clearPosts,
} = postsSlice.actions

export default postsSlice.reducer
