import * as React from "react";
import { Avatar, Box } from "@mui/material";
import facebookConnectedIcon from "../../assets/facebookConnectedIcon.png";
import instagramConnectedIcon from "../../assets/instagramConnectedIcon.png";
import tiktokConnectedIcon from "../../assets/tiktokConnectedIcon.png";
import youtubeConnectedIcon from "../../assets/youtubeConnectedIcon.png";
import { FACEBOOK_INSTAGRAM, FACEBOOK_PAGE, TIKTOK, YOUTUBE } from "../../services/business.services";

export interface SocialAccountProfileIconProps
{
  accountType: string;
  profileImageUrl?: string;
}

export function SocialAccountProfileIcon( props: SocialAccountProfileIconProps )
{
  function getSocialAccountIcon( socialAccountType: string )
  {
    let platformIconStyles = { position: "absolute", top: 15, right: "-20%", height: 18 };
    switch ( socialAccountType )
    {
      case FACEBOOK_PAGE:
        return <Box component="img" src={facebookConnectedIcon} alt="Facebook Icon" sx={platformIconStyles}/>;
      case FACEBOOK_INSTAGRAM:
        return <Box component="img" src={instagramConnectedIcon} alt="Instagram Icon" sx={platformIconStyles}/>;
      case TIKTOK:
        return <Box component="img" src={tiktokConnectedIcon} alt="TikTok Icon" sx={platformIconStyles}/>;
      case YOUTUBE:
        return <Box component="img" src={youtubeConnectedIcon} alt="YouTube Icon" sx={platformIconStyles}/>;
      default:
        return "";
    }
  }

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Avatar sx={{ width: "30px", height: "30px" }} src={props.profileImageUrl} role="presentation"/>
      {getSocialAccountIcon( props.accountType )}
    </Box>
  );
}

